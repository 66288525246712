import { isNotEmpty, isNotMaxLength } from "./validations.utils"

export const getClientDefaultProject = (client) => {
  return [
    {
      code: client.code,
      name: client.name,
      formData: {
        name: client.name,
        code: client.code,
      },
      validations: {
        name: {
          errors: [],
          value: null,
          typeValidations: [isNotEmpty],
        },
        code: {
          errors: [],
          value: null,
          typeValidations: [isNotEmpty, isNotMaxLength],
          validationOptions: { isNotMaxLength: { max: 3 } },
        },
      },
      modules: [
        {
          name: "No dev",
          id: "aux-id-0",
          isDeletable: 0,
          active: 1,
          order: 0,
        },
      ],
    },
  ]
}
