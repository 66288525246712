import { useState } from "react"

/**
 * Hook to save data in local storage
 * @param {string} key
 * @param {*} initialValue
 * @returns
 */
export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error, key, initialValue)
      return initialValue
    }
  })

  function setValue(value) {
    setStoredValue(value)
    localStorage.setItem(key, JSON.stringify(value))
  }

  return [storedValue, setValue]
}
