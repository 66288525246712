import React from 'react'
import PropTypes from 'prop-types'

export default function Label ({ htmlFor, children, className, bold = true }) {
  return <label htmlFor={htmlFor} className={`${className} ${bold && 'font-semibold'} block`}>{children}</label>
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  className: PropTypes.string
}
