import React from "react"
import { Message as PrimeMessage } from "primereact/message"

/**
 * @param {import('primereact/message').MessageProps} props
 */
const SimpleMessage = (props) => {
  return <PrimeMessage {...props} />
}

export default SimpleMessage
