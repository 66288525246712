import React, { useState } from "react"
import LabelWithQuantityBadge from "../../components/LabelWithQuantityBadge.molecule"
import TabPanel from "../../../../components/atoms/tabView/TabPanel.atom"
import TabView from "../../../../components/atoms/tabView/TabView.atom"
import TodoTasks from "./TodoTasks.controller"

const MyTasksContent = () => {
  const [todoTasksLength, setTodoTasksLength] = useState(0)
  const todoTasksTabHeaderTemplate = (
    <LabelWithQuantityBadge
      labelTemplate="Por hacer"
      labelProps={{
        className: `font-mulish text-md clr-text`,
      }}
      containerProps={{
        style: {
          gap: "4px",
        },
      }}
      badgeProps={{
        style: {
          maxHeight: "20px",
          backgroundColor: "var(--primary-color-highlight)",
          lineHeight: "20px",
        },
      }}
      quantity={todoTasksLength}
    />
  )
  const historicTabHeaderTemplate = (
    <div className="flex gap-2">
      <i className="pi pi-history " />
      <span>Histórico</span>
    </div>
  )
  return (
    <TabView>
      <TabPanel className="mis-tareas" header={todoTasksTabHeaderTemplate}>
        <TodoTasks setTodoTasksLength={setTodoTasksLength} />
      </TabPanel>
      <TabPanel className="historico" header={historicTabHeaderTemplate}>
        <i>Silence is golden</i>
      </TabPanel>
    </TabView>
  )
}

export default MyTasksContent
