/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"
import { DateTime } from "luxon"
import {
  generateInitialHoursByProjectAndTeamMemberReport,
  generateHoursByProjectAndTeamMemberReport,
} from "../../../services/report.service"
import useReportFiltersContext from "../../../hooks/contextConsumers/useReportFiltersContext.hook"
import { useSession } from "../../../hooks/auth/useSession.hook"
import ProgressSpinner from "../../../components/atoms/misc/ProgressSpinner.atom"
import TeamHoursConsumptionTable from "./tables/TeamHoursConsumption/TeamHoursConsumptionTable"
import ProjectPicker from "./components/ProjectPicker"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { UNIFIEDDATE_VALUES } from "../../../consts/CompoundRangeDatePicker"
import ResetButton from "./components/ResetButton"
import useToast from "../../../hooks/useToast.hook"

const INITIAL_PERIOD = UNIFIEDDATE_VALUES.RANGEWEEK
const INITIAL_SELECTED_CLIENT = null

const getYearMonth = (date) => date.year * 12 + date.month

const getYearOfDatePeriod = (date) => {
  return {
    startYear: date[0].toFormat("yyyy"),
    endYear: date[1].toFormat("yyyy"),
  }
}

const getDateFormat = (period) => {
  let format

  switch (period) {
    case UNIFIEDDATE_VALUES.RANGEWEEK:
      // eslint-disable-next-line
      format = `wo'/'YY`
      break
    case UNIFIEDDATE_VALUES.RANGEMONTH:
      format = "MMMM"
      break
  }

  return format
}

const disabledWeeksDate = (current, from) => {
  if (from) {
    const currentDate = current.startOf("week")
    const fromDate = from.startOf("week")

    return Math.abs(currentDate.diff(fromDate, "weeks").toObject().weeks) >= 6
  }

  return false
}

const disabledMonthsDate = (current, from) => {
  if (from) {
    const minDate = from.plus({ months: -5 })
    const maxDate = from.plus({ months: 5 })

    return (
      getYearMonth(current) < getYearMonth(minDate) ||
      getYearMonth(current) > getYearMonth(maxDate)
    )
  }

  return false
}

const TeamHoursConsumption = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true)
  const [initialReportData, setInitialReportData] = useState({})
  const [reportData, setReportData] = useState([])
  const {
    teamHoursConsumptionProjects,
    teamHoursConsumptionSelectedProject,
    setTeamHoursConsumptionSelectedProject,
    teamHoursConsumptionPeriod,
    setTeamHoursConsumptionPeriod,
    teamHoursConsumptionDateRange,
    setTeamHoursConsumptionDateRange,
    teamHoursConsumptionExpandedKeys,
    setTeamHoursConsumptionExpandedKeys,
  } = useReportFiltersContext()
  const { setErrorMessage } = useToast()

  const DEBOUNCE_DELAY = 500
  const debounceTimeout = useRef(null)

  useEffect(() => {
    getInitialReportData()
  }, [])

  const getInitialReportData = async () => {
    const reponse = await generateInitialHoursByProjectAndTeamMemberReport()
    if (reponse.success) {
      setInitialReportData({
        data: reponse.result.data,
        totals: reponse.result.totals,
      })
      setReportData({
        data: reponse.result.data,
        totals: reponse.result.totals,
      })
      setLoading(false)
    } else {
      setErrorMessage({
        title: "Error",
        message: reponse.message ?? "No se puede recuperar el formato inicial del reporte.",
      })
    }
  }

  useEffect(() => {
    if (!teamHoursConsumptionSelectedProject) return
    getTeamMemberTasks()
  }, [
    teamHoursConsumptionDateRange,
    teamHoursConsumptionSelectedProject,
    teamHoursConsumptionPeriod,
  ])

  const getTeamMemberTasks = async () => {
    let response
    let startDate
    let startOfWeek
    let endOfWeek
    let endDate
    let ignore = false
    setLoading(true)

    if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
      startOfWeek = teamHoursConsumptionDateRange[0].startOf("week")
      endOfWeek = teamHoursConsumptionDateRange[1].startOf("week")

      if (
        Math.abs(startOfWeek.diff(endOfWeek, "weeks").toObject().weeks) >= 6
      ) {
        ignore = true
        endOfWeek = startOfWeek.plus({ weeks: 5 })
        setTeamHoursConsumptionDateRange([startOfWeek, endOfWeek])
      }
    }

    if (!ignore) {
      if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEMONTH) {
        startDate = teamHoursConsumptionDateRange[0]
          .startOf("month")
          .toISODate()
        endDate = teamHoursConsumptionDateRange[1].endOf("month").toISODate()
      } else if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
        startDate = teamHoursConsumptionDateRange[0].startOf("week").toISODate()
        endDate = teamHoursConsumptionDateRange[1].endOf("week").toISODate()
      }

      const backendPeriod = teamHoursConsumptionPeriod.split("-")[1]

      clearTimeout(debounceTimeout.current)
      debounceTimeout.current = setTimeout(() => {
        response = generateHoursByProjectAndTeamMemberReport(
          startDate,
          endDate,
          backendPeriod,
          teamHoursConsumptionSelectedProject
        ).then((response) => {
          if (response.success) {
            setReportData({
              data: response.result.data,
              totals: response.result.totals,
            })
          } else {
            setErrorMessage({
              title: "Error",
              message: response.message ?? "Hubo un error",
            })
          }
          setLoading(false)
        })
      }, DEBOUNCE_DELAY)
      return () => clearTimeout(debounceTimeout.current)
    }
  }

  const disabledWeeksDate = (current, from) => {
    if (from) {
      const currentDate = current.startOf("week")
      const fromDate = from.startOf("week")

      return Math.abs(currentDate.diff(fromDate, "weeks").toObject().weeks) >= 6
    }

    return false
  }

  const disabledMonthsDate = (current, from) => {
    if (from) {
      const minDate = from.plus({ months: -5 })
      const maxDate = from.plus({ months: 5 })

      return (
        getYearMonth(current) < getYearMonth(minDate) ||
        getYearMonth(current) > getYearMonth(maxDate)
      )
    }

    return false
  }

  const disabledRangeDate = (current, { from }) => {
    if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEWEEK) {
      return disabledWeeksDate(current, from)
    } else if (teamHoursConsumptionPeriod === UNIFIEDDATE_VALUES.RANGEMONTH) {
      return disabledMonthsDate(current, from)
    }
  }

  const controls = (
    <div className="flex justify-content-between align-items-center pb-3 ">
      <div className="mr-0 ml-1 flex flex-1 align-items-center gap-3">
        {teamHoursConsumptionProjects.length > 1 ?
          <ProjectPicker
            projects={teamHoursConsumptionProjects}
            selectedProject={teamHoursConsumptionSelectedProject}
            setSelectedProject={setTeamHoursConsumptionSelectedProject}
          />
        : null}
        <UnifiedDatePicker
          dateRange={teamHoursConsumptionDateRange}
          setDateRange={setTeamHoursConsumptionDateRange}
          period={teamHoursConsumptionPeriod}
          setPeriod={setTeamHoursConsumptionPeriod}
          hasSingleDay={false}
          hasSingleWeek={false}
          hasSingleMonth={false}
          hasDayPeriod={false}
          hasWeekPeriod={true}
          hasMonthPeriod={true}
          disabledDate={disabledRangeDate}
          getFormat={getDateFormat}
        />
      </div>
      <ResetButton
        onClick={() => {
          setTeamHoursConsumptionPeriod(INITIAL_PERIOD)
          setTeamHoursConsumptionSelectedProject(INITIAL_SELECTED_CLIENT)
          ref.current?.reset()
          setTeamHoursConsumptionDateRange([
            DateTime.local().startOf("week"),
            DateTime.local().endOf("week"),
          ])
          setReportData(initialReportData)
        }}
      />
    </div>
  )

  return (
    <>
      {controls}
      {loading ?
        <div className="w-full h-20rem flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      : <TeamHoursConsumptionTable
          ref={ref}
          reportData={reportData}
          startYear={
            getYearOfDatePeriod(teamHoursConsumptionDateRange).startYear
          }
          endYear={getYearOfDatePeriod(teamHoursConsumptionDateRange).endYear}
          period={teamHoursConsumptionPeriod}
          expandedKeys={teamHoursConsumptionExpandedKeys}
          setExpandedKeys={setTeamHoursConsumptionExpandedKeys}
        />
      }
    </>
  )
})

export default TeamHoursConsumption
