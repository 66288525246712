import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import Dropdown from "../atoms/Dropdown.atom"
import { hasAnyErrors } from "../../utils/validations.utils"
import ErrorMessageForm from "../atoms/ErrorMessageForm.atom"
import Label from "../atoms/Label.atom"
import ViewOutputForm from "../atoms/ViewOutputForm.atom"
import { formatToHtmlId } from "../../utils/strings.utils"

const DropdownInput = forwardRef((props, ref) => {
  const className = props.className || ""
  const forId =
    props.id || `dropdown-input-${formatToHtmlId(props.name + props.label)}`
  let value = props.value ? props.value?.[props.optionLabel] : "-"
  if (!value) {
    value =
      props.options?.find(
        (option) => option[props.optionValue] === props.value
      )?.[props.optionLabel] || "-"
  }
  return (
    <>
      {(props.label || props.optional || props.require) && (
        <Label htmlFor={forId}>
          {" "}
          {props.label}
          {props.require ? " *" : ""}
          {props.optional ? " (Opcional)" : ""}
        </Label>
      )}
      {props.viewOnly ?
        <ViewOutputForm value={value} />
      : <Dropdown
          ref={ref}
          disabled={props.disabled}
          resetFilterOnHide={props.resetFilterOnHide}
          name={props.name}
          inputId={forId}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onHide={props.onHide}
          optionGroupLabel={props.optionGroupLabel}
          optionGroupChildren={props.optionGroupChildren}
          optionLabel={props.optionLabel}
          optionValue={props.optionValue}
          placeholder={props.placeholder}
          itemTemplate={props.itemTemplate}
          valueTemplate={props.valueTemplate}
          emptyMessage={
            props.emptyMessage || "Misión fallida... no encontramos nada"
          }
          className={
            hasAnyErrors(props.error) ? `p-invalid ${className}` : className
          }
          virtualScrollerOptions={props.virtualScrollerOptions}
          filter={props.filter}
          filterBy={props.filterBy}
          filterMatchMode={props.filterMatchMode}
          filterTemplate={props.filterTemplate}
          showFilterClear={props.showFilterClear}
          showClear={props.showClear}
          dropdownIcon={props.dropdownIcon}
          optionDisabled={props.optionDisabled}
          autoFocus={props.autoFocus}
          focusInputRef={props.focusInputRef}
          panelClassName={props.panelClassName}
          optionGroupTemplate={props.optionGroupTemplate}
        />
      }
      {hasAnyErrors(props.error) && !props.withoutErrorMessage ?
        <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} />
      : null}
    </>
  )
})

DropdownInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  itemTemplate: PropTypes.func,
  valueTemplate: PropTypes.func,
  optionGroupLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  optionGroupChildren: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  placeholder: PropTypes.string,
  emptyMessage: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.array,
    PropTypes.number,
  ]),
  filter: PropTypes.bool,
  filterMatchMode: PropTypes.string,
  filterTemplate: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  filterBy: PropTypes.string,
  showFilterClear: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  optionDisabled: PropTypes.func,
}

export default DropdownInput
