import React from 'react'
import PropTypes from 'prop-types'
import { SelectButton } from 'primereact/selectbutton'
import { MODULE_SORT_OPTIONS } from '../../../../../consts/modules/clients.consts'

const OrderSwitch = ({
  orderMode,
  setOrderMode
}) => {
  return (
    <div>
      <span className='clr-text font-semibold'>
        Orden de módulos:
      </span>
      <SelectButton
        value={orderMode === 0 ? MODULE_SORT_OPTIONS.CUSTOM : MODULE_SORT_OPTIONS.ALPHABETICAL}
        className='mt-2'
        onChange={(e) => setOrderMode(e.value === MODULE_SORT_OPTIONS.CUSTOM ? 0 : 1)}
        options={[
          { label: 'Personalizado', value: MODULE_SORT_OPTIONS.CUSTOM },
          { label: 'Alfabético', value: MODULE_SORT_OPTIONS.ALPHABETICAL }
        ]}
      />
    </div>
  )
}

export default OrderSwitch

OrderSwitch.propTypes = {
  orderMode: PropTypes.oneOf(Object.values(MODULE_SORT_OPTIONS)),
  setOrderMode: PropTypes.func
}
