import React from "react"
import InputRadioButton from "../atoms/InputRadioButton.atom"
import PropTypes from "prop-types"
import { hasAnyErrors } from "../../utils/validations.utils"
import ErrorMessageForm from "../atoms/ErrorMessageForm.atom"

export default function InputRadioButtonForm(props) {
  const forId = `input-radio-button-${props.label?.replace(/ /g, "-")}`
  return (
    <div className="flex align-items-center">
      <InputRadioButton
        inputId={forId}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={hasAnyErrors(props.error) ? "p-invalid" : ""}
        checked={props.checked}
      />
      <label htmlFor={forId} style={{ marginLeft: "9px", marginBottom: "0" }}>
        {props.label}
      </label>
      {hasAnyErrors(props.error) ?
        <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} />
      : null}
    </div>
  )
}

InputRadioButtonForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.array,
}
