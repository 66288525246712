import React, { useEffect, useMemo, useState } from "react"
import DataTable from "../../../components/atoms/table/DataTable.atom"
import Column from "../../../components/atoms/table/Column.atom"
import FollowedTaskItem from "./FollowedTaskItem.compound"
import { clsx } from "../../../utils/strings.utils"
import { ReactComponent as CelebrateIcon } from "../../../design/assests/images/icons/celebrate.svg"
import { unfollowTaskById } from "../../../services/followedTask.service"

const MyFollowedTasksDataTable = ({
  tasks = [],
  activeTeamMembers = [],
  showLoading = false,
  loading = false,
  triggerRefresh,
}) => {
  const memoizedTasks = useMemo(() => tasks, [tasks])
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [])

  const loadingTasksTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className="flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto h-13rem">
          <i
            className="pi pi-spin pi-spinner text-3xl "
            style={{ lineHeight: "normal" }}
          />
          <span className="text-xl font-semibold">Cargando tareas...</span>
        </div>
      </div>
    )
  }

  const EmptyFollowedTasksTemplate = () => {
    return (
      <div className="custom-empty-message flex flex-column justify-content-center align-items-center my-2 gap-1">
        <CelebrateIcon className="clr-text p-menuitem-icon svg" />
        <h3 className="message-title font-cairo text-xl clr-secondary-dark font-semibold my-2">
          Todo listo
        </h3>
        <span className="message-content clr-secondary-dark">
          Sin pendientes por ahora, disfruta el momento mientras dure.
        </span>
      </div>
    )
  }
  if (showLoading && loading && isFirstRender) {
    return loadingTasksTemplate()
  }
  if (tasks.length === 0) {
    return <EmptyFollowedTasksTemplate />
  }

  return (
    <DataTable
      className={clsx("pending-tasks-list")}
      value={memoizedTasks}
      responsiveLayout="scroll"
      noSameWidthColumns
      noFixedColumns
      withOutHeader
      virtualScrollerOptions={{ itemSize: 77 }}
    >
      <Column
        body={(rowData) => (
          <FollowedTaskItem
            key={rowData.id}
            {...rowData}
            activeTeamMembers={activeTeamMembers}
            finishService={() => {
              unfollowTaskById(rowData.id).then(() => {
                triggerRefresh()
              })
            }}
          />
        )}
        style={{
          padding: "0",
        }}
      />
    </DataTable>
  )
}

export default MyFollowedTasksDataTable
