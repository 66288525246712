import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
export function LoginRedirect () {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])

  return (
    <div className='exception-page h-full w-full py-6 px-8 flex flex-column justify-content-between font-cairo'></div>
  )
}
