import React from "react"
import DataTable from "../../../../components/atoms/table/DataTable.atom"
import Column from "../../../../components/atoms/table/Column.atom"
import ClientProjectChip from "../../../../components/molecules/ClientProjectChip.molecule"
import { ErrorBadge } from "../../../../components/molecules/CellReportBadges.molecule"
import TextIconWithTooltip from "../../../../components/molecules/TextIconWithTooltip.molecule"
import { numberFormatter } from "../../../../utils/numbers.utils"
import EmptyDataTeamMemberMessage from "../../../../components/molecules/EmptyDataTeamMemberMessage.molecule"
import { formatPercentageSuffix } from "../../../../utils/strings.utils"

const PerformanceTable = ({ data }) => {
  data = data?.sort((a, b) => a.clientId - b.clientId)
  return (
    <div className="flex flex-column gap-4 performance-table">
      <span className="text-4xl font-semibold">Rendimiento por proyectos</span>
      <DataTable
        value={data}
        emptyCustomMessage={<EmptyDataTeamMemberMessage height={188} />}
        scrollable
      >
        <Column
          header="Proyecto"
          body={(rowData) => (
            <ClientProjectChip
              clientCode={rowData.clientTagName}
              projectCode={rowData.projectTagName}
              className="w-3rem text-center flex justify-content-center"
            />
          )}
          style={{ paddingLeft: "7px" }}
        />
        <Column
          header="Horas trabajadas"
          style={{ paddingLeft: "7px" }}
          body={(rowData) => (
            <div className="flex gap-1 w-4rem justify-content-between">
              {numberFormatter(rowData.sisuTime)}
            </div>
          )}
        />
        <Column
          header="Horas facturadas"
          field="billedTime"
          style={{ paddingLeft: "7px" }}
          body={(rowData) => {
            if (!rowData?.clientIsBillable)
              return <span className="opacity-30">{"N/A"}</span>
            return (
              <div className="flex gap-1 w-4rem justify-content-between">
                {numberFormatter(rowData.billedTime)}
                {rowData.billedTime !== rowData.sisuTime && (
                  <ErrorBadge tooltipValue="Las horas facturadas no coinciden con las horas trabajadas" />
                )}
              </div>
            )
          }}
        />
        <Column
          header={
            <TextIconWithTooltip
              icon="pi pi-info-circle"
              tooltipValue="Porcentaje de horas facturadas respecto al total de horas trabajadas"
              content="Rendimiento"
            />
          }
          body={(rowData) => {
            if (!rowData?.clientIsBillable)
              return <span className="opacity-30">{"N/A"}</span>
            return <span>{formatPercentageSuffix(rowData.performance, 1)}</span>
          }}
          style={{ paddingLeft: "7px" }}
        />
      </DataTable>
    </div>
  )
}

export default PerformanceTable
