import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ModalForm from "./modalForm/ModalForm.compunds"
import FormContainer from "../layouts/containers/FormContainer.container"
import FieldContainer from "../layouts/containers/FieldContainer.container"
import {
  validateFieldValue,
  fieldsHaveErrors,
  checkAllFields,
  resetFieldErrors,
  addError,
  isNotEmpty,
  resetField,
  isANumberGreaterThanZero,
  setFieldTypeValidations,
} from "../../utils/validations.utils"
import {
  BUDGET_REGISTRY_TYPE,
  CHANGE_BUDGET_FORM_INITIAL_STATE,
  CHANGE_BUDGET_MOVEMENT_ERROR_MESSAGE,
  CREATE_BUDGET_MOVEMENT_ERROR_MESSAGE,
} from "../../consts/modules/budget.consts"
import DropdownInput from "../molecules/DropdownInput.molecule"
import _, { set } from "lodash"
import useToast from "../../hooks/useToast.hook"
import ClientChip from "../molecules/ClientChip.molecule"
import { CLIENT_BUDGET_TYPES } from "../../consts/modules/clients.consts"
import SimpleMessage from "../atoms/SimpleMessage.atom"
import { displayValueOrPlaceholder } from "../../utils/strings.utils"
import { editClientBudgetSettings } from "../../services/client.service"
import InputNumberForm from "../molecules/InputNumberForm.molecule"
import { createBudgetMovementToClientID } from "../../services/budget.service"
import { DateTime } from "luxon"
import { isBudgetTypeCredit, isBudgetTypeDebit } from "../../utils/budget.utils"

const ModalFormChangeBudgetType = ({
  visible,
  setVisible,
  client,
  currentBudget,
  onCompleted = () => {},
}) => {
  if (!client || !Object.keys(client)) return
  const initialState = {
    budgetType: client.clientBudgetTypeId?.toString(),
    initialBudget: 0,
    suggestedMonthlyBudgetLimit: parseFloat(client.suggestedMonthlyBudgetLimit),
  }
  const [canLoseRemainingBudget, setCanLoseRemainingBudget] = useState(false)
  const [fromBudgetType, setFromBudgetType] = useState(
    client.clientBudgetTypeId
  )
  const [toBudgetType, setToBudgetType] = useState(client.clientBudgetTypeId)
  const [data, setData] = useState(initialState)
  const [validations, setValidations] = useState({
    budgetType: {
      value: client.clientBudgetTypeId?.toString(),
      errors: [],
      typeValidations: [isNotEmpty],
      validationsOptions: {},
    },
    initialBudget: {
      value: 0,
      errors: [],
      typeValidations: [],
      validationsOptions: {},
    },
    suggestedMonthlyBudgetLimit: {
      value: client.suggestedMonthlyBudgetLimit,
      errors: [],
      typeValidations:
        isBudgetTypeCredit(client.clientBudgetTypeId) ?
          [isNotEmpty, isANumberGreaterThanZero]
        : [isNotEmpty],
      validationsOptions: {},
    },
  })
  const { setErrorMessage } = useToast()

  const registryTypeOptions = [
    { label: "Bolsa de horas", value: CLIENT_BUDGET_TYPES.DEBIT },
    { label: "Consumo limitado", value: CLIENT_BUDGET_TYPES.CREDIT },
  ]

  useEffect(() => {
    if (client) {
      resetFormState()
    }
  }, [client])
  useEffect(() => {
    if (toBudgetType === CLIENT_BUDGET_TYPES.CREDIT) {
      resetField(validations, "initialBudget")
      setData((prev) => ({ ...prev, initialBudget: 0 }))
      setFieldTypeValidations(validations, "suggestedMonthlyBudgetLimit", [
        isNotEmpty,
        isANumberGreaterThanZero,
      ])
    } else if (toBudgetType === CLIENT_BUDGET_TYPES.DEBIT) {
      setCanLoseRemainingBudget(false)
      setFieldTypeValidations(validations, "suggestedMonthlyBudgetLimit", [
        isNotEmpty,
      ])
    }
    checkAllFields(validations, setValidations)
  }, [data.budgetType])

  const ModalHeaderTemplate = ({
    title = "Cambiar tipo de presupuesto",
    iconClassName = "pi pi-pencil",
  }) => {
    return (
      <div className="flex gap-3 align-items-center">
        <i
          className={`${iconClassName} text-3xl`}
          style={{ color: "#2896D8" }}
        />
        <div className="flex flex-column gap-2">
          <span className="text-3xl font-semibold">{title}</span>
          <div className="flex gap-2 align-items-center">
            <ClientChip clientCode={client.code} />
            <span className="text-base font-bold">{client.name}</span>
          </div>
        </div>
      </div>
    )
  }

  const handleSubmit = async () => {
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }
    try {
      let adjustmentQuantity = 0
      if (canLoseRemainingBudget) {
        adjustmentQuantity = -1 * parseFloat(client.remainingBudget)
      } else if (
        fromBudgetType === CLIENT_BUDGET_TYPES.CREDIT &&
        toBudgetType === CLIENT_BUDGET_TYPES.DEBIT
      ) {
        adjustmentQuantity = data?.initialBudget || 0
      }
      let reponseAdjustment
      const reponseSettings = await editClientBudgetSettings(
        client.clientId,
        data.suggestedMonthlyBudgetLimit,
        data.budgetType
      )
      if (adjustmentQuantity !== 0) {
        reponseAdjustment = await createBudgetMovementToClientID(
          client.clientId,
          {
            description: "Ajuste por cambio de configuración.",
            authorizationDate: DateTime.local().toISODate(),
            quantity: adjustmentQuantity,
          }
        )
      }
      if (
        !reponseSettings.success ||
        (reponseAdjustment && !reponseAdjustment?.success)
      ) {
        throw new Error()
      }
      setVisible(false)
      onCompleted()
    } catch (error) {
      console.log(error)
      setErrorMessage(CHANGE_BUDGET_MOVEMENT_ERROR_MESSAGE)
    }
  }

  const resetFormState = () => {
    setData({
      budgetType: client.clientBudgetTypeId?.toString(),
      initialBudget: 0,
      suggestedMonthlyBudgetLimit: parseFloat(
        client.suggestedMonthlyBudgetLimit
      ),
    })
    setValidations({
      budgetType: {
        value: client.clientBudgetTypeId?.toString(),
        errors: [],
        typeValidations: [isNotEmpty],
        validationsOptions: {},
      },
      initialBudget: {
        value: 0,
        errors: [],
        typeValidations: [],
        validationsOptions: {},
      },
      suggestedMonthlyBudgetLimit: {
        value: client.suggestedMonthlyBudgetLimit,
        errors: [],
        typeValidations:
          isBudgetTypeCredit(client.clientBudgetTypeId) ?
            [isNotEmpty, isANumberGreaterThanZero]
          : [isNotEmpty],
        validationsOptions: {},
      },
    })
    setCanLoseRemainingBudget(false)
    setFromBudgetType(toBudgetType)
  }

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
    if (name === "budgetType") {
      setFromBudgetType(client.clientBudgetTypeId?.toString())
      setToBudgetType(value)
    }
    if (
      name === "budgetType" &&
      value === CLIENT_BUDGET_TYPES.CREDIT &&
      parseFloat(client.remainingBudget) > 0
    ) {
      setCanLoseRemainingBudget(true)
    }
  }

  const messageTemplate = ({
    remainingTime = displayValueOrPlaceholder(client.activeBagHours),
  }) => {
    return (
      <div className="flex px-2 py-1 w-full gap-3">
        <div>
          <i className="pi pi-exclamation-triangle clr-warning text-2xl"></i>
        </div>
        <div className="flex flex-column gap-2">
          <span className="text-xl font-semibold">
            ¡Espera! Tienes asuntos pendientes
          </span>
          <span>
            Tienes <strong>{remainingTime || "-"}</strong> horas disponibles. Si
            continúas, se descontarán y tu nuevo presupuesto comenzará desde
            cero.
          </span>
          <span>
            <strong>Te recomendamos</strong> esperar a que se agote tu
            presupuesto disponible antes de cambiar de tipo.
          </span>
        </div>
      </div>
    )
  }
  const handleChangeQuantity = ({ name, quantity }) => {
    resetFieldErrors(validations, name)
    handleChange({ target: { name: name, value: quantity } })
    setValidations(validateFieldValue(validations, name, quantity))
  }
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={<ModalHeaderTemplate />}
      service={handleSubmit}
      disabledSaveBtn={
        fieldsHaveErrors(validations) || _.isEqual(data, initialState)
      }
      className="add-budget-modal"
      cleanUp={resetFormState}
    >
      <FormContainer className="add-budget-form">
        <FieldContainer md={12}>
          <DropdownInput
            placeholder="Selecciona un tipo"
            label="Tipo de registro"
            name="budgetType"
            options={registryTypeOptions}
            optionLabel="label"
            optionValue="value"
            value={data.budgetType}
            error={validations.budgetType?.errors}
            onChange={(e) => {
              handleChange({ target: { name: "budgetType", value: e.value } })
            }}
          />
        </FieldContainer>
        {isBudgetTypeCredit(fromBudgetType) &&
          isBudgetTypeDebit(toBudgetType) && (
            <FieldContainer md={12}>
              <InputNumberForm
                label="Bolsa de horas inicial"
                className="w-full relative input-time"
                name="initialBudget"
                value={data.initialBudget}
                error={validations.initialBudget?.errors}
                onChange={(e) => {
                  handleChangeQuantity({
                    name: "initialBudget",
                    quantity: e.value,
                  })
                }}
                min={0}
                step={0.25}
                format={true}
                showButtons
                minFractionDigits={0}
                maxFractionDigits={2}
              />
            </FieldContainer>
          )}
        {fromBudgetType !== toBudgetType && (
          <FieldContainer md={12}>
            <InputNumberForm
              label="Límite mensual sugerido"
              className="w-full relative input-time"
              name="suggestedMonthlyBudgetLimit"
              value={data.suggestedMonthlyBudgetLimit}
              error={validations.suggestedMonthlyBudgetLimit?.errors}
              onChange={(e) => {
                handleChangeQuantity({
                  name: "suggestedMonthlyBudgetLimit",
                  quantity: e.value,
                })
              }}
              min={0}
              step={0.25}
              format={true}
              showButtons
              minFractionDigits={0}
              maxFractionDigits={2}
            />
          </FieldContainer>
        )}
        {canLoseRemainingBudget &&
          fromBudgetType === CLIENT_BUDGET_TYPES.DEBIT &&
          toBudgetType === CLIENT_BUDGET_TYPES.CREDIT && (
            <SimpleMessage
              severity="warn"
              className="mb-3 mx-2 mt-0 fadein"
              content={messageTemplate}
            />
          )}
      </FormContainer>
    </ModalForm>
  )
}

export default ModalFormChangeBudgetType

ModalFormChangeBudgetType.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  selectedClientID: PropTypes.number,
  currentBudget: PropTypes.number,
  onCreateCompleted: PropTypes.func,
}
