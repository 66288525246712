import React, { useEffect, useState } from "react"
import { isBudgetTypeCredit } from "../../../utils/budget.utils"
import { editClientBudgetSettings } from "../../../services/client.service"
import useToast from "../../../hooks/useToast.hook"
import InputNumberConfirmForm from "../../../components/molecules/InputConfirmNumber.form.molecule"
import { KEYBOARD_ACTIONS } from "../../../consts/keyBoard.consts"

const EditSuggestedMonthlyTemplate = ({
  client,
  onClose,
  onBlur,
  refreshData,
}) => {
  const [currentValue, setCurrentValue] = useState(
    client?.suggestedMonthlyBudgetLimit
  )
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    setCurrentValue(client?.suggestedMonthlyBudgetLimit)
  }, [client?.suggestedMonthlyBudgetLimit])

  const { setErrorMessage } = useToast()
  const isError =
    currentValue === "" ||
    currentValue === null ||
    currentValue === undefined ||
    (isBudgetTypeCredit(client?.clientBudgetTypeId) && currentValue === 0)
  const error =
    isError ?
      [
        {
          errorMessage: "El campo no puede estar vacío",
        },
      ]
    : []
  const onConfirm = async () => {
    setSubmitting(true)
    try {
      const response = await editClientBudgetSettings(
        client.clientId,
        currentValue,
        client.clientBudgetTypeId
      )
      if (!response.success) {
        throw new Error(response.message)
      }
      onClose()
      setTimeout(() => {
        refreshData()
      }, 0)
    } catch (error) {
      setErrorMessage({ message: error.message })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <InputNumberConfirmForm
      value={currentValue ? parseFloat(currentValue) : ""}
      onChange={({ value }) => {
        setCurrentValue(value)
      }}
      onKeyDown={(e) => {
        if (e.key === KEYBOARD_ACTIONS.ENTER && !isError) {
          e.preventDefault()
          e.stopPropagation()
          onConfirm()
        } else if (e.key === KEYBOARD_ACTIONS.ENTER && isError) {
          e.preventDefault()
          e.stopPropagation()
          setErrorMessage({ message: error[0].errorMessage })
        } else if (e.key === KEYBOARD_ACTIONS.ESCAPE) {
          e.preventDefault()
          e.stopPropagation()
          onBlur()
        }
      }}
      onBlur={() => {
        if (submitting) return
        setCurrentValue(client?.suggestedMonthlyBudgetLimit)
        onBlur()
      }}
      disabled={submitting}
      disableConfirmButton={submitting}
      onConfirm={onConfirm}
      error={error}
      min={0}
      maxFractionDigits={2}
      withoutErrorMessage
      numberTime
      autoFocus
      allowEmpty
      useGrouping={false}
    />
  )
}

export default EditSuggestedMonthlyTemplate
