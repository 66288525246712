import React from 'react'
import InputText from "../../../../../components/atoms/InputText.atom"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"
import { validateFieldValue } from "../../../../../utils/validations.utils"

const DescriptionInput = ({ inputRef, taskName, setTaskName, validations, setValidations, checkAllFields, fieldsDoesNotHaveErrors, handleSubmit, onNext }) => {
    return (
        <div className="w-full relative task-creation-name">
            {/* error icon */}
            <p
                className={`absolute top-0 left-0 pointer-events-none m-0 p-inputtext warning-icon-next w-full ${validations.name.errors.length ? "opacity-100" : "opacity-0"}`}
            >
                <span className="opacity-0 pointer-events-none">
                    ¿Qué hiciste este día
                </span>
                <i
                    className="pi pi-exclamation-triangle mx-1"
                    data-pr-tooltip="Este es un campo requerido"
                    data-pr-position="bottom"
                    style={{ color: "#FC3D39" }}
                />
            </p>
            <InputText
                id="taskName"
                className="w-full flex-1"
                value={taskName}
                ref={inputRef}
                onChange={(e) => {
                    setTaskName(e.target.value)
                    setValidations(
                        validateFieldValue(validations, "name", e.target.value)
                    )
                }}
                onBlur={() => {
                    setTaskName(taskName.trim())
                }}
                onKeyDown={(e) => {
                    if (e.key === KEYBOARD_ACTIONS.ENTER) {
                        inputRef?.current?.blur()
                        checkAllFields(validations, setValidations)
                        if (fieldsDoesNotHaveErrors(validations)) {
                            handleSubmit()
                            return
                        }
                    }

                    if (
                        e.key === KEYBOARD_ACTIONS.ENTER ||
                        e.key === KEYBOARD_ACTIONS.TAB
                    ) {
                        inputRef?.current?.blur()
                        if (e.key === KEYBOARD_ACTIONS.TAB) {
                            e.preventDefault()
                        }
                        // setTimeouts are a workaround for an "onclick outside close dropdown menu" not working otherwise bug
                        setTimeout(() => {
                            const syntheticEvent = new Event("click", {
                                bubbles: true,
                                cancelable: true,
                            })
                            const acceptButton = document.querySelector(
                                ".accept-project-button"
                            )

                            if (acceptButton) {
                                acceptButton.dispatchEvent(syntheticEvent)
                            }

                            onNext()
                        })
                    }
                }}
                placeholder={
                    validations.name.errors ?
                        "¿Qué hiciste este día?"
                        : "Nombre de tu tarea"
                }
                error={validations.name.errors?.length}
            />
        </div>
    )
}

export default DescriptionInput
