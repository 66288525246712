import { getToken } from '../auth.service'

async function _getRequestConfig (data) {
  const token = await getToken()
  if (data instanceof FormData) {
    return {
      headers: { Authorization: `Bearer ${token}` },
      'bypass-tunnel-reminder': 'true',
      body: data
    }
  }
  return {
    headers: {
      'Content-Type': 'application/json',
      'bypass-tunnel-reminder': 'true',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(data)
  }
}

export async function get (url, params, config = {}) {
  const BASE_CONFIG = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
      'bypass-tunnel-reminder': 'true'
    }
  }

  const apiUrl = new URL(url)
  apiUrl.search = new URLSearchParams(params || undefined).toString()

  return fetch(apiUrl, { ...BASE_CONFIG, ...config })
}

export async function post (url, body, config = {}) {
  const requestConfig = {
    method: 'POST',
    ...(await _getRequestConfig(body)),
    ...config
  }

  return fetch(url, requestConfig)
}

export async function patch (url, body, config = {}) {
  const requestConfig = {
    method: 'PATCH',
    ...(await _getRequestConfig(body)),
    ...config
  }

  return fetch(url, requestConfig)
}

export async function put (url, body, config = {}) {
  const requestConfig = {
    method: 'PUT',
    ...(await _getRequestConfig(body)),
    ...config
  }

  return fetch(url, requestConfig)
}

export async function del (url, body, config = {}) {
  const requestConfig = {
    method: 'DELETE',
    ...(await _getRequestConfig(body)),
    ...config
  }

  return fetch(url, requestConfig)
}

export const api = {
  get,
  post,
  put,
  delete: del,
  patch
}
