import React, { useEffect } from "react"
import ClientBudgetHeader from "./controllers/ClientBudgetHeader.controller"
import ClientBudgetContent from "./controllers/ClientBudgetContent.controller"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../consts/appRoutes.consts"

const ClientBudget = () => {
  const { state: selectedClient } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!selectedClient) {
      navigate(AppRoutes.clientes.index)
    }
  }, [])
  if (!selectedClient) return null
  return (
    <>
      <ClientBudgetHeader />
      <ClientBudgetContent />
    </>
  )
}

export default ClientBudget
