import React from "react"
import Chip from "../atoms/misc/Chip.atom"

const TeamChip = ({ teamTag }) => {
  const getTeamColor = (teamTagName) => {
    if (teamTagName === "UX/UI") {
      return "#326FD1"
    } else if (teamTagName === "DEVS") {
      return "#059BB4"
    } else if (teamTagName === "ADMIN" || teamTagName === "LEADS") {
      return "#F97316"
    } else {
      return "#64748b"
    }
  }

  return (
    <Chip
      className={"client-chip text-white"}
      template={
        <>
          <div
            className="bg-color"
            style={{ backgroundColor: `${getTeamColor(teamTag)}` }}
          ></div>
          <span
            className={"font-bold"}
            style={{ fontSize: "12px", padding: "3.5px 7px" }}
          >
            {teamTag}
          </span>
        </>
      }
    />
  )
}

export default TeamChip
