import React, { useState } from "react"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { DateTime } from "luxon"
import SecondaryButton from "../../../components/molecules/buttons/SecondaryButton.molecule"
import { compareDates } from "../../../utils/date.utils"
import { ReactComponent as CalendarTimeIcon } from "../../../design/assests/images/icons/time.svg"
import Tooltip from "../../../components/atoms/Tooltip.atom"
import { generateRandomId } from "../../../utils/strings.utils"
import InputPlaceholderChipTemplate from "../../../components/molecules/InputPlaceholderChipTemplate.molecule"
import Chip from "../../../components/atoms/misc/Chip.atom"
const ChooseTimeInput = ({
  value,
  onChange,
  label = "Fecha límite",
  name = "limitDate",
  error,
  inputRef,
  readOnly = false,
  chipClassName = `choose-time-chip-${generateRandomId()}`,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const minDate = DateTime.local().plus({ hours: 0.5 })
  value = value ? DateTime.fromISO(value) : null
  if (value && value < DateTime.local()) {
    error = true
  }
  const tooltipConfig =
    error || value ?
      {
        "data-pr-tooltip": `Fecha límite${error ? " con retraso" : ""}`,
        "data-pr-position": "bottom",
      }
    : {}
  const containerStyles = {
    width: "105px",
    height: "26px",
  }
  const handleChipClick = () => {
    setIsEditing(true)
    setTimeout(() => {
      inputRef.current?.nativeElement.click()
    }, 100)
  }

  const SelectedDateTemplate = () => {
    return (
      <Chip
        className={`btn ${chipClassName} btn-primary w-full ${readOnly ? "p-chip-readonly" : ""} ${isEditing || value ? "p-chip-highlight" : ""} ${error ? "error-chip" : ""}`}
        template={
          <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem">
            <span className="flex gap-1 align-items-center">
              <CalendarTimeIcon
                className="flex-shrink-0 clr-text"
                width={14}
                height={14}
              />
              <span>{value?.toFormat("dd/MM/yyyy")}</span>
            </span>
          </span>
        }
        onClick={handleChipClick}
        {...tooltipConfig}
      />
    )
  }
  const placeholderChipTemplate = () => {
    return (
      <Chip
        className={`btn ${chipClassName} btn-primary w-full ${readOnly ? "p-chip-readonly" : ""} ${error ? "error-chip" : ""} ${isEditing ? "p-chip-highlight" : ""}`}
        template={
          <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem">
            <span className="flex gap-1 align-items-center">
              {error ?
                <i className="pi pi-exclamation-triangle text-xs line-height-2 m-auto"></i>
              : <CalendarTimeIcon
                  className="flex-shrink-0 clr-text"
                  width={14}
                  height={14}
                />
              }
              <span>{label}</span>
            </span>
          </span>
        }
        onClick={handleChipClick}
        {...tooltipConfig}
      />
    )
  }

  return (
    ((readOnly && value) || !readOnly) && (
      <div
        key={isEditing}
        className="dropdown-choosettime-inplace input-panel-with-chip"
        style={containerStyles}
      >
        {value ?
          <SelectedDateTemplate />
        : placeholderChipTemplate()}
        <Tooltip target={`.${chipClassName}`} />
        {!readOnly && (
          <UnifiedDatePicker
            dateRange={[value, value || minDate]}
            setDateRange={(e) => onChange(e[1])}
            period="single-datetime"
            hidePeriodSelector
            datePickerProps={{
              name,
              picker: "datetime",
              popupClassName: "opacity-1",
              className: "opacity-0  overflow-hidden absolute py-0",
              showNow: false,
              showTime: {
                format: "HH:mm",
                minuteStep: 15,
                secondStep: 10,
              },
              minDate: minDate,
              disabledTime: (current) => {
                current = value || current
                if (compareDates(current, minDate)) {
                  return {
                    disabledHours: () => [...Array(minDate.hour).keys()],
                    disabledMinutes: (h) =>
                      h === minDate.hour ?
                        [...Array(minDate.minute).keys()]
                      : [],
                  }
                }
                return {}
              },
              style: {
                height: "25.5px",
              },
              renderExtraFooter: () => (
                <div>
                  <SecondaryButton
                    type="text"
                    label="Limpiar"
                    className="absolute ant-clear-btn"
                    onClick={() => {
                      onChange(null)
                    }}
                  />
                </div>
              ),
              ref: inputRef,
              onBlur: (e) => {
                if (
                  Array.from(e.relatedTarget?.classList || []).some((c) =>
                    c.includes("ant")
                  )
                ) {
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }
                setTimeout(() => setIsEditing(false), 300)
              },
            }}
          />
        )}
      </div>
    )
  )
}

export default ChooseTimeInput
