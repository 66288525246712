import React from "react"
import useTimeRegisterContext from "../../../hooks/useTimeRegisterContext.hook"
import Chip from "../../../../../components/atoms/misc/Chip.atom"
import DropdownCategoryFilter from "../../../../../components/compounds/DropdownCategoryFilter.compound"

export default function CategoryChipDropdown({
  value,
  onChange,
  label = "Elige una categoría",
  placeholder = "Elige una categoría",
  name = "categoryGroup",
  options,
  optionValue,
  optionLabel = "categoryTitle",
  error,
  dropdownRef,
  dropdownInputProps,
}) {
  optionValue =
    options?.[0]?.specialCategoryID ? "specialCategoryID" : "categoryID"
  options = options?.map((o) => ({
    ...o,
    categoryFull: o?.categoryGroup + o?.categoryTitle + o?.categorySubtitle,
  }))
  optionLabel = "categoryFull"
  if (options) {
    if (options?.[0]?.specialCategoryID) {
      options = options?.sort((a, b) =>
        a?.categoryTitle?.localeCompare(b?.categoryGroup)
      )
    } else {
      options = options?.sort((a, b) =>
        a?.categoryTitle?.localeCompare(b?.categoryTitle)
      )
    }
  }

  const getCurrentOption = (value, key) => {
    return options?.find((o) => o?.[key || optionValue] === value)
  }

  const displayedCategory =
    value ?
      getCurrentOption(value)?.categoryTitle ||
      getCurrentOption(value)?.categoryGroup
    : label

  const ChipTemplate = () => {
    return (
      <Chip
        className={`btn btn-primary  ${error ? "error-chip" : ""}`}
        template={
          <span
            className={`p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem ${error ? "flex gap-2 align-items-center" : ""}`}
          >
            {displayedCategory}{" "}
            {error ?
              <i
                className="pi pi-exclamation-triangle"
                data-pr-tooltip="Este es un campo requerido"
                data-pr-position="bottom"
              />
            : null}
          </span>
        }
        onClick={() => {
          dropdownRef.current?.show()
        }}
      />
    )
  }

  return (
    <div className={`dropdown-category-filter`}>
      <DropdownCategoryFilter
        ref={dropdownRef}
        value={value}
        onChange={onChange}
        name={name}
        options={options}
        optionValue={optionValue}
        optionLabel={optionLabel}
        error={error}
        placeholder={placeholder}
        dropdownInputProps={dropdownInputProps}
      />
      <ChipTemplate />
    </div>
  )
}
