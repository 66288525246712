import React from "react"
import TimeRegister from "../../views/timeRegister/TimeRegister.view"
import { AppRoutes } from "../../consts/appRoutes.consts"
import Home from "../../views/home/Home.view"
import GeneralReport from "../../views/generalReport/GeneralReport.view"
import IndividualReport from "../../views/individualReport/IndividualReport.view"
import ChangeLog from "../../views/changeLog/ChangeLog.view"
import PendingTasks from "../../views/pendingTasks/PendingTasks.view"
import { getRouteIfAllowedEnvironment } from "../../utils/routes.utils"
import { ENVIRONMENTS } from "../../consts/enviroments.consts"

export const TEAM_MEMBER_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />,
  },
  {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister />,
  },
  {
    path: AppRoutes.reporteGeneral.index,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteGeneral.horasSemana,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteIndividual.index,
    element: <IndividualReport />,
  },
  {
    path: AppRoutes.reporteIndividual.redirect,
    element: <IndividualReport />,
  },
  {
    path: AppRoutes.changeLog.index,
    element: <ChangeLog />,
  },
  ...getRouteIfAllowedEnvironment(
    {
      path: AppRoutes.listaPendientes.index,
      element: <PendingTasks />,
    },
    [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.TEST]
  ),
]
