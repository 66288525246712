import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  AVERAGE_CONSUMPTION_TYPES,
  AVERAGE_CONSUMPTION_TYPES_OPTIONS,
} from "../../../consts/modules/budget.consts"
import Card from "../../../components/atoms/Card.atom"
import DropdownInput from "../../../components/molecules/DropdownInput.molecule"
import {
  ValueWithLabelContainer,
  ValueWithLabelTemplate,
} from "../components/ValueWithLabelElements"
import { numberFormatter } from "../../../utils/numbers.utils"
import {
  getEstimatedBudgetEnd,
  isBudgetTypeDebit,
} from "../../../utils/budget.utils"
import CardHeaderTemplate from "../components/CardHeaderTemplate"
import TendencyIconWithTooltip from "../components/TendencyIconWithTooltip"
import { isObjectEmpty } from "../../../utils/object.utils"

const AverageConsumption = ({ data: budgetData, loading }) => {
  const [periodOption, setPeriodOption] = useState(
    AVERAGE_CONSUMPTION_TYPES.MENSUAL
  )
  const [data, setData] = useState({
    historicalAverage: 0,
    last6MonthsAverage: 0,
    last3MonthsAverage: 0,
    lastMonthAverage: 0,
    estimatedBudgetEnd: "Calculando...",
  })

  useEffect(() => {
    if (!isObjectEmpty(budgetData)) {
      formatResult(budgetData)
    }
  }, [periodOption, budgetData])

  const formatResult = (result) => {
    const isMonthly = periodOption === AVERAGE_CONSUMPTION_TYPES.MENSUAL
    const formattedResult = {
      historicalAverage:
        result.totalBilledTime /
          (isMonthly ? result.totalMonths : result.totalMonths * 4) || 0,
      last6MonthsAverage:
        result.lastSixMonthBilledTime / (isMonthly ? 6 : 6 * 4),
      last3MonthsAverage:
        result.lastThreeMonthBilledTime / (isMonthly ? 3 : 3 * 4),
      lastMonthAverage: result.lastMonthBilledTime / (isMonthly ? 1 : 4),
      hasBudget: result.hasBudget,
      consumedTimePercentage: result.consumedTimePercentage,
      suggestedMonthlyBudgetLimit: result.suggestedMonthlyBudgetLimit,
      remainingBudget: result.totalActiveBags,
    }
    setData(formattedResult)
  }

  const HeaderTemplate = () => {
    const isDebit = isBudgetTypeDebit(budgetData.clientBudgetTypeId)
    return (
      <CardHeaderTemplate
        title={`Consumo promedio ${isDebit ? periodOption?.toLocaleLowerCase() : ""}`}
        titleClassName={"line-height-2"}
        actions={
          isDebit && (
            <DropdownInput
              options={AVERAGE_CONSUMPTION_TYPES_OPTIONS}
              value={periodOption}
              onChange={(e) => setPeriodOption(e.value)}
              valueTemplate={(option) => {
                return (
                  <span className="flex align-items-center">
                    <i className="pi pi-calendar mr-2" />
                    {option.label}
                  </span>
                )
              }}
              className="w-12rem average-consumption-dropdown"
            />
          )
        }
      />
    )
  }
  const estimatedBudgetEndTemplate = (estimatedEnd) => {
    let title = "Fin estimado no disponible"
    if (parseFloat(budgetData.totalBudget) === 0) {
      title = "Sin presupuesto"
    } else {
      title = estimatedEnd.label
    }
    return (
      <span
        className="text-sm line-height-1 font-normal"
        style={{
          color: "light-dark(#6E7B8D, rgba(255,255,255,0.7))",
        }}
      >
        {title}
      </span>
    )
  }
  const customAverageConsumptionFormat = (time) => {
    let periodSuffix = "hs/mes"
    if (
      periodOption === AVERAGE_CONSUMPTION_TYPES.SEMANAL &&
      isBudgetTypeDebit(budgetData.clientBudgetTypeId)
    ) {
      periodSuffix = "hs/sem"
    }
    const suggestedMonthlyBudgetLimit = parseFloat(
      data.suggestedMonthlyBudgetLimit
    )
    return time > 0 ?
        <span className="flex align-items-center gap-1">
          <span className="flex align-items-baseline gap-1">
            {numberFormatter(time)} <p className="text-base">{periodSuffix}</p>
          </span>
          {suggestedMonthlyBudgetLimit > 0 && (
            <TendencyIconWithTooltip
              reference={suggestedMonthlyBudgetLimit}
              value={time}
              labelIfMore="Por arriba del límite sugerido"
              labelIfLess="Por debajo del límite sugerido"
            />
          )}
        </span>
      : <p className="text-base m-0">- {periodSuffix}</p>
  }

  const CustomValueByBudgetType = ({ value }) => {
    if (isBudgetTypeDebit(budgetData.clientBudgetTypeId)) {
      const isMonthly = periodOption === AVERAGE_CONSUMPTION_TYPES.MENSUAL
      const valueAverage = isMonthly ? value : value * 4
      const estimatedEndBudget = getEstimatedBudgetEnd(
        data.remainingBudget,
        valueAverage,
        valueAverage
      )
      return (
        <div className="flex flex-column justify-content-center">
          {customAverageConsumptionFormat(value)}
          {estimatedBudgetEndTemplate(estimatedEndBudget)}
        </div>
      )
    } else {
      return (
        <div className="flex gap-1 align-items-center">
          {customAverageConsumptionFormat(value)}
        </div>
      )
    }
  }
  return (
    <Card className={"dashboard-card shadow-2 min-h-fit max-h-fit"}>
      <div className="flex flex-column gap-3">
        <HeaderTemplate />
        <ValueWithLabelContainer>
          <ValueWithLabelTemplate
            label="Histórico"
            value={<CustomValueByBudgetType value={data.historicalAverage} />}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label="Últimos 6 meses"
            value={<CustomValueByBudgetType value={data.last6MonthsAverage} />}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label="Últimos 3 meses"
            value={<CustomValueByBudgetType value={data.last3MonthsAverage} />}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label="Mes anterior"
            value={<CustomValueByBudgetType value={data.lastMonthAverage} />}
            loading={loading}
          />
        </ValueWithLabelContainer>
      </div>
    </Card>
  )
}

export default AverageConsumption

AverageConsumption.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
}
