/* eslint-disable */
import React, { useEffect, useState } from "react"
import Column from "../../../../../components/atoms/table/Column.atom"
import MultiSelectTeamMemberFilter from "../../../../../components/compounds/MultiSelectTeamMemberFilter.compound"
import ColumnContent from "./ColumnContent.controller"
import { CustomTreeTable as TreeTable } from "../../../../../components/atoms/table/CustomTreeTable.atom"
import { roundReportData } from "../../../../../utils/report.utils"
import TeamChip from "../../../../../components/molecules/TeamChip.molecule"
import { UNIFIEDDATE_VALUES } from "../../../../../consts/CompoundRangeDatePicker"

const calculateTotals = (data, totals, filters) => {
  let filteredData = []
  let newTotals = {
    averageTime: 0,
    billedTime: 0,
  }
  const teamMemberData = data.flatMap((node) => node.children)

  if (filters["data.name"].value !== null) {
    if (totals.weekData !== undefined) {
      const weeksToConsider = Object.keys(totals.weekData)
      newTotals.weekData = {}
      weeksToConsider.forEach((week) => {
        newTotals.weekData[week] = {
          sisuTime: 0,
        }
      })
    } else if (totals.monthData !== undefined) {
      const monthsToConsider = Object.keys(totals.monthData)
      newTotals.monthData = {}
      monthsToConsider.forEach((month) => {
        newTotals.monthData[month] = {
          sisuTime: 0,
        }
      })
    }

    filteredData = teamMemberData.filter((node) =>
      filters["data.name"].value.includes(node.data.name)
    )

    filteredData.forEach((node) => {
      newTotals.averageTime += node.data.billedTime
      newTotals.billedTime += node.data.billedTime
      if (totals.weekData !== undefined) {
        const weeksToConsider = Object.keys(node.data.rangeData)
        weeksToConsider.forEach((week) => {
          newTotals.weekData[week].sisuTime +=
            node.data.rangeData[week].sisuTime
        })
      } else if (totals.monthData !== undefined) {
        const monthsToConsider = Object.keys(node.data.rangeData)
        monthsToConsider.forEach((month) => {
          newTotals.monthData[month].sisuTime +=
            node.data.rangeData[month].sisuTime
        })
      }
    })

    newTotals.averageTime /= totals.rangeDuration
    return newTotals
  } else {
    return totals
  }
}

const teamMemberNameTemplate = (rowData) => {
  if (rowData.data.code !== undefined) {
    return (
      <div className="flex align-items-center">
        <TeamChip teamTag={rowData.data.code} />
      </div>
    )
  } else if (rowData.data.teamMemberID !== undefined) {
    return (
      <div className="flex align-items-center">
        <span className="white-space-nowrap px-2">{rowData.data.name}</span>
      </div>
    )
  } else {
    return (
      <div className="flex align-items-center">
        <span className="white-space-nowrap px-2">N/A</span>
      </div>
    )
  }
}

const calculateFilteredGroupHeadersTotals = (activeFilters, originalData) => {
  const clonedData = structuredClone(originalData)
  clonedData.data.forEach((el) => {
    Object.keys(el.data.rangeData).forEach((key) => {
      el.data.rangeData[key].sisuTime = 0
    })
    el.data.averageTime = 0
    el.data.billedTime = 0
  })

  clonedData.data.forEach((el) => {
    const childrenBeingFiltered = el.children.filter((child) => {
      return activeFilters.includes(child.data.name)
    })
    const hasChildrenBeingFiltered = childrenBeingFiltered.length > 0
    if (hasChildrenBeingFiltered) {
      childrenBeingFiltered.forEach((child) => {
        Object.keys(child.data.rangeData).forEach((key) => {
          el.data.rangeData[key].sisuTime += child.data.rangeData[key].sisuTime
        })
        el.data.averageTime += child.data.averageTime
        el.data.billedTime += child.data.billedTime
      })
    }
  })
  return clonedData
}

const TeamHoursConsumptionTable = React.forwardRef(
  (
    {
      reportData: originalData,
      startYear,
      endYear,
      period,
      expandedKeys,
      setExpandedKeys,
    },
    ref
  ) => {
    const [filters, setFilters] = useState({
      "data.name": { value: null, matchMode: "in" },
    })

    const [reportData, setReportData] = useState(roundReportData(originalData))

    useEffect(() => {
      const activeFilters = filters["data.name"].value
      const isFilteringName = activeFilters !== null && activeFilters.length > 0

      if (isFilteringName) {
        const clonedData = calculateFilteredGroupHeadersTotals(
          activeFilters,
          originalData
        )

        setReportData(roundReportData(clonedData))
      } else {
        const clonedData = structuredClone(originalData)

        setReportData(roundReportData(clonedData))
      }
    }, [filters])

    const resetFilters = () => {
      collapseAll()
      setFilters({
        "data.name": { value: null, matchMode: "in" },
      })
    }

    const newRef = React.useRef()
    React.useImperativeHandle(
      ref,
      () => ({
        reset() {
          collapseAll()
          setFilters({
            "data.name": { value: null, matchMode: "in" },
          })
          newRef.current.reset()
        },
      }),
      []
    )

    const collapseAll = () => {
      setExpandedKeys({})
    }

    const expandAll = () => {
      const newExpandedKeys = {}
      reportData.data.forEach((node) => {
        newExpandedKeys[node.key] = true
      })
      setExpandedKeys(newExpandedKeys)
    }

    const teamMemberExpandCondition = (node) => {
      if (node?.children && node?.children.length > 0) return true
      else return false
    }

    const rangeArray = (reportDataTemporal) => {
      if (
        reportDataTemporal.totals?.monthsToConsider?.length !== undefined &&
        reportDataTemporal.totals?.monthsToConsider?.length !== 0
      )
        return reportDataTemporal.totals.monthsToConsider
      else if (
        reportDataTemporal.totals?.weeksToConsider?.length !== undefined &&
        reportDataTemporal.totals?.weeksToConsider?.length !== 0
      )
        return reportDataTemporal.totals.weeksToConsider
      else return []
    }

    return (
      <>
        <TreeTable
          ref={newRef}
          value={reportData.data}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          removableSort
          filters={filters}
          globalFilterFields={["data.name"]}
          expanderConditions={(node) => teamMemberExpandCondition(node)}
          allowExpandSingleChildren
          scrollable
          scrollHeight="100%"
          frozenExpandedColumn
        >
          <Column
            body={teamMemberNameTemplate}
            style={{
              width: "215px",
              minWidth: "215px",
              padding: "7px 7px 7px 0px",
            }}
            header={() => <p style={{ marginLeft: -45 }}>Equipos</p>}
            footer={() => (
              <p style={{ marginLeft: -45 }}>
                Total por{" "}
                {period === UNIFIEDDATE_VALUES.RANGEMONTH ? "mes" : "semana"}
              </p>
            )}
            filter
            frozen
            filterField="data.name"
            filterElement={(options) => (
              <MultiSelectTeamMemberFilter
                key={reportData.data.length}
                options={options}
                data={reportData.data}
              />
            )}
            showFilterMenuOptions={false}
            showFilterMatchModes={false}
            filterMatchMode={"in"}
            onFilterApplyClick={(e) => {
              if (!e.constraints?.value) {
                collapseAll()
              } else {
                expandAll()
                setFilters({
                  "data.name": { value: e.constraints?.value, matchMode: "in" },
                })
              }
            }}
            onFilterClear={resetFilters}
          />
          {ColumnContent(
            rangeArray(reportData),
            startYear,
            endYear,
            period,
            calculateTotals(reportData.data, reportData.totals, filters)
          )?.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </TreeTable>
      </>
    )
  }
)

export default TeamHoursConsumptionTable
