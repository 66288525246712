import React from "react"
import Chip from "../../../../../components/atoms/misc/Chip.atom"
import DropdownProjectFilter from "../../../../../components/compounds/DropdownProjectFilter.compound"
import useTimeRegisterContext from "../../../hooks/useTimeRegisterContext.hook"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"
import ClientProjectChip from "../../../../../components/molecules/ClientProjectChip.molecule"

function ProjectsChipDropdown({
  projectName,
  value,
  dropdownProjectFilterRef,
  categoryDropdownRef,
  validations,
  setValidations,
  handleSubmit,
  checkAllFields,
  fieldsDoesNotHaveErrors,
  activeProjects,
  setSelectedProject,
  validateFieldValue,
}) {
  return (
    <div className={`dropdown-project-chip-selector-wrapper`}>
      <DropdownProjectFilter
        ref={dropdownProjectFilterRef}
        value={value}
        onChange={(e) => {
          setSelectedProject(e)
          setValidations(validateFieldValue(validations, "projectId", e.value))
        }}
        resetFilterOnHide={true}
        options={activeProjects}
        error={validations.projectId.errors?.length}
        inputProps={(inputProps) => ({
          autoFocus: true,
          className: "w-full",
          onKeyDown: (e) => {
            if (e.key === KEYBOARD_ACTIONS.ENTER) {
              checkAllFields(validations, setValidations)
              if (fieldsDoesNotHaveErrors(validations)) {
                handleSubmit()
                return
              }
            }

            if (
              e.key === KEYBOARD_ACTIONS.ENTER ||
              e.key === KEYBOARD_ACTIONS.TAB
            ) {
              if (e.key === KEYBOARD_ACTIONS.TAB) {
                dropdownProjectFilterRef.current.hide()
                e.preventDefault()
              }
              categoryDropdownRef.current?.show()
            }

            // Let the original Dropdown keyboard handler process arrow keys
            inputProps.filterInputKeyDown(e)
          },
        })}
      />
      {projectName && value ?
        <ClientProjectChip
          clientCode={
            activeProjects.find((option) => option.projectID === value)
              ?.clientTagName
          }
          projectCode={
            activeProjects.find((option) => option.projectID === value)
              ?.projectTagName
          }
          onClick={() => {
            dropdownProjectFilterRef.current?.show()
          }}
        />
        : <Chip
          className={`btn btn-primary ${validations.projectId.errors?.length ? "error-chip" : ""}`}
          template={
            <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem flex gap-2 align-items-center">
              {value && projectName ? projectName : "Elige un proyecto"}{" "}
              {validations.projectId.errors?.length ?
                <i
                  className="pi pi-exclamation-triangle"
                  data-pr-tooltip="Este es un campo requerido"
                  data-pr-position="bottom"
                />
                : null}
            </span>
          }
          onClick={() => {
            dropdownProjectFilterRef.current?.show()
          }}
        />
      }
    </div>
  )
}

export default ProjectsChipDropdown
