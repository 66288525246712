import React from "react"
import useTimeRegisterContext from "../../../hooks/useTimeRegisterContext.hook"
import DropdownInput from "../../../../../components/molecules/DropdownInput.molecule"
import DropdownCustomFilterSearch from "../../../../../components/molecules/DropdownCustomFilterSearch.molecule"
import {
  checkAllFields,
  fieldsDoesNotHaveErrors,
} from "../../../../../utils/validations.utils"
import Chip from "../../../../../components/atoms/misc/Chip.atom"

const ModuleChipDropdown = ({
  value,
  onChange,
  label = "Elige un módulo",
  name = "moduleTagName",
  options,
  optionValue = "moduleID",
  optionLabel = "moduleName",
  error,
  dropdownRef,
  onPressEnter,
  loading,
  formValidations,
  setFormValidations,
  handleFormSubmit,
  disabled,
  ...props
}) => {
  if (!options) return
  const moduleName =
    value ?
      options.find((option) => option?.[optionValue] === value)?.[optionLabel]
      : ""

  return (
    <div
      className={`dropdown-module-filter-inplace ${disabled ? "disabled" : ""}`}
    >
      <DropdownInput
        ref={dropdownRef}
        name={name}
        filter
        className="modules-dropdown"
        placeholder={label}
        options={options}
        optionValue={optionValue}
        optionLabel={optionLabel}
        value={value}
        onChange={(e) => {
          onChange(e)
        }}
        itemTemplate={(option) => {
          return (
            <div className="flex gap-2 align-items-center module-item">
              <span className="flex-1">{option.moduleName}</span>
            </div>
          )
        }}
        emptyMessage="No hay modulos aquí... ¡este cliente es minimalista!"
        filterTemplate={(props) => (
          <DropdownCustomFilterSearch
            props={props}
            inputProps={{
              autoFocus: true,
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  checkAllFields(formValidations, setFormValidations)
                  if (fieldsDoesNotHaveErrors(formValidations)) {
                    handleFormSubmit()
                    return
                  }
                }

                if (e.key === "Enter" || e.key === "Tab") {
                  dropdownRef?.current?.hide()
                  if (e.key === "Tab") {
                    e.preventDefault()
                  }
                  onPressEnter(e)
                }
                props.filterInputKeyDown(e)
              },
            }}
          />
        )}
        panelClassName={`${loading ? "loading" : ""}`}
      />

      <Chip
        className={`btn btn-primary w-full ${error ? "error-chip" : ""}`}
        template={
          // eslint-disable-next-line
          <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem">
            {moduleName || label}{" "}
            {error ?
              <i
                className="pi pi-exclamation-triangle"
                data-pr-tooltip="Este es un campo requerido"
                data-pr-position="bottom"
              />
              : null}
          </span>
        }
        onClick={() => {
          dropdownRef.current?.show()
        }}
      />

    </div>
  )
}

export default ModuleChipDropdown
