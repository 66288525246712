import { useEffect } from 'react';

/**
 * https://github.com/streamich/react-use/blob/master/docs/useMount.md
 * @param {() => void} effect
 * @returns {void}
 */
const useMount = (effect) => {
    useEffect(() => { effect() }, [])
}

export default useMount