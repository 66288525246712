import React from "react"
import Badge from "../atoms/Badge.atom"
import Tooltip from "../atoms/Tooltip.atom"
import { generateRandomNumber } from "../../utils/numbers.utils"

const ReportBadgeWithTooltip = ({
  value,
  tooltipValue = "",
  // if there's no position by default, then it's dynamic so it doesn't overflow the viewport
  position,
  iconClassName,
  className,
  type,
  legend,
  badgeProps,
  tooltipProps,
  legendProps,
  ...rest
}) => {
  const id = generateRandomNumber()
  return (
    <span
      className={`${legend !== null || legend !== undefined ? "flex align-items-center min-w-4rem w-fit justify-content-between" : null}`}
    >
      <Tooltip
        target={`.report-badge-${id}`}
        position={position}
        {...tooltipProps}
      >
        {tooltipValue}
      </Tooltip>
      <Badge
        className={`report-badge report-badge-${id} bg-${type} ${className} min-w-0 flex align-items-center justify-content-center`}
        value={
          value || (
            <i
              className={`${iconClassName} flex align-items-center justify-content-center`}
            ></i>
          )
        }
        {...badgeProps}
      />
      <span className="ml-1" {...legendProps}>
        {legend}
      </span>
    </span>
  )
}

export default ReportBadgeWithTooltip
