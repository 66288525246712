/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from "react"
import Column from "./Column.atom"
import DataTable from "./DataTable.atom"

const getValueDataExpanded = (
  allowExpandSingleChildren,
  value,
  expandedKeys,
  parentKey
) => {
  return value
    .map((node) => {
      const { key, children = [] } = node
      const expanded = expandedKeys[key] ?? false
      const expandChildren =
        allowExpandSingleChildren ? children.length >= 1 : children.length > 1
      if (expanded && expandChildren) {
        return [
          { ...node, parentKey },
          ...getValueDataExpanded(
            allowExpandSingleChildren,
            children,
            expandedKeys,
            key
          ),
        ]
      }

      return [{ ...node, parentKey }]
    })
    .flat()
}

/**
 * @typedef {Object} CustomTreeTableValueModel
 * @property {string} key
 * @property {{ [key: string]: * }} data
 * @property {CustomTreeTableValueModel[]} children
 *
 * @typedef {{ [key: string]: boolean }} StringBooleanObject
 *
 * @typedef {Object} CustomTreeTableProps
 * @property {CustomTreeTableValueModel[]} value
 * @property {StringBooleanObject=} expandedKeys
 * @property {(e: { value: { [key: string]: * } }) => *=} onToggle
 * @property {(data: CustomTreeTableValueModel) => StringBooleanObject=} rowClassName
 * @property {*} children
 */

/**
 * Componente para mostrar datos anidados en una tabla.
 * Se usa en lugar del componente TreeTable de PrimeReact.
 * Las props se nombran igual que las del componente TreeTable de PrimeReact para facilitar el cambio futuro.
 * @param {CustomTreeTableProps & import("primereact/datatable").DataTableProps & {noSameWidthColumns?: boolean, noFixedColumns?: boolean, hasVirtualScroll?: boolean}} props
 * @returns
 */
export const CustomTreeTable = React.forwardRef(
  (
    {
      value = [],
      expandedKeys,
      onToggle = undefined,
      rowClassName = undefined,
      children,
      className = "",
      expanderConditions = null,
      expandedColumnHeader = null,
      frozenExpandedColumn = false,
      allowExpandSingleChildren = false,
      ...rest
    },
    ref
  ) => {
    const [_valueData, _setValueData] = useState([])

    useEffect(() => {
      if (expandedKeys == null) {
        _setValueData(value)
        return
      }
      _setValueData(
        getValueDataExpanded(allowExpandSingleChildren, value, expandedKeys)
      )
    }, [expandedKeys, value])

    const onRowToggle = (e) => {
      onToggle?.({ value: e.data })
    }

    const isRowExpanded = (rowData) => {
      return Object.prototype.hasOwnProperty.call(
        expandedKeys || {},
        rowData.parentKey
      )
    }
    const getRowClassName = (rowData) => {
      const _rowClassName =
        typeof rowClassName === "string" ? rowClassName : (
          rowClassName?.(rowData) || ""
        )

      if (typeof _rowClassName === "string") {
        const expandedClassName =
          isRowExpanded(rowData) ? "expanded-row-content" : ""
        return `${expandedClassName} ${_rowClassName}`
      } else if (typeof _rowClassName === "object") {
        return {
          ..._rowClassName,
          "expanded-row-content": isRowExpanded(rowData),
        }
      }
      return { "expanded-row-content": isRowExpanded(rowData) }
    }
    return (
      <>
        {/* @ts-ignore */}
        <DataTable
          ref={ref}
          value={_valueData}
          dataKey="key"
          expandedRows={expandedKeys}
          onRowToggle={onRowToggle}
          rowClassName={getRowClassName}
          hasScroll
          className={`custom-tree-table ${className || ""}`}
          {...rest}
        >
          <Column
            className="custom-tree-expander"
            header={expandedColumnHeader}
            style={{ width: 45, minWidth: 45, maxWidth: 45 }}
            expander={(node) => {
              return expanderConditions ?
                  expanderConditions(node)
                : node?.children?.length > 1
            }}
            frozen={frozenExpandedColumn}
          />
          {children}
        </DataTable>
      </>
    )
  }
)

/* Usage Example */
export function EjemploCustomTreeTable() {
  const [expandedKeys, setExpandedKeys] = useState({ 2: true })

  const exampleValue = [
    {
      key: "2",
      data: {
        name: "Desktop",
        size: "150kb",
        type: "Folder",
      },
      children: [
        {
          key: "2-0",
          data: {
            name: "note-meeting.txt",
            size: "50kb",
            type: "Text",
          },
        },
        {
          key: "2-1",
          data: {
            name: "note-todo.txt",
            size: "100kb",
            type: "Text",
          },
        },
      ],
    },
    {
      key: "4",
      data: {
        name: "Downloads",
        size: "25kb",
        type: "Folder",
      },
      children: [
        {
          key: "4-0",
          data: {
            name: "Spanish",
            size: "10kb",
            type: "Folder",
          },
          children: [
            {
              key: "4-0-0",
              data: {
                name: "tutorial-a1.txt",
                size: "5kb",
                type: "Text",
              },
            },
            {
              key: "4-0-1",
              data: {
                name: "tutorial-a2.txt",
                size: "5kb",
                type: "Text",
              },
            },
          ],
        },
        {
          key: "4-1",
          data: {
            name: "Travel",
            size: "15kb",
            type: "Text",
          },
          children: [
            {
              key: "4-1-0",
              data: {
                name: "Hotel.pdf",
                size: "10kb",
                type: "PDF",
              },
            },
            {
              key: "4-1-1",
              data: {
                name: "Flight.pdf",
                size: "5kb",
                type: "PDF",
              },
            },
          ],
        },
      ],
    },
  ]

  const nameTemplate = (node) => {
    return (
      <>
        <span>{node.data.name}</span>
      </>
    )
  }

  return (
    <>
      <CustomTreeTable
        value={exampleValue}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
      >
        <Column field="data.name" header="Name" body={nameTemplate}></Column>
        <Column field="data.size" header="Size"></Column>
        <Column field="data.type" header="Type"></Column>
      </CustomTreeTable>
    </>
  )
}
