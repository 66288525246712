import React from "react"
import PropTypes from "prop-types"
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded"
import Tooltip from "../atoms/Tooltip.atom"
import { generateRandomNumber } from "../../utils/numbers.utils"

const MarkAsFinishedIcon = ({
  CustomIcon = CheckCircleOutlineRoundedIcon,
  isFinished = false,
  tooltipConfig = {
    position: "bottom",
    tooltipBody: "",
  },
  tooltipBodyIfFinished = "Marcar como por hacer",
  tooltipBodyIfNotFinished = "Marcar como finalizado",
  iconStyle = {
    fontSize: "2rem",
    cursor: "pointer",
  },
  onClick = () => {},
  loading = false,
}) => {
  const id = generateRandomNumber()
  const iconClassName = `mark-as-finished-icon-${id}`
  tooltipConfig.tooltipBody =
    isFinished ? tooltipBodyIfFinished : tooltipBodyIfNotFinished
  return (
    <>
      <div
        className={`${iconClassName} flex align-items-center justify-content-center h-2rem w-2rem`}
      >
        {loading ?
          <i className={`${iconClassName} pi pi-spin pi-spinner text-2xl`}></i>
        : <CustomIcon
            style={{
              ...iconStyle,
              fill: isFinished ? "var(--clr-success)" : "var(--text-color)",
            }}
            onClick={onClick}
          />
        }
      </div>
      {!loading && (
        <Tooltip target={`.${iconClassName}`} position={tooltipConfig.position}>
          {tooltipConfig.tooltipBody}
        </Tooltip>
      )}
    </>
  )
}

export default MarkAsFinishedIcon

MarkAsFinishedIcon.propTypes = {
  CustomIcon: PropTypes.elementType,
  isFinished: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  tooltipConfig: PropTypes.shape({
    position: PropTypes.string,
    tooltipBody: PropTypes.string,
  }),
  tooltipBodyIfFinished: PropTypes.string,
  tooltipBodyIfNotFinished: PropTypes.string,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}
