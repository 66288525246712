import React, { useState } from 'react'
import { InputCheckbox } from '../atoms/InputCheckbox.atom'
import { clsx } from '../../utils/strings.utils'
import InputText from '../atoms/InputText.atom'
import Button from '../atoms/Button.atom'
import ProgressSpinner from '../atoms/misc/ProgressSpinner.atom'

const buttonDropdownHeight = 63

/**
 * @template T
 * @param {import('./InlineMultiSelectDropdown.molecule').InlineMultiSelectDropdownProps<T>} props
 */
export const InlineMultiSelectDropdown = ({
  value,
  itemTemplate,
  optionLabel,
  options,
  onChange,
  optionValue,
  placeholder,
  selectedItemsLabel,
  maxSelectedLabels,
  maxHeight,
  loading
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [filter, setFilter] = useState('')
  const [isContentVisible, setIsContentVisible] = useState(false)
  const optionLabels = Array.isArray(optionLabel) ? [...optionLabel, 'clientTagName'] : [optionLabel, 'clientTagName']
  const writeTextLabel = () => {
    if (value.length === 0) return placeholder

    if (value.length <= maxSelectedLabels) {
      const elements = value.map(v => options.find(el => el[optionValue] === v))
      const labels = elements.map(el => el[optionLabel])
      return labels.join(',')
    } else {
      return selectedItemsLabel
    }
  }

  const filteredOptions = options.filter(el => optionLabels.some(label => el?.[label].toLowerCase().includes(filter?.toLowerCase())))
  const filteredValues = value.filter(v => filteredOptions.some(el => el[optionValue] === v))
  const filteredAllChecked = filteredValues.length === filteredOptions.length && filteredOptions.length > 0

  return (
    <div className='inline-multiselect flex flex-column' >
      <button
        onClick={() => setIsContentVisible(!isContentVisible)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={clsx('p-multiselect', isFocused && 'p-focus', isFocused && 'p-inputwrapper-focus')}>
        <p>
          {writeTextLabel()}
        </p>
        <div className='trigger-icon'>
          <span className='p-multiselect-trigger-icon p-c pi pi-chevron-down'></span>
        </div>
      </button>
      {/* content */}

      <div className={clsx('p-multiselect-panel', isContentVisible && 'is-visible')}
        style={{
          height: '100%',
          maxHeight: isContentVisible ? maxHeight + buttonDropdownHeight : 0,
          overflow: 'clip',
          marginTop: isContentVisible ? 8 : 0
        }}
      >
        <div className='p-multiselect-header'>
          <InputCheckbox
            inputId='inline-multiselect-select-all'
            onChange={() => {
              if (filteredAllChecked) {
                onChange(value.filter(v => !filteredOptions.some(el => el[optionValue] === v)))
              } else {
                const newValues = new Set([...value, ...filteredOptions.map(el => el[optionValue])])
                onChange([
                  ...newValues
                ])
              }
            }}
            checked={filteredAllChecked || value.length === options.length}
          />
          <div className='p-multiselect-filter-container'>
            <InputText id={'inline-multiselect-search-input'} autoFocus value={filter} onChange={(e) => setFilter(e.target.value)} />
            <span className='p-multiselect-filter-icon pi pi-search'>
            </span>
          </div>
          <Button onClick={() => setIsContentVisible(false)} icon='pi pi-times' className='p-multiselect-close p-button-link' aria-label='Close' />
        </div>
        {/* items list */}
        <div className='p-multiselect-items-wrapper' style={{ maxHeight: maxHeight }}>
          <ul className='p-multiselect-items p-component' role='listbox' aria-multiselectable='true'>
            {
              options.length === 0 && loading &&
              <div className='w-full h-full flex align-items-center justify-content-center'>
                <ProgressSpinner/>
              </div>
            }
            {options.length === 0 && !loading
              ? <li className='p-multiselect-empty-message'>No hay opciones disponibles</li>
              : filteredOptions.map((el, i) => {
                return <ItemTemplate
                  isChecked={value.some(v => v === el[optionValue])}
                  key={el[optionValue] + i + el[optionLabel]}
                  onChange={() => {
                    const index = value.findIndex(v => v === el[optionValue])
                    const notSelected = index === -1
                    if (notSelected) {
                      onChange([...value, el[optionValue]])
                    } else {
                      onChange(value.filter(v => v !== el[optionValue]))
                    }
                  }}
                >
                  {itemTemplate(el)}
                </ItemTemplate>
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

const ItemTemplate = ({ children, onChange, isChecked }) => {
  const checkboxId = Math.random().toString(36).substring(7)
  return (
    <li onClick={() => {
      onChange(!isChecked)
    }} className={clsx('p-multiselect-item', isChecked && 'p-highlight')} tabIndex='0' role='option' aria-selected='false'>
      <InputCheckbox inputId={checkboxId} checked={isChecked} />
      <span>
        {children}
      </span>
      <span role='presentation' className='p-ink'>
      </span>
    </li>
  )
}
