import React from "react"
import MainTitle from "../../../components/molecules/titles/MainTitle.molecule"
import SelectButton from "../../../components/atoms/SelectButton.atom"
import { PENDING_TASK_VIEW_SELECT_OPTIONS } from "../../../consts/modules/pendingTasks.consts"
const PendingTasksHeader = ({ selectedView, setSelectedView }) => {
  return (
    <div className="pending-tasks-view-header flex justify-content-between">
      <MainTitle className="mb-4" title="Lista de pendientes" />
      <ViewSelector value={selectedView} setSelectedView={setSelectedView} />
    </div>
  )
}

const ViewSelector = ({
  options = PENDING_TASK_VIEW_SELECT_OPTIONS,
  value,
  setSelectedView,
}) => {
  return (
    <SelectButton
      className="pending-task-view-selector"
      value={value}
      options={options}
      onChange={(e) => {
        setSelectedView(e.value ?? value)
      }}
      unselectable
    />
  )
}

export default PendingTasksHeader
