import React, { useEffect, useState } from "react"
import Card from "../../../../components/atoms/Card.atom"
import { ReactComponent as CalendarTimeIcon } from "../../../../design/assests/images/icons/time.svg"
import { getTeamMembers } from "../../../../services/teamMember.service"
import { getUserFollowedTasks } from "../../../../services/followedTask.service"
import { FOLLOWED_TASKS_SORT_OPTIONS } from "../../../../consts/modules/pendingTasks.consts"
import CustomSortOverlay from "../../components/CustomSortOverlay.compound"
import MyFollowedTasksDataTable from "../../components/MyFollowedTasksDataTable.compound"

const FollowedTasksContent = () => {
    // Refresh states
    const [refresh, setRefresh] = useState(false)
    const triggerRefresh = () => setRefresh(!refresh)

    const [loading, setLoading] = useState(true)
    const [activeTeamMembers, setActiveTeamMembers] = useState([])
    const [followedTasks, setFollowedTasks] = useState([])
    const [sortConfig, setSortConfig] = useState({
      label: "Por fecha de creación",
      icon: <CalendarTimeIcon className="clr-text p-menuitem-icon svg" />,
      field: "taskCreatedAt",
      order: "desc",
    })
  
    useEffect(() => {
      setLoading(true)
      Promise.all([
        gettingActiveTeamMembersData(),
        gettingUserFollowedTasks()
      ]).finally(() => setLoading(false))
    }, [refresh, sortConfig])

    const gettingActiveTeamMembersData = async () => {
      const response = await getTeamMembers()
      if (response.success) {
        setActiveTeamMembers(response.result)
      }
    }
  
    const gettingUserFollowedTasks = async () => {
      if (sortConfig.field === "custom") {
        return
      }
      const response = await getUserFollowedTasks({
        label: sortConfig.label,
        field: sortConfig.field,
        order: sortConfig.order,
      })
      if (response.success) {
        setFollowedTasks(response.result)
      }
    }

    const HeaderTemplate = () => {
      return (
        <div className="flex justify-content-between align-items-center">
          <div></div>
          <CustomSortOverlay
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            sortConfigOptions={FOLLOWED_TASKS_SORT_OPTIONS}
          />
        </div>
      )
    }
  
    document.documentElement.style.setProperty(
      "--fill-drop-area-task-list",
      "20px"
    )
    return (
      <Card className="pending-task-list-wrapper shadow-2 min-h-0 overflow-hidden">
        {HeaderTemplate()}
        <div className="pending-task-list-content flex flex-column gap-3">
          <>
            <MyFollowedTasksDataTable
              tasks={followedTasks}
              activeTeamMembers={activeTeamMembers}
              showLoading={true}
              loading={loading}
              triggerRefresh={triggerRefresh}
            />
          </>
        </div>
      </Card>
    )
}

export default FollowedTasksContent
