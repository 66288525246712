import React from "react"
import DataTable from "../../../components/atoms/table/DataTable.atom"
import Column from "../../../components/atoms/table/Column.atom"
import IconWithTooltipAndCustomBody from "../../../components/molecules/IconWithTooltipAndCustomBody.molecule"
import TendencyChip from "../components/TendencyChip"
import { TimeDifferenceBadge } from "../components/TimeDifferenceBadge"
import { numberFormatter } from "../../../utils/numbers.utils"
import { formatPercentageSuffix } from "../../../utils/strings.utils"
import { DateTime } from "luxon"
import { CustomMessage } from "../../../components/compounds/customMessages/CustomMessage.compound"
import {
  getBudgetMovementChipType,
  getBudgetMovementDescription,
  getBudgetMovementMonthlyLimitValue,
  getBudgetMovementQuantityValue,
  getBudgetMovementsEmptyMessage,
} from "../../../utils/budget.utils"
import { BUDGET_MOVEMENTS_TYPES } from "../../../consts/modules/budget.consts"

const BudgetMovementsTable = ({ data, budgetTypeId, loading }) => {
  const emptyMessage = getBudgetMovementsEmptyMessage(budgetTypeId)

  const descriptionColumn = () => {
    const descriptionTooltipTemplate = (data) => (
      <div className="flex flex-column">
        <span>Creador: {data.createdBy}</span>
        {data?.clientBudgetMovementTypeName !==
          BUDGET_MOVEMENTS_TYPES.CONFIGURACION && (
          <span>
            Autorización:
            {DateTime.fromISO(data.createdAt).toFormat(" dd/LL/yyyy HH:mm")}
          </span>
        )}
      </div>
    )
    return (
      <Column
        field="description"
        header="Motivo"
        body={(rowData) => {
          const descriptionValue = getBudgetMovementDescription(rowData)
          return (
            <div
              className="flex align-items-center"
              style={{
                gap: "0.5rem",
                width: "100%",
              }}
            >
              <span>{descriptionValue}</span>
              {(
                rowData.clientBudgetMovementTypeName !==
                BUDGET_MOVEMENTS_TYPES.CONSUMO_AUTOMATICO
              ) ?
                <IconWithTooltipAndCustomBody
                  iconClassName="pi pi-exclamation-circle text-xl"
                  tooltipBody={descriptionTooltipTemplate(rowData)}
                  position="bottom"
                />
              : null}
            </div>
          )
        }}
      />
    )
  }

  const quantityColumn = () => {
    const headerTemplate = (
      <>
        Monto <span className="font-normal">(horas)</span>
      </>
    )
    const getBudgetProgressPercentage = (rowData) => {
      if (
        rowData.quantity !== undefined &&
        rowData.suggestedMonthlyBudgetLimit !== undefined
      ) {
        if (!parseFloat(rowData.suggestedMonthlyBudgetLimit)) return 0
        return formatPercentageSuffix(
          (Math.abs(rowData.quantity) - rowData.suggestedMonthlyBudgetLimit) /
            rowData.suggestedMonthlyBudgetLimit
        )
      }
      return 0
    }
    return (
      <Column
        field="quantity"
        header={headerTemplate}
        body={(rowData) => {
          const percentage = getBudgetProgressPercentage(rowData)
          const quantityValue = getBudgetMovementQuantityValue(rowData)
          if (
            rowData?.clientBudgetMovementTypeName ===
            BUDGET_MOVEMENTS_TYPES.CONFIGURACION
          ) {
            return null
          }
          return (
            <div
              className="flex align-items-center flex-wrap"
              style={{
                gap: "0.5rem",
                width: "100%",
              }}
            >
              {}
              <span>{quantityValue}</span>
              {(
                rowData.clientBudgetMovementTypeName ===
                  BUDGET_MOVEMENTS_TYPES.CONSUMO_AUTOMATICO &&
                Number(rowData.suggestedMonthlyBudgetLimit)
              ) ?
                <TimeDifferenceBadge
                  value={Math.abs(Number(rowData.quantity))}
                  referenceValue={rowData.suggestedMonthlyBudgetLimit}
                  minTreshold={0}
                  maxTreshold={0}
                  percentage={percentage}
                />
              : null}
            </div>
          )
        }}
        style={{ maxWidth: "min(14%, 214px)" }}
      />
    )
  }
  return (
    <DataTable
      className="budget-movements-and-updates-table budget-movements-table"
      value={data}
      rowClassName={(rowData) => {
        return rowData.isDisabled ? "budget-movement-disabled" : ""
      }}
      scrollable
      scrollHeight="100%"
      style={{
        tableLayout: "fixed",
        minHeight: data?.length === 0 ? "215px" : "80px",
        height: "100%",
      }}
      emptyCustomMessage={
        <CustomMessage
          className="mx-auto"
          title={emptyMessage.title}
          icon={emptyMessage.icon}
          message={emptyMessage.message}
        />
      }
      loading={loading}
      loadingConfig={{
        qtyOfRows: 4,
      }}
    >
      <Column
        field="createdAtBadge"
        header=""
        body={(rowData) => {
          return <TendencyChip severity={getBudgetMovementChipType(rowData)} />
        }}
        style={{ maxWidth: "31.5px" }}
      />
      <Column
        field="createdAt"
        header="Fecha"
        body={(rowData) => {
          const formattedDate = DateTime.fromISO(rowData.createdAt).toFormat(
            "dd/LL/yyyy"
          )
          return <span>{formattedDate}</span>
        }}
        style={{ maxWidth: "114px" }}
      />
      <Column field="generatedId" header="ID" style={{ maxWidth: "128px" }} />
      {descriptionColumn()}
      {quantityColumn()}
      <Column
        field="suggestedMonthlyBudgetLimit"
        header="Límite sugerido"
        body={(rowData) => {
          const monthlyLimitValue = getBudgetMovementMonthlyLimitValue(rowData)
          if (
            rowData.clientBudgetMovementTypeName ===
            BUDGET_MOVEMENTS_TYPES.CONFIGURACION
          )
            return null
          return <>{numberFormatter(monthlyLimitValue)}</>
        }}
        style={{ maxWidth: "min(14%, 214px)" }}
      />
      <Column
        field="remainingBudget"
        header="Saldo"
        body={(rowData) => numberFormatter(rowData.remainingBudget)}
        style={{ maxWidth: "min(14%, 214px)" }}
      />
    </DataTable>
  )
}

export default BudgetMovementsTable
