import React from "react"
import { useNavigate } from "react-router-dom"

const ReleaseVersion = ({ ...props }) => {
  if (!process.env.REACT_APP_RELEASE_VERSION) return null
  const navigate = useNavigate()

  const redirectToChangeLog = (e) => {
    e.preventDefault()
    navigate("/change-log")
  }

  return (
    <div
      {...props}
      className={`release-version-note ${props.className}`}
      onClick={(e) => redirectToChangeLog(e)}
    >
      {process.env.REACT_APP_RELEASE_VERSION}
    </div>
  )
}

export default ReleaseVersion
