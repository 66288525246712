import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Card from "../../../../components/atoms/Card.atom"
import {
  getActiveProjects,
  getAllProjects,
} from "../../../../services/project.service"
import { getTeamMembers } from "../../../../services/teamMember.service"
import CustomSortOverlay from "../../components/CustomSortOverlay.compound"
import LabelWithQuantityBadge from "../../components/LabelWithQuantityBadge.molecule"
import { getUserPendingTasksWithCustomSort } from "../../../../services/pendingTask.service"
import { Panel } from "../../../../components/atoms/Panel.atom"
import MyPendingTasksDataTable from "../../components/MyPendingTasksDataTable.compound"
import {
  LOCAL_STORAGE_CUSTOM_TASK_ORDER,
  LOCAL_STORAGE_SORT_CONFIG,
  MY_TASKS_DEFAULT_SORT_CONFIG,
  PENDING_TASK_FETCH_DATA_INTERVAL,
  SORT_OVERLAY_VALUES,
} from "../../../../consts/modules/pendingTasks.consts"
import { useLocalStorage } from "../../../../hooks/useLocalStorage.hook"
import { useSession } from "../../../../hooks/auth/useSession.hook"
import _ from "lodash"

const PendingTaskList = ({ refresh, setTodoTasksLength, triggerRefresh }) => {
  const [loading, setLoading] = useState(true)
  const [finishedTasks, setFinishedTasks] = useState([])
  const [todoTasks, setTodoTasks] = useState([])
  const [activeProjects, setActiveProjects] = useState([])
  const [activeTeamMembers, setActiveTeamMembers] = useState([])
  const [prevSortConfig, setPrevSortConfig] = useState({})
  const [sortConfig, setSortConfig] = useLocalStorage(
    LOCAL_STORAGE_SORT_CONFIG,
    MY_TASKS_DEFAULT_SORT_CONFIG
  )
  const [todoTasksOrder, setTodoTasksOrder] = useLocalStorage(
    LOCAL_STORAGE_CUSTOM_TASK_ORDER,
    []
  )
  const { user } = useSession()

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
    }, PENDING_TASK_FETCH_DATA_INTERVAL)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!_.isEqual(prevSortConfig, sortConfig)) {
      setPrevSortConfig(sortConfig)
      fetchData()
    }
  }, [sortConfig])

  useEffect(() => {
    fetchData()
  }, [refresh])

  const fetchData = async () => {
    setLoading(true)
    await Promise.all([
      gettingActiveProjectsData(),
      gettingActiveTeamMembersData(),
      gettingUserTasksWithCustomSort(),
    ])
    setLoading(false)
  }

  const gettingActiveProjectsData = async () => {
    // const response = await getActiveProjects()
    const response = await getAllProjects()
    if (response.success) {
      setActiveProjects(response.result)
    }
  }

  const gettingActiveTeamMembersData = async () => {
    const response = await getTeamMembers()
    if (response.success) {
      const allTeamMembers = response.result
      const teamMemberExceptMe = allTeamMembers.filter(
        (tm) => user.id !== tm.id
      )
      setActiveTeamMembers(teamMemberExceptMe)
    }
  }
  const gettingUserTasksWithCustomSort = async () => {
    let sortConfigToPost = { ...sortConfig }
    if (sortConfig.field === SORT_OVERLAY_VALUES.CUSTOM) {
      sortConfigToPost = {
        ...MY_TASKS_DEFAULT_SORT_CONFIG,
      }
    }
    const response = await getUserPendingTasksWithCustomSort({
      label: sortConfigToPost.label,
      field: sortConfigToPost.field,
      order: sortConfigToPost.order,
    })
    if (response.success) {
      processTasks(response.result)
    }
  }

  const processTasks = (tasks) => {
    const combinedTasks = tasks.reduce(
      (acc, task) => {
        if (task.isFinished) {
          acc.finishedTasks.push(task)
        } else {
          acc.todoTasks.push(task)
        }
        return acc
      },
      { finishedTasks: [], todoTasks: [] }
    )
    if (
      sortConfig.field === SORT_OVERLAY_VALUES.CUSTOM &&
      todoTasksOrder.length > 0
    ) {
      combinedTasks.todoTasks = applyLocalStorageCustomSort(
        combinedTasks.todoTasks
      )
      updateTodoTasksOrder(combinedTasks.todoTasks)
    }
    setFinishedTasks(combinedTasks.finishedTasks)
    setTodoTasks(combinedTasks.todoTasks)
    setTodoTasksLength(combinedTasks.todoTasks.length)
  }

  const applyLocalStorageCustomSort = (tasks) => {
    const ordered = todoTasksOrder
      .map((order) => tasks.find((task) => task.id === order.id))
      .filter(Boolean)
    const unordered = tasks.filter(
      (task) => !todoTasksOrder.some((order) => order.id === task.id)
    )
    return [...ordered, ...unordered]
  }
  const updateTodoTasksOrder = (tasks) => {
    const taskOrder = tasks.map((task, index) => ({
      id: task.id,
      orderIndex: index,
    }))
    setTodoTasksOrder(taskOrder)
  }

  const handleTodoItemDrop = async (event) => {
    const newOrderedTasks = event.value
    setTodoTasks(newOrderedTasks)
    const newOrder = newOrderedTasks.map((task, index) => ({
      id: task.id,
      orderIndex: index,
    }))
    setTodoTasksOrder(newOrder)
  }

  return (
    <Card className="pending-task-list-wrapper shadow-2 min-h-0 overflow-hidden">
      <HeaderTemplate
        todoTasks={todoTasks}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
      <div className="pending-task-list-content flex flex-column gap-3">
        <MyPendingTasksDataTable
          tasks={todoTasks}
          activeProjects={activeProjects}
          activeTeamMembers={activeTeamMembers}
          showLoading
          loading={loading}
          sortConfig={sortConfig}
          triggerRefresh={triggerRefresh}
          handleItemDrop={handleTodoItemDrop}
          isReorderable
        />
        {finishedTasks.length > 0 && (
          <Panel
            className="finished-pending-task-list"
            headerTemplate={finishedTasksPanelHeaderTemplate(finishedTasks)}
            toggleable
            collapsed={true}
          >
            <MyPendingTasksDataTable
              tasks={finishedTasks}
              activeProjects={activeProjects}
              activeTeamMembers={activeTeamMembers}
              showLoading={false}
              isReorderable={false}
              showCustomSort={false}
              triggerRefresh={triggerRefresh}
            />
          </Panel>
        )}
      </div>
    </Card>
  )
}

const HeaderTemplate = ({ todoTasks, sortConfig, setSortConfig }) => (
  <div className="flex justify-content-between align-items-center">
    <LabelWithQuantityBadge
      labelTemplate="Por hacer"
      labelProps={{
        className: "font-cairo text-xl clr-text font-semibold",
      }}
      containerProps={{
        style: {
          gap: "8px",
        },
      }}
      quantity={todoTasks.length}
    />
    <CustomSortOverlay
      sortConfig={sortConfig}
      setSortConfig={setSortConfig}
      showCustomSort
      disabled={todoTasks.length === 0}
    />
  </div>
)

const finishedTasksPanelHeaderTemplate = (finishedTasks) => (options) => {
  const toggleIcon = options.collapsed ? "pi pi-angle-down" : "pi pi-angle-up"
  const className = `${options.className} justify-content-start`
  const titleClassName = `${options.titleClassName} pl-1`

  return (
    <div className={className}>
      <button
        className={options.togglerClassName}
        onClick={options.onTogglerClick}
      >
        <span className={toggleIcon}></span>
      </button>
      <LabelWithQuantityBadge
        labelTemplate="Finalizadas de hoy"
        labelProps={{
          className: `font-cairo text-xl clr-text font-semibold ${titleClassName}`,
        }}
        containerProps={{
          style: {
            gap: "8px",
          },
        }}
        badgeProps={{
          style: {
            background: "var(--secondary-color)",
          },
        }}
        quantity={finishedTasks.length}
      />
    </div>
  )
}

export default PendingTaskList

PendingTaskList.propTypes = {
  refresh: PropTypes.bool,
  setTodoTasksLength: PropTypes.func,
  triggerRefresh: PropTypes.func,
}
