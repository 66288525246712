import { API_PENDING_TASK } from "../consts/apiRoutes.consts"
import SERVICE_CONSTS from "../consts/services.consts"
import { api } from "./base/api.service"

const { RESPONSE_BASIC_STRUCTURE, MESSAGES } = SERVICE_CONSTS

export const createPendingTask = async (pendingTaskData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_PENDING_TASK}/createPendingTask`,
      pendingTaskData
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getUserPendingTasksWithCustomSort = async (sortConfig) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: [] }

  let result
  try {
    result = await api.post(
      `${API_PENDING_TASK}/getUserPendingTasksWithCustomSort`,
      {
        sortConfig,
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const finishPendingTaskById = async (pendingTaskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_PENDING_TASK}/finishPendingTaskById`, {
      pendingTaskId,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const unfinishPendingTaskById = async (pendingTaskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_PENDING_TASK}/unfinishPendingTaskById`, {
      pendingTaskId,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const updatePendingTask = async (pendingTaskData) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.put(`${API_PENDING_TASK}/updatePendingTask`, {
      pendingTaskData,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const deletePendingTaskById = async (pendingTaskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.delete(`${API_PENDING_TASK}/${pendingTaskId}`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
