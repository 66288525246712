import React, { useState } from "react"
import LinkOffIcon from "@mui/icons-material/LinkOffOutlined"
import FollowedTaskDate from "./FollowedTaskDate.atom"
import DropdownFollowerInput from "./DropdownFollowerInput.compound"
import InputTimeOverlay from "./InputTimeOverlay.compound"
import InputCommentOverlay, {
  INPUT_COMMENT_OVERLAY_VARIANTS,
} from "./InputCommentOverlay.compound"
import _ from "lodash"
import MarkAsFinishedIcon from "../../../components/molecules/MarkAsFinishedIcon.molecule"
import ClientProjectChip from "../../../components/molecules/ClientProjectChip.molecule"
import CustomInputTextInplace from "./CustomInputTextInplace.compound"
import useSessionContext from "../../../hooks/contextConsumers/useSessionContext.hook"
import UserChip from "../../../components/molecules/UserChip.molecule"
import FinishedChip from "./FinishedChip.molecule"

const FollowedTaskItem = ({
  name,
  projectId,
  projectName,
  clientCode,
  projectTagName,
  limitDate,
  followerIds,
  estimatedTime,
  comment,
  activeTeamMembers,
  isFinished = 0,
  isArchived = false,
  userId,
  userName,
  userLastName,
  userTagName,
  userColor,
  finishService,
}) => {
  const initialFormData = {
    name: name || "",
    projectId: projectId,
    limitDate: limitDate,
    followerIds: followerIds || [],
    estimatedTime: estimatedTime,
    comment: comment || "",
    isFinished: isFinished,
  }
  const [data, setData] = useState(initialFormData)
  const [submitting, setSubmitting] = useState(false)
  const { user } = useSessionContext()
  const isCreator = user.id === userId

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value })
  }

  return (
    <div
      className={`pending-task-wrapper w-full flex align-items-center justify-content-flex-start h-fit gap-3 ${submitting ? "pointer-events-none" : ""}`}
    >
      <UserChip
        user={{
          name: userName,
          lastName: userLastName,
          nameTag: userTagName,
          color: userColor,
        }}
        variant="custom"
        size="large"
        useTooltip
      />
      <div className="w-full flex flex-column pending-task-data gap-2">
        <CustomInputTextInplace
          value={data.name}
          inputClassName={"font-mulish"}
          readOnly={true}
        />
        <div className="flex gap-2 align-items-center flex-wrap pending-task-chips">
          {(clientCode && projectTagName) ? <div
            className="dropdown-project-filter-inplace input-panel-with-chip"
            style={{
              width: "92px",
              height: "26px",
            }}
          >
            <ClientProjectChip
              clientCode={clientCode}
              projectCode={projectTagName}
              onClick={() => {}}
              tooltipPosition="bottom"
              className={"p-chip-readonly"}
              style={{
                top: "50%",
                transform: "translateY(-50%)",
                width: "88px",
              }}
            />
          </div> : null}
          {(data.limitDate && data.limitDate !== "") ? <FollowedTaskDate
            value={data.limitDate}
          /> : null}
          {(data.followerIds && data.followerIds.length > 0) ? <DropdownFollowerInput
            options={activeTeamMembers}
            selectedValues={data.followerIds}
            iconClassName="pi-plus"
            readOnly={true}
          /> : null}
          {(data.estimatedTime && Number(data.estimatedTime) > 0) ? <InputTimeOverlay
            key={submitting}
            currentValue={data.estimatedTime}
            readOnly={true}
          /> : null}
          {(data.comment && data.comment !== "") ? <InputCommentOverlay
            currentValue={data.comment}
            variant={INPUT_COMMENT_OVERLAY_VARIANTS.SHORT}
            readOnly={true}
          /> : null}
        </div>
      </div>
      <FinishedChip
        finishedState={data.isFinished}
      />
      <MarkAsFinishedIcon
        CustomIcon={LinkOffIcon}
        onClick={() => {
          // handleChange("isFinished", !data.isFinished)
          // readOnly = true
          finishService()
        }}
        tooltipBodyIfFinished = "Dejar de seguir"
        tooltipBodyIfNotFinished = "Dejar de seguir"
        loading={submitting}
      />
    </div>
  )
}

export default FollowedTaskItem
