import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { InputText } from "primereact/inputtext"
import { ReactComponent as DragFullIcon } from "../../../../../design/assests/images/icons/drag_full.svg"
import { ReactComponent as DragLateralIcon } from "../../../../../design/assests/images/icons/drag_lateral.svg"
import { ReactComponent as CheckedCheckboxIcon } from "../../../../../design/assests/images/icons/checked_checkbox.svg"
import { MODULE_SORT_OPTIONS } from "../../../../../consts/modules/clients.consts"

const ModuleTemplate = React.forwardRef(
  (
    {
      orderMode,
      module,
      onEdit,
      onDelete,
      visibleModalWarn,
      columnTitle,
      readOnly = false,
    },
    ref
  ) => {
    const [newValue, setNewValue] = useState(module.name)
    const [editMode, setEditMode] = useState(false)
    useEffect(() => {
      if (editMode && !visibleModalWarn) {
        setEditMode(false)
      }
    }, [visibleModalWarn])
    if (editMode) {
      return (
        <div className="custom-item module-item flex justify-content-between gap-3">
          <InputText
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
          />
          <div className="module-item-controls-visible flex gap-3 align-items-center">
            <i
              className="pi pi-times"
              onClick={(e) => {
                setEditMode(false)
                e.stopPropagation()
                e.preventDefault()
              }}
            ></i>
            <i
              className="pi pi-check"
              onClick={(e) => {
                onEdit({
                  ...module,
                  name: newValue,
                })
                e.stopPropagation()
                e.preventDefault()
              }}
            ></i>
          </div>
        </div>
      )
    } else {
      return (
        <div
          ref={ref}
          className="custom-item module-item flex justify-content-between"
        >
          <div
            style={{
              userSelect: "none",
            }}
            className="flex align-items-center gap-2"
          >
            {readOnly ?
              <div style={{ height: "26px" }}></div>
            : <div
                className="flex align-items-center"
                style={{ height: "26px" }}
              >
                {orderMode === 0 && columnTitle !== "Inactivos" ?
                  <DragFullIcon className="module-item-drag" ref={ref} />
                : <DragLateralIcon className="module-item-drag" ref={ref} />}
              </div>
            }
            <CheckedCheckboxIcon className="module-item-selected hidden" />
            {module.name}
          </div>
          {!readOnly && (
            <div className="module-item-controls gap-3 flex align-items-center">
              <i
                className="pi pi-pencil"
                onClick={(e) => {
                  setEditMode(true)
                  e.stopPropagation()
                  e.preventDefault()
                }}
              ></i>
              <i
                className={`pi pi-trash ${!module.isDeletable ? "pointer-events-none opacity-50" : ""}`}
                onClick={(e) => {
                  onDelete(module)
                  e.stopPropagation()
                  e.preventDefault()
                }}
              ></i>
            </div>
          )}
        </div>
      )
    }
  }
)

export default ModuleTemplate

ModuleTemplate.propTypes = {
  orderMode: PropTypes.oneOf(Object.values(MODULE_SORT_OPTIONS)),
  module: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  visibleModalWarn: PropTypes.bool,
  columnTitle: PropTypes.string,
}
