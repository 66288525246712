import { API_FOLLOWED_TASK } from "../consts/apiRoutes.consts"
import SERVICE_CONSTS from "../consts/services.consts"
import { api } from "./base/api.service"

const { RESPONSE_BASIC_STRUCTURE, MESSAGES } = SERVICE_CONSTS

export const getUserFollowedTasks = async (sortConfig) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: [] }

  let result
  try {
    result = await api.post(`${API_FOLLOWED_TASK}/getUserFollowedTasks`, {
      sortConfig
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const unfollowTaskById = async (followedTaskId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_FOLLOWED_TASK}/unfollowTaskById`, {
        followedTaskId,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
