import React, { useState } from "react"
import PropTypes from "prop-types"
import Card from "../../../components/atoms/Card.atom"
import { numberFormatter } from "../../../utils/numbers.utils"
import {
  ValueWithLabelContainer,
  ValueWithLabelTemplate,
} from "../components/ValueWithLabelElements"
import EditableElement from "../components/EditableElement"
import ModalFormChangeBudgetType from "../../../components/compounds/ModalFormChangeBudgetType.compound"
import { useLocation } from "react-router-dom"
import { displayValueOrPlaceholder } from "../../../utils/strings.utils"
import CardHeaderTemplate from "../components/CardHeaderTemplate"
import {
  isBudgetTypeCredit,
  isBudgetTypeDebit,
} from "../../../utils/budget.utils"
import EditSuggestedMonthlyTemplate from "../components/EditSuggestedMonthlyTemplate"
import { isObjectEmpty } from "../../../utils/object.utils"

const GeneralBudgetInformation = ({ data, loading, refreshData }) => {
  const [changeBudgetModalVisible, setChangeBudgetModalVisible] =
    useState(false)
  const { state: selectedClient } = useLocation()

  const onEditSuggestedMonthlyEditTemplate = ({ onClose, onBlur }) => {
    return (
      <EditSuggestedMonthlyTemplate
        client={data}
        onClose={onClose}
        onBlur={onBlur}
        refreshData={refreshData}
      />
    )
  }
  return (
    <Card
      id="general-budget-information-card"
      className="dashboard-card shadow-2 min-h-fit max-h-fit"
      style={{
        minHeight: "146px",
      }}
    >
      <div className="flex flex-column gap-3">
        <CardHeaderTemplate title="Información general" />
        <ValueWithLabelContainer>
          <ValueWithLabelTemplate
            label="Tipo de presupuesto"
            value={
              <EditableElement
                placeholderTemplate={data?.clientBudgetTypeName}
                onEdit={() => {
                  setChangeBudgetModalVisible(true)
                }}
              />
            }
            loading={loading}
          />
          {isBudgetTypeDebit(data?.clientBudgetTypeId) && (
            <ValueWithLabelTemplate
              label="Total de horas autorizadas"
              value={numberFormatter(data?.totalBudget) ?? "-"}
              loading={loading}
            />
          )}
          <ValueWithLabelTemplate
            label="Total de horas consumidas"
            value={numberFormatter(data?.totalBilledTime)}
            loading={loading}
          />
          <ValueWithLabelTemplate
            label={
              isBudgetTypeDebit(data?.clientBudgetTypeId) ?
                "Límite mensual sugerido"
              : "Límite actual sugerido"
            }
            value={
              <EditableElement
                placeholderTemplate={displayValueOrPlaceholder(
                  data?.suggestedMonthlyBudgetLimit
                )}
                onEditTemplate={onEditSuggestedMonthlyEditTemplate}
              />
            }
            loading={loading}
          />
          {isBudgetTypeCredit(data?.clientBudgetTypeId) && (
            <div className="flex-1 flex-shrink-0 white-space-nowrap"></div>
          )}
        </ValueWithLabelContainer>
      </div>
      {!isObjectEmpty(data) && (
        <ModalFormChangeBudgetType
          key={data}
          visible={changeBudgetModalVisible}
          client={{
            ...data,
            code: selectedClient?.code,
            name: selectedClient?.name,
            color: selectedClient?.color,
          }}
          setVisible={setChangeBudgetModalVisible}
          onCompleted={refreshData}
        />
      )}
    </Card>
  )
}

export default GeneralBudgetInformation

GeneralBudgetInformation.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
}
