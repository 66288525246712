import React from "react"
import InputText from "../../../components/atoms/InputText.atom"
import InputTextForm from "../../../components/molecules/InputTextForm.molecule"

const CustomInputTextInplace = ({
  id,
  value,
  name = "name",
  placeholder = "¿Tienes una tarea pendiente? Escríbela aquí",
  onChange,
  onBlur,
  error,
  inputClassName,
  inputProps,
  readOnly,
  isArchived,
  inputRef,
}) => {
  const CustomPlaceholder = () => (
    <p
      // eslint-disable-next-line max-len
      className={`absolute top-0 left-0 pointer-events-none m-0 p-inputtext warning-icon-next w-full ${error ? "opacity-100" : "opacity-0"} input-large-cairo-placeholder w-full flex-1 border-none bg-transparent px-0 py-0`}
    >
      <span className="opacity-0 pointer-events-none input-large-cairo-placeholder w-full flex-1 border-none bg-transparent px-0 py-0">
        {placeholder}
      </span>
      <i
        className="pi pi-exclamation-triangle mx-3"
        data-pr-tooltip="Este es un campo requerido"
        data-pr-position="bottom"
        style={{
          color: "#FC3D39",
          top: "50%",
          transform: "translateY(-40%)",
        }}
      />
    </p>
  )
  return (
    <div className="w-full relative pending-task-creation-name">
      <CustomPlaceholder />
      <InputTextForm
        ref={inputRef}
        id={id}
        type="text"
        value={value}
        className={`${inputClassName} input-large-cairo-placeholder w-full flex-1 border-none bg-transparent px-0 py-0`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        {...inputProps}
        disabled={readOnly}
        autoTrim
      />
    </div>
  )
}

export default CustomInputTextInplace
