import React, { useState } from "react"
import PropTypes from "prop-types"
import ModalForm from "../../../components/compounds/modalForm/ModalForm.compunds"
import PrimaryButton from "../../../components/molecules/buttons/PrimaryButton.molecule"
import SecondaryButton from "../../../components/molecules/buttons/SecondaryButton.molecule"
import {
  CLIENT_ACTION_MODAL_MESSAGES,
  CLIENT_TABLE_ROW_ACTIONS,
} from "../../../consts/modules/clients.consts"
import useToast from "../../../hooks/useToast.hook"

const ActionModal = ({
  row,
  visible,
  setVisible,
  modalAction,
  onFinishedSubmit,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const isClient = Boolean(row?.children)
  const isDeactivate = modalAction === CLIENT_TABLE_ROW_ACTIONS.DEACTIVATE
  const { setErrorMessage } = useToast()

  const getModalMessage = () => {
    if (isDeactivate) {
      return isClient ?
          CLIENT_ACTION_MODAL_MESSAGES.DEACTIVATE_CLIENT
        : CLIENT_ACTION_MODAL_MESSAGES.DEACTIVATE_PROJECT
    }
    return isClient ?
        CLIENT_ACTION_MODAL_MESSAGES.DELETE_CLIENT
      : CLIENT_ACTION_MODAL_MESSAGES.DELETE_PROJECT
  }

  const modalContent = getModalMessage()

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      const response = await modalContent.service(row.id)
      if (!response.success) {
        throw new Error("Error")
      }
      setVisible(false)
      onFinishedSubmit()
    } catch (error) {
      setErrorMessage({
        message: "Ha ocurrido un error al realizar la acción.",
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerIconClass="pi pi-info-circle"
      headerTitle={modalContent?.title}
      footer={
        <div>
          <SecondaryButton
            rounded
            label="Cancelar"
            type="text"
            onClick={() => setVisible(false)}
          />
          <PrimaryButton
            rounded
            icon={`pi ${submitting ? "pi-spin pi-spinner" : "pi-check"}`}
            label={"Aceptar"}
            onClick={handleSubmit}
            disabled={submitting}
          />
        </div>
      }
      service={handleSubmit}
    >
      <span>{modalContent?.description}</span>
    </ModalForm>
  )
}

export default ActionModal

ActionModal.propTypes = {
  row: PropTypes.object,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  modalAction: PropTypes.string,
  onFinishedSubmit: PropTypes.func,
}
