import React from "react"
import { Skeleton } from "../../atoms/misc/Skeleton.atom"

const addedStyles = new Set()
const lazyLoadingTemplate = (options) => {
  const isColumnInSkeleton = !options.column.props.hidden
  const id = `column-skeleton-${options.column.props.field}-${options.props.className}`
  if (!addedStyles.has(id) && isColumnInSkeleton) {
    const styleSheet = document.createElement("style")
    styleSheet.textContent = `
      td:has(.${id}) {
        width: ${isColumnInSkeleton ? (options.column.props.style.width ?? "auto") : "auto"};
        max-width: ${isColumnInSkeleton ? (options.column.props.style.maxWidth ?? "auto") : "auto"};
        min-width: ${isColumnInSkeleton ? (options.column.props.style.minWidth ?? "auto") : "auto"};
        flex: ${isColumnInSkeleton ? (options.column.props.style.flex + "!important" ?? "auto") : "1 0 auto"};
      }
    `
    document.head.appendChild(styleSheet)
    addedStyles.add(id)
  }
  return (
    <div
      className={`flex align-items-center ${id}`}
      style={{
        flexGrow: "1",
        overflow: "hidden",
        padding: "0.5rem",
        width: "100%",
      }}
    >
      <Skeleton className="column-skeleton" height="1rem" />
    </div>
  )
}

export default lazyLoadingTemplate
