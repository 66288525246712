import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Column from "../../../../../components/atoms/table/Column.atom"
import { CustomTreeTable as TreeTable } from "../../../../../components/atoms/table/CustomTreeTable.atom"
import { roundReportData } from "../../../../../utils/report.utils"
import { CLIENT_CHIP_COLUMN_PROPS } from "../../../../../consts/tables.consts"
import MultiSelectClientProjectFilter from "../../../../../components/compounds/MultiSelectClientProjectFilter.compound"
import { displayValueOrPlaceholder } from "../../../../../utils/strings.utils"

const calculateFilteredGroupHeadersTotals = (
  activeFilters,
  originalData,
  allTeamMembersKeys
) => {
  const clonedData = structuredClone(originalData)
  clonedData.data.forEach((el) => {
    allTeamMembersKeys.forEach((tm) => {
      el.data[tm] = 0
    })
  })

  const parentChildrenKeys = []
  const childrenEls = []
  originalData.data.forEach((el) => {
    for (const child of el.children) {
      if (activeFilters.includes(child.data.name)) {
        parentChildrenKeys.push([child.key, el.key])
        childrenEls.push(child)
      }
    }
  })

  for (const [key, parentKey] of parentChildrenKeys) {
    clonedData.data.forEach((el) => {
      if (el.key === parentKey) {
        const parent = originalData.data.find((el) => el.key === parentKey)
        for (const subChild of parent.children) {
          if (subChild.key === key) {
            allTeamMembersKeys.forEach((tm) => {
              el.data[tm] += subChild.data[tm]
            })
          }
        }
      }
    })
  }

  return clonedData
}

const calculateFooterTotals = (clonedData, allTeamMembersKeys) => {
  const finalFooterTotals = {}
  allTeamMembersKeys.forEach((tm) => {
    finalFooterTotals[tm] = 0
  })

  for (const item of clonedData.data) {
    allTeamMembersKeys.forEach((tm) => {
      finalFooterTotals[tm] += item.data[tm]
    })
  }

  return finalFooterTotals
}

const HoursByTeamAndTeamMemberReportDataTable = React.forwardRef(
  ({ reportData: originalData, teamMembers, dateRange, period }, ref) => {
    const [expandedKeys, setExpandedKeys] = useState({})
    const [filters, setFilters] = useState({
      "data.name": { value: null, matchMode: "in" },
    })
    const [reportData, setReportData] = useState(roundReportData(originalData))
    if (!teamMembers?.length) {
      teamMembers = reportData.teamMembers.map((tm) => tm.teamMemberTagName)
    }

    useEffect(() => {
      const activeFilters = filters["data.name"].value
      const isFilteringName = activeFilters !== null && activeFilters.length > 0

      if (isFilteringName) {
        const allTeamMembersKeys = reportData.teamMembers.map(
          (tm) => tm.teamMemberTagName
        )

        const clonedData = calculateFilteredGroupHeadersTotals(
          activeFilters,
          originalData,
          allTeamMembersKeys
        )

        clonedData.totals = calculateFooterTotals(
          clonedData,
          allTeamMembersKeys
        )

        setReportData(roundReportData(clonedData))
      } else {
        const clonedData = structuredClone(originalData)

        setReportData(roundReportData(clonedData))
      }
    }, [filters])

    const collapseAll = () => {
      setExpandedKeys({})
    }

    const expandAll = () => {
      const newExpandedKeys = {}
      reportData.data.forEach((node) => {
        newExpandedKeys[node.key] = true
      })
      setExpandedKeys(newExpandedKeys)
    }

    return (
      <>
        <TreeTable
          ref={ref}
          className="hours-by-team-and-team-member-report"
          value={reportData.data}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          filters={filters}
          globalFilterFields={["data.name"]}
          scrollable
          scrollHeight="100%"
          frozenExpandedColumn
        >
          <Column
            {...CLIENT_CHIP_COLUMN_PROPS(
              {
                field: "data.name",
                filter: true,
                filterElement: (options) => (
                  <MultiSelectClientProjectFilter
                    key={reportData.data?.length}
                    options={options}
                    data={reportData.data}
                  />
                ),
                filterField: "data.name",
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMatchMode: "in",
                onFilterApplyClick: (e) => {
                  if (!e.constraints?.value) {
                    collapseAll()
                  } else {
                    expandAll()
                    setFilters({
                      "data.name": {
                        value: e.constraints?.value,
                        matchMode: "in",
                      },
                    })
                  }
                },
                onFilterClear: () => {
                  collapseAll()
                  setFilters({
                    "data.name": { value: null, matchMode: "in" },
                  })
                },
                footer: () => <p style={{ translate: -45 }}>Totales</p>,
                frozen: true,
              },
              "Proyecto"
            )}
          />
          {teamMembers?.length > 0 ?
            teamMembers.map((tm) => {
              const teamMember = reportData.teamMembers.find(
                (t) => t.teamMemberTagName === tm
              )
              if (!teamMember) return null

              return (
                <Column
                  key={teamMember}
                  field={`data.${teamMember?.teamMemberTagName}`}
                  headerTooltip={teamMember?.teamMemberName}
                  headerTooltipOptions={{ position: "bottom" }}
                  headerClassName="cursor-pointer"
                  header={() => {
                    const [startDate, endDate] = dateRange
                    return (
                      <Link
                        className="team-member-name"
                        to="/reporte-individual"
                        state={{
                          teamMemberId: teamMember?.teamMemberId,
                          startDate: startDate.toISODate(),
                          endDate: endDate.toISODate(),
                          period,
                        }}
                        title="Ir al reporte individual ↗"
                      >
                        {teamMember?.teamMemberTagName}
                      </Link>
                    )
                  }}
                  body={(node) => (
                    <span>
                      {displayValueOrPlaceholder(
                        node.data[teamMember?.teamMemberTagName]
                      )}
                    </span>
                  )}
                  style={{ minWidth: 63 }}
                  footer={() => {
                    return displayValueOrPlaceholder(
                      reportData.totals[teamMember?.teamMemberTagName]
                    )
                  }}
                />
              )
            })
          : <Column body={"-"} footer={"-"} />}
        </TreeTable>
      </>
    )
  }
)

export default HoursByTeamAndTeamMemberReportDataTable
