import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react"
import { CSSTransition } from "react-transition-group"
import { classNames } from "primereact/utils"
import { Ripple } from "primereact/ripple"
import useSidebarContext from "../../hooks/contextConsumers/useSidebarContext.hook"
import useScreenDimensionsContext from "../../hooks/contextConsumers/useScreenDimensionsContext.hook"
import IconMaterial from "../../components/atoms/IconMaterial.atom"
import { useNavigate } from "react-router-dom"

const checkSectionIfEqualsToPath = (keyPath, item) => {
  if (keyPath === item.path) return true
  if (!item?.modules) return false
  return item.modules.some((itemModule) => itemModule.path === keyPath)
}

export const AppSubmenu = forwardRef(
  /**
   *
   * @param {*} props
   * @param {*} ref
   * @returns
   */
  (props, ref) => {
    const {
      keyPath,
      setKeyPath,
      setScreenTitle,
      setSidebarActive,
      setBockOpenTheNormalMenu,
    } = useSidebarContext()
    const { setShowFiltersMenu, setHideMobileMenu } =
      useScreenDimensionsContext()
    const [activeIndex, setActiveIndex] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
      if (!props?.items) return
      const result = props?.items.reduce((acc, item, index) => {
        const activeByPath =
          keyPath ? checkSectionIfEqualsToPath(keyPath, item) : false
        if (activeByPath) acc = index
        return acc
      }, null)
      setActiveIndex(result)
    }, [keyPath])

    function onMenuItemClick(event, item, index) {
      event.preventDefault()
      if (item.command) {
        item.command({ originalEvent: event, item })
      }

      if (item.modules) {
        setActiveIndex(index === activeIndex ? null : index)
        if (index === activeIndex) {
          document
            .querySelector(".layout-root-menuitem:has(.active-route)")
            ?.classList.add("active-menuitem")
        } else {
          document
            .querySelector(".layout-root-menuitem:has(.active-route)")
            ?.classList.remove("active-menuitem")
        }
      } else {
        setActiveIndex(index)
      }

      props.onMenuItemClick({
        originalEvent: event,
        item,
      })
      if (item.path) {
        navigate(item.path)
        setKeyPath(item.path)
        setScreenTitle(item.name)
        setSidebarActive(true)
        setBockOpenTheNormalMenu(true)
        if (item.path !== "/") {
          setShowFiltersMenu(true)
        } else {
          setHideMobileMenu(true)
        }
        setTimeout(() => {
          setBockOpenTheNormalMenu(false)
        }, 200)
      }
    }

    function onMenuItemMouseEnter(index) {
      if (props.root && props.menuActive && isHorizontalOrSlim()) {
        setActiveIndex(index)
      }
    }
    function isStatic() {
      return props.menuMode === "static"
    }

    const isHorizontalOrSlim = useCallback(() => {
      return props.menuMode === "horizontal" || props.menuMode === "slim"
    }, [props.menuMode])

    function getLink(item, index) {
      const renderIcon = (item) => {
        let menuitemIconClassName = null
        if (item?.iconClass) {
          menuitemIconClassName = classNames(
            "layout-menuitem-icon",
            item?.iconClass
          )
        }
        if (menuitemIconClassName) {
          return <i className={menuitemIconClassName}></i>
        }
        if (item?.iconSvg) {
          return (
            <IconMaterial
              fontSize="medium"
              icon={item?.iconSvg}
              color="gray"
              inheritViewBox
            />
          )
        }
        return <IconMaterial fontSize="medium" icon={item?.icon} color="gray" />
      }
      const content = (
        <>
          {renderIcon(item)}
          <span className="layout-menuitem-text">{item.name}</span>
          {item.modules && (
            <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler"></i>
          )}
          <Ripple />
        </>
      )
      const commonLinkProps = {
        style: item.style,
        className: classNames(item.className, "p-ripple", {
          "p-disabled": item.disabled,
          "active-route": item.path === keyPath,
        }),
        target: item.target,
        onClick: (e) => onMenuItemClick(e, item, index),
        onMouseEnter: () => onMenuItemMouseEnter(index),
      }
      return (
        <a
          href={item.url}
          rel="noopener noreferrer"
          tabIndex={item.url ? -1 : 0}
          {...commonLinkProps}
        >
          {content}
        </a>
      )
    }

    function getItems() {
      const transitionTimeout =
        isHorizontalOrSlim() && !props.root ? { enter: 1000, exit: 450 }
        : isHorizontalOrSlim() ? 0
        : { enter: 1000, exit: 450 }
      return props.items.map((item, i) => {
        const submenuRef = createRef()

        const active = activeIndex === i
        const menuitemClassName = classNames({
          "layout-root-menuitem": props.root,
          "active-menuitem": active && !item.disabled,
        })
        const link = getLink(item, i)
        return (
          <li
            key={item.name || i}
            className={menuitemClassName}
            role="menuitem"
          >
            {props.root && isStatic() && (
              <div className="layout-menuitem-text">{item.name}</div>
            )}
            {link}
            <CSSTransition
              nodeRef={submenuRef}
              classNames="p-toggleable-content"
              timeout={transitionTimeout}
              in={(item.modules && props.root && isStatic()) || active}
              unmountOnExit
            >
              <AppSubmenu
                ref={submenuRef}
                items={item.modules}
                menuActive={props.menuActive}
                menuMode={props.menuMode}
                parentMenuItemActive={active}
                onMenuItemClick={props.onMenuItemClick}
              ></AppSubmenu>
            </CSSTransition>
          </li>
        )
      })
    }

    useEffect(() => {
      if (props.resetActiveIndex && isHorizontalOrSlim()) {
        setActiveIndex(null)
      }
    }, [props.resetActiveIndex, isHorizontalOrSlim])

    useEffect(() => {
      if (!props.menuActive && isHorizontalOrSlim()) {
        setActiveIndex(null)
      }
    }, [props.menuActive, isHorizontalOrSlim])

    if (!props.items) {
      return null
    }
    const items = getItems()
    return (
      <ul ref={ref} className={props.className} role="menu">
        {items}
      </ul>
    )
  }
)
