import React from "react"
import {
  getBudgetProgressBarLabelFromPercentage,
  isBudgetTypeCredit,
  isBudgetTypeDebit,
} from "../../../utils/budget.utils"
import { Skeleton } from "../../../components/atoms/misc/Skeleton.atom"
import ProgressBar from "../../../components/atoms/misc/ProgressBar.atom"
import { formatDecimals } from "../../../utils/numbers.utils"
import PhaseLegendTemplate from "./PhaseLegendTemplate"

const BudgetPhaseProgress = ({
  value, // ConsumedTime
  budgetTypeId,
  hasBudget,
  loading,
  hasBilledTime,
  hasMonthlyLimit,
}) => {
  hasBudget = isBudgetTypeCredit(budgetTypeId) ? hasBilledTime : hasBudget
  const phaseInfo = getBudgetProgressBarLabelFromPercentage({
    percentage: value,
    hasBudget,
    hasBilledTime,
    budgetType: budgetTypeId,
    hasMonthlyLimit,
  })
  if (loading) {
    return (
      <div className="flex flex-column gap-2">
        <Skeleton height={15} />
        <Skeleton height={15} width={120} className="m-0" />
      </div>
    )
  }
  const getProgressBarValue = () => {
    const progressBarValue = 100 - formatDecimals(value)
    if (
      progressBarValue < 0 ||
      (!hasBudget && isBudgetTypeDebit(budgetTypeId))
    ) {
      return 0
    }
    if (progressBarValue > 100) {
      return 100
    }
    return progressBarValue
  }
  return (
    <div
      className="flex flex-column gap-2"
      style={{
        color: "light-dark(var(--secondary-color-dark), var(--text-color)",
      }}
    >
      <ProgressBar
        style={{ height: "15px" }}
        value={getProgressBarValue()}
        showValue={false}
        color={phaseInfo.color}
      />
      <PhaseLegendTemplate color={phaseInfo.color} label={phaseInfo.label} />
    </div>
  )
}

export default BudgetPhaseProgress
