export const RANGES = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  CUSTOM: "custom",
}

export const UNIFIEDDATE_VALUES = {
  DAY: "custom",
  SINGLEDAY: "single-day",
  SINGLEDATETIME: "single-datetime",
  SINGLEWEEK: "single-week",
  SINGLEMONTH: "single-month",
  SINGLEYEAR: "single-year",
  RANGEDAY: "range-day",
  RANGEWEEK: "range-week",
  RANGEMONTH: "range-month",
  RANGEYEAR: "range-year",
  CUSTOM: "custom",
}

export const UNIFIEDDATE_SELECTOR = {
  SINGLE: "single",
  RANGE: "range",
}

export const UNIFIEDDATE_RANGE = {
  DAY: "day",
  DATETIME: "datetime",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
}
