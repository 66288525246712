import React from "react"
import { capitalizeFirstLetter } from "../../../../../utils/strings.utils"
import { ErrorBadge } from "../../../../../components/molecules/CellReportBadges.molecule"
import { DateTime } from "luxon"
import {
  formatDecimals,
  numberFormatter,
} from "../../../../../utils/numbers.utils"

export const groupHeaderTemplate = (data, options, totalsByDay) => {
  if (!data?.date) return
  options.customRendering = true
  const day = new Date(data?.date).toISOString().split("T")[0]
  let dayName = DateTime.fromISO(day)
    .setLocale("es")
    .startOf("day")
    .toFormat("cccc")
  dayName = capitalizeFirstLetter(dayName)
  const dayRestOfDay = DateTime.fromISO(day)
    .setLocale("es")
    .startOf("day")
    .toFormat("dd 'de' MMMM 'del' yyyy")
  return (
    <>
      <td
        className={`p-datatable-rowgroup-header class-${dayRestOfDay?.replace(/ /g, "-")} p-frozen-column left-0`}
        role="cell"
        style={{
          width: "102px",
          minWidth: "102px",
          padding: "0.5rem",
        }}
      >
        <div
          className="flex align-items-center z-1 pl-2"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <i className="pi pi-calendar" style={{ marginRight: "10px" }}></i>
          <span>
            <strong>{dayName}</strong> {dayRestOfDay}
          </span>
        </div>
      </td>
      <td
        style={{
          width: "180px",
          minWidth: "180px",
          flex: "1 0 180px",
          padding: "0.5rem",
        }}
      ></td>
      <td
        style={{
          width: "180px",
          minWidth: "180px",
          flex: "1 0 180px",
          padding: "0.5rem",
        }}
      ></td>
      <td
        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
        role="cell"
        style={{
          width: "180px",
          minWidth: "180px",
          flex: "1 0 180px",
          padding: "0.5rem",
        }}
      >
        <div className="overflow-hidden white-space-nowrap text-overflow-ellipsis"></div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: "360px",
          minWidth: "360px",
          flex: "1 0 360px",
          padding: "0.5rem",
        }}
      >
        <div className="w-full flex justify-content-between align-items-center"></div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: 120,
          minWidth: 106,
          flex: "0 0 115px",
          padding: "0.5rem",
        }}
      >
        <strong>{formatDecimals(totalsByDay?.[day]?.sisuTime)}</strong>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: "120px",
          minWidth: "106px",
          flex: "0 0 115px",
          padding: "0.5rem",
        }}
      >
        <strong className={`${!data?.clientIsBillable ? "opacity-30" : ""}`}>
          {data?.clientIsBillable ?
            formatDecimals(totalsByDay?.[day]?.billedTime)
          : "N/A"}
        </strong>
      </td>
      <td
        className=""
        role="cell"
        style={{
          minWidth: "106px",
          flex: "0 0 115px",
          paddingLeft: "7px",
        }}
      >
        <div
          className={`flex gap-1 ${!data?.clientIsBillable ? "opacity-30" : ""}`}
        >
          <strong>
            {data?.clientIsBillable ?
              formatDecimals(totalsByDay?.[day]?.timeDifference)
            : "N/A"}
          </strong>
        </div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          maxWidth: "30px",
          flex: "0 0 30px",
          padding: "0.5rem",
        }}
      ></td>
    </>
  )
}

export const StickyFooter = ({ totals, isBillable }) => {
  return (
    <>
      <tbody className="p-datatable-tbody">
        <tr
          role="row"
          className="sticky-datatable-footer"
          style={{
            height: 38,
          }}
        >
          <td
            className="p-frozen-column left-0"
            role="cell"
            style={{ flex: "0 0 102px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Proyecto</span>
            <div className="flex gap-2 align-items-center">
              <strong>Total</strong>
            </div>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "1 0 180px", padding: "0.5rem" }}
          ></td>
          <td
            className=""
            role="cell"
            style={{ flex: "1 0 180px", padding: "0.5rem" }}
          ></td>
          <td
            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
            role="cell"
            style={{ flex: "1 0 180px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Categoría</span>
            <div className="overflow-hidden white-space-nowrap text-overflow-ellipsis"></div>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "1 0 360px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Tarea</span>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "0 0 115px", padding: "0.5rem" }}
          >
            <strong>
              {totals?.sisuTime ? numberFormatter(totals?.sisuTime) : ""}
            </strong>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "0 0 115px", padding: "0.5rem" }}
          >
            <strong className={`${!isBillable ? "opacity-30" : ""}`}>
              {isBillable ?
                totals?.billedTime ?
                  numberFormatter(totals?.billedTime)
                : "0"
              : "N/A"}
            </strong>
          </td>
          <td
            className=""
            role="cell"
            style={{ paddingLeft: "7px", flex: "0 0 115px" }}
          >
            <strong className={`flex gap-1 ${!isBillable ? "opacity-30" : ""}`}>
              {isBillable ?
                totals?.timeDifference ?
                  numberFormatter(totals?.timeDifference)
                : "0"
              : "N/A"}
              {totals?.timeDifference && totals?.timeDifference !== "N/A" ?
                <ErrorBadge tooltipValue="Las horas facturadas no coinciden con las horas trabajadas" />
              : null}
            </strong>
          </td>
          <td
            className=""
            role="cell"
            style={{
              flex: "0 0 30px",
              padding: "0.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          ></td>
        </tr>
      </tbody>
    </>
  )
}
