import React, { useEffect, useRef, useState } from "react"
import { Menu } from "primereact/menu"
import { ReactComponent as CalendarTimeIcon } from "../../../design/assests/images/icons/time.svg"
import {
  MY_TASKS_DEFAULT_SORT_OPTIONS,
  SORT_OVERLAY_VALUES,
} from "../../../consts/modules/pendingTasks.consts"

const CustomSortOverlay = ({
  sortConfig = {
    label: "Por fecha de creación",
    icon: <CalendarTimeIcon className="clr-text p-menuitem-icon svg" />,
    field: "taskCreatedAt",
    order: "desc",
  },
  setSortConfig = () => {},
  sortConfigOptions = [...MY_TASKS_DEFAULT_SORT_OPTIONS],
  showCustomSort = false,
  disabled,
}) => {
  const [isCustomSort, setIsCustomSort] = useState(
    sortConfig.field === SORT_OVERLAY_VALUES.CUSTOM
  )
  const [panelElements, setPanelElements] = useState(
    sortConfigOptions
  )
  const sortPanelRef = useRef()

  useEffect(() => {
    const filteredSorts =
      showCustomSort ?
        sortConfigOptions
      : sortConfigOptions.filter((item) => item.value !== SORT_OVERLAY_VALUES.CUSTOM)

    const mapItems = (items) =>
      items.map((item) => ({
        ...item,
        command: () => handleSortSelection(item),
        template: sortPanelItemTemplate(item),
        ...(item.items ? { items: mapItems(item.items) } : {}),
      }))

    setPanelElements(mapItems(filteredSorts))
  }, [showCustomSort, sortConfig])
  const handleSortSelection = (item) => {
    if (item.value !== "asc" && item.value !== "desc") {
      setSortConfig({
        label: item.label,
        icon: item.icon,
        field: item.value,
        order: sortConfig.order,
      })
    } else {
      setSortConfig({
        label: sortConfig.label,
        icon: item.icon,
        field: sortConfig.field,
        order: item.value,
      })
    }

    if (item.value === SORT_OVERLAY_VALUES.CUSTOM) {
      setIsCustomSort(true)
    } else if (item.value !== "asc" && item.value !== "desc") {
      setIsCustomSort(false)
    }
  }
  const adjustPanelPosition = () => {
    const topOffset = 10
    setTimeout(() => {
      const panel = sortPanelRef.current?.getElement()
      if (panel) {
        panel.style.top = `${parseFloat(panel.style.top) + topOffset}px`
      }
    }, 0)
  }

  const renderIcon = (icon) => {
    return typeof icon === "string" ?
        <span className={`p-menuitem-icon pi ${icon}`}></span>
      : icon
  }
  const sortPanelItemTemplate = (item) => {
    let isActive =
      sortConfig.field === item.value || sortConfig.order === item.value
    const isDisabled =
      isCustomSort && (item.value === "asc" || item.value === "desc")
    if (isDisabled) {
      isActive = false
    }
    return (
      <a
        href="#"
        className={`p-menuitem-link ${isDisabled ? "p-disabled" : ""}`}
        role="menuitem"
        tabIndex="0"
        onClick={() => {
          handleSortSelection(item)
        }}
      >
        {renderIcon(item.icon)}
        <span className="p-menuitem-text">{item.label}</span>
        {isActive && <i className="pi pi-check ml-auto clr-info text-xs"></i>}
      </a>
    )
  }

  const getSortOrderIcon = () => {
    if (sortConfig.label === "Orden personalizado") {
      return sortConfig.icon
    }
    return panelElements
      .filter((item) => item.value === "sort-order")[0]
      .items.filter((item) => item.value === sortConfig.order)[0].icon
  }
  return (
    <>
      <Menu
        model={panelElements}
        popup
        ref={sortPanelRef}
        className="custom-sort-overlay-panel"
      />
      <span
        className={`custom-sort-overlay-trigger ${disabled ? "p-disabled" : ""}`}
        onClick={(e) => {
          if (disabled) return
          sortPanelRef?.current?.toggle(e)
          adjustPanelPosition()
        }}
      >
        <span>{sortConfig.label}</span>
        {renderIcon(getSortOrderIcon())}
      </span>
    </>
  )
}

export default CustomSortOverlay
