import React from 'react'
import Dropdown from '../../atoms/Dropdown.atom'

const STATUS_FILTER_OPTIONS = [
  { label: 'Activo', value: 1 },
  { label: 'Inactivo', value: 0 }
]

const statusFilterElement = ({
  options,
  ...props
}) => {
  const id = `status-filter-${new Date().getTime()}`
  if (props.filterApplyCallbackRef) {
    if (props.filterApplyCallbackRef.current !== options.filterApplyCallback) {
      props.filterApplyCallbackRef.current = options.filterApplyCallback
    }
  }
  return (
    <Dropdown
      id={id}
      inputId={`input-${id}`}
      value={options.value}
      options={STATUS_FILTER_OPTIONS}
      onChange={(e) => {
        options.filterCallback(e.value)
        if (props.onChange) {
          props.onChange(e)
        }
      }}
      optionLabel="label"
      optionValue="value"
      placeholder="Filtrar estatus"
    />
  )
}

export default statusFilterElement
