import React, { useEffect, useState, useRef } from "react"
import { getOccupationReport } from "../../../services/report.service"
import useReportFiltersContext from "../../../hooks/contextConsumers/useReportFiltersContext.hook"
import ProgressSpinner from "../../../components/atoms/misc/ProgressSpinner.atom"
import OccupationReportOneYearTable from "./OccupationReportOneYearTable.controller"
import OccupationReportMultipleYearsTable from "./OccupationReportMultipleYearsTable.controller"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { UNIFIEDDATE_VALUES } from "../../../consts/CompoundRangeDatePicker"

const INITIAL_REPORT_STRUCTURE = {
  data: [],
  totals: {
    occupationPercentage: 0,
    totalBilledTime: 0,
    totalSisuClientTime: 0,
    totalSisuTime: 0,
    totalUnbilledTime: 0,
  },
  type: 1,
}

const OccupationReport = () => {
  const { occupationReportDateRange, setOccupationReportDateRange } =
    useReportFiltersContext()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState(INITIAL_REPORT_STRUCTURE)
  const [sortOrder, setSortOrder] = useState(1)
  const resetSortOrderRef = useRef(null)
  const [expandedKeys, setExpandedKeys] = useState({})

  useEffect(() => {
    gettingReport()
  }, [occupationReportDateRange])

  const gettingReport = async () => {
    setError(null)
    setIsLoading(true)
    const startDate = occupationReportDateRange[0].startOf("year").toISODate()
    const endDate = occupationReportDateRange[1].endOf("year").toISODate()
    const response = await getOccupationReport(startDate, endDate)
    if (response.success) {
      console.log("Reporte: ", response.result)
      setReportData(response.result)
    } else {
      setError(response.message)
      setReportData(INITIAL_REPORT_STRUCTURE)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div
        className={
          "mr-0 ml-1 flex flex-1 justify-content-between align-items-center pb-3"
        }
      >
        <div className="flex flex-1 gap-3">
          <UnifiedDatePicker
            dateRange={occupationReportDateRange}
            setDateRange={setOccupationReportDateRange}
            period={UNIFIEDDATE_VALUES.RANGEYEAR}
            hidePeriodSelector
            separator={
              <i className="pi pi-arrow-right text-xs clr-secondary" />
            }
          />
        </div>
      </div>

      {isLoading ?
        <div className="w-full h-20rem flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      : error ?
        <div>Error: {error}</div>
      : reportData.type === 1 ?
        <OccupationReportOneYearTable
          reportData={reportData}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          resetSortOrderRef={resetSortOrderRef}
        />
      : <OccupationReportMultipleYearsTable
          reportData={reportData}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          resetSortOrderRef={resetSortOrderRef}
        />
      }
    </>
  )
}

export default OccupationReport
