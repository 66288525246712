import React, { useEffect, useState } from "react"
import DataTable from "../../../components/atoms/table/DataTable.atom"
import Column from "../../../components/atoms/table/Column.atom"
import OccupationPercentageChip from "../../../components/molecules/OccupationPercentageChip.molecule"
import { roundReportData } from "../../../utils/report.utils"
import { getMonthLongNameByNumber } from "../../../utils/date.utils"
import {
  floatToPercentage,
  formatDecimals,
  numberFormatter,
} from "../../../utils/numbers.utils"

const OccupationReportOneYearTable = React.forwardRef(
  ({ reportData: originalData }, ref) => {
    const reportData = roundReportData(originalData)

    return (
      <>
        <DataTable
          ref={ref}
          value={reportData.data}
          responsiveLayout="scroll"
          className="week-hours-report-data-table"
          scrollable
          style={{ height: "100%" }}
          scrollHeight="100%"
        >
          <Column
            field="data.distributionSisuTime"
            body={(node) => (
              <span>{getMonthLongNameByNumber(node?.month)}</span>
            )}
            header="Fecha"
            footer="Horas totales"
            frozen
            style={{ width: "100px" }}
          />
          <Column
            field="data.totalSisuTime"
            body={(node) => <span>{numberFormatter(node?.totalSisuTime)}</span>}
            header="Horas trabajadas"
            footer={numberFormatter(reportData?.totals?.totalSisuTime)}
          />
          <Column
            field="data.totalSisuClientTime"
            body={(node) => (
              <span>{numberFormatter(node?.totalSisuClientTime)}</span>
            )}
            header="Horas internas"
            footer={numberFormatter(reportData?.totals?.totalSisuClientTime)}
          />
          <Column
            field="data.totalBilledTime"
            body={(node) => (
              <span>{numberFormatter(node?.totalBilledTime)}</span>
            )}
            header="Horas facturadas"
            footer={numberFormatter(reportData?.totals?.totalBilledTime)}
          />
          <Column
            field="data.totalUnbilledHours"
            body={(node) => (
              <span>{numberFormatter(node?.totalUnbilledHours)}</span>
            )}
            header="Horas perdonadas"
            footer={numberFormatter(reportData?.totals?.totalUnbilledHours)}
          />
          <Column
            body={(node) => (
              <span>
                <OccupationPercentageChip
                  percentageValue={node?.ocupationPercentage}
                />
              </span>
            )}
            header="Porcentaje de ocupación"
            footer={
              <OccupationPercentageChip
                percentageValue={reportData?.totals?.ocupationPercentage}
              />
            }
          />
        </DataTable>
      </>
    )
  }
)

export default OccupationReportOneYearTable
