import React, { useEffect, useRef, useState } from "react"
import useThemeContext from "../../../hooks/contextConsumers/useThemeContext.hook"
import { validateFieldValue } from "../../../utils/validations.utils"
import { OverlayPanel } from "../../../components/atoms/OverlayPanel.atom"
import InputTextFilter from "../../../components/molecules/InputTextFilter.molecule"
import { KEYBOARD_ACTIONS } from "../../../consts/keyBoard.consts"
import Divider from "../../../components/atoms/Divider.atom"
import SecondaryButton from "../../../components/molecules/buttons/SecondaryButton.molecule"
import PrimaryButton from "../../../components/molecules/buttons/PrimaryButton.molecule"
import Tooltip from "../../../components/atoms/Tooltip.atom"
import { generateRandomId } from "../../../utils/strings.utils"
import InputPlaceholderChipTemplate from "../../../components/molecules/InputPlaceholderChipTemplate.molecule"
import InputTextareaForm from "../../../components/molecules/InputTextareaForm.molecule"
import useWidthTransition from "../../../hooks/useWidthTransition.hook"

export const INPUT_COMMENT_OVERLAY_VARIANTS = {
  SHORT: "short",
  LONG: "long",
}
const InputCommentOverlay = ({
  error,
  placeholderIcon = "pi pi-comment",
  label = "Comentario",
  currentValue,
  onSubmit,
  inputProps,
  variant = INPUT_COMMENT_OVERLAY_VARIANTS.LONG,
  readOnly,
  chipClassName = `comment-button-${generateRandomId()}`,
}) => {
  const [tooltipProps, setTooltipProps] = useState({
    body: currentValue || "",
    position: "bottom",
  })
  const [data, setData] = useState({
    comment: currentValue || "",
  })
  const [isEditing, setIsEditing] = useState(false)
  const commentModalRef = useRef(null)
  const { elementRef: chipRef, style: chipStyle } = useWidthTransition({
    style: { minWidth: "33px" },
  })
  const { theme } = useThemeContext()
  const initialFieldsState = {
    comment: {
      value: currentValue || "",
      errors: [],
      typeValidations: [],
      validationsOptions: {},
    },
  }
  const [validations, setValidations] = useState(initialFieldsState)
  useEffect(() => {
    if (currentValue === undefined) {
      setData({ comment: "" })
      setValidations(initialFieldsState)
    }
  }, [currentValue])

  function handleChange({ target: { name, value } }) {
    setData((prevData) => ({ ...prevData, [name]: value }))
    setValidations(validateFieldValue(validations, name, value))
  }

  function cleanUp() {
    setData({ comment: currentValue || "" })
    setValidations(initialFieldsState)
  }

  const accept = (e) => {
    onSubmit(data.comment)
    setTooltipProps((prev) => ({ ...prev, body: data.comment }))
    commentModalRef?.current?.hide(e)
  }

  const reject = (e) => {
    setIsEditing(false)
    if (e) {
      onSubmit(currentValue)
    }
    cleanUp()
    commentModalRef?.current?.hide(e)
  }
  const { onKeyDown: onInputKeyDown, ...restInputProps } = inputProps || {}
  return (
    <>
      <OverlayPanel ref={commentModalRef} onHide={reject}>
        <div className="comment-modal flex flex-column">
          <InputTextareaForm
            className="mt-2 w-full"
            label="Comentario"
            name="comment"
            placeholder="Este es el comentario de mi tarea"
            value={data.comment}
            onChange={handleChange}
            autoFocus
            onKeyDown={(e) => {
              onInputKeyDown(e, data.comment?.trim() || "")
            }}
            {...restInputProps}
          />
          <Divider />
          <div className="w-full flex justify-content-end gap-3">
            <SecondaryButton label="Cancelar" type="text" onClick={reject} />
            <PrimaryButton
              label="Aceptar"
              onClick={accept}
              className="accept-project-button"
            />
          </div>
        </div>
      </OverlayPanel>
      {((readOnly && currentValue) || !readOnly) && (
        <InputPlaceholderChipTemplate
          readOnly={readOnly}
          error={error}
          isEditing={isEditing || currentValue}
          chipClassName={`${chipClassName} ${currentValue ? "bg-info" : ""} ${theme === "light" && currentValue ? "text-white" : ""} text-overflow-ellipsis white-space-nowrap overflow-hidden`}
          labelTemplate={
            <div ref={chipRef} className="flex gap-2 align-items-center ">
              {(
                !currentValue && variant === INPUT_COMMENT_OVERLAY_VARIANTS.LONG
              ) ?
                <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden">
                  <span className="flex gap-2 align-items-center">
                    {error ?
                      <i className="pi pi-exclamation-triangle text-xs line-height-2 m-auto"></i>
                    : <i
                        className={`pi ${placeholderIcon} text-xs line-height-2 m-auto`}
                      ></i>
                    }
                    <span className="text-overflow-ellipsis white-space-nowrap overflow-hidden">
                      {label}
                    </span>
                  </span>
                </span>
              : <i
                  className={`pi pi-comment mx-0 text-xs block ${theme === "light" && currentValue ? "text-white" : ""}`}
                />
              }
            </div>
          }
          onClick={(event) => {
            if (readOnly) return
            setIsEditing(!commentModalRef?.current.getElement())
            commentModalRef?.current?.toggle(event)
          }}
          style={chipStyle}
        />
      )}

      {currentValue && (
        <Tooltip target={`.${chipClassName}`} position={tooltipProps.position}>
          <span className={chipClassName}>{tooltipProps.body}</span>
        </Tooltip>
      )}
    </>
  )
}

export default InputCommentOverlay
