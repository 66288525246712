import React from "react"
import Badge from "../../../components/atoms/Badge.atom"

const LabelWithQuantityBadge = ({
  labelTemplate,
  quantity = 0,
  containerProps,
  labelProps,
  badgeProps,
  hideBadgeIfZero = true,
}) => {
  return (
    <div className="flex align-items-center" {...containerProps}>
      <span {...labelProps}>{labelTemplate}</span>
      {((quantity > 0 && hideBadgeIfZero) || !hideBadgeIfZero) && (
        <Badge value={quantity} {...badgeProps} />
      )}
    </div>
  )
}

export default LabelWithQuantityBadge
