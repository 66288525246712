import React from "react"
import Chip from "../../../../components/atoms/misc/Chip.atom"
import { backwardCompatibleClientProjectLabel } from "../../../../utils/strings.utils"

const contrastTextColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16)
  const g = parseInt(hexColor.substr(3, 2), 16)
  const b = parseInt(hexColor.substr(5, 2), 16)
  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000)
  return brightness > 128 ? "black" : "white"
}

const BadgePreview = ({ color = "var(--secondary-color)", label = "TEST" }) => {
  const resolvedColor =
    getComputedStyle(document.documentElement)
      .getPropertyValue(color.match(/--[a-zA-Z0-9-]+/)?.[0])
      .trim() || color
  const textColor = contrastTextColor(resolvedColor)
  return (
    <div className="flex flex-column gap-1 h-full">
      <span>Preview del indicador</span>
      <div className="flex-1 flex align-items-center">
        <Chip
          className="client-chip-preview text-white justify-content-center"
          template={
            <span
              className={`font-bold ${textColor === "black" ? "text-black" : "text-white"}`}
              style={{
                fontSize: "12px",
                lineHeight: "150%",
                color: "var(--text-color)",
              }}
            >
              {backwardCompatibleClientProjectLabel(label)}
            </span>
          }
          style={{
            backgroundColor: color,
            color: textColor,
            height: "22px",
            minWidth: "40px",
            width: "fit-content",
            padding: "0 7px",
            marginLeft: "5px",
          }}
        />
      </div>
    </div>
  )
}

export default BadgePreview
