import React, { forwardRef } from "react"
import DropdownWithTitleSubtitle from "../molecules/DropdownWithTitleSubtitle.molecule"
import { categoryDropdownOptionGroupTemplate } from "../../utils/category.utils"
import Label from "../atoms/Label.atom"

const DropdownCategoryFilter = forwardRef(
  (
    {
      value,
      onChange,
      label = null,
      placeholder = "Elige una categoría",
      name = "categoryGroup",
      options,
      optionValue,
      optionLabel = "categoryTitle",
      error,
      withoutChip = false,
      dropdownRef,
      loading,
      dropdownInputProps,
      ...props
    },
    ref
  ) => {
    optionValue =
      options?.[0]?.specialCategoryID ? "specialCategoryID" : "categoryID"
    options = options?.map((o) => ({
      ...o,
      categoryFull: o?.categoryGroup + o?.categoryTitle + o?.categorySubtitle,
    }))
    optionLabel = "categoryFull"
    if (options) {
      if (options?.[0]?.specialCategoryID) {
        options = options?.sort((a, b) =>
          a?.categoryTitle?.localeCompare(b?.categoryGroup)
        )
      } else {
        options = options?.sort((a, b) =>
          a?.categoryTitle?.localeCompare(b?.categoryTitle)
        )
      }
    }
    const canGroupByName =
      options?.findIndex((c) => c.categoryGroup && !c.specialCategoryID) !== -1

    return (
      <div className="field m-0">
        <DropdownWithTitleSubtitle
          ref={ref}
          inputId={name}
          label={label}
          placeholder={placeholder}
          options={options}
          optionValue={optionValue}
          optionLabel={optionLabel}
          value={value}
          onChange={onChange}
          name={name}
          titleKey={`${optionValue === "specialCategoryID" ? "categoryGroup" : "categoryTitle"}`}
          subtitleKey="categorySubtitle"
          fullWidth
          panelClassName={`max-w-15rem register-task-dropdown-panel ${loading ? "loading align-items-center" : ""}`}
          inputProps={dropdownInputProps}
          optionGroupTemplate={categoryDropdownOptionGroupTemplate}
          error={error}
          {...(canGroupByName &&
            !loading && {
              optionGroupLabel: (option) => {
                if (canGroupByName || loading) return null
                return (
                  <div className="flex w-full font-cairo text-xl bg-red-100">
                    {option.label}
                  </div>
                )
              },
              optionGroupChildren: "items",
              groupKey: "categoryGroup",
            })}
        />
      </div>
    )
  }
)

export default DropdownCategoryFilter
