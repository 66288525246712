import React, { useEffect, useRef, useState } from "react"
import TaskCreation from "./taskCreation/TaskCreation.controller"
import TimeReport from "./timeReport/TimeReport.controller"
import {
  getFirstWorkDayByCanSaveChanges,
  getLastWorkDayFromDate,
  getWorkDayAtDate,
} from "../../../services/workDay.service"
import useSessionContext from "../../../hooks/contextConsumers/useSessionContext.hook"
import TimeSummary from "./timeSummary/TimeSummary.controller"
import { compareDates } from "../../../utils/date.utils"
import { DateTime } from "luxon"
import useTimeRegisterContext from "../hooks/useTimeRegisterContext.hook"
import UncompleteDayDialog from "./dayAlerts/UncompleteDayDialog.controller"
import MissingDayDialog from "./dayAlerts/MissingDayDialog.controller"

const TimeRegisterContent = ({ refreshProjects }) => {
  const {
    doFetchTasks,
    setDoFetchTasks,
    setIsDayFinished,
    setIsDayEditable,
    setShowUncompleteDayDialog,
    setShowMissingDayDialog,
    selectedDate,
    setSelectedDate,
    setUncompleteDay,
    setLastWorkDay,
    setCanSaveChanges,
    setLoading,
    workDayToDisplay,
    setWorkDayToDisplay,
    editingMissingDay,
    setEditingMissingDay,
    uncompleteDay,
  } = useTimeRegisterContext()
  const { user } = useSessionContext()
  const dropdownProjectFilterRef = useRef(null)

  useEffect(() => {
    if (
      !uncompleteDay &&
      !editingMissingDay &&
      !compareDates(
        DateTime.fromJSDate(selectedDate),
        DateTime.fromISO(workDayToDisplay?.date)
      )
    ) {
      getWorkDayToDisplay()
    }
  }, [selectedDate])

  const getUncompleteDay = async () => {
    // 1. Dia editado sin sincronizar
    // 2. Dia sin finalizar que no sea hoy
    const responseWorkDayThatCanSaveChanges =
      await getFirstWorkDayByCanSaveChanges(user.teamMemberID)
    const workDayThatCanSaveChanges = responseWorkDayThatCanSaveChanges?.result
    const dateOfWorkDayThatCanSaveChanges = DateTime.fromISO(
      workDayThatCanSaveChanges?.date?.split("T")[0]
    )
    // Wishlist: Refactorizar condiciones del if
    if (
      workDayThatCanSaveChanges && // Existe un dia que puede guardar cambios
      !compareDates(
        dateOfWorkDayThatCanSaveChanges,
        DateTime.fromJSDate(selectedDate)
      ) && // El dia que puede guardar cambios no es el seleccionado
      (workDayThatCanSaveChanges.isFinished ||
        dateOfWorkDayThatCanSaveChanges.toISODate() !==
          DateTime.local().toISODate()) // El dia que puede guardar cambios esta finalizado o no es el dia actual
    ) {
      setShowUncompleteDayDialog(true)
      setSelectedDate(dateOfWorkDayThatCanSaveChanges.toJSDate())
      setWorkDayToDisplay(workDayThatCanSaveChanges)
      setCanSaveChanges(true)
      // Refactorizar para no usarlo multiples veces
      setUncompleteDay(workDayThatCanSaveChanges)
      setLastWorkDay(workDayThatCanSaveChanges)
      return true
    }
    return false
  }

  const getMissingDay = async () => {
    let missingDayDate = false
    // 3. Dia sin iniciar que no sea hoy
    const firstDayOfCurrentWeek = DateTime.local().startOf("week")
    const todayDate = DateTime.local()
    if (
      !compareDates(todayDate, firstDayOfCurrentWeek.toJSDate()) &&
      !compareDates(firstDayOfCurrentWeek.toJSDate(), selectedDate)
    ) {
      // Si hoy no es el primer dia de la semana
      const responseLastWorkDayOfCurrentWeek = await getLastWorkDayFromDate(
        user.teamMemberID,
        firstDayOfCurrentWeek.toISODate()
      )
      const lastWorkDayOfCurrentWeek = responseLastWorkDayOfCurrentWeek?.result
      const dateOfLastWorkDayCurrentWeek = DateTime.fromISO(
        lastWorkDayOfCurrentWeek?.date?.split("T")[0]
      )
      if (lastWorkDayOfCurrentWeek) {
        missingDayDate = dateOfLastWorkDayCurrentWeek?.plus({ days: 1 })
      } else {
        missingDayDate = firstDayOfCurrentWeek
      }
      const dateOfYesterday = todayDate.minus({ days: 1 })
      if (
        !lastWorkDayOfCurrentWeek ||
        dateOfLastWorkDayCurrentWeek.startOf("day") <
          dateOfYesterday.startOf("day")
      ) {
        // Si el ultima workDay no es ayer, es porque hay dias pendientes
        setEditingMissingDay(true)
        setShowMissingDayDialog(true)
        setSelectedDate(missingDayDate.toJSDate())
        return true
      }
    }
    return false
  }
  const getWorkDayToDisplay = async () => {
    setLoading(true)
    try {
      // 0. Dia dentro de la semana actual
      if (
        DateTime.fromJSDate(selectedDate) >= DateTime.local().startOf("week")
      ) {
        const uncompleteDay = await getUncompleteDay()
        if (!uncompleteDay) {
          const missingDay = await getMissingDay()
          if (!missingDay) {
            // 4. Dia seleccionado
            // 5. Dia actual
            const responseWorkDayOfSelectedDate = await getWorkDayAtDate(
              user.teamMemberID,
              selectedDate
            )
            const workDayOfSelectedDate = responseWorkDayOfSelectedDate?.result
            const isDayFinished = workDayOfSelectedDate?.isFinished === 1

            setIsDayFinished(isDayFinished)
            setLastWorkDay(workDayOfSelectedDate)
            setWorkDayToDisplay(workDayOfSelectedDate)
          }
        }
      } else {
        setUncompleteDay(null)
        setLastWorkDay(null)
        setWorkDayToDisplay(null)
        setIsDayFinished(true)
        setIsDayEditable(false)
      }
    } catch (error) {
      setLastWorkDay(null)
      setIsDayFinished(false)
      setIsDayEditable(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <TaskCreation
        refreshProjects={refreshProjects}
        dropdownProjectFilterRef={dropdownProjectFilterRef}
      />
      <TimeReport
        onClickBeginEditingNewTaskName={() => {
          setTimeout(() => {
            dropdownProjectFilterRef.current?.show()
          })
        }}
      />
      <TimeSummary setRefresh={() => setDoFetchTasks(!doFetchTasks)} />
      <UncompleteDayDialog />
      <MissingDayDialog />
    </>
  )
}

export default TimeRegisterContent
