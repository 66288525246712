import { DateTime } from "luxon"
import { REPORT_PERIOD_BY_LANG } from "../consts/date.consts"
import { RANGES, UNIFIEDDATE_VALUES } from "../consts/CompoundRangeDatePicker"
import {
  INDIVIDUAL_REPORT_ROW_HEIGHT,
  INDIVIDUAL_REPORT_SUBHEADER_HEIGHT,
} from "../consts/reports.consts"

export const roundReportData = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "number") {
      obj[key] = parseFloat(obj[key].toFixed(2))
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      roundReportData(obj[key])
    }
  }
  return obj
}

export const roundReportData3Decimals = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "number") {
      obj[key] = parseFloat(obj[key].toFixed(3))
    } else if (typeof obj[key] === "object" && obj[key] !== null) {
      roundReportData3Decimals(obj[key])
    }
  }
  return obj
}

export const getUserWithMostDays = (dataset) => {
  if (!dataset || dataset.length === 0) return null
  const days = Object.keys(dataset[0].week).length
  let user = dataset[0]
  dataset.forEach((item) => {
    const userDays = Object.keys(item.week).length
    if (userDays > days) {
      user = item
    }
  })
  return user
}

/**
 * Calculate end date from a start date and a period type
 * Example: '2024-11-01' + 'weekly' => '2024-11-08'
 *
 * @param {string} period
 * @param {DateTime} startDate
 * @param {'es' | 'eng'} lang
 * @returns {{startDate, endDate}}
 */
export const calculateDateRangeWithPeriodAndStartDate = (
  period,
  startDate,
  lang = "en",
  endDate = null
) => {
  let newStartDate = startDate
  let calculatedEndDate
  const getValidDate = (date) => {
    if (date.isValid) {
      return date
    }
    if (DateTime.fromJSDate(date).isValid) {
      return DateTime.fromJSDate(date)
    }
    if (DateTime.fromISO(date).isValid) {
      return DateTime.fromISO(date)
    }
  }
  switch (period) {
    case REPORT_PERIOD_BY_LANG[lang].DIARIO:
      newStartDate = getValidDate(startDate)
      calculatedEndDate = getValidDate(startDate)
        .set({ hour: 12 })
        .startOf("day")
      break
    case REPORT_PERIOD_BY_LANG[lang].SEMANAL:
      newStartDate = getValidDate(startDate).startOf("week").set({ hour: 12 })
      calculatedEndDate = getValidDate(startDate)
        .endOf("week")
        .set({ hour: 12 })
      break
    case REPORT_PERIOD_BY_LANG[lang].MENSUAL:
      newStartDate = getValidDate(startDate).startOf("month").set({ hour: 12 })
      calculatedEndDate = getValidDate(startDate)
        .endOf("month")
        .set({ hour: 12 })
      break
    case REPORT_PERIOD_BY_LANG[lang].ANUAL:
      newStartDate = getValidDate(startDate).startOf("year").set({ hour: 12 })
      calculatedEndDate = getValidDate(startDate)
        .endOf("year")
        .set({ hour: 12 })
      break
    case REPORT_PERIOD_BY_LANG[lang].CUSTOM:
      newStartDate = getValidDate(startDate)
      calculatedEndDate = getValidDate(endDate)
      break
    default:
      throw new Error(
        "Periodo no soportado, las opciones son: " +
          Object.values(REPORT_PERIOD_BY_LANG[lang]).join(", ")
      )
  }
  return { startDate: newStartDate, endDate: calculatedEndDate }
}

export const getExportFilename = (base, dateRange, period) => {
  let periodStr = ""
  const [startDate, endDate] = dateRange

  switch (period) {
    case UNIFIEDDATE_VALUES.SINGLEDAY:
      {
        const day = String(startDate.day).padStart(2, "0")
        const month = String(startDate.month).padStart(2, "0")
        const year = String(startDate.year).slice(-2)
        periodStr = `${day}-${month}-${year}`
      }
      break
    case UNIFIEDDATE_VALUES.SINGLEWEEK:
      {
        const weekNumber = startDate.weekNumber
        const year = startDate.year.toString().slice(-2)
        periodStr = "Semana" + weekNumber + "-" + year
      }
      break
    case UNIFIEDDATE_VALUES.SINGLEMONTH:
      {
        const month =
          startDate.setLocale("es").monthLong.charAt(0).toUpperCase() +
          startDate.setLocale("es").monthLong.slice(1)
        const year = startDate.year.toString().slice(-2)
        periodStr = month + "-" + year
      }
      break
    case UNIFIEDDATE_VALUES.RANGEDAY:
      {
        const day1 = String(startDate.day).padStart(2, "0")
        const month1 = String(startDate.month).padStart(2, "0")
        const year1 = String(startDate.year).slice(-2)

        const day2 = String(endDate.day).padStart(2, "0")
        const month2 = String(endDate.month).padStart(2, "0")
        const year2 = String(endDate.year).slice(-2)
        periodStr = `${day1}-${month1}-${year1}_${day2}-${month2}-${year2}`
      }
      break
    default:
      periodStr = "Error"
      throw new Error("Periodo no válido al intentar exportar")
  }
  return `${base}_${periodStr}`
}

export const convertToCSV = (data, headers) => {
  const array = Array.isArray(data) ? data : JSON.parse(data)
  const headerRow = headers.map((header) => `"${header.header}"`).join(",")
  const rows = array.map((item) =>
    headers
      .map(
        (header) => `"${String(item[header.field] ?? "").replace(/"/g, '""')}"`
      )
      .join(",")
  )
  return [headerRow, ...rows].join("\r\n")
}

export const downloadCSV = (data, filename) => {
  const blob = new Blob(["\ufeff" + data], { type: "text/csv;charset=utf-8;" })
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.setAttribute("href", url)
  link.setAttribute("download", filename)
  link.style.visibility = "hidden"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getContextMenuItemsForIndividualReport = (
  selectedTask,
  cmItems
) => {
  if (!selectedTask) {
    return []
  }
  if (!selectedTask?.hasChangelog) {
    return cmItems.slice(0, 1)
  }
  return cmItems
}

export const calculateTotalsByDayForIndividualTasksReport = (data) => {
  return data.reduce((acc, task) => {
    const day = new Date(task.date).toISOString().split("T")[0]
    if (!acc[day]) {
      acc[day] = {
        sisuTime: 0,
        billedTime: 0,
        timeDifference: 0,
      }
    }
    acc[day].sisuTime += task.sisuTime
    acc[day].billedTime += task.billedTime === "N/A" ? 0 : task.billedTime
    acc[day].timeDifference +=
      task.timeDifference === "N/A" ? 0 : Math.abs(task.timeDifference)
    return acc
  }, {})
}

export const calculateTotalsIndividualTasksReport = (data) => {
  return data.reduce(
    (acc, task) => {
      acc.sisuTime += task.sisuTime
      acc.billedTime += task.billedTime === "N/A" ? 0 : task.billedTime
      acc.timeDifference +=
        task.timeDifference === "N/A" ? 0 : Math.abs(task.timeDifference)
      return acc
    },
    {
      sisuTime: 0,
      billedTime: 0,
      timeDifference: 0,
    }
  )
}

export const calculateLazyScrollIndexOffset = (
  tasks,
  currentIndex,
  sortConfig = {}
) => {
  let numberOfUniqueDays = getLazyUniqueDaysDisplayed(
    tasks,
    currentIndex,
    sortConfig
  )
  numberOfUniqueDays =
    numberOfUniqueDays < 0 && currentIndex === 0 ? 1 : numberOfUniqueDays
  const newOffset =
    currentIndex * INDIVIDUAL_REPORT_ROW_HEIGHT +
    numberOfUniqueDays * INDIVIDUAL_REPORT_SUBHEADER_HEIGHT
  return newOffset
}

export const getLazyUniqueDaysDisplayed = (tasks, currentIndex, sortConfig) => {
  if (!tasks || tasks.length === 0) {
    return 0
  }
  if (sortConfig?.sortField) {
    return tasks
      .slice(0, currentIndex)
      .filter(
        (value, index, array) =>
          index === 0 || value.date !== array[index - 1].date
      ).length
  }
  const uniqueDays = new Set()
  for (let i = 0; i < currentIndex; i++) {
    uniqueDays.add(tasks[i]?.date)
  }
  return uniqueDays.size
}
