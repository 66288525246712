export const roundTwoDecimal = (number) => number.toFixed(2)

export const numberFormatter = (numberIncoming, maximumFractionDigits = 2) => {
  if (numberIncoming === 'N/A') return numberIncoming
  const validNumberIncoming = parseFloat(numberIncoming)
  if (!isNumber(validNumberIncoming)) return numberIncoming
  const number = validNumberIncoming || 0
  const options = { maximumFractionDigits }
  const numberFormat = new Intl.NumberFormat('en-US', options)
  return numberFormat.format(number)
}

export const isTheArrayConsecutive = (array, { optionValue = null, startCount = 1 }) => {
  let result = true
  let count = startCount
  array.forEach((data, index) => {
    let value = null
    if (optionValue) {
      value = Number(data[optionValue])
    } else {
      value = Number(data)
    }
    if (value !== count) {
      result = false
    }
    count++
  })
  return result
}

export const numberPercentajeFormatter = (numberIncoming, decimals = 1, withPercentajeString = true, capped = false) => {
  const number = numberIncoming || 0
  let value = number * 100
  if (capped) {
    if (value > 100) {
      value = 100
    }

    if (value < 0) {
      value = 0
    }
  }
  return `${(value).toFixed(decimals)}${withPercentajeString ? '%' : ''}`
}

export const currencyFormat = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4
  }).format(value)
}

export const currencyFormatMxn = (value, minDecimales = 2, maxDecimales = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: minDecimales,
    maximumFractionDigits: maxDecimales
  }).format(value)
}

export const isPositiveInteger = (val) => {
  let str = String(val)
  str = str.trim()
  if (!str) {
    return false
  }
  str = str.replace(/^0+/, '') || '0'
  const n = Math.ceil(Number(str))
  return n !== Infinity && String(n) === str && n >= 0
}

export const generateRandomNumber = () => Math.round((Math.random() + 1) * 10000000)

/**
 * Formats float number to percentage
 * 0.97 -> 97%
 * 0.56666 -> 56.67%
 * 0.90 -> 90%
 * @param {*} value Number from 0 to 1
 */
export const floatToPercentage = (value, decimals = 1, useCommas = false) => {
  const percentage = value * 100
  let roundedPercentage = Math.round(percentage * 100) / 100
  if (!Number.isInteger(roundedPercentage)) {
    roundedPercentage = roundedPercentage.toFixed(decimals)
  }
  if (useCommas) {
    roundedPercentage = numberFormatter(roundedPercentage)
  }
  return `${roundedPercentage}%`
}

export const formatDecimals = (value, decimals = 2) => {
  const float = parseFloat(value)
  if (Number.isInteger(float)) {
    return Number(float)
  }
  const formattedValue = float.toFixed(decimals)
  return parseFloat(formattedValue).toString()
}

export const isNumber = (value) => {
  return typeof value === 'number' && isFinite(value)
}
