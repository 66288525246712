import React from 'react'
import Chip from '../atoms/misc/Chip.atom'
import useClientColorsContext from '../../hooks/contextConsumers/useClientColorsContext.hook'
import { backwardCompatibleClientProjectLabel } from '../../utils/strings.utils'
/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
 * @typedef {import("primereact/chip").ChipProps & JSXDivElement & {
*   code: string,
*   labelClassname?: string,
*   className?: string
* }} ClientChipProps
*/

/**
* @param {ClientChipProps} props
* @returns {JSX.Element}
*/
const ClientChip = ({
  clientCode,
  labelClassname = 'my-0 overflow-hidden text-overflow-ellipsis white-space-nowrap',
  className = '',
  ...props
}) => {
  if (!clientCode) return null
  const { colors } = useClientColorsContext()
  const client = colors.find(client => client.code === clientCode)

  return (
    <Chip
      className={`client-chip text-white ${className}`}
      template={
        <>
          <div className='bg-color' style={{ backgroundColor: client?.color }}></div>
          <span
            className={`${labelClassname} font-bold`}
            style={{ fontSize: '12px', maxWidth: '5ch' }}
            title={clientCode?.length > 3 ? clientCode : ''}
          >{backwardCompatibleClientProjectLabel(clientCode)}</span>
        </>
      }
      {...props}
      style={{ ...props.style }}
    />
  )
}

export default ClientChip
