import React from "react"
import { DateTime } from "luxon"
import { ReactComponent as CalendarTimeIcon } from "../../../design/assests/images/icons/time.svg"
import Tooltip from "../../../components/atoms/Tooltip.atom"
import { generateRandomId } from "../../../utils/strings.utils"
import Chip from "../../../components/atoms/misc/Chip.atom"

const FollowedTaskDate = ({
  value,
  error,
  chipClassName = `choose-time-chip-${generateRandomId()}`,
}) => {
  value = value ? DateTime.fromISO(value) : null
  if (value && value < DateTime.local()) {
    error = true
  }
  const tooltipConfig =
    error || value ?
      {
        "data-pr-tooltip": `Fecha límite${error ? " con retraso" : ""}`,
        "data-pr-position": "bottom",
      }
    : {}
  const containerStyles = {
    width: "105px",
    height: "26px",
  }

  const SelectedDateTemplate = () => {
    return (
      <Chip
        className={`btn ${chipClassName} btn-primary w-full p-chip-readonly ${error ? "error-chip" : ""}`}
        template={
          <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem">
            <span className="flex gap-1 align-items-center">
              <CalendarTimeIcon
                className="flex-shrink-0 clr-text"
                width={14}
                height={14}
              />
              <span>{value?.toFormat("dd/MM/yyyy")}</span>
            </span>
          </span>
        }
        {...tooltipConfig}
      />
    )
  }

  return (
    <div
      className="dropdown-choosettime-inplace input-panel-with-chip"
      style={containerStyles}
    >
      <SelectedDateTemplate />
      <Tooltip target={`.${chipClassName}`} />
    </div>
  )
}

export default FollowedTaskDate
