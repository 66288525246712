import React from "react"
import Home from "../../views/home/Home.view"
import { AppRoutes } from "../../consts/appRoutes.consts"
import Sandbox from "../../views/sandbox/Sandbox.view"
import { Logout } from "../../views/auth/Logout.view"

export const DEFAULT_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />,
  },
  {
    path: AppRoutes.auth.loginExplicit,
    element: <Home />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  ...((
    process.env.REACT_APP_ENVIROMENT !== "production" // Available for development and testing only
  ) ?
    [
      {
        path: "/sandbox",
        element: <Sandbox />,
      },
    ]
  : []),
]
