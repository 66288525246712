import React, { useEffect, useState } from "react"
import Column from "../../../../../components/atoms/table/Column.atom"
import { CustomTreeTable as TreeTable } from "../../../../../components/atoms/table/CustomTreeTable.atom"
import { useSession } from "../../../../../hooks/auth/useSession.hook"
import { roundReportData3Decimals } from "../../../../../utils/report.utils"
import { CLIENT_CHIP_COLUMN_PROPS } from "../../../../../consts/tables.consts"
import TextIconWithTooltip from "../../../../../components/molecules/TextIconWithTooltip.molecule"
import { numberFormatter } from "../../../../../utils/numbers.utils"
import { TimeDifferenceBadge } from "../../components/CellReportBadges"
import MultiSelectClientProjectFilter from "../../../../../components/compounds/MultiSelectClientProjectFilter.compound"
import { sortParentsWithStickyChildren } from "../../../../../utils/treetable.utils"
import DifferenceReasonTable from "../../components/DifferenceReasonTable"
import listDetailsIcon from "../../../../../design/assests/images/icons/list_details.svg"
import Modal from "../../../../../components/atoms/Modal.atom"
import DataTable from "../../../../../components/atoms/table/DataTable.atom"
import ClientChip from "../../../../../components/molecules/ClientChip.molecule"
import ProjectChip from "../../../../../components/molecules/ProjectChip.molecule"
import ClientProjectChip from "../../../../../components/molecules/ClientProjectChip.molecule"
import { formatPercentageSuffix } from "../../../../../utils/strings.utils"
import useReportFiltersContext from "../../../../../hooks/contextConsumers/useReportFiltersContext.hook"
import { AIKALaunchDate } from "../../../../../consts/general.consts"

function TimeDifferenceModal({ modalData, onClose, isOpen }) {
  if (!modalData) return null
  const {
    fullName,
    total,
    timeDifferences,
    clientCode,
    hasMoreThanOneProject,
  } = modalData
  let isRoot = modalData.isRoot
  let groupedHeaderTimeDifferences = timeDifferences

  if (isRoot) {
    if (hasMoreThanOneProject) {
      groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
        const [, projectName] = el.projectName.split(" - ")
        return { ...el, key: projectName }
      })
    } else {
      groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
        return { ...el, key: el.teamMemberName }
      })
      isRoot = false
    }
  } else {
    groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.map((el) => {
      return { ...el, key: el.teamMemberName }
    })
  }

  groupedHeaderTimeDifferences = groupedHeaderTimeDifferences.sort((a, b) =>
    a.teamMemberName.localeCompare(b.teamMemberName)
  )

  return (
    <Modal
      visible={isOpen}
      draggable={false}
      onHide={onClose}
      header={
        <div className="flex gap-3">
          <img style={{ width: 32 }} src={listDetailsIcon} alt="Detalles" />
          <div className="flex flex-column">
            <div style={{ fontSize: 24 }}>Detalles de horas perdonadas</div>
            <div className="flex gap-2 align-items-center mt-2">
              {isRoot ?
                <>
                  <ClientChip clientCode={clientCode} />
                  <p style={{ fontSize: 14, fontWeight: 700 }}>{fullName}</p>
                </>
              : <ClientProjectChip
                  clientCode={clientCode}
                  projectCode={timeDifferences[0].projectCode}
                />
              }
            </div>
          </div>
        </div>
      }
      className="rendimiento-por-proyectos-report-hours-details-modal w-full"
      contentClassName="flex"
      dismissableMask
    >
      <div className="w-full flex-1 overflow-auto">
        <DataTable
          scrollable
          scrollHeight="flex"
          value={groupedHeaderTimeDifferences}
          rowGroupMode="subheader"
          groupRowsBy="key"
          sortField="key"
          sortOrder={1}
          rowGroupHeaderTemplate={(data) => {
            return (
              <div className="flex align-items-center w-full gap-2">
                {isRoot && (
                  <ProjectChip
                    projectCode={data.projectCode}
                    clientCode={clientCode}
                  />
                )}
                <strong>{data.key}</strong>
              </div>
            )
          }}
        >
          {isRoot && (
            <Column field="teamMemberName" footer="Total" header="Integrante" />
          )}
          <Column
            field="comment"
            footer={!isRoot ? "Total" : ""}
            header="Motivo"
          />
          <Column
            style={{ maxWidth: "135px" }}
            field="time"
            footer={total}
            header="Horas"
          />
        </DataTable>
      </div>
    </Modal>
  )
}

const calculateFooterTotals = (clonedData) => {
  const finalFooterTotals = {
    distributionSisuTime: 0,
    distributionBilledTime: 0,
    sisuTime: 0,
    billedTime: 0,
    timeDifference: 0,
    timePerformance: 0,
  }

  for (const item of clonedData.data) {
    finalFooterTotals.distributionSisuTime += item.data.distributionSisuTime
    finalFooterTotals.sisuTime += item.data.sisuTime
    if (item.data.distributionBilledTime !== "N/A") {
      finalFooterTotals.distributionBilledTime +=
        item.data.distributionBilledTime
      finalFooterTotals.billedTime += item.data.billedTime
      finalFooterTotals.timeDifference += item.data.timeDifference
    }
  }

  finalFooterTotals.timePerformance +=
    finalFooterTotals.billedTime / finalFooterTotals.sisuTime
  return finalFooterTotals
}

const calculateFilteredGroupHeadersTotals = (activeFilters, originalData) => {
  const clonedData = structuredClone(originalData)
  clonedData.data.forEach((el) => {
    el.data.distributionSisuTime = 0
    el.data.sisuTime = 0
    el.data.billedTime = 0
    el.data.distributionBilledTime = 0
    el.data.timeDifference = 0
    el.data.timePerformance = 0
  })

  const parentChildrenKeys = []
  const childrenEls = []
  originalData.data.forEach((el) => {
    for (const child of el.children) {
      if (activeFilters.includes(child.data.name)) {
        parentChildrenKeys.push([child.key, el.key])
        childrenEls.push(child)
      }
    }
  })

  for (const [key, parentKey] of parentChildrenKeys) {
    clonedData.data.forEach((el) => {
      if (el.key === parentKey) {
        const isBillable = el.data.isBillable
        const parent = originalData.data.find((el) => el.key === parentKey)
        for (const subChild of parent.children) {
          if (subChild.key === key) {
            el.data.sisuTime += subChild.data.sisuTime
            el.data.distributionSisuTime += subChild.data.distributionSisuTime
            if (isBillable) {
              el.data.billedTime += subChild.data.billedTime
              el.data.distributionBilledTime +=
                subChild.data.distributionBilledTime
              el.data.timeDifference += subChild.data.timeDifference
              el.data.timePerformance += subChild.data.timePerformance
            }
          }
        }
      }
    })
  }

  for (let i = 0; i < clonedData.data.length; i++) {
    const isBillable = clonedData.data[i].data.isBillable
    if (isBillable) {
      clonedData.data[i].data.timePerformance =
        clonedData.data[i].data.billedTime / clonedData.data[i].data.sisuTime
      if (isNaN(clonedData.data[i].data.timePerformance)) {
        clonedData.data[i].data.timePerformance = 0
      }
    }
  }

  return clonedData
}

/**
 * @param {string | number} num
 * @returns {string}
 */
function pctPadEnd(num) {
  const numStr = String(num)
  const isFloat = numStr.includes(".")

  if (isFloat) {
    return <span>{numStr}</span>
  } else {
    const integer = numStr.split("%")[0]
    return <span>{`${integer}.0%`}</span>
  }
}

/**
 * @param {string | number} num
 * @param {number} padAmount
 * @param {string} padContent
 * @returns {string}
 */
function padFloat(num, padAmount, padContent) {
  num = String(num)

  const [whole, decimal] = num.split(".")
  if (decimal) {
    return `${whole}.${decimal.padEnd(padAmount, padContent)}`
  }
  return num + "." + "0".repeat(padAmount)
}

const HoursByProjectReportDataTable = React.forwardRef(
  (
    {
      reportData: originalData,
      expandedKeys,
      setExpandedKeys,
      expandAll,
      collapseAll,
    },
    ref
  ) => {
    const { projectsPerformanceDateRange } = useReportFiltersContext()

    const isDateBeforeAIKALaunch =
      projectsPerformanceDateRange[1] < AIKALaunchDate

    const [filters, setFilters] = useState({
      "data.name": { value: null, matchMode: "in" },
    })

    const [reportData, setReportData] = useState(
      roundReportData3Decimals(structuredClone(originalData))
    )
    const [footerTotals, setFooterTotals] = useState({})

    useEffect(() => {
      const activeFilters = filters["data.name"].value
      const isFilteringName = activeFilters !== null && activeFilters.length > 0

      if (isFilteringName) {
        const clonedData = calculateFilteredGroupHeadersTotals(
          activeFilters,
          originalData
        )

        setFooterTotals(calculateFooterTotals(clonedData))
        setReportData(roundReportData3Decimals(clonedData))
      } else {
        const clonedData = structuredClone(originalData)

        setReportData(roundReportData3Decimals(clonedData))
      }
    }, [filters])

    const resetFilters = () => {
      collapseAll()
      setFilters({
        "data.name": { value: null, matchMode: "in" },
      })
    }
    const auth = useSession()
    const newRef = React.useRef()
    const [modalData, setModalData] = useState(null)
    const [isTimeDiffDetailsModalOpen, setIsTimeDiffDetailsModalOpen] =
      useState(false)

    React.useImperativeHandle(
      ref,
      () => ({
        ...newRef.current,
        reset() {
          collapseAll()
          setFilters({
            "data.name": { value: null, matchMode: "in" },
          })
          newRef.current.reset()
        },
      }),
      []
    )

    const adminExpandCondition = (node) => {
      if (node?.children?.length > 1) {
        return true
      } else {
        return false
      }
    }

    const teamMemberExpandCondition = (node) => {
      if (node?.children?.length > 1) {
        return true
      } else {
        return false
      }
    }

    let finalFooterTotals = reportData.totals
    const activeFilters = filters["data.name"].value
    const isFilteringName = activeFilters !== null
    if (isFilteringName) {
      finalFooterTotals = footerTotals
    }

    return (
      <>
        <TimeDifferenceModal
          modalData={modalData}
          onClose={() => {
            setIsTimeDiffDetailsModalOpen(false)
            setModalData(null)
          }}
          isOpen={isTimeDiffDetailsModalOpen}
        />
        <TreeTable
          ref={newRef}
          value={reportData.data}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          removableSort
          filters={filters}
          globalFilterFields={["data.name"]}
          expanderConditions={(node) => {
            if (auth.user.rol === "admin") {
              return adminExpandCondition(node)
            } else {
              return teamMemberExpandCondition(node)
            }
          }}
          scrollable
          scrollHeight="100%"
          frozenExpandedColumn
        >
          <Column
            {...CLIENT_CHIP_COLUMN_PROPS(
              {
                field: "data.name",
                filter: true,
                filterField: "data.name",
                filterElement: (options) => (
                  <MultiSelectClientProjectFilter
                    key={reportData.data.length}
                    options={options}
                    data={reportData.data}
                  />
                ),
                showFilterMenuOptions: false,
                showFilterMatchModes: false,
                filterMatchMode: "in",
                onFilterApplyClick: (e) => {
                  if (!e.constraints?.value) {
                    collapseAll()
                  } else {
                    expandAll()
                    setFilters({
                      "data.name": {
                        value: e.constraints?.value,
                        matchMode: "in",
                      },
                    })
                  }
                },
                onFilterClear: resetFilters,
                frozen: true,
              },
              "Cliente/Proyecto"
            )}
            footer={() => <p style={{ translate: -45 }}>Totales</p>}
          />
          <Column
            header="Horas trabajadas"
            field="data.sisuTime"
            style={{ minWidth: 180 }}
            sortable
            sortFunction={sortParentsWithStickyChildren}
            body={(node) => (
              <p>
                {padFloat(numberFormatter(node?.data?.sisuTime), 2, "0")}{" "}
                <span
                  style={{
                    color: "light-dark(#6e7b8d, #dce1e8)",
                    fontSize: 12,
                  }}
                >
                  (
                  {pctPadEnd(
                    formatPercentageSuffix(node?.data?.distributionSisuTime)
                  )}
                  )
                </span>
              </p>
            )}
            footer={padFloat(
              numberFormatter(finalFooterTotals.sisuTime),
              2,
              "0"
            )}
          />
          <Column
            header="Horas Facturadas"
            field="data.billedTime"
            style={{ minWidth: 180 }}
            footer={padFloat(
              numberFormatter(finalFooterTotals.billedTime),
              2,
              "0"
            )}
            body={(node) => {
              if (!node?.data?.isBillable) {
                return <span className={"opacity-30"}>N/A</span>
              }

              let distributionBilledTime
              if (node?.data?.distributionBilledTime < 0.001) {
                distributionBilledTime = "< 1%"
              } else {
                distributionBilledTime = formatPercentageSuffix(
                  node?.data?.distributionBilledTime
                )
              }

              return (
                <p
                  className={`${node?.data?.billedTime === "N/A" ? "opacity-30" : ""}`}
                >
                  {padFloat(numberFormatter(node?.data?.billedTime), 2, "0")}{" "}
                  <span
                    style={{
                      color: "light-dark(#6e7b8d, #dce1e8)",
                      fontSize: 12,
                    }}
                  >
                    ({pctPadEnd(distributionBilledTime)})
                  </span>
                </p>
              )
            }}
            sortable
            sortFunction={sortParentsWithStickyChildren}
          />

          <Column
            style={{ minWidth: 220 }}
            header="Diferencia de horas"
            field="data.timeDifference"
            footer={() => {
              const totalDifference = finalFooterTotals.timeDifference
              const totalSisuTime = finalFooterTotals.sisuTime
              const percentageDifference = totalDifference / totalSisuTime
              return (
                <span className="flex gap-2 w-full">
                  <span className="w-3">
                    {padFloat(numberFormatter(totalDifference), 2, "0")}
                  </span>
                  {totalDifference > 0 && (
                    <TimeDifferenceBadge
                      value={totalDifference}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      percentage={formatPercentageSuffix(percentageDifference)}
                    />
                  )}
                </span>
              )
            }}
            sortable
            sortFunction={sortParentsWithStickyChildren}
            body={(node) => {
              if (!node?.data?.isBillable) {
                return <span className={"opacity-30"}>N/A</span>
              }

              let timeDifferenceComments = []
              let total = 0
              let isRoot = false

              if (node.data.taskTimeDifferenceComments?.length > 0) {
                timeDifferenceComments = Object.entries(
                  node.data.condensedTimeDifferenceData?.items
                )
                total = node.data?.condensedTimeDifferenceData?.total
              }

              let onClick
              if (
                node.data.taskTimeDifferenceComments !== undefined &&
                node.data.taskTimeDifferenceComments.length !== 0
              ) {
                const fullName = node.data.name
                if (
                  node.data.taskTimeDifferenceComments[0].projectName !==
                  undefined
                ) {
                  isRoot = true
                }

                const timeDifferences =
                  node.data.taskTimeDifferenceComments.map((el) => ({
                    teamMemberName: el.name + " " + el.lastName,
                    comment: el.comment.trim(),
                    time: el.time.trim(),
                    projectCode: el.projectCode,
                    projectName: el.projectName,
                  }))

                onClick = () => {
                  let hasMoreThanOneProject = false
                  if (node.children) {
                    hasMoreThanOneProject = node.children.length > 1
                  }
                  setModalData({
                    fullName,
                    timeDifferences,
                    total,
                    clientCode: node.data.code,
                    isRoot,
                    hasMoreThanOneProject,
                  })
                  setIsTimeDiffDetailsModalOpen(true)
                }
              }

              return (
                <span className="flex justify-content-between align-items-center w-full gap-2">
                  <span className="w-3 text-left">
                    {padFloat(node.data.timeDifference, 2, "0")}
                  </span>
                  {node.data.timeDifference > 0 && (
                    <span className="flex-grow-1 text-left">
                      <TimeDifferenceBadge
                        value={node.data.timeDifference}
                        minTreshold={0}
                        maxTreshold={0}
                        referenceValue={0}
                        badgeProps={{
                          onClick,
                          style: {
                            cursor: onClick ? "pointer" : "default",
                          },
                        }}
                        percentage={formatPercentageSuffix(
                          node.data.timeDifference / node.data.sisuTime,
                          1
                        )}
                        tooltip={
                          isDateBeforeAIKALaunch ?
                            <table>
                              <tbody>
                                <tr>
                                  <td className="text-center">
                                    Sin información
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          : <DifferenceReasonTable
                              header={["Integrante", "Horas"]}
                              body={timeDifferenceComments}
                              total={total}
                            />
                        }
                      />
                    </span>
                  )}
                </span>
              )
            }}
          />
          <Column
            style={{ minWidth: 170 }}
            sortable
            sortFunction={sortParentsWithStickyChildren}
            field="data.timePerformance"
            header={
              <TextIconWithTooltip
                content="Rendimiento"
                tooltipValue="Porcentaje de horas facturadas respecto al total de horas trabajadas"
              />
            }
            body={(node) => {
              if (!node?.data?.isBillable) {
                return <span className={"opacity-30"}>N/A</span>
              }

              return pctPadEnd(
                formatPercentageSuffix(node?.data?.timePerformance)
              )
            }}
            footer={() => {
              if (
                finalFooterTotals.timePerformance === 0 ||
                finalFooterTotals.timePerformance === "-"
              ) {
                return "-"
              }
              return pctPadEnd(
                formatPercentageSuffix(finalFooterTotals.timePerformance)
              )
            }}
          />
        </TreeTable>
      </>
    )
  }
)

export default React.memo(HoursByProjectReportDataTable)
