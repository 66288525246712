import React, { useState } from "react"
import DropdownProjectFilter from "../../../components/compounds/DropdownProjectFilter.compound"
import ClientProjectChip from "../../../components/molecules/ClientProjectChip.molecule"
import InputPlaceholderChipTemplate from "../../../components/molecules/InputPlaceholderChipTemplate.molecule"
import { generateRandomId } from "../../../utils/strings.utils"

const DropdownProjectInplace = ({
  value,
  onChange,
  label = "Proyecto",
  placeholder = "Proyecto",
  placeholderIcon = "pi pi-briefcase",
  name = "projectTagName",
  options,
  optionValue = "projectID",
  optionLabel = "projectName",
  error,
  dropdownRef,
  dropdownInputProps,
  readOnly = false,
  containerStyles = {
    width: "92px",
    height: "26px",
  },
  ...props
}) => {
  if (!options) return
  const [isEditing, setIsEditing] = useState(false)
  const selectedProject = options.find(
    (option) => option?.[optionValue] === value
  )
  const projectName = selectedProject?.[optionLabel] || ""

  options = options.filter((option) => {
    if (Object.keys(option).includes("active")) {
      return option[optionValue] === value || option.active
    }
    return option
  })
  if (value) {
    containerStyles = {
      ...containerStyles,
      height: "22px",
    }
  }
  const dropdownProjectId = `dropdown-project-${name}-${generateRandomId()}`
  const handleChipClick = () => {
    setIsEditing(true)
    setTimeout(() => {
      dropdownRef.current?.show()
      dropdownRef.current?.getFocusInput().focus()
    }, 100)
  }

  const ClientProjectChipTemplate = () => {
    return (
      <ClientProjectChip
        clientCode={selectedProject?.clientTagName}
        projectCode={selectedProject?.projectTagName}
        onClick={handleChipClick}
        tooltipPosition="bottom"
        className={`${readOnly ? "p-chip-readonly" : ""}`}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          width: "88px",
        }}
      />
    )
  }

  const placeholderChipTemplate = () => {
    return (
      <InputPlaceholderChipTemplate
        readOnly={readOnly}
        error={error}
        isEditing={isEditing}
        chipClassName="relative"
        labelTemplate={
          <span className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem m-0">
            {value && projectName ?
              projectName
            : <span className="flex gap-2 align-items-center">
                {error ?
                  <i className="pi pi-exclamation-triangle text-xs line-height-2 m-auto"></i>
                : <i className="pi pi-briefcase text-xs line-height-2 m-auto"></i>
                }
                <span>{label}</span>
              </span>
            }{" "}
          </span>
        }
        onClick={handleChipClick}
        style={{
          width: "calc(100% - 2px)",
          height: "calc(100% - 2px",
        }}
      />
    )
  }
  return (
    <div
      className="dropdown-project-filter-inplace input-panel-with-chip"
      style={containerStyles}
    >
      {(projectName && value) || readOnly ?
        <ClientProjectChipTemplate />
      : placeholderChipTemplate()}
      {!readOnly && (
        <DropdownProjectFilter
          ref={dropdownRef}
          id={dropdownProjectId}
          value={value}
          onChange={onChange}
          name={name}
          options={options}
          optionValue={optionValue}
          optionLabel={optionLabel}
          error={error}
          placeholder={placeholder}
          inputProps={dropdownInputProps}
          className="opacity-0 h-2rem overflow-hidden absolute w-full"
          style={{
            height: "26px",
          }}
          onHide={() => {
            setIsEditing(false)
          }}
        />
      )}
    </div>
  )
}

export default DropdownProjectInplace
