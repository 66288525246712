import React, { useState } from "react"
import FormCreatePendingTask from "./FormCreatePendingTask.controller"
import PendingTaskList from "./PendingTaskList.controller"

const TodoTasks = ({ setTodoTasksLength }) => {
  const [refresh, setRefresh] = useState(false)
  const triggerRefresh = () => setRefresh(!refresh)
  return (
    <div className="flex flex-column gap-4">
      <FormCreatePendingTask triggerRefresh={triggerRefresh} />
      <PendingTaskList
        refresh={refresh}
        triggerRefresh={triggerRefresh}
        setTodoTasksLength={setTodoTasksLength}
      />
    </div>
  )
}

export default TodoTasks
