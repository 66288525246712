import React from "react"
import PropTypes from "prop-types"
import InputText from "../atoms/InputText.atom"
import Label from "../atoms/Label.atom"

export default function InputTextFilter(props) {
  const forId = `input-text-${props.label?.replace(/ /g, "-")}-${new Date().getTime()}`
  return (
    <div className="field">
      {props.label && <Label htmlFor={forId}>{props.label}</Label>}
      <InputText
        id={forId}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={props.className}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        maxLength={props.maxLength}
      />
    </div>
  )
}

InputTextFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}
