import React, { useState } from "react"
import Dropdown from "../../../components/atoms/Dropdown.atom"
import DropdownCustomFilterSearch from "../../../components/molecules/DropdownCustomFilterSearch.molecule"
import InputPlaceholderChipTemplate from "../../../components/molecules/InputPlaceholderChipTemplate.molecule"
import FollowersChipList from "./FollowersChipList.compound"
import { generateRandomId } from "../../../utils/strings.utils"

const DropdownFollowerInplace = ({
  selectedValues = [],
  onChange,
  options,
  optionValue = "id",
  optionLabel = "nameTag",
  label = "Seguidor",
  placeholder = "Seleccionar seguidores",
  iconClassName = "pi-user-plus",
  name = "dropdown-follower-inplace",
  error,
  dropdownRef,
  readOnly = false,
  containerStyles = {
    minWidth: "89px",
    height: "26px",
  },
  removableFollowers = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const selectedFollowers = selectedValues
    .map((value) => options.find((option) => option[optionValue] === value))
    .filter((follower) => follower !== undefined)
  const optionsWithFullLabel = options.map((option) => ({
    ...option,
    fullLabel: `${option.nameTag} - ${option.name} ${option.lastName}`,
  }))
  const dropdownFollowerId = `dropdown-follower-${name}-${generateRandomId()}`
  const handleChipClick = () => {
    setIsEditing(true)
    setTimeout(() => {
      dropdownRef.current?.show()
      dropdownRef.current?.getFocusInput().focus()
    }, 100)
  }

  const placeholderChipTemplate = () => {
    return (
      <InputPlaceholderChipTemplate
        readOnly={readOnly}
        error={error}
        isEditing={isEditing}
        chipClassName="relative"
        labelTemplate={
          <span className="flex gap-2 align-items-center">
            {error ?
              <i className="pi pi-exclamation-triangle text-xs line-height-2 m-auto"></i>
            : <i
                className={`pi ${iconClassName} text-xs line-height-2 m-auto`}
              ></i>
            }
            <span>{label}</span>
          </span>
        }
        onClick={handleChipClick}
      />
    )
  }

  return (
    <div className="dropdown-follower-inplace-with-chips flex gap-2">
      <FollowersChipList
        selectedFollowers={selectedFollowers}
        optionValue={optionValue}
        onChange={onChange}
        removableFollowers={removableFollowers}
      />
      {!readOnly && (
        <div
          key={isEditing}
          className="dropdown-follower-inplace flex input-panel-with-chip"
          style={containerStyles}
        >
          {placeholderChipTemplate()}
          <Dropdown
            ref={dropdownRef}
            value={selectedValues}
            name={name}
            onChange={(e) => {
              onChange(e)
            }}
            options={optionsWithFullLabel}
            optionValue={optionValue}
            optionLabel={optionLabel}
            placeholder={placeholder}
            className="opacity-0 h-2rem overflow-hidden absolute"
            inputId={dropdownFollowerId}
            style={{
              height: "26px",
            }}
            itemTemplate={(option) => {
              const isSelected = selectedValues.includes(option[optionValue])
              return (
                <div
                  className={`max-w-16rem overflow-hidden white-space-nowrap text-overflow-ellipsis ${isSelected ? "dropdown-prev-selected" : ""}`}
                  title={option[optionLabel]}
                >
                  {option.fullLabel}
                </div>
              )
            }}
            filter
            filterBy="fullLabel"
            filterTemplate={(props) => (
              <DropdownCustomFilterSearch
                props={props}
                inputProps={{
                  autoFocus: true,
                }}
              />
            )}
            onHide={() => {
              setIsEditing(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default DropdownFollowerInplace
