import React from "react"
import PropTypes from "prop-types"
import { useDrag, useDrop } from "react-dnd"
import ModuleTemplate from "./ModuleTemplate"
import { MODULE_SORT_OPTIONS } from "../../../../../consts/modules/clients.consts"

const DraggableItem = ({
  item,
  orderMode,
  onDelete,
  onEdit,
  moveItem,
  onSelect,
  isSelected,
  blockDrag = false,
  visibleModalWarn,
  itemType = "ITEM",
  columnTitle,
  readOnly = false,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: itemType,
    item: { name: item.name, originalIndex: item.order, active: item.active },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [, drop] = useDrop({
    accept: itemType,
    hover: (draggedItem) => {
      if (draggedItem.name !== item.name) {
        const fromColumn = draggedItem.active ? "Activos" : "Inactivos"
        if (
          orderMode === MODULE_SORT_OPTIONS.ALPHABETICAL ||
          blockDrag ||
          columnTitle !== fromColumn
        ) {
          return
        }
        moveItem(draggedItem.name, item.name, columnTitle)
        draggedItem.order = item.order
      }
    },
  })

  return (
    <li
      key={item.name}
      ref={(node) => drop(node)}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className={`p-picklist-item ${isSelected ? "p-highlight" : ""}`}
      onClick={() => {
        onSelect(item)
      }}
    >
      <ModuleTemplate
        ref={(node) => {
          if (!readOnly) {
            drag(node)
          }
        }}
        key={item.name}
        orderMode={orderMode}
        module={item}
        onDelete={onDelete}
        onEdit={onEdit}
        visibleModalWarn={visibleModalWarn}
        columnTitle={columnTitle}
        readOnly={readOnly}
      />
    </li>
  )
}

export default DraggableItem

DraggableItem.propTypes = {
  item: PropTypes.object,
  orderMode: PropTypes.oneOf(Object.values(MODULE_SORT_OPTIONS)),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  moveItem: PropTypes.func,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  blockDrag: PropTypes.bool,
  visibleModalWarn: PropTypes.bool,
  itemType: PropTypes.string,
  columnTitle: PropTypes.string,
}
