import React from "react"
import Tooltip from "../atoms/Tooltip.atom"
import { generateRandomNumber } from "../../utils/numbers.utils"

const IconWithTooltipAndCustomBody = ({
  iconClassName = "pi pi-info-circle",
  tooltipBody = undefined,
  position = undefined,
}) => {
  const id = generateRandomNumber()
  const defaultPosition = "bottom"
  return (
    <>
      <Tooltip
        target={`.icon-tooltip-${id}`}
        position={position || defaultPosition}
      >
        {tooltipBody}
      </Tooltip>
      <i className={`${iconClassName} icon-tooltip-${id}`} />
    </>
  )
}

export default IconWithTooltipAndCustomBody
