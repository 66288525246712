/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Cell,
  Legend,
  Pie,
  PieChart as PieChartRecharts,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { getColor, getRadiusOfPie, getXAndYAxisLabelPieChart } from '../../../utils/graphics.utils'
import TooltipSingleDefault from './components/TooltipSingleDefault'
import { defaultColors, TYPE_OF_TOOLTIP_COLOR_VARIANTS } from '../../../consts/modules/graphics.consts'
import ContainerOfChart from './components/ContainerOfChart'
import EmptyDataMessage from '../../molecules/EmptyDataPieChartMessage.molecule'

const PieChart = ({
  className = '',
  title,
  width = 500,
  height = 500,
  data: piesData = [],
  tooltipTemplate,
  tooltip,
  tooltipConfig = {},
  colorsArray = defaultColors,
  label = false,
  labelTextTemplate,
  legendTemplate,
  legend = false,
  legendConfig = {},
  pieConfig = {},
  onePie = false,
  responsiveContainerConfig = {},
  whiteBackground = true,
  margin,
  showEmptyMessage,
  emptyMessageConfig,
  chartContainerConfig,
  loading = false
}) => {
  const DEFAULT_RESPONSIVE_CONTAINER_CONFIG = {
    width: '100%',
    height: '100%',
    minWidth: '100px',
    minHeight: '100px',
    maxHeight: '1000px',
    maxWidth: '1000px'
  }
  const responseContainerWidth = responsiveContainerConfig?.width || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.width
  const responseContainerHeight = responsiveContainerConfig?.height || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.height
  const responseContainerMinWidth = responsiveContainerConfig?.minWidth || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minWidth
  const responseContainerMinHeight = responsiveContainerConfig?.minHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.minHeight
  const responseContainerMaxHeight = responsiveContainerConfig?.maxHeight || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.maxHeight
  const responseContainerMaxWidth = responsiveContainerConfig?.maxWidth || DEFAULT_RESPONSIVE_CONTAINER_CONFIG.maxWidth

  const DEFAULT_TOOLTIP_CONFIG = {
    colorType: TYPE_OF_TOOLTIP_COLOR_VARIANTS.square,
    nameFormatter: undefined,
    valueFormatter: undefined,
    unit: ''
  }
  const tooltipUnit = tooltipConfig?.unit ?? DEFAULT_TOOLTIP_CONFIG.unit
  const tooltipValueFormatter = tooltipConfig?.valueFormatter ?? DEFAULT_TOOLTIP_CONFIG.valueFormatter
  const tooltipNameFormatter = tooltipConfig?.nameFormatter ?? DEFAULT_TOOLTIP_CONFIG.nameFormatter
  const tooltipColorType = tooltipConfig?.colorType ?? DEFAULT_TOOLTIP_CONFIG.colorType

  const pieInnerRadius = pieConfig?.innerRadius
  const pieOuterRadius = pieConfig?.outerRadius

  const DEFAULT_LEGEND_CONFIG = {
    nameTemplate: (name) => name,
    verticalAlign: 'top',
    align: 'center',
    layout: 'horizontal'
  }

  const legendNameTemplate = legendConfig?.nameTemplate ?? DEFAULT_LEGEND_CONFIG.nameTemplate
  const legendVerticalAlign = legendConfig?.verticalAlign ?? DEFAULT_LEGEND_CONFIG.verticalAlign
  const legendAlign = legendConfig?.align ?? DEFAULT_LEGEND_CONFIG.align
  const legendLayout = legendConfig?.layout ?? DEFAULT_LEGEND_CONFIG.layout

  const DEFAULT_EMPTY_MESSAGE_CONFIG = {
    title: 'Sin información',
    message: 'No hay datos disponibles para mostrar.'
  }
  const emptyMessageTitle = emptyMessageConfig?.title ?? DEFAULT_EMPTY_MESSAGE_CONFIG.title
  const emptyMessageMessage = emptyMessageConfig?.message ?? DEFAULT_EMPTY_MESSAGE_CONFIG.message

  const DEFAULT_CHART_CONTAINER_CONFIG = {
    title: '',
    iconClassName: '',
    className: '',
    shadow: true
  }
  const chartContainerTitle = chartContainerConfig?.title ?? DEFAULT_CHART_CONTAINER_CONFIG.title
  const chartContainerIconClassName = chartContainerConfig?.iconClassName ?? DEFAULT_CHART_CONTAINER_CONFIG.iconClassName
  const chartContainerClassName = chartContainerConfig?.className ?? DEFAULT_CHART_CONTAINER_CONFIG.className
  const chartContainerShadow = chartContainerConfig?.shadow ?? DEFAULT_CHART_CONTAINER_CONFIG.shadow

  const renderCustomizedLabel = (
    { cx, cy, midAngle, innerRadius, outerRadius, percent, payload },
    labelTextTemplate,
    countOfAllPies,
    index
  ) => {
    const axies = getXAndYAxisLabelPieChart({ cx, cy, innerRadius, outerRadius, midAngle, countOfAllPies, index })
    const porcentajePiePart = `${(percent * 100).toFixed(0)}%`
    const piePartData = {
      name: payload.name,
      value: payload.value,
      color: payload.fill,
      extraInfo: payload.payload?.extraInfo || null,
      porcentaje: porcentajePiePart
    }

    const labelTextTemplateRender = labelTextTemplate && typeof labelTextTemplate === 'string'
      ? labelTextTemplate
      : labelTextTemplate && labelTextTemplate(piePartData)

    return (
      <text x={axies?.x} y={axies?.y} fill="white" textAnchor={axies?.x > cx ? 'start' : 'end'} dominantBaseline="central">
        {labelTextTemplate ? labelTextTemplateRender : porcentajePiePart}
      </text>
    )
  }

  const getPie = (pieData, index) => {
    const key = `pie-${index}`
    const records = pieData?.data || []
    const radiuses = getRadiusOfPie(piesData.length, index, onePie)
    const labelToUse = pieData?.label ?? label

    return (
      <Pie
        key={key}
        data={records}
        dataKey="value"
        cx="50%"
        cy="50%"
        outerRadius={pieOuterRadius || radiuses?.outerRadius}
        innerRadius={pieInnerRadius || radiuses?.innerRadius}
        fill="#8884d8"
        labelLine={false}
        label={
          labelToUse
            ? (rawRechartsData) => renderCustomizedLabel(rawRechartsData, labelTextTemplate, piesData.length, index)
            : null
        }
      >
        {records.map((record, index) => {
          const key = `cell-${index}`
          return (
            <Cell
              key={key}
              fill={record?.color || getColor(index, colorsArray)}
              opacity={record?.extraInfo?.opacity || 1}
            />
          )
        })}
      </Pie>
    )
  }

  const getPiesRender = (piesData) => {
    if (onePie) return getPie(piesData[0], -1)
    const limintOfPies = 5
    const piesElements = []
    for (let index = 0; index < limintOfPies; index++) {
      const pieData = piesData[index]
      if (!pieData) break
      piesElements.push(getPie(pieData, index))
    }
    return piesElements
  }

  const legendRender = (rawLegendsData) => {
    const legendsData = rawLegendsData.payload.map((legendData) => {
      return {
        name: legendData.value,
        value: legendData.payload.value,
        color: legendData.color,
        extraInfo: legendData.payload.extraInfo
      }
    })
    return (
      <div className="container-legend-graphic">
        {legendTemplate(legendsData)}
      </div>
    )
  }

  const tooltipRender = (rawTooltipData) => {
    if (!rawTooltipData?.active || !rawTooltipData?.payload?.length) return null
    const payloadData = rawTooltipData?.payload?.[0]

    const recordData = payloadData?.payload ? payloadData?.payload : {}

    const extraInfo = recordData?.extraInfo || null

    const piePartData = {
      name: payloadData?.name,
      value: payloadData?.value,
      color: recordData?.fill,
      extraInfo
    }

    if (!tooltipTemplate) {
      return <TooltipSingleDefault
        data={piePartData}
        tooltipUnit={tooltipUnit}
        valueFormatter={tooltipValueFormatter}
        nameFormatter={tooltipNameFormatter}
        colorType={tooltipColorType}
      />
    }

    return (
      <div className="container-tooltip-graphic">
        {tooltipTemplate(piePartData)}
      </div>
    )
  }
  if (loading) {
    const mockData = [
      { name: '1', value: 100, color: '#dddddd' },
      { name: '2', value: 20, color: '#aaaaaa' }
    ]
    const radiuses = getRadiusOfPie(1, 0, false)
    const minPx = (value1, value2) => {
      const num1 = Number(value1.match(/\d+/)[0])
      const num2 = Number(value2.match(/\d+/)[0])
      return num1 < num2 ? value1 : value2
    }
    return (
      <ContainerOfChart
        withWhiteBackgroundColor={true}
        title={chartContainerTitle}
        iconClassName={chartContainerIconClassName}
        className={chartContainerClassName}
        shadow={chartContainerShadow}
      >
        <div
          className='w-full mx-auto flex justify-content-center'
          style={{
            maxWidth: responseContainerMaxWidth || '100%'
          }}
        >
          <ResponsiveContainer
            width={responseContainerWidth}
            height={responseContainerHeight}
            minWidth={minPx(responseContainerMinWidth, responseContainerMaxHeight)}
            minHeight={minPx(responseContainerMinHeight, responseContainerMaxHeight)}
            maxHeight={minPx(responseContainerMinWidth, responseContainerMaxHeight)}
          >
            <PieChartRecharts
              className={`${className} spin-animation`}
              width={width}
              height={height}
              margin={margin}
            >
              <Pie
                isAnimationActive={false}
                data={mockData}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={pieOuterRadius || radiuses?.outerRadius}
                innerRadius={pieInnerRadius || radiuses?.innerRadius}
                fill="#8884d8"
             >
                {
                  mockData?.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))
                }

             </Pie>
            </PieChartRecharts>
          </ResponsiveContainer>
        </div>
      </ContainerOfChart>
    )
  }

  if (!piesData.length || showEmptyMessage) {
    return (
      <ContainerOfChart
        withWhiteBackgroundColor={whiteBackground}
        title={chartContainerTitle}
        iconClassName={chartContainerIconClassName}
        className={chartContainerClassName}
        shadow={chartContainerShadow}
        loading={loading}
      >
        <EmptyDataMessage
          title={emptyMessageTitle}
          message={emptyMessageMessage}
        />
      </ContainerOfChart>
    )
  }
  return (
    <ContainerOfChart
      withWhiteBackgroundColor={whiteBackground}
      title={chartContainerTitle}
      iconClassName={chartContainerIconClassName}
      className={chartContainerClassName}
      shadow={chartContainerShadow}
      style={chartContainerConfig?.style}
      loading={loading}
    >
      <div
        className='w-full mx-auto'
        style={{
          maxWidth: responseContainerMaxWidth || '100%'
        }}
      >
        <ResponsiveContainer
          width={responseContainerWidth}
          height={responseContainerHeight}
          minWidth={responseContainerMinWidth}
          minHeight={responseContainerMinHeight}
          maxHeight={responseContainerMaxHeight}
        >
          <PieChartRecharts
            className={className}
            width={width}
            height={height}
            margin={margin}
          >
            {getPiesRender(piesData)}

            {tooltip && <Tooltip content={tooltipRender}/>}
            {legend && <Legend
              content={legendTemplate ? legendRender : null}
              verticalAlign={legendVerticalAlign}
              align={legendAlign}
              layout={legendLayout}
              formatter={legendNameTemplate || null}
            />}
          </PieChartRecharts>
        </ResponsiveContainer>
      </div>
    </ContainerOfChart>
  )
}

PieChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        color: PropTypes.string
      })).isRequired
    })
  ),
  tooltipTemplate: PropTypes.func,
  tooltip: PropTypes.bool,
  tooltipConfig: PropTypes.shape({
    unit: PropTypes.string,
    valueFormatter: PropTypes.func,
    nameFormatter: PropTypes.func,
    colorType: PropTypes.oneOf(Object.values(TYPE_OF_TOOLTIP_COLOR_VARIANTS))
  }),
  colorsArray: PropTypes.array,
  label: PropTypes.bool,
  labelTextTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  legendTemplate: PropTypes.func,
  legend: PropTypes.bool,
  legendConfig: PropTypes.shape({
    nameTemplate: PropTypes.func,
    verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    layout: PropTypes.oneOf(['horizontal', 'vertical'])
  }),
  responsiveContainerConfig: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string
  }),
  onePie: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  pieConfig: PropTypes.shape({
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  showEmptyMessage: PropTypes.bool,
  emptyMessageConfig: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string
  }),
  chartContainerConfig: PropTypes.shape({
    title: PropTypes.string,
    iconClassName: PropTypes.string,
    className: PropTypes.string,
    shadow: PropTypes.bool,
    style: PropTypes.object
  }),
  loading: PropTypes.bool
}

export default React.memo(PieChart)
