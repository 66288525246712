import React, { useEffect, useState } from 'react'
import TabView from '../../../components/atoms/tabView/TabView.atom'
import TabPanel from '../../../components/atoms/tabView/TabPanel.atom'
import RegisteredTasks from './RegisteredTasks.controller'
import IndividualReportControls from './IndividualReportControls.controller'
import IndividualReportSummary from './IndividualReportSummary.controller'
import { getLastIndividualTeamMemberReport } from '../../../services/report.service'
import useReportFiltersContext from '../../../hooks/contextConsumers/useReportFiltersContext.hook'
import { useSession } from '../../../hooks/auth/useSession.hook'
import { useLocation } from 'react-router-dom'
import { thisWeekRange } from '../../../utils/date.utils'
import { UNIFIEDDATE_VALUES } from '../../../consts/CompoundRangeDatePicker'
import { isObjectEmpty } from '../../../utils/object.utils'
import { DateTime } from 'luxon'

const IndividualReportContent = ({
  setCurrentTab,
  openCreateTaskForTeamMemberModal,
  setOpenCreateTaskForTeamMemberModal,
  registeredTasksRef
}) => {
  const {
    individualTeamMember,
    individualPeriod,
    setIndividualPeriod,
    individualDateRange,
    setIndividualDateRange
  } = useReportFiltersContext()
  const [showControls, setShowControls] = useState(false)
  const [stateObtained, setStateObtained] = useState(false)
  const { user } = useSession()
  const { state } = useLocation()
  useEffect(() => {
    getStateFromLocation()
  }, [])
  const getStateFromLocation = async () => {
    const isTeamMember = user.isTeamMember()
    if (!isTeamMember && !state?.teamMemberId) {
      gettingLastTeamMemberReport()
    }

    if (!isTeamMember && state?.teamMemberId) {
      setIndividualDateRange([
        DateTime.fromISO(state.startDate),
        DateTime.fromISO(state.endDate)
      ])
      setIndividualPeriod(state.period)
      individualTeamMember.resetWith({
        id: state.teamMemberId
      })
    }
    if (isTeamMember && (!state || isObjectEmpty(state))) {
      individualTeamMember.resetWith({
        id: user.id
      })
    }
    setStateObtained(true)
  }

  const gettingLastTeamMemberReport = async () => {
    const response = await getLastIndividualTeamMemberReport()
    if (response.success) {
      individualTeamMember.resetWith(response.result)
    }
  }


  if (!stateObtained) return null
  return (
    <div id="individual-report">
      <IndividualReportControls
        key={individualTeamMember.value || showControls}
        teamMember={individualTeamMember.value}
        setTeamMember={individualTeamMember.setValue}
        period={individualPeriod}
        setPeriod={setIndividualPeriod}
        dateRange={individualDateRange}
        setDateRange={setIndividualDateRange}
        onReset={
          () => {
            const hasChangedTeamMember = individualTeamMember.defaultValue?.id !== individualTeamMember.value?.id
            if (hasChangedTeamMember) {
              individualTeamMember.revert()
            }
            setIndividualPeriod(UNIFIEDDATE_VALUES.SINGLEWEEK)
            setIndividualDateRange(thisWeekRange())
          }
        }
        setRefresh={setShowControls}
      />
      <TabView
        onBeforeTabChange={e => {
          setCurrentTab(e.index)
        }}
      >
        <TabPanel header='Tareas registradas'>
          <RegisteredTasks
            ref={registeredTasksRef}
            teamMember={individualTeamMember.value}
            dateRange={individualDateRange}
            setShowControls={setShowControls}
            period={individualPeriod}
            openCreateTaskForTeamMemberModal={openCreateTaskForTeamMemberModal}
            setOpenCreateTaskForTeamMemberModal={setOpenCreateTaskForTeamMemberModal}
          />
        </TabPanel>
        <TabPanel header='Resumen'>
          <IndividualReportSummary
            teamMember={individualTeamMember.value}
            dateRange={individualDateRange}
          />
        </TabPanel>
      </TabView>
    </div>
  )
}

export default IndividualReportContent
