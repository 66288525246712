import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useLocation, useNavigate } from "react-router-dom"
import FormClientDetails from "./formSections/FormClientDetails.controller"
import FormClientProjects from "./formSections/FormClientProjects.controller"
import FormClientFooter from "./formSections/FormClientFooter.controller"
import { getClient } from "../../../../services/client.service"
import Card from "../../../../components/atoms/Card.atom"
import Divider from "../../../../components/atoms/Divider.atom"
import ProgressSpinner from "../../../../components/atoms/misc/ProgressSpinner.atom"
import {
  CLIENT_INITIAL_STATE,
  FORM_CLIENT_MODE,
} from "../../../../consts/modules/clients.consts"
import { AppRoutes } from "../../../../consts/appRoutes.consts"
import useToast from "../../../../hooks/useToast.hook"
import FormClientBudget from "./formSections/FormClientBudget.controller"

const FormClient = ({ mode = FORM_CLIENT_MODE.CREATE, setSelectedClient }) => {
  const [client, setClient] = useState(CLIENT_INITIAL_STATE)
  const [projects, setProjects] = useState([])
  const [error, setError] = useState({
    details: false,
    budget: false,
    projects: false,
  })
  const [loading, setLoading] = useState(mode === FORM_CLIENT_MODE.EDIT)

  const location = useLocation()
  const navigate = useNavigate()
  const { setErrorMessage } = useToast()
  const clientId = location?.state?.clientId
  const isCreate = mode === FORM_CLIENT_MODE.CREATE
  useEffect(
    function getClientIfEditMode() {
      if (mode === FORM_CLIENT_MODE.EDIT) {
        if (!clientId) {
          navigate(AppRoutes.clientes.index)
        } else {
          gettingClient()
        }
      }
    },
    [clientId, mode]
  )
  const gettingClient = async () => {
    setLoading(true)
    try {
      const response = await getClient(clientId)
      if (!response.success) throw new Error("Error al obtener el cliente")
      const client = {
        ...response.result.client,
        ...location?.state.data,
      }
      setSelectedClient && setSelectedClient(client)
      setClient(client)
      setProjects(response.result.projects)
    } catch (error) {
      setErrorMessage({ message: "Error al obtener el cliente" })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div id="client-form">
        <Card
          className="p-0 overflow-hidden flex flex-column gap-3 p-1 shadow-none justify-content-center align-items-center"
          style={{ height: "calc(100vh - 245px)" }}
        >
          <ProgressSpinner />
        </Card>
      </div>
    )
  }
  return (
    <div id="client-form">
      <Card className="form-card p-0 overflow-hidden flex flex-column gap-3 p-1 shadow-none">
        <FormClientDetails
          client={client}
          setClient={setClient}
          isCreate={isCreate}
          projects={projects}
          setError={setError}
          setProjects={setProjects}
        />
        <Divider className="mt-0 mb-3" stroke="sm" />
        {isCreate && (
          <>
            <FormClientBudget
              client={client}
              setClient={setClient}
              setError={setError}
            />
            <Divider className="my-3" stroke="sm" />
          </>
        )}

        <FormClientProjects
          client={client}
          projects={projects}
          setProjects={setProjects}
          isCreate={isCreate}
          setError={setError}
        />
      </Card>
      <FormClientFooter
        client={client}
        projects={projects}
        isCreate={isCreate}
        error={error}
      />
    </div>
  )
}

export default FormClient

FormClient.propTypes = {
  mode: PropTypes.oneOf(Object.values(FORM_CLIENT_MODE)),
  setSelectedClient: PropTypes.func,
}
