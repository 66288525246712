import { useState } from "react"

/**
 * A hook that provides state management with the ability to revert to a default value.
 * @template T The type of the state value
 * @param {T} initialValue The initial value for both the state and default value
 * @returns {{ value: T, setValue: (value: T) => void, revert: () => void, setDefaultValue: (newDefault: T) => void }}
 * Returns an object containing:
 * - Current value
 * - Set value function
 * - Revert to default function
 * - Set new default function
 */
const useDefaultableState = (initialValue) => {
  const [value, setValue] = useState(initialValue)
  const [defaultValue, setDefaultValue] = useState(initialValue)

  const revert = () => setValue(defaultValue)
  const resetWith = (newDefaultValue) => {
    setValue(newDefaultValue)
    setDefaultValue(newDefaultValue)
  }

  return { value, setValue, revert, setDefaultValue, resetWith, defaultValue }
}

export default useDefaultableState
