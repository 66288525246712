/* eslint-disable */
import React from 'react'
import InputText from '../atoms/InputText.atom'
import InputTextForm from './InputTextForm.molecule'

const ICON_STYLES = {
  color: 'light-dark(var(--secondary-color), var(--text-color))'
}

/**
 * @typedef {import("primereact/inputtext").InputTextProps} InputTextProps
 */


/**
 * @typedef {object} DropdownCustomFilterSearchProps
 * @property {InputTextProps} inputProps
 * @property {any} props
 */

/**
/**
* @param {DropdownCustomFilterSearchProps} props
* @returns {JSX.Element}
*/
const DropdownCustomFilterSearch = ({ props, inputProps }) => {
  const id = `dropdown-filter-search-${Math.random().toString(36).substring(7)}`
  return (
    <div className='flex gap-2 align-items-center w-full'>
      <span className="p-input-icon-right w-full">
        <i className="pi pi-search" style={{ ...ICON_STYLES }}/>
        <InputTextForm
          id={id}
          className='w-full'
          value={props?.props?.filterValue}
          onChange={(e) => {
            props?.props?.onFilterInputChange(e)
          }}
          {...inputProps}
        />
      </span>
      <i
        className='pi pi-times cursor-pointer mx-auto'
        onClick={() => {
          props?.filterOptions?.reset()
        }}
        style={{ ...ICON_STYLES }}
      />
    </div>
  )
}

export default DropdownCustomFilterSearch
