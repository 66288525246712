import React from "react"
import Chip from "../atoms/misc/Chip.atom"

const InputPlaceholderChipTemplate = ({
  chipClassName,
  readOnly,
  error,
  isEditing,
  labelTemplate,
  onClick,
  tooltipConfig,
  style,
}) => {
  return (
    <Chip
      className={`btn  btn-primary ${chipClassName} ${readOnly ? "p-chip-readonly" : ""} ${error ? "error-chip" : ""} ${isEditing ? "p-chip-highlight" : ""}`}
      template={
        <span className="p-chip-text">
          <span className="flex gap-1 align-items-center">{labelTemplate}</span>
        </span>
      }
      onClick={onClick}
      style={style}
      {...tooltipConfig}
    />
  )
}

export default InputPlaceholderChipTemplate
