import React from "react"
import { capitalizeFirstLetter } from "../../../../../utils/strings.utils"
import { ErrorBadge } from "../../../../../components/molecules/CellReportBadges.molecule"
import { DateTime } from "luxon"
import {
  formatDecimals,
  numberFormatter,
} from "../../../../../utils/numbers.utils"

export const groupHeaderTemplate = (data, options, totalsByDay) => {
  if (!data?.date) return
  options.customRendering = true
  const day = new Date(data?.date).toISOString().split("T")[0]
  let dayName = DateTime.fromISO(day)
    .setLocale("es")
    .startOf("day")
    .toFormat("cccc")
  dayName = capitalizeFirstLetter(dayName)
  const dayRestOfDay = DateTime.fromISO(day)
    .setLocale("es")
    .startOf("day")
    .toFormat("dd 'de' MMMM 'del' yyyy")
  return (
    <>
      <td
        className={`p-datatable-rowgroup-header class-${dayRestOfDay?.replace(/ /g, "-")} p-frozen-column left-0`}
        role="cell"
        style={{
          width: "107px",
          minWidth: "107px",
          maxWidth: "107px",
          padding: "0.5rem",
        }}
      >
        <div
          className="flex align-items-center z-1 pl-2 pr-2"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <i className="pi pi-calendar" style={{ marginRight: "10px" }}></i>
          <span>
            <strong>{dayName}</strong> {dayRestOfDay}
          </span>
        </div>
      </td>
      <td
        style={{
          width: "122px",
          minWidth: "122px",
          padding: "0.5rem",
        }}
      ></td>
      <td
        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
        role="cell"
        style={{
          width: "122px",
          minWidth: "122px",
          padding: "0.5rem",
        }}
      >
        <div className="overflow-hidden white-space-nowrap text-overflow-ellipsis"></div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: "360px",
          minWidth: "360px",
          padding: "0.5rem",
        }}
      >
        <div className="w-full flex justify-content-between align-items-center"></div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: 120,
          minWidth: 120,
          padding: "0.5rem",
        }}
      >
        <strong>{formatDecimals(totalsByDay?.[day]?.sisuTime)}</strong>
      </td>
      <td
        className=""
        role="cell"
        style={{
          width: "120px",
          minWidth: "120px",
          padding: "0.5rem",
        }}
      >
        <strong>{formatDecimals(totalsByDay?.[day]?.billedTime)}</strong>
      </td>
      <td
        className=""
        role="cell"
        style={{
          minWidth: "120px",
          paddingLeft: "7px",
        }}
      >
        <div className="flex gap-1">
          <strong>{formatDecimals(totalsByDay?.[day]?.timeDifference)}</strong>
        </div>
      </td>
      <td
        className=""
        role="cell"
        style={{
          minWidth: "30px",
          maxWidth: "30px",
          padding: "0.5rem",
        }}
      ></td>
    </>
  )
}

export const StickyFooter = ({ totals }) => {
  return (
    <>
      <tbody className="p-datatable-tbody">
        <tr
          role="row"
          className="sticky-datatable-footer"
          style={{
            height: 38,
          }}
        >
          <td
            className="p-frozen-column left-0"
            role="cell"
            colSpan={1}
            style={{ flex: "0 0 107px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Proyecto</span>
            <div className="flex gap-2 align-items-center">
              <strong>Total</strong>
            </div>
          </td>
          <td
            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
            role="cell"
            colSpan={1}
            style={{ flex: "1 0 122px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Módulo</span>
            <div className="overflow-hidden white-space-nowrap text-overflow-ellipsis"></div>
          </td>
          <td
            className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
            role="cell"
            style={{ flex: "1 0 122px", padding: "0.5rem" }}
            colSpan={1}
          >
            <span className="p-column-title">Categoría</span>
            <div className="overflow-hidden white-space-nowrap text-overflow-ellipsis"></div>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "0 0 360px", padding: "0.5rem" }}
          >
            <span className="p-column-title">Tarea</span>
          </td>
          <td
            className=""
            role="cell"
            colSpan={1}
            style={{ flex: "1 0 120px", padding: "0.5rem" }}
          >
            <strong>
              {/* {totals?.sisuTime !== 'N/A' ? formatDecimals(totals?.sisuTime) : 'N/A'} */}
              {numberFormatter(totals?.sisuTime)}
            </strong>
          </td>
          <td
            className=""
            role="cell"
            colSpan={1}
            style={{ flex: "1 0 120px", padding: "0.5rem" }}
          >
            <strong>
              {/* {totals?.billedTime !== 'N/A' ? formatDecimals(totals?.billedTime) : 'N/A'} */}
              {numberFormatter(totals?.billedTime)}
            </strong>
          </td>
          <td
            className=""
            role="cell"
            style={{ flex: "1 0 120px", padding: "0.5rem" }}
          >
            <strong className="flex gap-1">
              {/* {totals?.timeDifference !== 'N/A' ? formatDecimals(totals?.timeDifference) : 'N/A'} */}
              {numberFormatter(totals?.timeDifference)}
              {totals?.timeDifference && totals?.timeDifference !== "N/A" ?
                <ErrorBadge tooltipValue="Las horas facturadas no coinciden con las horas trabajadas" />
              : null}
            </strong>
          </td>
          <td
            className="last-column"
            role="cell"
            colSpan={1}
            style={{
              flex: "0 0 30px",
              padding: "0.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          ></td>
        </tr>
      </tbody>
    </>
  )
}
