import React, { useEffect, useState } from 'react'
import Dropdown from '../atoms/Dropdown.atom'
import { useSession } from '../../hooks/auth/useSession.hook'
import { getTeamMembers } from '../../services/teamMember.service'
import DropdownCustomFilterSearch from './DropdownCustomFilterSearch.molecule'
import useMount from '../../hooks/useMount'

const TeamMemberDropdown = ({
  selectedTeamMember,
  setSelectedTeamMember,
  initiallySelected,
  placeholder = 'Selecciona un miembro',
  optionLabel = 'name',
  id = `team-member-dropdown-${new Date().getTime()}`,
  ...props
}) => {
  const [options, setOptions] = useState([])
  const auth = useSession()

  async function fetchTeamMembers() {
    const response = await getTeamMembers()
    if (response.success) {
      setOptions(response.result)
      if (!auth.user.isAdmin()) {
        const teamMemberData = response.result.find(member => member.id === auth.user.id)
        setSelectedTeamMember(teamMemberData)
      }
    }
  }

  useMount(fetchTeamMembers)

  const itemTemplate = item => (
    <div className='overflow-hidden white-space-nowrap w-full text-overflow-ellipsis'>
      <i className="pi pi-user mr-2"></i>
      <span>{item?.nameTag} - </span>
      <span>
        {item?.name} {item?.lastName}
      </span>
    </div>
  )

  useEffect(() => {
    if (!selectedTeamMember && initiallySelected) {
      setSelectedTeamMember(initiallySelected(options))
    }
  }, [selectedTeamMember, initiallySelected, options])

  return (
    <Dropdown
      inputId={id}
      options={options}
      value={selectedTeamMember}
      onChange={e => {
        if (props.optionValue) {
          setSelectedTeamMember(options.find(option => option[props.optionValue] === e.value))
        } else {
          setSelectedTeamMember(e.value)
        }
      }}
      optionLabel={optionLabel}
      placeholder={placeholder}
      itemTemplate={itemTemplate}
      valueTemplate={(item) => {
        if (!item) return placeholder
        return itemTemplate(item)
      }}
      filter
      filterBy='nameTag,name,lastName'
      filterTemplate={(props) => <DropdownCustomFilterSearch props={props} />}
      emptyFilterMessage='Sin resultados ¿Seguro que trabaja aquí?'
      disabled={options.length === 0}
      {...props}
    />
  )
}

export default TeamMemberDropdown
