import React from "react"
import Chip from "../../../components/atoms/misc/Chip.atom"

const FinishedChip = ({ finishedState }) => {
  const getClassName = (finishedState) => {
    if (finishedState === 1) {
      return "followed-task-state-chip--finished"
    } else {
      return "followed-task-state-chip--to-do"
    }
  }

  return (
    <Chip
      className={`followed-task-state-chip ${getClassName(finishedState)}`}
      template={
        <>
          <div
            className="bg-color"
          ></div>
          <span
            className={"font-bold"}
            style={{ fontSize: "12px", padding: "3.5px 7px" }}
          >
            {finishedState === 1 ? "Finalizada" : "Por hacer"}
          </span>
        </>
      }
    />
  )
}

export default FinishedChip
