import React, { useEffect, useState } from "react"
import BudgetMovements from "./BudgetMovements.controller"
import GeneralBudgetInformation from "./GeneralBudgetInformation.controller"
import AverageConsumption from "./AverageConsumption.controller"
import useToast from "../../../hooks/useToast.hook"
import { GET_BUDGET_INFORMATION_ERROR_MESSAGE } from "../../../consts/modules/budget.consts"
import { getGeneralBudgetSummaryByClientID } from "../../../services/budget.service"
import { useLocation } from "react-router-dom"
import AvailableBudget from "./AvailableBudget.controller"

const ClientBudgetContent = () => {
  const [refresh, setRefresh] = useState(false)
  const [budgetData, setBudgetData] = useState({})
  const [loading, setLoading] = useState(false)

  const { state: selectedClient } = useLocation()
  const { setErrorMessage } = useToast()

  useEffect(() => {
    getGeneralBudgetInformation()
  }, [refresh])

  const refreshData = () => {
    setRefresh(!refresh)
  }

  const getGeneralBudgetInformation = async () => {
    setLoading(true)
    try {
      const response = await getGeneralBudgetSummaryByClientID(
        selectedClient.id
      )
      if (!response.success) {
        throw new Error()
      }
      const result = response.result
      for (const key in result) {
        if (result[key] === null) {
          result[key] = 0
        }
      }
      setBudgetData({
        clientId: selectedClient.id,
        ...result,
      })
    } catch (error) {
      setErrorMessage(GET_BUDGET_INFORMATION_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="client-budget-layout-container flex flex-column gap-3 flex-grow-1">
      <GeneralBudgetInformation
        refresh={refresh}
        refreshData={refreshData}
        data={budgetData}
        loading={loading}
      />
      <AvailableBudget
        refreshData={refreshData}
        data={budgetData}
        loading={loading}
      />
      <AverageConsumption
        refresh={refresh}
        data={budgetData}
        loading={loading}
      />
      <BudgetMovements
        data={budgetData}
        refresh={refresh}
        refreshData={refreshData}
      />
    </div>
  )
}

export default ClientBudgetContent
