import React, { useState, useEffect } from "react"
import ClientProjectChip from "../../../../../components/molecules/ClientProjectChip.molecule"
import IconWithTooltip from "../../../../../components/molecules/IconWithTooltip.molecule"
import { WarningBadge } from "../../../../../components/molecules/CellReportBadges.molecule"
import { TimeDifferenceBadge } from "../../../../generalReport/controllers/components/CellReportBadges"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"
import { useSession } from "../../../../../hooks/auth/useSession.hook"

/**
 * @returns {import("primereact/column").ColumnProps[]}
 */
const ColumnContent = ({
  clientFilterTemplate,
  moduleFilterTemplate,
  categoryFilterTemplate,
  taskNameFilterTemplate,
  cm,
  setSelectedTask,
  isHoldingCtrlDown,
  hasSpecialCategories,
  onLeftClickModuleName,
  onLeftClickCategoryName,
  onLeftClickProjectName,
  onLeftClickClientCode,
  updatingTask,
  setLazyLoading,
  lazyLoading,
}) => {
  const [, setIsHoldingCtrl] = useState(false)
  const { user } = useSession()
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === KEYBOARD_ACTIONS.CONTROL) {
        setIsHoldingCtrl(true)
      }
    }

    const onKeyUp = (e) => {
      if (e.key === KEYBOARD_ACTIONS.CONTROL) {
        setIsHoldingCtrl(false)
      }
    }

    document.addEventListener("keydown", onKeyDown)
    document.addEventListener("keyup", onKeyUp)

    return () => {
      document.removeEventListener("keydown", onKeyDown)
      document.removeEventListener("keyup", onKeyUp)
    }
  }, [])
  const adjustFilterLazyLoading = {
    onFilterApplyClick: (e) => {
      const value = e?.constraints?.constraints?.[0]?.value
      setLazyLoading(Boolean(value))
    },
    onFilterClear: () => {
      setLazyLoading(true)
    },
  }
  return [
    {
      field: "date",
      header: "Fecha",
      hidden: true,
      exportable: true,
    },
    {
      field: "clientName",
      header: "Cliente",
      hidden: true,
      exportable: true,
    },
    {
      field: "projectCode",
      header: "Proyecto",
      colSpan: 1,
      style: {
        width: "100px",
        minWidth: "107px",
        maxWidth: "107px",
        padding: "0.5rem",
      },
      filter: true,
      filterMatchMode: "in",
      filterElement: clientFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: "projectName",
      body: (rowData) => {
        return (
          <div className="flex gap-2 align-items-center project-chip-td">
            <ClientProjectChip
              clientCode={rowData?.clientCode}
              projectCode={rowData?.projectCode}
              className={`${isHoldingCtrlDown ? "ctrl-selectable-badge" : ""}`}
              onClientClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickClientCode(rowData?.clientCode)
                }
              }}
              onProjectClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickProjectName(rowData?.projectName)
                }
              }}
            />
          </div>
        )
      },
      ...adjustFilterLazyLoading,
    },
    {
      field: "projectName",
      header: "Nombre del proyecto",
      hidden: true,
      exportable: true,
      filter: true,
      filterMatchMode: "in",
    },
    {
      field: "module",
      header: "Módulo",
      colSpan: 1,
      style: { width: "122px", minWidth: "122px", padding: "0.5rem" },
      filter: true,
      filterMatchMode: "in",
      filterElement: moduleFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: "moduleId",
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${isHoldingCtrlDown ? "ctrl-selectable" : ""}`}
          onClick={() => {
            if (isHoldingCtrlDown) {
              onLeftClickModuleName(rowData?.moduleId)
            }
          }}
          title={rowData?.module}
        >
          {rowData?.module}
        </div>
      ),
      ...adjustFilterLazyLoading,
    },
    {
      field: "category",
      header: "Grupo de categoría",
      hidden: true,
      exportable: true,
    },
    {
      field: "categoryId",
      header: "categoryId",
      hidden: true,
      exportable: false,
    },
    {
      field: "categoryTitle",
      header: "Categoría",
      colSpan: 1,
      className: "white-space-nowrap overflow-hidden text-overflow-ellipsis",
      style: { width: "122px", minWidth: "122px", padding: "0.5rem" },
      filter: true,
      filterMatchMode: "in",
      filterElement: categoryFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: "categoryId",
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${isHoldingCtrlDown ? "ctrl-selectable" : ""}`}
          onClick={() => {
            if (isHoldingCtrlDown) {
              onLeftClickCategoryName(rowData?.categoryId)
            }
          }}
          title={rowData?.specialCategoryTitle || rowData?.categorySubtitle}
        >
          {rowData?.specialCategoryTitle || rowData?.categoryTitle}
        </div>
      ),
      ...adjustFilterLazyLoading,
    },
    ...(hasSpecialCategories ?
      [
        {
          field: "specialCategoryTitle",
          header: "Categoría Especial",
          hidden: true,
          exportable: true,
        },
      ]
    : []),
    {
      field: "taskId",
      header: "ID Tarea",
      hidden: true,
      exportable: true,
    },
    {
      body: (rowData) => {
        return (
          <div className="w-full flex justify-content-between align-items-center gap-2">
            <span
              className="overflow-hidden white-space-nowrap text-overflow-ellipsis"
              title={rowData?.task}
            >
              {rowData?.task}
            </span>
            {rowData?.comment ?
              <IconWithTooltip
                tooltipValue={rowData?.comment}
                iconClassName="pi pi-comment"
              />
            : null}
          </div>
        )
      },
      field: "task",
      header: "Tarea",
      style: { width: "360px", minWidth: "360px", padding: "0.5rem" },
      filter: true,
      filterMatchMode: "contains",
      filterField: "task",
      showFilterMenuOptions: false,
      filterElement: taskNameFilterTemplate,
      ...adjustFilterLazyLoading,
    },
    {
      field: "sisuTime",
      header: "Horas trabajadas",
      colSpan: 1,
      style: { width: "120px", minWidth: "120px", padding: "0.5rem" },
      body: (rowData) => {
        return (
          <div className="flex gap-1">
            <span>{rowData?.sisuTime}</span>
            {rowData?.sisuTimeModifiedAfterFinished ?
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            : null}
          </div>
        )
      },
      sortable: true,
      sortField: "sisuTime",
    },
    {
      field: "billedTime",
      header: "Horas facturadas",
      sortable: true,
      sortField: "realBilledTime",
      colSpan: 1,
      body: (rowData) => {
        return (
          <div
            className={`flex gap-1 ${rowData?.clientIsBillable ? "" : "opacity-30"}`}
          >
            {rowData?.clientIsBillable ? rowData?.billedTime : "N/A"}
            {rowData?.billedTimeModifiedAfterFinished ?
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            : null}
          </div>
        )
      },
      style: { width: "120px", minWidth: "120px", padding: "0.5rem" },
    },
    {
      field: "realBilledTime",
      hidden: true,
      exportable: false,
    },
    {
      field: "timeDifference",
      header: "Diferencia",
      sortable: true,
      sortField: "realTimeDifference",
      style: { width: "120px", minWidth: "120px", padding: "0.5rem" },
      body: (rowData) => (
        <div
          className={`flex gap-1 w-4rem justify-content-between ${rowData?.clientIsBillable ? "" : "opacity-30"}`}
        >
          {rowData?.clientIsBillable ? rowData?.timeDifference : "N/A"}
          {rowData?.billedTime !== rowData?.sisuTime && (
            // <ErrorBadge />
            <TimeDifferenceBadge
              value={rowData?.timeDifference}
              minTreshold={0}
              maxTreshold={0}
              referenceValue={0}
              tooltip={
                rowData?.timeDifferenceComment ?
                  `Motivo: ${rowData?.timeDifferenceComment}`
                : "Sin comentarios"
              }
            />
          )}
        </div>
      ),
    },
    {
      body: (item) => {
        return (
          item && updatingTask && item?.taskId === updatingTask?.taskId ?
            <IconWithTooltip
              iconClassName="pi pi-spin pi-spinner"
              tooltipValue="Guardando cambios"
            />
          : user?.isAdmin() ?
            <i
              className="pi pi-ellipsis-v text-xl cursor-pointer"
              onClick={(event) => {
                cm?.current.show(event)
                setSelectedTask(item)
              }}
            />
          : null
        )
      },
      colSpan: 1,
      field: "actions",
      style: {
        minWidth: "30px",
        maxWidth: "30px",
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
      },
    },
    {
      field: "realTimeDifference",
      hidden: true,
      exportable: false,
    },
    {
      field: "timeDifferenceComment",
      header: "Comentario Diferencia",
      hidden: true,
      exportable: true,
    },
  ]
}

export default ColumnContent
