import React from "react"
import TieredMenu from "../../components/atoms/TieredMenu"
import useThemeContext from "../../hooks/contextConsumers/useThemeContext.hook"
import { THEME_MODES } from "../../consts/themes.consts"

function ThemeMenu() {
  const { changeTheme } = useThemeContext()
  /**
   * @type {import("primereact/tieredmenu").TieredMenuProps["model"]}
   */
  const items = [
    {
      label: "Tema",
      items: [
        {
          label: "Dark",
          icon: "pi pi-fw pi-moon",
          command: () => changeTheme(THEME_MODES.DARK),
        },
        {
          label: "Light",
          icon: "pi pi-fw pi-sun",
          command: () => changeTheme(THEME_MODES.LIGHT),
        },
      ],
    },
  ]

  return <TieredMenu className="theme-menu" model={items} />
}

export default ThemeMenu
