import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Tabview from "../../../../../components/atoms/tabView/TabView.atom"
import TabPanel from "../../../../../components/atoms/tabView/TabPanel.atom"
import { createPortal } from "react-dom"
import TabviewControllersContainer from "../../components/TabviewControllersContainer"
import PrimaryButton from "../../../../../components/molecules/buttons/PrimaryButton.molecule"
import ModalFormCreateProject from "./ModalFormCreateProject.controller"
import FormSectionTitle from "./FormSectionTitle"
import FormProject from "./FormProject.controller"
import {
  isNotEmpty,
  isNotMaxLength,
  validateFieldValue,
} from "../../../../../utils/validations.utils"
import FormClientModules from "./FormClientModules.controller"
import Divider from "../../../../../components/atoms/Divider.atom"
import BlockUI from "../../../../../components/atoms/misc/BlockUI.atom"
import { useSession } from "../../../../../hooks/auth/useSession.hook"
import ProjectChip from "../../../../../components/molecules/ProjectChip.molecule"
import { getClientDefaultProject } from "../../../../../utils/clients.utils"
import { TeamMemberProjectRoleContainer } from "../../../../../components/layouts/containers/TeamMemberProjectRoleContainer.container"
import { Rol } from "../../../../../consts/roles.consts"

const formatExistingProjects = (projects) => {
  projects = projects.map((project) => ({
    ...project,
    formData: {
      name: project?.name || "",
      code: project?.code || "",
      active: undefined || project?.active,
      memberInCharge: project?.memberInCharge || null,
    },
    validations: {
      name: {
        errors: [],
        value: project?.name || "",
        typeValidations: [isNotEmpty],
      },
      code: {
        errors: [],
        value: project?.code || "",
        typeValidations: [isNotEmpty, isNotMaxLength],
        validationOptions: { isNotMaxLength: { max: 3 } },
      },
      active: {
        errors: [],
        value: project?.active || false,
        typeValidations: [isNotEmpty],
      },
      memberInCharge: {
        errors: [],
        value: project?.memberInCharge || null,
        typeValidations: [],
      },
    },
  }))
  projects?.forEach((project) => {
    if (!Object.keys(project?.validations)) return
    Object.keys(project?.validations)?.forEach((key) => {
      const validation = validateFieldValue(
        project.validations,
        key,
        project.validations[key].value
      )
      project.validations[key] = validation[key]
    })
  })
  return projects
}

const FormClientProjects = ({
  client,
  projects: createdProjects,
  setProjects: setCreatedProjects,
  error,
  setError,
  isCreate,
}) => {
  createdProjects = formatExistingProjects(createdProjects) || []
  const [defaultProject, setDefaultProject] = useState(null)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [tabViewContainer, setTabViewContainer] = useState(null)
  const { user } = useSession()

  useEffect(
    function handleCreateDefaultProject() {
      const isCreateDefaultProject =
        createdProjects.length === 0 &&
        client?.name &&
        client?.code &&
        client?.code.length === 3 &&
        isCreate &&
        (!defaultProject || (client?.name === "" && client?.code === ""))
      if (isCreateDefaultProject) {
        const defaultProject = getClientDefaultProject(client)
        setCreatedProjects(defaultProject)
        setDefaultProject(defaultProject)
      }
    },
    [client, createdProjects.length, defaultProject, setCreatedProjects]
  )

  useEffect(
    function handleCreatedProjectsChange() {
      const container = document.querySelector(
        "#projects-tabview.p-tabview-nav-content"
      )
      if (container) {
        setTabViewContainer(container)
      }
      let errorFound = false
      for (const project of createdProjects) {
        if (!project.modules || project.modules.length === 0) {
          errorFound = true
        }
        if (project.validations) {
          if (
            project.validations.name.errors.length ||
            project.validations.code.errors.length ||
            (!isCreate &&
              project.validations.active &&
              project.validations.active.errors.length)
          ) {
            errorFound = true
          }
        }
      }
      if (client?.id === 1) {
        errorFound = false
      }
      setError((prev) => ({ ...prev, projects: errorFound }))
    },
    [createdProjects]
  )

  useEffect(
    function handleFirstEditableProject() {
      if (!isCreate && createdProjects.length > 0 && user) {
        const editableIndex = createdProjects.findIndex((project) => {
          return (
            user?.isAdmin() ||
            (user.isTeamLeader() && user.leaderProjects.includes(project.id))
          )
        })
        if (editableIndex !== -1) {
          setActiveIndex(editableIndex)
        }
      }
    },
    [createdProjects, user]
  )

  const handleCreateProject = (project) => {
    const isInt = client.id === 1
    if (!isCreate) user.leaderProjects.push(project.id)
    setCreatedProjects([
      ...createdProjects,
      {
        ...project,
        formData: {
          name: project?.name || "",
          code: project?.code || "",
          active: project?.active || 1,
        },
        validations: {
          name: {
            errors: [],
            value: null,
            typeValidations: [isNotEmpty],
          },
          code: {
            errors: [],
            value: project?.code || "",
            typeValidations: [isNotEmpty, isNotMaxLength],
            validationOptions: { isNotMaxLength: { max: 3 } },
          },
          active: {
            errors: [],
            value: null,
            typeValidations: [isNotEmpty],
          },
        },
        isDeletable: 1,
        active: 1,
        modules:
          isInt ?
            []
            : [
              {
                name: "No dev",
                id: "aux-id-0",
                isDeletable: 0,
                active: 1,
                order: 0,
              },
            ],
      },
    ])
  }

  const handleChangeProject = (index, name, value) => {
    const project = createdProjects[index]
    project.formData[name] = value
    const validations = validateFieldValue(
      { ...project.validations },
      name,
      value
    )
    project[name] = value
    project.validations = validations
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      project,
      ...createdProjects.slice(index + 1),
    ])
  }

  const handleDeleteProject = ({ index }) => {
    setCreatedProjects([
      ...createdProjects.slice(0, index),
      ...createdProjects.slice(index + 1),
    ])
    if (!isCreate) setActiveIndex(0)
  }

  const TabHeaderTemplate = ({ project }) => {
    if (isCreate) return `${project.code} - ${project.name}`
    return (
      <div className="flex justify-content-between align-items-center gap-2">
        <ProjectChip
          projectCode={project.code}
          clientCode={client.code}
          customColor={client.color}
        />
        <span>{project.name}</span>
      </div>
    )
  }

  const EmptyProjectsTemplate = () => (
    <div className="custom-empty-message flex justify-content-center align-items-center h-full w-full">
      <div className="flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center">
        <i className="pi pi-folder-open text-xl" />
        <span className="text-xl font-semibold font-cairo">
          Primero lo primero
        </span>
        <span>
          Llena el campo de ‘Nombre’ y ‘Código’, antes de continuar con la
          creación de proyectos
        </span>
      </div>
    </div>
  )
  const stopTabPropagation = (e) => {
    e.originalEvent.preventDefault()
    e.originalEvent.stopPropagation()
  }
  return (
    <BlockUI
      className="bg-transparent h-full"
      containerClassName="h-full flex flex-column flex-1 gap-3"
      blocked={
        (createdProjects?.length === 0 && client?.name === "") ||
        client?.code === ""
      }
    >
      <FormSectionTitle title="Proyectos" />
      <Tabview
        key={createdProjects.length + activeIndex}
        id="projects-tabview"
        className="flex flex-column flex-1"
        activeIndex={activeIndex}
        onBeforeTabClose={stopTabPropagation}
        onTabClose={(e) => {
          stopTabPropagation(e)
          handleDeleteProject({ index: e.index })
        }}
      >
        {createdProjects?.length > 0 ?
          createdProjects.map((project, index) => {
            const isProjectEditable =
              !isCreate ?
                user?.isAdmin() ||
                (user.isTeamLeader() &&
                  user.leaderProjects.includes(project.id))
                : true
            return (
              <TabPanel
                key={index}
                header={<TabHeaderTemplate project={project} />}
                closable={isCreate ? true : Boolean(project?.isDeletable)}
                className="deletable-project flex-column"
                disabled={!isCreate && !isProjectEditable}
              >
                <BlockUI
                  className="bg-transparent"
                  blocked={(!client?.active && !isCreate) || !isProjectEditable}
                >
                  <FormProject
                    client={client}
                    formData={project.formData}
                    handleChange={(e) => {
                      handleChangeProject(index, e.target.name, e.target.value)
                    }}
                    validations={project.validations}
                    layout="horizontal"
                    isCreate={isCreate}
                  />
                  <Divider className="my-3" stroke="sm" />
                  <FormClientModules
                    client={client}
                    project={project}
                    modules={project.modules}
                    error={error}
                    setError={setError}
                    setModules={(modules) => {
                      const project = createdProjects[index]
                      project.modules = modules
                      setCreatedProjects([
                        ...createdProjects.slice(0, index),
                        project,
                        ...createdProjects.slice(index + 1),
                      ])
                    }}
                    isCreate={isCreate}
                  />
                </BlockUI>
              </TabPanel>
            )
          })
          : <TabPanel
            headerStyle={{ display: "none" }}
            className="display-none my-auto"
            contentClassName="flex flex-column flex-1"
          >
            <EmptyProjectsTemplate />
          </TabPanel>
        }
      </Tabview>
      <TeamMemberProjectRoleContainer roles={[Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT]}>
        {tabViewContainer &&
          createPortal(
            <TabviewControllersContainer className="py-3">
              <PrimaryButton
                label="Crear proyecto"
                icon="pi pi-plus"
                onClick={() => {
                  if (
                    (client?.name && client?.code && client?.code.length === 3) ||
                    createdProjects.length > 0
                  ) {
                    setOpenProjectModal(true)
                  }
                }}
                className="white-space-nowrap"
              />
            </TabviewControllersContainer>,
            tabViewContainer
          )}
      </TeamMemberProjectRoleContainer>

      <ModalFormCreateProject
        visible={openProjectModal}
        setVisible={setOpenProjectModal}
        createProject={handleCreateProject}
        projects={createdProjects}
        isCreate={isCreate}
      />
    </BlockUI>
  )
}

export default React.memo(FormClientProjects)

FormClientProjects.propTypes = {
  client: PropTypes.object,
  projects: PropTypes.array,
  setProjects: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
  isCreate: PropTypes.bool,
}
