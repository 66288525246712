import React from 'react'
import FormHeaderCreateClient from './controllers/FormHeaderClient.controller'
import FormClient from './controllers/clientForm/FormClient.controller'
import { FORM_CLIENT_MODE } from '../../consts/modules/clients.consts'

const CreateClient = () => {
  return (
    <>
      <FormHeaderCreateClient/>
      <FormClient
        mode={FORM_CLIENT_MODE.CREATE}
      />
    </>
  )
}

export default CreateClient
