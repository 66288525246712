import React from "react"
import Chip from "../../../components/atoms/misc/Chip.atom"

const PhaseLegendTemplate = ({ color, label }) => {
  return (
    <div className="flex align-items-center gap-2">
      <Chip className="tendency-chip" style={{ backgroundColor: color }} />
      <span className="font-semibold">{label}</span>
    </div>
  )
}

export default PhaseLegendTemplate
