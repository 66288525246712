import React from "react"
import ContentLayoutHeader from "../../../components/layouts/ContentLayoutHeader.layout"
import { useLocation } from "react-router-dom"
import ClientChip from "../../../components/molecules/ClientChip.molecule"

const ClientBudgetHeader = () => {
  const { state: selectedClient } = useLocation()
  const subtitleTemplate = () => {
    return (
      <div className="flex gap-2 align-items-center">
        <ClientChip clientCode={selectedClient.code} />
        <span className="text-xl">{selectedClient.name}</span>
      </div>
    )
  }
  return (
    <ContentLayoutHeader
      title="Consulta de presupuesto"
      subtitle={subtitleTemplate()}
      goBack
    />
  )
}

export default ClientBudgetHeader
