import React from "react"
import PropTypes from "prop-types"
import FormContainer from "../../../../../components/layouts/containers/FormContainer.container"
import FieldContainer from "../../../../../components/layouts/containers/FieldContainer.container"
import InputTextForm from "../../../../../components/molecules/InputTextForm.molecule"
import InputSwitchForm from "../../../../../components/molecules/InputSwitchForm.molecule"
import Label from "../../../../../components/atoms/Label.atom"
import TeamMemberDropdown from "../../../../../components/molecules/TeamMemberDropdown"
import { TeamMemberProjectRoleContainer } from "../../../../../components/layouts/containers/TeamMemberProjectRoleContainer.container"
import { Rol } from "../../../../../consts/roles.consts"

const FormProject = ({
  formData,
  handleChange,
  validations,
  layout = "vertical",
  isCreate,
}) => {
  if (!formData) return
  let cols = 12
  if (layout === "horizontal") {
    cols = 6
    if (!isCreate) {
      cols = 4
    }
  }

  return (
    <FormContainer className="mt-3">
      <FieldContainer md={cols}>
        <InputTextForm
          label="Nombre"
          placeholder="Nombre del proyecto"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={validations.name.errors}
          maxLength={255}
        />
      </FieldContainer>
      <FieldContainer md={cols}>
        <InputTextForm
          label="Código"
          placeholder="Código del proyecto"
          name="code"
          value={formData.code}
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase()
            handleChange(e)
          }}
          error={validations.code.errors}
          maxLength={3}
        />
      </FieldContainer>
      <TeamMemberProjectRoleContainer roles={[Rol.ADMIN, Rol.ADMIN_WITHOUT_REPORT]}>
        <FieldContainer md={cols} className="mb-0">
          <Label htmlFor="team-leader">Responsable</Label>{" "}
          <TeamMemberDropdown
            optionValue="id"
            selectedTeamMember={formData.memberInCharge}
            setSelectedTeamMember={({ id }) => {
              handleChange({ target: { name: "memberInCharge", value: id } })
            }}
            id="team-leader"
          />
        </FieldContainer>
      </TeamMemberProjectRoleContainer>
      {!isCreate && formData.active !== undefined && (
        <FieldContainer col={cols} md={cols} className="mb-0">
          <span className="mb-2 line-height-3 inline-block font-semibold">
            Estatus
          </span>
          <InputSwitchForm
            className="mt-1 success-slider"
            name="active"
            label={formData.active ? "Activo" : "Inactivo"}
            value={Boolean(formData.active)}
            onChange={handleChange}
          />
        </FieldContainer>
      )}
    </FormContainer>
  )
}

export default FormProject

FormProject.propTypes = {
  formData: PropTypes.object,
  handleChange: PropTypes.func,
  validations: PropTypes.object,
  layout: PropTypes.string,
  isCreate: PropTypes.bool,
}
