import React, { useEffect, useRef, useState } from "react"
import SectionHeading from "../../../../components/layouts/containers/SectionHeading.container"
import TabMenuContentContainer from "../../../../components/layouts/containers/TabMenuContentContainer.container"
import {
  getTeamMemberActiveProjects,
  getFavoriteActiveProjects,
  getProjectData,
} from "../../../../services/project.service"
import { getProjectCategories } from "../../../../services/category.service"
import { getProjectModules } from "../../../../services/module.service"
import useSessionContext from "../../../../hooks/contextConsumers/useSessionContext.hook"
import InplaceTimeControlled from "../../../../components/molecules/InplaceTimeControlled.molecule"
import Button from "../../../../components/atoms/Button.atom"
import Tooltip from "../../../../components/atoms/Tooltip.atom"
import { createDayTasks } from "../../../../services/task.service"
import {
  validateFieldValue,
  checkAllFields,
  fieldsDoesNotHaveErrors,
  removeFieldTypeValidations,
  resetField,
} from "../../../../utils/validations.utils"
import { CREATE_TASK_INITIAL_FIELD_STATE } from "../../../../consts/modules/timeRegister.consts"
import TimeDifferenceModal from "./components/TimeDifferenceModal"
import CommentModal from "./components/CommentModal"
import { DateTime } from "luxon"
import useTimeRegisterContext from "../../hooks/useTimeRegisterContext.hook"
import useToast from "../../../../hooks/useToast.hook"
import { KEYBOARD_ACTIONS } from "../../../../consts/keyBoard.consts"
import _ from "lodash"
import useMountedState from "../../../../hooks/useMountedState"
import ProjectsChipDropdown from "./components/ProjectsChipDropdown"
import CategoryChipDropdown from "./components/CategoryChipDropdown"
import ModuleChipDropdown from "./components/ModuleChipDropdown"
import DescriptionInput from "./components/DescriptionInput"

const TaskCreation = ({ refreshProjects, dropdownProjectFilterRef }) => {
  const {
    setRefresh,
    isDayEditable,
    isDayFinished,
    isEditingSisuTime,
    setIsEditingSisuTime,
    isEditingBilledTime,
    setIsEditingBilledTime,
    lastWorkDay,
    selectedDate,
    setCanSaveChanges,
  } = useTimeRegisterContext()

  const { setErrorMessage } = useToast()
  const [activeProjects, setActiveProjects] = useState([])

  const [selectedProject, setSelectedProject] = useState(1)
  const [projectName, setProjectName] = useState(null)
  const [categories, setCategories] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [modules, setModules] = useState([])
  const [loadingModules, setLoadingModules] = useState(false)
  const [selectedModule, setSelectedModule] = useState(null)
  const [comment, setComment] = useState(undefined)
  const [timeDifferenceComment, setTimeDifferenceComment] = useState("")
  const [openTimeDifferenceModal, setOpenTimeDifferenceModal] = useState(false)

  const [taskName, setTaskName] = useState("")
  const [sisuTime, setSisuTime] = useState("")
  const [billedTime, setBilledTime] = useState("")
  const [hasChangedBilledTime, setHasChangedBilledTime] = useState(false)

  const initialFieldState = _.cloneDeep(CREATE_TASK_INITIAL_FIELD_STATE)
  const [validations, setValidations] = useState({
    ...removeFieldTypeValidations({ ...initialFieldState }, "moduleId"),
  })
  const [submitting, setSubmitting] = useState(false)

  const dropdownCategoryFilterRef = useRef(null)
  const dropdownModuleFilterRef = useRef(null)
  const buttonSubmitRef = useRef(null)
  const inputTaskNameRef = useRef(null)

  const { user } = useSessionContext()

  useEffect(() => {
    return () => {
      resetFormState()
    }
  }, [])

  useEffect(() => {
    if (!isDayEditable && isDayFinished) {
      setSelectedProject(1)
      setSelectedCategory(null)
      setSelectedModule(null)
      setTaskName("")
      setSisuTime("")
      setBilledTime("")
      setComment("")
      setTimeDifferenceComment("")
      setValidations({ ...initialFieldState })
    }
  }, [isDayEditable, isDayFinished])

  useEffect(() => {
    if (!sisuTime && typeof sisuTime !== "number") return
    if (!hasChangedBilledTime) {
      setBilledTime(sisuTime)
      setValidations(validateFieldValue(validations, "billedTime", sisuTime))
    } else if (hasChangedBilledTime && billedTime === sisuTime) {
      setHasChangedBilledTime(false)
      setBilledTime(sisuTime)
      setValidations(validateFieldValue(validations, "billedTime", sisuTime))
    }
  }, [sisuTime])

  const preFillTitleCategories = ["UX/DEV", "Crosscheck", "Revisión de sprint"]

  useEffect(() => {
    if (modules.length === 0 || categories.length === 0) return
    if (selectedCategory) {

      const selectedModule = modules.find(
        (module) => module.moduleName === "No dev"
      )

      if (selectedModule === undefined) return
      if (categories[0].specialCategoryID !== null) return

      if (selectedCategory.categoryGroup === "Llamadas internas" ||
        selectedCategory.categoryGroup === "SDV" ||
        selectedCategory.categoryGroup === "Administrativo") {
        setSelectedModule({
          value: selectedModule.moduleID
        })
        setValidations(validateFieldValue(
          { ...validations },
          "moduleId",
          selectedModule.moduleID
        ))
      }
      else if (selectedCategory.categoryTitle === "Revisión de sprint" ||
        selectedCategory.categoryTitle === "Llamadas con cliente" ||
        selectedCategory.categoryTitle === "Pruebas"
      ) {
        setSelectedModule({ value: selectedModule.moduleID })
        setValidations(validateFieldValue(
          { ...validations },
          "moduleId",
          selectedModule.moduleID
        ))
      }
      else {
        setSelectedModule(null)
        setValidations(resetField({ ...validations }, "moduleId"))
      }

      if (preFillTitleCategories.includes(selectedCategory.categoryTitle)) {
        setTaskName(selectedCategory.categoryTitle)
        setValidations(validateFieldValue(
          { ...validations },
          "name",
          selectedCategory.categoryTitle
        ))
      } else {
        const currentTaskNameInPreFillTitleCategories = preFillTitleCategories.find(category => category === taskName)
        if (currentTaskNameInPreFillTitleCategories) {
          setTaskName("")
          setValidations(resetField({ ...validations }, "name"))
        }
      }
    }
  }, [selectedCategory])

  useEffect(() => {
    gettingActiveProjectsData()
  }, [refreshProjects])

  //* Request identifier ref,
  //* to allow ignoring stale responses / prevent race conditions
  const currentClientRequestId = useRef(null)
  const currentCategoriesRequestId = useRef(null)
  const currentModulesRequestId = useRef(null)
  const isMounted = useMountedState()

  useEffect(() => {
    if (!selectedProject.value) return
    setSelectedCategory(null)
    resetField(validations, "categoryId")
    setSelectedModule(null)
    resetField(validations, "moduleId")
    getClientData()
    gettingCategoriesData()
    gettingModulesData()
  }, [selectedProject])

  const gettingActiveProjectsData = async () => {
    const responseFavoriteProjects = await getFavoriteActiveProjects(
      user.teamMemberID
    )
    if (
      responseFavoriteProjects.result &&
      responseFavoriteProjects.result.length > 1
    ) {
      setActiveProjects(responseFavoriteProjects.result)
    } else {
      const responseActiveProjects = await getTeamMemberActiveProjects(
        user.teamMemberID
      )
      setActiveProjects(responseActiveProjects.result)
    }
  }

  const getClientData = async () => {
    //* We generate & assign this promise's ID
    const requestId = DateTime.now().toISO()
    currentClientRequestId.current = requestId

    const projectData = await getProjectData(selectedProject.value)

    if (!isMounted()) return
    //* We check if this response is still relevant...
    if (currentClientRequestId.current !== requestId) {
      return //* ...and we ignore it if it's stale
    }

    if (projectData.success) {
      setProjectName(projectData.result.clientName)
      if (projectData.result.clientID === 1) {
        setHasChangedBilledTime(false)
        setBilledTime(sisuTime)
      }
    }
  }

  const gettingCategoriesData = async () => {
    setLoadingCategories(true)
    try {
      //* We generate & assign this promise's ID
      const requestId = DateTime.now().toISO()
      currentCategoriesRequestId.current = requestId

      const responseCategories = await getProjectCategories(
        selectedProject.value
      )
      if (!isMounted()) return
      //* We check if this response is still relevant...
      if (currentCategoriesRequestId.current !== requestId) {
        return //* ...and we ignore it if it's stale
      }

      setCategories(responseCategories.result)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCategories(false)
    }
  }

  const gettingModulesData = async () => {
    setLoadingModules(true)
    try {
      //* We generate & assign this promise's ID
      const requestId = DateTime.now().toISO()
      currentModulesRequestId.current = requestId

      const responseModules = await getProjectModules(selectedProject.value)
      if (!isMounted()) return
      //* We check if this response is still relevant...
      if (currentModulesRequestId.current !== requestId) {
        return //* ...and we ignore it if it's stale
      }

      setModules(responseModules.result)
      if (!responseModules?.result?.length) {
        setValidations({
          ...validations,
          moduleId: {
            errors: [],
            value: null,
            typeValidations: [],
            validationOptions: {},
          },
        })
      } else {
        setValidations({
          ...validations,
          moduleId: {
            ...initialFieldState.moduleId,
          },
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingModules(false)
    }
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) {
      setSubmitting(false)
      return
    }
    if (sisuTime !== billedTime) {
      setOpenTimeDifferenceModal(true)
      setSubmitting(false)

      setTimeout(() => {
        const modalTextInputElement = document.querySelector(
          ".time-difference-modal__text-input"
        )
        if (modalTextInputElement) {
          modalTextInputElement.focus()
        }
      }, 200)

      return
    }
    try {
      await handleSaveTask()
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveTask = async () => {
    const categoryId = selectedCategory?.categoryID || 0
    const moduleId = selectedModule?.value || 0
    const teamMemberId = user.teamMemberID
    const specialCategoryId = selectedCategory?.specialCategoryID || null
    const projectId = selectedProject.value
    const taskDate = DateTime.fromJSDate(selectedDate).toSeconds()

    const response = await createDayTasks({
      taskDayData: {
        taskName: taskName.trim(),
        sisuTime,
        billedTime,
        date: taskDate,
        comment: comment === "" ? null : comment,
        timeDifferenceComment:
          timeDifferenceComment === "" ? null : timeDifferenceComment,
        categoryId,
        moduleId: moduleId || null,
        teamMemberId,
        specialCategoryId,
        projectId,
      },
      date: taskDate,
    })
    if (response.success) {
      setCanSaveChanges(true)
      setRefresh()
      resetFormState()
      setTimeout(() => {
        const syntheticEvent = new Event("click", {
          bubbles: true,
          cancelable: true,
        })
        const taskNameComponent = document.querySelector(
          ".p-inplace.p-inplace-closable > .p-inplace-display"
        )

        if (taskNameComponent) {
          taskNameComponent.dispatchEvent(syntheticEvent)
        }
      }, 200)
    } else {
      setErrorMessage({
        message: "No se pudo crear la tarea.",
      })
    }
  }

  const resetFormState = () => {
    setTaskName("")
    setSisuTime("")
    setBilledTime("")
    setComment(undefined)
    setTimeDifferenceComment("")
    setValidations((prev) => {
      const newValidations = { ...prev }
      Object.keys(newValidations).forEach((key) => {
        newValidations[key].errors = []
        newValidations[key].value = null
      })
      return newValidations
    })
    setSelectedProject(1)
    setSelectedCategory(null)
    setSelectedModule(null)
    setModules([])
    setCategories([])

    setIsEditingSisuTime(false)
    setIsEditingBilledTime(false)

    setHasChangedBilledTime(false)
    setOpenTimeDifferenceModal(false)
    setSubmitting(false)
  }

  let BLOCK_TASK_FORM = false
  if (
    DateTime.fromJSDate(selectedDate) < DateTime.local().startOf("week") &&
    !lastWorkDay?.isEditable
  ) {
    BLOCK_TASK_FORM = true
  }

  return (
    <React.Fragment key={isDayFinished || isDayEditable}>
      {Object.keys(validations).some(
        (key) => validations[key].errors?.length
      ) && <Tooltip target=".pi-exclamation-triangle" />}
      <TabMenuContentContainer
        className={`task-creation-wrapper ${BLOCK_TASK_FORM ? "pointer-events-none" : ""}`}
      >
        <SectionHeading className="p-0 w-full gap-3">
          <ProjectsChipDropdown
            projectName={projectName}
            value={selectedProject?.value}
            dropdownProjectFilterRef={dropdownProjectFilterRef}
            categoryDropdownRef={dropdownCategoryFilterRef}
            validations={validations}
            setValidations={setValidations}
            handleSubmit={handleSubmit}
            checkAllFields={checkAllFields}
            fieldsDoesNotHaveErrors={fieldsDoesNotHaveErrors}
            activeProjects={activeProjects}
            setSelectedProject={setSelectedProject}
            validateFieldValue={validateFieldValue}
          />
          <CategoryChipDropdown
            value={
              selectedCategory?.specialCategoryID ||
              selectedCategory?.categoryID
            }
            onChange={(e) => {
              const optionValue =
                categories?.[0]?.specialCategoryID ?
                  "specialCategoryID"
                  : "categoryID"
              const obj = categories?.find(
                (o) => o?.[optionValue] === e?.value
              )
              setSelectedCategory(obj)
              setValidations(
                validateFieldValue(
                  validations,
                  "categoryId",
                  obj?.categoryID
                )
              )
            }}
            options={categories}
            error={validations.categoryId.errors?.length}
            dropdownRef={dropdownCategoryFilterRef}
            loading={loadingCategories}
            dropdownInputProps={(inputProps) => ({
              autoFocus: true,
              onKeyDown: (e) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  checkAllFields(validations, setValidations)
                  if (fieldsDoesNotHaveErrors(validations)) {
                    return handleSubmit()
                  }
                }

                if (e.key === KEYBOARD_ACTIONS.TAB) {
                  e.preventDefault()
                }
                if (
                  e.key === KEYBOARD_ACTIONS.ENTER ||
                  e.key === KEYBOARD_ACTIONS.TAB
                ) {
                  dropdownCategoryFilterRef?.current?.hide()
                  if (modules?.length) {
                    dropdownModuleFilterRef?.current?.show()
                  } else {
                    inputTaskNameRef.current?.focus()
                  }
                }

                inputProps.filterInputKeyDown(e)
              },
            })}
          />

          <ModuleChipDropdown
            value={selectedModule?.value}
            disabled={!modules?.length}
            onChange={(e) => {
              setSelectedModule(e)
              setValidations(
                validateFieldValue(validations, "moduleId", e.value)
              )
            }}
            options={modules}
            error={validations.moduleId.errors?.length}
            dropdownRef={dropdownModuleFilterRef}
            onPressEnter={() => {
              inputTaskNameRef.current?.focus()
            }}
            loading={loadingModules}
            formValidations={validations}
            setFormValidations={setValidations}
            handleFormSubmit={handleSubmit}
          />
          <DescriptionInput
            inputRef={inputTaskNameRef}
            taskName={taskName}
            setTaskName={setTaskName}
            validations={validations}
            setValidations={setValidations}
            onNext={() => {
              setIsEditingSisuTime(true)
            }}
            checkAllFields={checkAllFields}
            fieldsDoesNotHaveErrors={fieldsDoesNotHaveErrors}
          />
          <InplaceTimeControlled
            value={sisuTime}
            onChange={(value) => {
              setSisuTime(value)
              setValidations(
                validateFieldValue(validations, "sisuTime", value)
              )
            }}
            inputNumberProps={{
              min: 0,
              max: 24,
              onKeyDown: (e) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  checkAllFields(validations, setValidations)
                  if (fieldsDoesNotHaveErrors(validations)) {
                    handleSubmit()
                    return
                  }
                }
                if (e.key === KEYBOARD_ACTIONS.TAB) {
                  e.preventDefault()
                }
                if (
                  e.key === KEYBOARD_ACTIONS.ENTER ||
                  e.key === KEYBOARD_ACTIONS.TAB
                ) {
                  setIsEditingSisuTime(false)
                  if (projectName === "Getro") {
                    setIsEditingBilledTime(true)
                  } else {
                    const syntheticEvent = new Event("click", {
                      bubbles: true,
                      cancelable: true,
                    })
                    const button = document.querySelector(".comment-button")

                    if (button) {
                      button.dispatchEvent(syntheticEvent)
                    }
                  }
                }
              },
            }}
            inplaceDisplayProps={{
              className: "p-0",
            }}
            onBlur={() => {
              setIsEditingSisuTime(false)
            }}
            onToggle={(prev) => {
              setIsEditingSisuTime(prev.value)
            }}
            active={isEditingSisuTime}
            error={validations.sisuTime?.errors?.length}
          />
          {projectName === "Getro" ?
            <InplaceTimeControlled
              value={billedTime}
              onChange={(value) => {
                setHasChangedBilledTime(true)
                setBilledTime(value)
                setValidations(
                  validateFieldValue(validations, "billedTime", value)
                )
              }}
              iconClass="pi pi-dollar"
              inplaceDisplayProps={{
                className: "p-0",
              }}
              inputNumberProps={{
                onKeyDown: (e) => {
                  if (e.key === KEYBOARD_ACTIONS.ENTER) {
                    checkAllFields(validations, setValidations)
                    if (fieldsDoesNotHaveErrors(validations)) {
                      handleSubmit()
                      return
                    }
                  }
                  if (e.key === KEYBOARD_ACTIONS.TAB) {
                    e.preventDefault()
                  }
                  if (
                    e.key === KEYBOARD_ACTIONS.ENTER ||
                    e.key === KEYBOARD_ACTIONS.TAB
                  ) {
                    const syntheticEvent = new Event("click", {
                      bubbles: true,
                      cancelable: true,
                    })
                    const button = document.querySelector(".comment-button")

                    if (button) {
                      button.dispatchEvent(syntheticEvent)
                    }
                  }
                },
              }}
              error={validations.billedTime.errors?.length}
              active={isEditingBilledTime}
              onToggle={(prev) => {
                setIsEditingBilledTime(prev.value)
              }}
              onBlur={() => {
                setIsEditingBilledTime(false)
              }}
            />
            : null}
          <CommentModal
            formReset={setRefresh}
            currentValue={comment}
            onSubmit={(comment) => setComment(comment)}
            inputProps={{
              onKeyDown: (e, currentValue) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  checkAllFields(validations, setValidations)
                  if (fieldsDoesNotHaveErrors(validations)) {
                    setComment(currentValue)
                  }
                  e.preventDefault()
                }
                if (e.key === KEYBOARD_ACTIONS.TAB) {
                  e.preventDefault()
                }
                if (
                  e.key === KEYBOARD_ACTIONS.ENTER ||
                  e.key === KEYBOARD_ACTIONS.TAB
                ) {
                  document.querySelector(".comment-button")?.click()
                  buttonSubmitRef.current?.focus()
                }
              },
            }}
          />
          <Button
            ref={buttonSubmitRef}
            icon={`pi ${submitting ? "pi-spin pi-spinner" : "pi-plus"}`}
            className="p-button-success border-circle flex-shrink-0"
            onClick={handleSubmit}
            style={{
              background: `${!BLOCK_TASK_FORM ? "var(--clr-success)" : "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #CDD4DE"}`,
              border: "none",
            }}
            disabled={BLOCK_TASK_FORM || submitting}
          />
        </SectionHeading>
        <TimeDifferenceModal
          visible={openTimeDifferenceModal}
          setVisible={setOpenTimeDifferenceModal}
          service={handleSaveTask}
          value={timeDifferenceComment}
          postSubmit={() => setOpenTimeDifferenceModal(false)}
          onChange={(e) => {
            setTimeDifferenceComment(e.target.value)
            setValidations(
              validateFieldValue(
                validations,
                "timeDifferenceComment",
                e.target.value
              )
            )
          }}
          timeDifferenceComment={timeDifferenceComment}
        />
      </TabMenuContentContainer>
    </React.Fragment>
  )
}

export default TaskCreation
