/* eslint-disable */
import { API_REPORT } from "../consts/apiRoutes.consts"
import SERVICE_CONSTS from "../consts/services.consts"
import { api } from "./base/api.service"

const { RESPONSE_BASIC_STRUCTURE, MESSAGES } = SERVICE_CONSTS

export const generateWeekHoursReport = async (startDate, endDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/generateHoursReport`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }

  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const generateTotalHoursPerProjectReport = (startDate, endDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  return api
    .post(`${API_REPORT}/generateTotalHoursPerProjectReport`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    .then((result) => {
      if (!result.ok) {
        return result.json().then((errorResult) => {
          response.message = errorResult.details
          throw new Error(response.message)
        })
      }
      return result.json()
    })
    .then((resultJson) => {
      response.result = resultJson.result
      response.success = true
      response.message = MESSAGES.SUCCESS
      return response
    })
    .catch((error) => {
      console.error("Error generating report:", error)
      return response
    })
}

export const generateTotalHoursPerProjectForTeamMemberReport = (
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  return api
    .post(`${API_REPORT}/generateTotalHoursPerProjectForTeamMemberReport`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    .then((result) => {
      if (!result.ok) {
        return result.json().then((errorResult) => {
          response.message = errorResult.details
          throw new Error(response.message)
        })
      }
      return result.json()
    })
    .then((resultJson) => {
      response.result = resultJson.result
      response.success = true
      response.message = MESSAGES.SUCCESS
      return response
    })
    .catch((error) => {
      console.error("Error generating report:", error)
      return response
    })
}

export const generateHoursByTeamAndTeamMemberReport = async (
  startDate,
  endDate,
  teamMembers
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_REPORT}/generateHoursByTeamAndTeamMemberReport`,
      {
        dateRange: {
          startDate,
          endDate,
          teamMembers,
        },
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const generateInitialHoursByProjectAndTeamMemberReport = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(
      `${API_REPORT}/generateInitialHoursByProjectAndTeamMemberReport`
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const generateHoursByProjectAndTeamMemberReport = async (
  startDate,
  endDate,
  period,
  projectID
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_REPORT}/generateHoursByProjectAndTeamMemberReport`,
      {
        dateRange: {
          startDate,
          endDate,
          period,
        },
        projectID,
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualTasksOfTeamMemberId = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualTasksOfTeamMemberId`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualTasksOfTeamMemberIdLazy = async (
  teamMemberId,
  startDate,
  endDate,
  first,
  last,
  sortField,
  sortOrder,
  filterFields,
  filterValues
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_REPORT}/getIndividualTasksOfTeamMemberIdLazy`,
      {
        teamMemberId,
        startDate,
        endDate,
        first,
        last,
        sortField,
        sortOrder,
        filterFields,
        filterValues,
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getNumberOfTasksOfTeamMemberIdLazy = async (
  teamMemberId,
  startDate,
  endDate,
  first,
  last,
  sortField,
  sortOrder,
  filterFields,
  filterValues
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_REPORT}/getNumberOfTasksOfTeamMemberIdLazy`,
      {
        teamMemberId,
        startDate,
        endDate,
        first,
        last,
        sortField,
        sortOrder,
        filterFields,
        filterValues,
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getCategoriesOfTasksOfTeamMemberId = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(
      `${API_REPORT}/getCategoriesOfTasksOfTeamMemberId`,
      {
        teamMemberId,
        startDate,
        endDate,
      }
    )
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getModulesOfTasksOfTeamMemberId = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getModulesOfTasksOfTeamMemberId`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getProjectsOfTasksOfTeamMemberId = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getProjectsOfTasksOfTeamMemberId`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getCategoriesOfTasksOfClientId = async (
  clientId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getCategoriesOfTasksOfClientId`, {
      clientId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getModulesOfTasksOfClientId = async (
  clientId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getModulesOfTasksOfClientId`, {
      clientId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getProjectsOfTasksOfClientId = async (
  clientId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getProjectsOfTasksOfClientId`, {
      clientId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualResumeOfTeamMemberId = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualResumeOfTeamMemberId`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualActiveProjectsChart = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualActiveProjectsChart`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualCategoriesChart = async (
  teamMemberId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualCategoriesChart`, {
      teamMemberId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getLastIndividualTeamMemberReport = async () => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.get(`${API_REPORT}/getLastIndividualTeamMemberReport`)
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const setLastIndividualTeamMemberReport = async (teamMemberId) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/setLastIndividualTeamMemberReport`, {
      teamMemberId,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualTasksOfClientId = async (
  clientId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualTasksOfClientId`, {
      clientId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getIndividualTasksOfProjectId = async (
  projectId,
  startDate,
  endDate
) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getIndividualTasksOfProjectId`, {
      projectId,
      startDate,
      endDate,
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getOccupationReport = async (startDate, endDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getOccupationReport`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getAbsoluteOccupationChart = async (startDate, endDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getAbsoluteOccupationChart`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}

export const getPercentageOccupationChart = async (startDate, endDate) => {
  const response = { ...RESPONSE_BASIC_STRUCTURE, result: null }

  let result
  try {
    result = await api.post(`${API_REPORT}/getPercentageOccupationChart`, {
      dateRange: {
        startDate,
        endDate,
      },
    })
    if (!result.ok) {
      const errorResult = await result.json()
      response.message = errorResult.details
      throw new Error()
    }
  } catch (error) {
    return response
  }
  const resultJson = await result.json()
  response.result = resultJson.result
  response.success = true
  response.message = MESSAGES.SUCCESS
  return response
}
