import React, { useEffect, useState } from "react"
import { generateHoursByTeamAndTeamMemberReport } from "../../../services/report.service"
import useReportFiltersContext from "../../../hooks/contextConsumers/useReportFiltersContext.hook"
import ProgressSpinner from "../../../components/atoms/misc/ProgressSpinner.atom"
import HoursByTeamAndTeamMemberReportDataTable from "./tables/HoursByTeamAndTeamMemberReportDataTable/HoursByTeamAndTeamMemberReportDataTable"
import TeamMemberPicker from "./components/TeamMemberPicker"
import { DateTime } from "luxon"
import ResetButton from "./components/ResetButton"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { UNIFIEDDATE_VALUES } from "../../../consts/CompoundRangeDatePicker"

const HoursByTeamAndTeamMemberReport = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const {
    memberConsumptionTeamMembers,
    setMemberConsumptionTeamMembers,
    memberConsumptionSelectedTeamMembers,
    setMemberConsumptionSelectedTeamMembers,
    memberConsumptionDateRange,
    setMemberConsumptionDateRange,
    memberConsumptionPeriod,
    setMemberConsumptionPeriod,
  } = useReportFiltersContext()

  useEffect(() => {
    gettingReport()
  }, [memberConsumptionDateRange, memberConsumptionSelectedTeamMembers])

  const gettingReport = async () => {
    setError(null)
    setIsLoading(true)
    const [startDate, endDate] = memberConsumptionDateRange
    const response = await generateHoursByTeamAndTeamMemberReport(
      startDate,
      endDate,
      memberConsumptionSelectedTeamMembers
    )
    if (response.success) {
      setMemberConsumptionTeamMembers(
        response.result.teamMembers.map((el) => ({
          ...el,
          teamMemberName: `${el.teamMemberTagName} - ${el.teamMemberName}`,
        }))
      )
      setReportData(response.result)
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div
        className={
          "mr-0 ml-1 flex flex-1 justify-content-between align-items-center pb-3"
        }
      >
        <div className="flex flex-1 gap-3">
          <TeamMemberPicker
            teamMembers={memberConsumptionTeamMembers}
            selectedTeamMembers={memberConsumptionSelectedTeamMembers}
            setSelectedTeamMembers={setMemberConsumptionSelectedTeamMembers}
          />
          <UnifiedDatePicker
            dateRange={memberConsumptionDateRange}
            setDateRange={setMemberConsumptionDateRange}
            period={memberConsumptionPeriod}
            setPeriod={setMemberConsumptionPeriod}
          />
        </div>
        <ResetButton
          onClick={() => {
            setMemberConsumptionSelectedTeamMembers([])
            setMemberConsumptionDateRange([DateTime.local(), DateTime.local()])
            setMemberConsumptionPeriod(UNIFIEDDATE_VALUES.SINGLEDAY)

            ref.current?.reset()
          }}
        />
      </div>

      {isLoading ?
        <div className="w-full h-20rem flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      : error ?
        <div>Error: {error}</div>
      : <HoursByTeamAndTeamMemberReportDataTable
          ref={ref}
          reportData={reportData}
          teamMembers={memberConsumptionSelectedTeamMembers}
          dateRange={memberConsumptionDateRange}
          period={memberConsumptionPeriod}
        />
      }
    </>
  )
})

export default HoursByTeamAndTeamMemberReport
