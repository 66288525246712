import React, { useState } from "react"
import PendingTasksContent from "./controllers/PendingTasksContent.controller"
import PendingTasksHeader from "./controllers/PendingTasksHeader.controller"
import { PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES } from "../../consts/modules/pendingTasks.consts"

const PendingTasks = () => {
  const [selectedView, setSelectedView] = useState(
    PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES.MY_TASKS
  )
  return (
    <>
      <PendingTasksHeader
        setSelectedView={setSelectedView}
        selectedView={selectedView}
      />
      <PendingTasksContent selectedView={selectedView} />
    </>
  )
}

export default PendingTasks
