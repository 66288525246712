/* eslint-disable */
import React from 'react'
import { classNames } from 'primereact/utils'

export default function MainLayout ({ children }) {
  return (
    <div className={classNames('layout-wrapper layout-active layout-mobile-active layout-sidebar')}>
      <div className={`layout-main`}>
        {children}
      </div>
    </div>
  )
}
