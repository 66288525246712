import React from "react"
import IconWithTooltip from "../../../components/molecules/IconWithTooltip.molecule"
import PropTypes from "prop-types"

export const ValueWithLabelTemplate = ({
  label,
  value,
  labelTooltip,
  labelTooltipContent,
  labelTooltipPosition,
  labelTooltipIcon,
  showWarningBadge,
  loading,
}) => {
  return (
    <div className="value-with-label-template flex flex-column flex-1 flex-shrink-0 white-space-nowrap gap-1">
      <span
        className="font-semibold text-base font-mulish"
        style={{
          color: "light-dark(var(--secondary-color-dark), var(--text-color)",
        }}
      >
        {label}
        {labelTooltip && (
          <IconWithTooltip
            tooltipValue={labelTooltipContent}
            position={labelTooltipPosition}
            iconClassName={`${labelTooltipIcon} mx-1`}
          />
        )}
      </span>
      <span
        className="font-cairo font-semibold text-xl"
        style={{
          color: showWarningBadge ? "var(--clr-danger)" : "var(--text-color)",
          lineHeight: "1.5",
        }}
      >
        {loading ?
          <i className="pi pi-spin pi-spinner" />
        : typeof value === "function" ?
          value()
        : value || "-"}
        {showWarningBadge && <i className="pi pi-exclamation-triangle ml-1" />}
      </span>
    </div>
  )
}

export const ValueWithLabelContainer = ({ children }) => {
  return (
    <div
      className="value-with-label-container flex flex-wrap justify-content-center"
      style={{ gap: "16px" }}
    >
      {children}
    </div>
  )
}

ValueWithLabelTemplate.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.func,
  ]),
  labelTooltip: PropTypes.bool,
  labelTooltipContent: PropTypes.string,
  labelTooltipPosition: PropTypes.string,
  labelTooltipIcon: PropTypes.string,
  showWarningBadge: PropTypes.bool,
}

ValueWithLabelContainer.propTypes = {
  children: PropTypes.node,
}
