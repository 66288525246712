import React from "react"
import ColumnGroup from "../../../../../components/atoms/table/ColumnGroup.atom"
import Row from "../../../../../components/atoms/table/RowColumnGroup.atom"
import Column from "../../../../../components/atoms/table/Column.atom"
import {
  floatToPercentage,
  formatDecimals,
  numberFormatter,
} from "../../../../../utils/numbers.utils"
import { TimeDifferenceBadge } from "../../components/CellReportBadges"
import { formatPercentageSuffix } from "../../../../../utils/strings.utils"

const footerColumnGroup = (
  WEEK_SIZE,
  reportData,
  recalculateFooter,
  filteredUsers
) => {
  const [footerData, setFooterData] = React.useState()
  React.useEffect(() => {
    calculateTotals()
  }, [reportData, recalculateFooter, filteredUsers])

  const calculateTotals = () => {
    reportData.teamMemberReport.forEach((member) => {
      member.weekSisuTime = Math.abs(member.weekSisuTime)
      member.weekBilledTime = Math.abs(member.weekBilledTime)
      member.weekClientTime = Math.abs(member.weekClientTime)
      member.weekClientBilledTime = Math.abs(member.weekClientBilledTime)
      member.weekTimeDifference = Math.abs(member.weekTimeDifference)
    })

    const totalReport = reportData.teamMemberReport.reduce(
      (acc, current) => {
        if (
          filteredUsers?.length > 0 &&
          !filteredUsers.includes(current.teamMemberTagName)
        ) {
          return acc
        }
        acc.ocupationRatioAverage += parseFloat(current.ocupationRatio)
        acc.weekSisuTime += parseFloat(current.weekSisuTime)
        acc.weekBilledTime += parseFloat(current.weekBilledTime)
        acc.weekClientTime += parseFloat(current.weekClientTime)
        acc.weekClientBilledTime += parseFloat(current.weekClientBilledTime)
        acc.weekTimeDifference += parseFloat(current.weekTimeDifference)
        return acc
      },
      {
        ocupationRatioAverage: 0,
        weekSisuTime: 0,
        weekBilledTime: 0,
        weekClientTime: 0,
        weekClientBilledTime: 0,
        weekTimeDifference: 0,
        timeDifferenceComments: [],
      }
    )

    totalReport.ocupationRatioAverage =
      totalReport.ocupationRatioAverage / reportData.teamMemberReport.length
    totalReport.weekSisuTimeAverage = (
      totalReport.weekSisuTime / reportData.teamMemberReport.length
    ).toFixed(2)
    totalReport.weekBilledTimeAverage = (
      totalReport.weekBilledTime / reportData.teamMemberReport.length
    ).toFixed(2)
    totalReport.weekClientTimeAverage = (
      totalReport.weekClientTime / reportData.teamMemberReport.length
    ).toFixed(2)
    totalReport.weekClientBilledTimeAverage = (
      totalReport.weekClientBilledTime / reportData.teamMemberReport.length
    ).toFixed(2)
    totalReport.weekTimeDifferenceAverage = (
      totalReport.weekTimeDifference / reportData.teamMemberReport.length
    ).toFixed(2)
    setFooterData({ totalReport })
  }
  return (
    footerData && (
      <ColumnGroup key={recalculateFooter}>
        <Row>
          <Column
            footer="Totales"
            frozen
            style={{ minWidth: "236px", width: 236 }}
          />
          {new Array(WEEK_SIZE).fill(null).map((_, index) => {
            return <Column key={index} footer={""} style={{ minWidth: 103 }} />
          })}
          <Column
            footer={numberFormatter(footerData.totalReport.weekSisuTime)}
          />
          <Column
            footer={numberFormatter(
              footerData.totalReport.weekClientBilledTime
            )}
          />
          <Column
            footer={() => (
              <div className="flex justify-content-between align-items-center w-full">
                <span className="w-4 text-left">
                  {numberFormatter(footerData.totalReport.weekTimeDifference) ||
                    "0"}
                </span>
                <span className="flex-grow-1 text-left">
                  {footerData.totalReport.weekTimeDifference !== 0 && (
                    <TimeDifferenceBadge
                      value={numberFormatter(
                        footerData.totalReport.weekTimeDifference
                      )}
                      minTreshold={0}
                      maxTreshold={0}
                      referenceValue={0}
                      percentage={formatPercentageSuffix(
                        footerData.totalReport.weekTimeDifference /
                          footerData.totalReport.weekClientTime,
                        1
                      )}
                    />
                  )}
                </span>
              </div>
            )}
          />
          <Column footer={"-"} />
        </Row>
        <Row>
          <Column
            footer="Promedios"
            frozen
            style={{ minWidth: "236px", width: 236 }}
          />
          {new Array(WEEK_SIZE).fill(null).map((_, index) => {
            return <Column key={index} footer={""} style={{ minWidth: 103 }} />
          })}
          <Column
            footer={numberFormatter(footerData.totalReport.weekSisuTimeAverage)}
          />
          <Column
            footer={numberFormatter(
              footerData.totalReport.weekClientBilledTimeAverage
            )}
          />
          <Column
            footer={numberFormatter(
              footerData.totalReport.weekTimeDifferenceAverage,
              2
            )}
          />
          <Column
            footer={formatPercentageSuffix(
              footerData.totalReport.ocupationRatioAverage
            )}
          />
        </Row>
      </ColumnGroup>
    )
  )
}

export default footerColumnGroup
