import React, { useEffect, useState } from "react"
import FollowerChip from "./FollowerChip.compound"

const FollowersChipList = React.memo(
  ({
    maxChips,
    selectedFollowers,
    onChange,
    optionValue,
    removableFollowers = false,
  }) => {
    const FOLLOWER_CHIP_SIZE = 25.5
    const FOLLOWER_OVERLAP_OFFSET = 4

    const getContainerWidth = (followersLength) => {
      if (followersLength > maxChips) {
        followersLength = maxChips
      }
      return (
        followersLength * FOLLOWER_CHIP_SIZE -
        (followersLength - 1) * FOLLOWER_OVERLAP_OFFSET
      )
    }

    const [prevContainerWidth, setPrevContainerWidth] = useState(
      getContainerWidth(selectedFollowers.length)
    )
    useEffect(() => {
      const containerWidth = getContainerWidth(selectedFollowers.length) || 0
      if (containerWidth !== prevContainerWidth) {
        setPrevContainerWidth(containerWidth)
      }
    }, [selectedFollowers.length, prevContainerWidth])

    if (!selectedFollowers || selectedFollowers.length === 0) return null
    return (
      <div
        className="follower-chips-container whitespace-nowrap align-items-center"
        style={{
          width: `${prevContainerWidth}px`,
          maxWidth: "100%",
          overflow: "hidden",
          transition: `width 0.2s ease-in-out`,
        }}
      >
        {selectedFollowers.map((follower, index) => {
          if (maxChips && index >= maxChips) return null
          return (
            <FollowerChip
              key={follower[optionValue]}
              follower={follower}
              index={index}
              onRemove={(followerId) => {
                onChange({ value: followerId })
              }}
              chipStyle={{
                zIndex: selectedFollowers.length - index,
                marginLeft: index !== 0 && "-4px",
              }}
              removable={removableFollowers}
            />
          )
        })}
      </div>
    )
  }
)

export default FollowersChipList
