import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import FormSectionTitle from "./FormSectionTitle"
import FormContainer from "../../../../../components/layouts/containers/FormContainer.container"
import InputRadioButtonForm from "../../../../../components/molecules/InputRadioButtonForm.molecule"
import {
  CLIENT_BUDGET_INITIAL_STATE,
  CLIENT_BUDGET_TYPES,
} from "../../../../../consts/modules/clients.consts"
import {
  validateFieldValue,
  fieldsDoesNotHaveErrors,
  isNotEmpty,
  isANumberGreaterThanZero,
  checkAllFields,
  setFieldTypeValidations,
  resetFieldErrors,
} from "../../../../../utils/validations.utils"
import InputNumberForm from "../../../../../components/molecules/InputNumberForm.molecule"
import FieldContainer from "../../../../../components/layouts/containers/FieldContainer.container"
import { isBudgetTypeCredit } from "../../../../../utils/budget.utils"

const FormClientBudget = ({ client, setClient, setError }) => {
  const [validations, setValidations] = useState(CLIENT_BUDGET_INITIAL_STATE)
  const [formData, setFormData] = useState({
    budgetType: client?.budgetType || CLIENT_BUDGET_TYPES.DEBIT,
    initialBudgetBalance: client?.initialBudgetBalance || 0,
    suggestedMonthlyBudget: client?.suggestedMonthlyBudget || 0,
  })
  useEffect(() => {
    setError((prev) => ({
      ...prev,
      budget: !fieldsDoesNotHaveErrors(validations),
    }))
    setClient({
      ...client,
      budgetType: formData.budgetType,
      initialBudgetBalance: formData.initialBudgetBalance,
      suggestedMonthlyBudget: formData.suggestedMonthlyBudget,
    })
  }, [])
  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
    if (name === "budgetType") {
      resetFieldErrors(validations, "suggestedMonthlyBudget")
      if (isBudgetTypeCredit(value)) {
        setFieldTypeValidations(validations, "suggestedMonthlyBudget", [
          isNotEmpty,
          isANumberGreaterThanZero,
        ])
      } else {
        setFieldTypeValidations(validations, "suggestedMonthlyBudget", [
          isNotEmpty,
        ])
      }
    }
    setValidations(validateFieldValue(validations, name, value))
    checkAllFields(validations, setValidations)
    setClient({ ...client, [name]: value })
    let isError = false
    if (!fieldsDoesNotHaveErrors(validations)) {
      isError = true
    }
    setError((prev) => ({ ...prev, budget: isError }))
  }

  return (
    <>
      <FormSectionTitle title="Configuración de presupuesto" />
      <FormContainer className="mt-3">
        <FieldContainer col={4} md={4}>
          <div className="mb-1">Tipo de presupuesto</div>
          <div className="flex gap-3" style={{ height: "41px" }}>
            <InputRadioButtonForm
              value={CLIENT_BUDGET_TYPES.DEBIT}
              error={validations.budgetType.errors}
              checked={formData.budgetType === CLIENT_BUDGET_TYPES.DEBIT}
              name="budgetType"
              label="Bolsa de horas"
              onChange={handleChange}
            />
            <InputRadioButtonForm
              value={CLIENT_BUDGET_TYPES.CREDIT}
              error={validations.budgetType.errors}
              checked={formData.budgetType === CLIENT_BUDGET_TYPES.CREDIT}
              name="budgetType"
              label="Consumo límite"
              onChange={handleChange}
            />
          </div>
        </FieldContainer>
        {formData.budgetType === CLIENT_BUDGET_TYPES.DEBIT && (
          <FieldContainer col={4} md={4}>
            <InputNumberForm
              label="Bolsa de horas inicial"
              className="w-full relative"
              name="initialBudgetBalance"
              value={formData.initialBudgetBalance}
              onChange={(e) => {
                handleChange({
                  target: { name: "initialBudgetBalance", value: e.value },
                })
              }}
              error={validations.initialBudgetBalance.errors}
              min={0}
              step={0.25}
              format={true}
              showButtons
              minFractionDigits={0}
              maxFractionDigits={2}
            />
          </FieldContainer>
        )}
        <FieldContainer col={4} md={4}>
          <InputNumberForm
            label="Límite mensual sugerido"
            name="suggestedMonthlyBudget"
            value={formData.suggestedMonthlyBudget}
            onChange={(e) => {
              handleChange({
                target: { name: "suggestedMonthlyBudget", value: e.value },
              })
            }}
            error={validations.suggestedMonthlyBudget.errors}
            min={0}
            step={0.25}
            format={true}
            showButtons
            minFractionDigits={0}
            maxFractionDigits={2}
            optional={formData.budgetType === CLIENT_BUDGET_TYPES.DEBIT}
          />
        </FieldContainer>
      </FormContainer>
    </>
  )
}

export default FormClientBudget

FormClientBudget.propTypes = {
  client: PropTypes.object,
  setClient: PropTypes.func,
}
