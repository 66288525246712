import React from "react"
import InputTextarea from "../atoms/InputTextarea.atom"
import PropTypes from "prop-types"
import Label from "../atoms/Label.atom"
import { hasAnyErrors } from "../../utils/validations.utils"
import ErrorMessageForm from "../atoms/ErrorMessageForm.atom"

const InputTextareaForm = (props) => {
  const forId = `input-textarea-${props.label?.replace(/ /g, "-")}`

  const errorClassName = hasAnyErrors(props.error) ? "p-invalid" : ""
  const { className } = props

  return (
    <>
      <Label htmlFor={forId}>
        {props.label}
        {props.require ? " *" : ""}
        {props.optional ? " (Opcional)" : ""}
      </Label>
      <InputTextarea
        id={forId}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        onBlur={(e) => {
          if (props.autoTrim) {
            const trimmedValue = e.target.value.trim()
            e.target.value = trimmedValue
            props.onChange &&
              props.onChange({
                target: { name: props.name, value: trimmedValue },
              })
          }
          props.onBlur && props.onBlur(e)
        }}
        name={props.name}
        placeholder={props.placeholder}
        className={`${className} ${errorClassName}`}
        maxLength={props.maxLength}
        disabled={props.disabled || props.viewOnly}
        autoResize={props.autoResize}
        rows={props.rows}
        cols={props.cols}
        autoFocus={props.autoFocus}
      />
      {hasAnyErrors(props.error) ?
        <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} />
      : null}
    </>
  )
}

InputTextareaForm.defaultProps = {
  autoTrim: true,
}

InputTextareaForm.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoResize: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  autoTrim: PropTypes.bool,
}

export default InputTextareaForm
