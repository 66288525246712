import React, { useEffect, useState } from 'react'
import { getDashboardSummaryByDateRange } from '../../../services/dashboard.service'
import Card from '../../../components/atoms/Card.atom'
import { Skeleton } from '../../../components/atoms/misc/Skeleton.atom'
import { displayValueOrPlaceholder, formatPercentageSuffix } from '../../../utils/strings.utils'
import { ReactComponent as PercentageViewIcon } from '../../../design/assests/images/icons/percentage-view.svg'
import UserActiveIcon from '../../../design/assests/images/icons/user-active.js'

const INITAL_DASHBOARD_SUMMARY_STATE = {
  totalSisuTime: 0,
  totalActiveProjects: 0,
  totalActiveTeamMembers: 0,
  teamOccupationPercentage: 0
}

const DashboardSummary = ({
  dateRange
}) => {
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState(INITAL_DASHBOARD_SUMMARY_STATE)

  useEffect(() => {
    getSummaryData()
  }, [dateRange])

  const getSummaryData = async () => {
    setLoading(true)
    try {
      const response = await getDashboardSummaryByDateRange(dateRange?.[0], dateRange?.[1])
      if (response.success) {
        setSummaryData(response.result)
      } else {
        throw new Error(response.message)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const CardTemplate = ({
    icon,
    iconClassName,
    title,
    data,
    className,
    loading = false
  }) => {
    const DEFAULT_SHADOW = 'shadow-2'
    return (
      <Card className={`dashboard-card ${DEFAULT_SHADOW} ${className} w-6`}>
        <div className='flex gap-2 align-items-center'>
          {
            iconClassName
              ? <i className={`${iconClassName} clr-primary-highlight`} />
              : icon
          }
          <span style={{ fontSize: 'min(3.25vw, 18px)', lineHeight: '1' }} className='font-semibold'>{title}</span>
        </div>
        <span className='text-4xl font-bold flex align-items-bottom'>
          {
            loading
              ? <Skeleton height={39}/>
              : data
          }
        </span>
      </Card>
    )
  }

  return (
    <>
      <div className='flex gap-3 flex-wrap mb-3'>
        <CardTemplate
          title='Horas trabajadas'
          data={displayValueOrPlaceholder(summaryData.totalSisuTime)}
          iconClassName='pi pi-clock text-3xl'
          className='flex-1 font-cairo flex-shrink-'
          loading={loading}
        />
        <CardTemplate
          title='Ocupación'
          data={displayValueOrPlaceholder(summaryData.teamOccupationPercentage, formatPercentageSuffix)}
          icon={<PercentageViewIcon/>}
          className='flex-1 font-cairo flex-shrink-0'
          loading={loading}
        />
        <CardTemplate
          title='Proyectos activos'
          data={displayValueOrPlaceholder(summaryData.totalActiveProjects)}
          iconClassName='pi pi-folder-open text-3xl'
          className='flex-1 font-cairo flex-shrink-0'
          loading={loading}
        />
        <CardTemplate
          title='Integrantes con actividades'
          data={displayValueOrPlaceholder(summaryData.totalActiveTeamMembers)}
          icon={<UserActiveIcon className='flex-shrink-0'/>}
          className='flex-1 font-cairo flex-shrink-0'
          loading={loading}
        />
      </div>
    </>
  )
}

export default DashboardSummary
