import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import DropdownProjectInplace from "./DropdownProjectInplace.compound"
import ChooseTimeInput from "./ChooseTimeInput.compound"
import DropdownFollowerInput from "./DropdownFollowerInput.compound"
import InputTimeOverlay from "./InputTimeOverlay.compound"
import InputCommentOverlay, {
  INPUT_COMMENT_OVERLAY_VARIANTS,
} from "./InputCommentOverlay.compound"
import { KEYBOARD_ACTIONS } from "../../../consts/keyBoard.consts"
import {
  CREATE_PENDING_TASK_INITIAL_FIELD_STATE,
  PENDING_TASK_COMMENT_MAX_LENGTH,
  PENDING_TASK_EDIT_ERROR_MESSAGE,
} from "../../../consts/modules/pendingTasks.consts"
import _ from "lodash"
import MarkAsFinishedIcon from "../../../components/molecules/MarkAsFinishedIcon.molecule"
import CustomInputTextInplace from "./CustomInputTextInplace.compound"
import useSessionContext from "../../../hooks/contextConsumers/useSessionContext.hook"
import UserChip, {
  USER_CHIP_SIZES,
  USER_CHIP_TYPES,
} from "../../../components/molecules/UserChip.molecule"
import { validateFieldValue } from "../../../utils/validations.utils"
import { updatePendingTask } from "../../../services/pendingTask.service"
import useToast from "../../../hooks/useToast.hook"

const PendingTaskItem = ({
  id,
  name,
  projectId,
  limitDate,
  followerIds,
  estimatedTime,
  comment,
  activeProjects,
  activeTeamMembers,
  isFinished = false,
  isArchived = false,
  userId,
  userName,
  userLastname,
  userTagName,
  userColor,
  readOnly: controlledReadOnly = false,
  finishService,
  deleteService,
  triggerRefresh,
}) => {
  const initialFieldState = _.cloneDeep(CREATE_PENDING_TASK_INITIAL_FIELD_STATE)
  const initialFormData = useMemo(
    () => ({
      id,
      name: name || "",
      projectId,
      limitDate,
      followerIds: followerIds || [],
      estimatedTime,
      comment: comment || "",
      isFinished,
    }),
    [
      id,
      name,
      projectId,
      limitDate,
      followerIds,
      estimatedTime,
      comment,
      isFinished,
    ]
  )
  // Form state
  const [data, setData] = useState(initialFormData)
  const [validations, setValidations] = useState(initialFieldState)
  const [submitting, setSubmitting] = useState(false)
  const [readOnly, setReadOnly] = useState(controlledReadOnly || isArchived)

  // References
  const inputNameRef = useRef()
  const dropdownProjectRef = useRef()
  const limitDateRef = useRef()
  const dropdownFollowersRef = useRef()

  // Hooks
  const { user } = useSessionContext()
  const { setErrorMessage } = useToast()

  const isCreator = user.id === userId

  const handleChange = async (key, value) => {
    let newData = { ...data, [key]: value }
    if (key !== "name") {
      newData = await handleUpdate(newData, data)
    }
    setData(newData)
    if (key !== "isFinished") {
      setValidations(validateFieldValue(validations, key, value))
    } else {
      setReadOnly(value)
    }
  }

  const handleUpdate = async (newData = data, prevData = data) => {
    setSubmitting(true)
    let updatedData = newData
    try {
      const response = await updatePendingTask(newData)
      if (!response.success) {
        throw new Error(response.message)
      }
      triggerRefresh()
    } catch (error) {
      setErrorMessage(PENDING_TASK_EDIT_ERROR_MESSAGE)
      updatedData = prevData
    } finally {
      setSubmitting(false)
    }
    return updatedData
  }

  return (
    <div
      className={`pending-task-wrapper w-full flex align-items-center justify-content-flex-start h-fit gap-3 ${submitting ? "pointer-events-none" : ""}`}
    >
      {!isCreator && (
        <UserChip
          user={{
            name: userName,
            lastName: userLastname,
            nameTag: userTagName,
            color: userColor,
          }}
          variant={USER_CHIP_TYPES.CUSTOM}
          size={USER_CHIP_SIZES.LARGE}
          useTooltip
        />
      )}
      <div className="w-full flex flex-column pending-task-data gap-2">
        <CustomInputTextInplace
          inputRef={inputNameRef}
          value={data.name}
          inputClassName={`font-mulish ${data.isFinished ? "finished" : ""}`}
          onChange={(e) => {
            handleChange("name", e.target.value)
          }}
          onBlur={async () => {
            const isEmpty = data.name === ""
            const newData = { ...data, name: isEmpty ? name : data.name }
            if (!isEmpty) {
              const updatedData = await handleUpdate(newData, initialFormData)
              setData(updatedData)
            } else {
              setData(newData)
            }
          }}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === KEYBOARD_ACTIONS.ENTER) {
                inputNameRef.current.blur()
              }
            },
          }}
          readOnly={readOnly}
          isArchived={isArchived}
        />
        <div className="flex gap-2 align-items-center flex-wrap pending-task-chips">
          <DropdownProjectInplace
            value={data.projectId}
            onChange={(e) => {
              handleChange("projectId", e.value)
            }}
            options={activeProjects}
            filter
            dropdownInputProps={() => ({
              autoFocus: true,
              className: "w-full",
            })}
            dropdownRef={dropdownProjectRef}
            readOnly={readOnly}
          />
          <ChooseTimeInput
            value={data.limitDate}
            onChange={(e) => {
              handleChange("limitDate", e)
            }}
            className="w-full"
            inputRef={limitDateRef}
            readOnly={readOnly}
          />
          <DropdownFollowerInput
            selectedValues={data.followerIds}
            iconClassName="pi-plus"
            options={activeTeamMembers}
            onChange={(e) => {
              const currentFollowerIds = data.followerIds
              const newFollower = e.value
              const followerIds =
                currentFollowerIds.includes(newFollower) ?
                  currentFollowerIds.filter((id) => id !== newFollower)
                : currentFollowerIds.concat(newFollower)
              handleChange("followerIds", followerIds)
            }}
            dropdownRef={dropdownFollowersRef}
            readOnly={readOnly}
            removableFollowers={!readOnly}
          />
          <InputTimeOverlay
            key={submitting}
            currentValue={data.estimatedTime}
            onSubmit={(estimatedTime) => {
              if (estimatedTime === 0 || estimatedTime === "") {
                estimatedTime = null
              }
              handleChange("estimatedTime", estimatedTime)
            }}
            inputProps={{
              onKeyDown: (e, currentValue) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  handleChange("estimatedTime", currentValue)
                  e.preventDefault()
                }
              },
            }}
            readOnly={readOnly}
          />
          <InputCommentOverlay
            currentValue={data.comment}
            onSubmit={(comment) => {
              handleChange("comment", comment)
            }}
            inputProps={{
              maxLength: PENDING_TASK_COMMENT_MAX_LENGTH,
              rows: 3,
              onKeyDown: (e, currentValue) => {
                if (e.key === KEYBOARD_ACTIONS.ENTER) {
                  handleChange("comment", currentValue)
                  e.preventDefault()
                }
              },
            }}
            variant={INPUT_COMMENT_OVERLAY_VARIANTS.SHORT}
            readOnly={readOnly}
          />
        </div>
      </div>
      {isCreator && !isArchived && (
        <MarkAsFinishedIcon
          onClick={async () => {
            setSubmitting(true)
            await finishService()
            setSubmitting(false)
          }}
          isFinished={data.isFinished}
          loading={submitting}
        />
      )}
      {isCreator && !isArchived && !readOnly && (
        <i
          className="pi pi-trash text-xl p-1 cursor-pointer"
          onClick={async () => {
            setSubmitting(true)
            await deleteService(id)
            setSubmitting(false)
          }}
        />
      )}
    </div>
  )
}

export default PendingTaskItem

PendingTaskItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  projectId: PropTypes.number,
  limitDate: PropTypes.string,
  followerIds: PropTypes.array,
  estimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  comment: PropTypes.string,
  activeProjects: PropTypes.array,
  activeTeamMembers: PropTypes.array,
  isFinished: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isArchived: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  userId: PropTypes.number,
  userName: PropTypes.string,
  userLastname: PropTypes.string,
  userTagName: PropTypes.string,
  userColor: PropTypes.string,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  finishService: PropTypes.func,
  deleteService: PropTypes.func,
  triggerRefresh: PropTypes.func,
}
