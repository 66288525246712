import React from "react"
import Chip from "../../../components/atoms/misc/Chip.atom"
import Tooltip from "../../atoms/Tooltip.atom"
import { generateRandomNumber } from "../../../utils/numbers.utils"

const defaultTooltipConfig = {
  content: "Hay horas registradas antes de la creación de la bolsa inicial",
  position: "bottom",
  icon: "pi pi-exclamation-triangle text-white",
}

const PhaseLegendTemplate = ({
  color,
  phaseTooltipConfig = defaultTooltipConfig,
  showTooltip = false,
  label,
}) => {
  const id = generateRandomNumber()
  return (
    <div className="phase-legend-template flex align-items-center gap-2">
      <Chip
        className={`tendency-chip tendency-tooltip-${id}`}
        style={{ backgroundColor: color }}
        icon={showTooltip && phaseTooltipConfig?.icon}
      />
      {showTooltip && (
        <Tooltip target={`.tendency-tooltip-${id}`} {...phaseTooltipConfig}>
          <span>{phaseTooltipConfig?.content}</span>
        </Tooltip>
      )}
      <span className="font-semibold">{label}</span>
    </div>
  )
}

export default PhaseLegendTemplate
