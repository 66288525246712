import { Rol } from "../consts/roles.consts"
import { ADMIN_ROUTES } from "../routers/roleRoutes/adminRoutes.routes"
import { ADMIN_WITHOUT_REPORT_ROUTES } from "../routers/roleRoutes/adminWithoutReportRoutes.routes"
import { TEAM_LEADER_ROUTES } from "../routers/roleRoutes/teamLeaderRoutes.routes"
import { TEAM_MEMBER_ROUTES } from "../routers/roleRoutes/teamMemberRoutes.routes"

const RoutesByRole = {
  [Rol.ADMIN]: ADMIN_ROUTES,
  [Rol.ADMIN_WITHOUT_REPORT]: ADMIN_WITHOUT_REPORT_ROUTES,
  [Rol.TEAM_LEADER]: TEAM_LEADER_ROUTES,
  [Rol.TEAM_MEMBER]: TEAM_MEMBER_ROUTES,
}

/**
 *
 * @param {string} role
 */
export function getRoutesByRole(role) {
  return RoutesByRole[role] || []
}

export function getRouteIfAllowedEnvironment(routeObj, environments) {
  if (environments.includes(process.env.REACT_APP_ENVIROMENT)) {
    return [routeObj]
  }
  return []
}
