import React from "react"
import Card from "../../../components/atoms/Card.atom"
import {
  getBudgetProgressBarLabelFromPercentage,
  getBudgetProgressPercentage,
  isBudgetTypeCredit,
  isBudgetTypeDebit,
} from "../../../utils/budget.utils"
import {
  floatToPercentage,
  numberFormatter,
} from "../../../utils/numbers.utils"
import {
  ValueWithLabelContainer,
  ValueWithLabelTemplate,
} from "../components/ValueWithLabelElements"
import CardHeaderTemplate from "../components/CardHeaderTemplate"
import BudgetPhaseProgress from "../components/BudgetPhaseProgress"
import PhaseLegendTemplate from "../components/PhaseLegendTemplate"
import { displayValueOrPlaceholder } from "../../../utils/strings.utils"

const AvailableBudget = ({ data, loading }) => {
  const reducedStateTemplate = () => {
    const value = getBudgetProgressPercentage(data)
    const phaseInfo = getBudgetProgressBarLabelFromPercentage({
      percentage: value,
      hasBudget: data?.hasBudget,
      hasBilledTime: data?.totalBilledTime > 0,
      budgetType: data?.clientBudgetTypeId,
      // isRemainingBudgetNegative: data?.remainingBudget < 0,
      hasMonthlyLimit: data?.suggestedMonthlyBudgetLimit > 0,
    })
    return (
      <PhaseLegendTemplate color={phaseInfo.color} label={phaseInfo.title} />
    )
  }
  return (
    <Card className="dashboard-card shadow-2 min-h-fit max-h-fit">
      <div className="flex flex-column gap-3">
        <CardHeaderTemplate title="Presupuesto disponible" />
        <ValueWithLabelContainer>
          {(
            parseFloat(data?.suggestedMonthlyBudgetLimit) ||
            isBudgetTypeCredit(data?.clientBudgetTypeId)
          ) ?
            <ValueWithLabelTemplate
              label="Consumo sugerido"
              value={displayValueOrPlaceholder(
                data?.suggestedMonthlyBudgetLimit
              )}
              loading={loading}
            />
          : <ValueWithLabelTemplate
              label="Horas autorizadas"
              value={displayValueOrPlaceholder(data?.activeBagHours)}
              loading={loading}
            />
          }
          <ValueWithLabelTemplate
            label="Horas consumidas"
            value={
              (
                parseFloat(data?.suggestedMonthlyBudgetLimit) ||
                isBudgetTypeCredit(data?.clientBudgetTypeId)
              ) ?
                displayValueOrPlaceholder(data?.currentMonthBilledTime)
              : displayValueOrPlaceholder(data?.consumedHoursSinceLastBag)
            }
            loading={loading}
          />
          <ValueWithLabelTemplate
            label="Presupuesto consumido"
            value={
              // data?.remainingBudget > 0 ?
              //   floatToPercentage(getBudgetProgressPercentage(data) / 100, 2, true)
              // : "-"
              (
                parseFloat(data?.suggestedMonthlyBudgetLimit) ||
                isBudgetTypeCredit(data?.clientBudgetTypeId)
              ) ?
                floatToPercentage(
                  getBudgetProgressPercentage(data) / 100,
                  2,
                  true
                )
              : data?.remainingBudget > 0 ?
                floatToPercentage(
                  getBudgetProgressPercentage(data) / 100,
                  2,
                  true
                )
              : "-"
            }
            loading={loading}
          />
          <ValueWithLabelTemplate
            label="Estado"
            value={reducedStateTemplate()}
            loading={loading}
          />
        </ValueWithLabelContainer>
        <BudgetPhaseProgress
          value={getBudgetProgressPercentage(data)}
          hasBudget={data?.hasBudget}
          loading={loading}
          hasBilledTime={data?.totalBilledTime > 0}
          budgetTypeId={data?.clientBudgetTypeId}
          hasMonthlyLimit={data?.suggestedMonthlyBudgetLimit > 0}
        />
      </div>
    </Card>
  )
}

export default AvailableBudget
