import React from "react"
import PropTypes from "prop-types"

export function ScreenMessage(props) {
  const { title = "", message = "", className = "", children = null } = props
  return (
    <section className={props.classNameContainer}>
      <article
        className={`flex flex-column align-items-center justify-content-center py-8 px-4 ${className}`}
      >
        <div style={{ maxWidth: 512 }} className="text-center">
          {props.icon ?? null}
          {title && (
            <div className="empty-title font-semibold text-xl mb-2">
              {title}
            </div>
          )}
          {message && (
            <div
              className={`empty-subtitle font-normal text-sm ${children ? "mb-4" : ""}`}
            >
              {message}
            </div>
          )}
          {children}
        </div>
      </article>
    </section>
  )
}

ScreenMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
