import React from "react"
import { isNotEmpty, isNotMaxLength } from "../../utils/validations.utils"
import { ReactComponent as CheckCircleIcon } from "../../design/assests/images/icons/check-circle.svg"
import { ReactComponent as CalendarTimeIcon } from "../../design/assests/images/icons/time.svg"

export const PENDING_TASK_NAME_MAX_LENGTH = 60
export const PENDING_TASK_COMMENT_MAX_LENGTH = 200

export const CREATE_PENDING_TASK_INITIAL_FIELD_STATE = {
  name: {
    errors: [],
    value: "",
    typeValidations: [isNotEmpty, isNotMaxLength],
    validationOptions: {
      isNotMaxLength: { max: PENDING_TASK_NAME_MAX_LENGTH },
    },
  },
  projectId: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
    validationOptions: {},
  },
  limitDate: {
    errors: [],
    value: null,
    typeValidations: [],
    validationOptions: {},
  },
  followerIds: {
    errors: [],
    value: [],
    typeValidations: [],
    validationOptions: {},
  },
  estimatedTime: {
    errors: [],
    value: null,
    typeValidations: [],
    validationOptions: {},
  },
  comment: {
    errors: [],
    value: "",
    typeValidations: [isNotMaxLength],
    validationOptions: {
      isNotMaxLength: { max: PENDING_TASK_COMMENT_MAX_LENGTH },
    },
  },
}

export const PENDING_TASK_CREATE_SUCCESS_MESSAGE = {
  title: "Tarea por hacer creada",
  message:
    "Tu tarea se ha creado exitosamente, consúltala en la sección “Por hacer”",
}

export const PENDING_TASK_CREATE_ERROR_MESSAGE = {
  title: "Error",
  message: "No se ha podido crear la tarea pendiente.",
}

export const PENDING_TASK_FINISH_SUCCESS_MESSAGE = {
  title: "Tarea finalizada",
  message:
    "Hoy la verás en la sección “Por hacer”, pero pasará al historial a partir de mañana.",
}

export const PENDING_TASK_FINISH_ERROR_MESSAGE = {
  title: "Error",
  message: "No se ha podido cambiar el estado de la tarea.",
}

export const PENDING_TASK_EDIT_ERROR_MESSAGE = {
  title: "Error",
  message: "No se ha podido editar la tarea.",
}

export const MY_TASKS_DEFAULT_SORT_OPTIONS = [
  {
    label: "Por fecha de creación",
    icon: <CalendarTimeIcon className="clr-text p-menuitem-icon svg" />,
    value: "taskCreatedAt",
  },
  {
    label: "Por proyecto",
    icon: "pi pi-briefcase",
    value: "projectName",
  },
  {
    label: "Por nombre de tarea",
    icon: "pi pi-pencil",
    value: "taskName",
  },
  {
    label: "Por fecha límite",
    icon: <CalendarTimeIcon className="clr-text p-menuitem-icon svg" />,
    value: "limitDate",
  },
  {
    label: "Última modificación",
    icon: "pi pi-clock",
    value: "taskUpdatedAt",
  },
  {
    label: "Orden personalizado",
    icon: "pi pi-sort-alt",
    value: "custom",
  },
  {
    label: null,
    className: "p-menu-separator",
    value: "sort-order",
    items: [
      {
        label: "Ascendente",
        icon: "pi pi-sort-amount-up-alt",
        value: "asc",
      },
      {
        label: "Descendente",
        icon: "pi pi-sort-amount-down-alt",
        value: "desc",
      },
    ],
  },
]

export const FOLLOWED_TASKS_SORT_OPTIONS = [
  {
    label: "Por estado",
    icon: <CheckCircleIcon className="clr-text p-menuitem-icon svg" />,
    value: "isFinished",
  },
  {
    label: "Por fecha de creación",
    icon: <CalendarTimeIcon className="clr-text p-menuitem-icon svg" />,
    value: "taskCreatedAt",
  },
  {
    label: null,
    className: "p-menu-separator",
    value: "sort-order",
    items: [
      {
        label: "Ascendente",
        icon: "pi pi-sort-amount-up-alt",
        value: "asc",
      },
      {
        label: "Descendente",
        icon: "pi pi-sort-amount-down-alt",
        value: "desc",
      },
    ],
  },
]

export const MY_TASKS_DEFAULT_SORT_CONFIG = {
  ...MY_TASKS_DEFAULT_SORT_OPTIONS[0],
  field: "taskCreatedAt",
  order: "desc",
}

export const SORT_OVERLAY_VALUES = {
  TASK_CREATED_AT: "taskCreatedAt",
  PROJECT_NAME: "projectName",
  TASK_NAME: "taskName",
  LIMIT_DATE: "limitDate",
  TASK_UPDATED_AT: "taskUpdatedAt",
  CUSTOM: "custom",
  ASC: "asc",
  DESC: "desc",
}

export const PENDING_TASK_VIEW_SELECT_OPTIONS = [
  {
    label: "Mis tareas",
    value: "my-tasks",
  },
  {
    label: "Seguidas",
    value: "followed",
  },
]

export const PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES = {
  MY_TASKS: "my-tasks",
  FOLLOWED: "followed",
}

export const LOCAL_STORAGE_SORT_CONFIG = "myTasksSortConfig"
export const LOCAL_STORAGE_CUSTOM_TASK_ORDER = "myTasksCustomTaskOrder"

export const PENDING_TASK_FETCH_DATA_INTERVAL = 30 * 60 * 1000 // 30 minutes
