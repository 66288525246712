import React from "react"
import PropTypes from "prop-types"
import { useDrop } from "react-dnd"
import DraggableItem from "./DraggableItem"
import { InputText } from "primereact/inputtext"
import { MODULE_SORT_OPTIONS } from "../../../../../consts/modules/clients.consts"

const Column = ({
  title,
  items,
  moveItem,
  orderMode,
  onDelete,
  onEdit,
  onSelect,
  selectedModules,
  blockDrag = false,
  visibleModalWarn,
  itemType = "ITEM",
}) => {
  const [searchLabel, setSearchLabel] = React.useState("")
  const inputSearchId = `search-${new Date().getTime()}`
  const [, drop] = useDrop(() => ({
    accept: itemType,
    drop: (draggedItem) => {
      const fromColumn = draggedItem.active ? "Activos" : "Inactivos"
      if (
        (orderMode === 1 && fromColumn === title) ||
        selectedModules.length > 0
      ) {
        return
      }
      moveItem(draggedItem.name, null, title)
      setTimeout(() => {
        const column = title === "Activos" ? "first-child" : "last-child"
        const parent = document.querySelector(
          `.p-picklist:${column} .p-picklist-list`
        )

        for (const element of document.querySelectorAll(
          `.p-picklist:${column} .p-picklist-list .p-picklist-item`
        )) {
          if (element.textContent === draggedItem.name) {
            const scrollPos =
              element.offsetTop -
              parent.clientHeight / 2 +
              element.clientHeight / 2
            const topPos = scrollPos
            parent.scrollTo({
              top: topPos,
              behavior: "smooth",
            })
            break
          }
        }
      }, 100)
    },
  }))
  return (
    <div key={title} ref={drop} className="p-picklist  p-component flex-1">
      <div className="p-picklist-list-wrapper">
        <div className="p-picklist-header">{title}</div>
        <div className="p-picklist-filter-container">
          <span className="p-input-icon-right w-full">
            <i className="pi pi-search z-2" />
            <InputText
              id={inputSearchId}
              className="p-picklist-filter w-full p-picklist-filter-input "
              placeholder="Buscar"
              value={searchLabel}
              onChange={(e) => setSearchLabel(e.target.value)}
            />
          </span>
        </div>
        <ul className="p-picklist-list">
          {items
            .filter((item) =>
              item?.name?.toLowerCase().includes(searchLabel?.toLowerCase())
            )
            .filter((item) => item.name !== "No dev")
            .map((item) => (
              <DraggableItem
                key={item?.name}
                item={item}
                orderMode={orderMode}
                onDelete={onDelete}
                onEdit={onEdit}
                moveItem={moveItem}
                onSelect={onSelect}
                isSelected={selectedModules?.some(
                  (module) => module.name === item.name
                )}
                blockDrag={blockDrag}
                visibleModalWarn={visibleModalWarn}
                columnTitle={title}
              />
            ))}
          {items.filter((item) =>
            item?.name?.toLowerCase().includes(searchLabel?.toLowerCase())
          ).length === 0 &&
            searchLabel && (
              <div className="text-center mt-4 font-cairo">
                ¡Ups! No hay elementos que coincidan con tu búsqueda...
              </div>
            )}
        </ul>
      </div>
    </div>
  )
}

export default Column

Column.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  moveItem: PropTypes.func,
  orderMode: PropTypes.oneOf(Object.values(MODULE_SORT_OPTIONS)),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onSelect: PropTypes.func,
  selectedModules: PropTypes.array,
  blockDrag: PropTypes.bool,
  visibleModalWarn: PropTypes.func,
  itemType: PropTypes.string,
}
