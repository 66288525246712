import React from 'react'
import MultiSelect from '../../atoms/MultiSelect.atom'

const moduleFilterTemplate = ({
  options,
  modules,
  filterConfig,
  moduleFilterOptionsRef
}) => {
  const filterFields = filterConfig?.filterFields?.split(',')
  const filterValues = filterConfig?.filterValues?.split(',')
  const savedModules = filterFields?.includes('moduleId') ? filterValues?.filter((value, index) => filterFields[index] === 'moduleId').map(value => parseInt(value)) : null
  if (!options.value && savedModules) {
    options.value = savedModules
  }
  moduleFilterOptionsRef.current = options
  const nonEmptyModules = modules.filter(m => m.value !== null)
  const modulesWithEmpty = [{
    label: 'Sin módulo',
    value: undefined
  }, ...nonEmptyModules]
  return (
    <MultiSelect
      value={options.value}
      options={modulesWithEmpty}
      optionLabel='label'
      optionValue='value'
      onChange={(e) => {
        options.filterCallback(e.value)
      }}
      placeholder='Filtrar módulo'
      emptyFilterMessage='Misión fallida... no encontramos nada'
      maxSelectedLabels={1}
      selectedItemsLabel='{0} seleccionados'
      disabled={modules.length === 0}
    />
  )
}

export default moduleFilterTemplate
