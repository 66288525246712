import React, { useEffect, useRef, useState } from "react"
import { validateFieldValue } from "../../../../../utils/validations.utils"
import Chip from "../../../../../components/atoms/misc/Chip.atom"
import useTimeRegisterContext from "../../../hooks/useTimeRegisterContext.hook"
import Tooltip from "../../../../../components/atoms/Tooltip.atom"
import { OverlayPanel } from "primereact/overlaypanel"
import SecondaryButton from "../../../../../components/molecules/buttons/SecondaryButton.molecule"
import PrimaryButton from "../../../../../components/molecules/buttons/PrimaryButton.molecule"
import Divider from "../../../../../components/atoms/Divider.atom"
import useThemeContext from "../../../../../hooks/contextConsumers/useThemeContext.hook"
import InputTextFilter from "../../../../../components/molecules/InputTextFilter.molecule"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"

/**
 * @callback onSubmit
 * @param {string} comment
 */

/** @callback onPressEnter
 * @param {Event} e
 */

/**
 * @typedef {Object} CommentModalProps
 * @property {string} currentValue
 * @property {onSubmit} onSubmit
 */

/**
 * @param {CommentModalProps} props
 * @returns {JSX.Element}
 */
const CommentModal = ({ currentValue, onSubmit, inputProps }) => {
  const [tooltipProps, setTooltipProps] = useState({
    "data-pr-tooltip": "",
    "data-pr-position": "bottom",
  })
  const [data, setData] = useState({
    comment: currentValue || "",
  })
  const commentModalRef = useRef(null)
  const { theme } = useThemeContext()
  const initialFieldsState = {
    comment: {
      value: currentValue || "",
      errors: [],
      typeValidations: [],
      validationsOptions: {},
    },
  }
  const [validations, setValidations] = useState(initialFieldsState)
  useEffect(() => {
    if (currentValue === undefined) {
      setData({ comment: "" })
      setValidations(initialFieldsState)
    }
  }, [currentValue])

  function handleChange({ target: { name, value } }) {
    setData((prevData) => ({ ...prevData, [name]: value }))
    setValidations(validateFieldValue(validations, name, value))
  }

  function cleanUp() {
    setData({ comment: currentValue || "" })
    setValidations(initialFieldsState)
  }

  const accept = (e) => {
    onSubmit(data.comment?.trim())
    setTooltipProps((prev) => ({ ...prev, "data-pr-tooltip": data.comment }))
    commentModalRef?.current?.hide(e)
  }

  const reject = (e) => {
    if (e) {
      onSubmit(currentValue)
    }
    cleanUp()
    commentModalRef?.current?.hide(e)
  }
  const { onKeyDown: onInputKeyDown, ...restInputProps } = inputProps
  return (
    <>
      <OverlayPanel ref={commentModalRef} onHide={reject}>
        <div className="comment-modal flex flex-column">
          <InputTextFilter
            className="mt-2 w-full"
            label="Comentario"
            name="comment"
            placeholder="Este es el comentario de mi tarea"
            value={data.comment}
            onChange={handleChange}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === KEYBOARD_ACTIONS.ENTER) {
                accept(e)
              }
              onInputKeyDown(e, data.comment?.trim() || "")
            }}
            {...restInputProps}
          />
          <Divider />
          <div className="w-full flex justify-content-end gap-3">
            <SecondaryButton label="Cancelar" type="text" onClick={reject} />
            <PrimaryButton
              label="Aceptar"
              onClick={accept}
              className="accept-project-button"
            />
          </div>
        </div>
      </OverlayPanel>
      <Chip
        className={`comment-button my-auto border-round-2xl text-center ${currentValue ? "bg-info" : ""} ${theme === "light" && currentValue ? "text-white" : ""}`}
        icon="pi pi-comment mx-0"
        onClick={(event) => {
          commentModalRef?.current?.toggle(event)
        }}
        {...(currentValue ? tooltipProps : {})}
      />
      <Tooltip target=".comment-button" />
    </>
  )
}

export default CommentModal
