import React, { useEffect, useState } from "react"
import useClientColorsContext from "../../../../hooks/contextConsumers/useClientColorsContext.hook"
import {
  formatDecimals,
  numberFormatter,
} from "../../../../utils/numbers.utils"
import PieChart from "../../../../components/compounds/graphics/PieChart.compound"
import useScreenDimensionsContext from "../../../../hooks/contextConsumers/useScreenDimensionsContext.hook"
import SquareColorListLegend from "../../../../components/compounds/graphics/components/SquareColorListLegend"
import { OPACITY_HIGHLIGHT_LEVELS } from "../../../../consts/modules/graphics.consts"
import { debounce } from "lodash"
import { TEAMMEMBER_EMPTY_CHART_MESSAGE } from "../../../../consts/charts.consts"

const ActiveProjectsChart = ({ data: chartData }) => {
  chartData = chartData?.sort((a, b) =>
    a.clientName.localeCompare(b.clientName)
  )
  const { colors } = useClientColorsContext()
  const formattedData = [
    {
      data:
        chartData?.map((item) => ({
          name: item.clientName,
          value: item.sisuTime,
          color: colors.find((client) => client.name === item.clientName)
            ?.color,
          extraInfo: {
            clientTagName: item.clientTagName,
            projectTagName: item.projectTagName,
          },
        })) || [],
    },
  ]

  const [data, setData] = useState(formattedData)
  useEffect(() => {
    setData(formattedData)
  }, [chartData])

  const { screenWidth } = useScreenDimensionsContext()
  const isMobile = screenWidth < 780

  const tooltipTemplate = (data) => {
    const sum = formattedData[0].data?.reduce((acc, cur) => acc + cur.value, 0)
    const percentage = (data.value / sum) * 100
    return (
      <div className="custom-recharts-tooltip flex text-white flex-column">
        <span className="font-bold">
          {data.extraInfo.clientTagName}-{data.extraInfo.projectTagName}
        </span>
        <span>{numberFormatter(data.value)} horas</span>
        <span>{formatDecimals(percentage, 1)}%</span>
      </div>
    )
  }

  const debouncedSetData = debounce(setData, 50)
  const handleMouseEnterLegend = (e) => {
    const hoveredLegend = e?.currentTarget?.dataset?.name
    data[0].data = data[0].data.map((item) => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity:
          item.name === hoveredLegend ?
            OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED
          : OPACITY_HIGHLIGHT_LEVELS.NOT_HIGHLIGHTED,
      },
    }))
    debouncedSetData([...data])
  }

  const handleMouseLeaveLegend = () => {
    data[0].data = data[0].data.map((item) => ({
      ...item,
      extraInfo: {
        ...item.extraInfo,
        opacity: OPACITY_HIGHLIGHT_LEVELS.HIGHLIGHTED,
      },
    }))
    debouncedSetData([...data])
  }

  return (
    <div className="doughnut-chart active-projects-chart">
      <span className="chart-title text-4xl font-semibold">
        Proyectos activos
      </span>
      <PieChart
        data={data}
        chartContainerConfig={{
          shadow: false,
          className: "w-full mx-auto",
        }}
        label={false}
        responsiveContainerConfig={{
          minHeight: "230px",
          minWidth: "250px",
          maxWidth: !isMobile ? "450px" : "200px",
          maxHeight: "230px",
        }}
        legend
        legendTemplate={(data) => (
          <SquareColorListLegend
            data={data}
            onMouseEnter={handleMouseEnterLegend}
            onMouseLeave={handleMouseLeaveLegend}
          />
        )}
        legendConfig={{
          verticalAlign: isMobile ? "bottom" : "middle",
          align: isMobile ? "center" : "right",
          layout: isMobile ? "horizontal" : "vertical",
        }}
        width={220}
        height={220}
        tooltip
        tooltipTemplate={tooltipTemplate}
        showEmptyMessage={!data?.[0].data?.length}
        emptyMessageConfig={TEAMMEMBER_EMPTY_CHART_MESSAGE}
      />
    </div>
  )
}

export default ActiveProjectsChart
