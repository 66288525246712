import React from "react"
import { DateTime } from "luxon"
import {
  BUDGET_MOVEMENTS_DESCRIPTIONS,
  BUDGET_MOVEMENTS_TYPES,
  BUDGET_PHASE_LABELS,
  BUDGET_PHASE_PERCENTAGES_RANGES,
  BUDGET_PHASE_RANGE_NAMES,
} from "../consts/modules/budget.consts"
import { CLIENT_BUDGET_TYPES } from "../consts/modules/clients.consts"
import { ReactComponent as CricketIcon } from "../design/assests/images/icons/cricket-icon.svg"
import { numberFormatter } from "./numbers.utils"

export const isBudgetTypeDebit = (budgetType) => {
  return budgetType?.toString() === CLIENT_BUDGET_TYPES.DEBIT
}

export const isBudgetTypeCredit = (budgetType) => {
  return budgetType?.toString() === CLIENT_BUDGET_TYPES.CREDIT
}

export const getBudgetPhaseFromPercentage = (
  percentage,
  budgetType = CLIENT_BUDGET_TYPES.DEBIT
) => {
  const ranges = BUDGET_PHASE_PERCENTAGES_RANGES[budgetType]
  if (percentage === ranges.POR_INICIAR) {
    return ranges.POR_INICIAR
  } else if (percentage >= ranges.EN_CURSO) {
    return ranges.EN_CURSO
  } else if (percentage >= ranges.REDUCIDO) {
    return ranges.REDUCIDO
  } else if (percentage >= ranges.POR_TERMINAR) {
    return ranges.POR_TERMINAR
  } else if (percentage >= ranges.AGOTADO) {
    return ranges.AGOTADO
  } else {
    return ranges.SOBREGIRADO
  }
}

export const getBudgetProgressBarLabelFromPercentage = ({
  percentage,
  hasBudget = true,
  hasBilledTime = true,
  budgetType = CLIENT_BUDGET_TYPES.DEBIT,
  isRemainingBudgetNegative = false,
  hasMonthlyLimit = false,
  showNoBudgetWithHours = false,
}) => {
  percentage = parseFloat(percentage)
  if (percentage < 0) {
    return {
      title: "Error",
      color: "var(--clr-danger)",
      label: "Error en el cálculo del presupuesto",
    }
  }
  if (percentage > 0 && percentage < 1) {
    percentage = 1
  }
  budgetType = budgetType?.toString()
  const ranges = BUDGET_PHASE_PERCENTAGES_RANGES[budgetType]
  const names = BUDGET_PHASE_RANGE_NAMES[budgetType]
  const labels = BUDGET_PHASE_LABELS[budgetType]
  if (
    !hasBudget &&
    hasBilledTime &&
    isBudgetTypeDebit(budgetType) &&
    !hasMonthlyLimit &&
    !showNoBudgetWithHours
  ) {
    return {
      title: "Sobregirado",
      color: "var(--clr-danger)",
      label: "Se registraron horas sin tener presupuesto asignado...",
    }
  }
  if (!hasBudget && isBudgetTypeDebit(budgetType) && !hasMonthlyLimit) {
    return {
      title: "Sin bolsa de horas",
      color: "light-dark(#33383F, var(--maskbg))",
      label: "No tienes presupuesto disponible, agrega una bolsa de horas",
    }
  }
  if (
    hasBudget &&
    hasBilledTime &&
    isRemainingBudgetNegative &&
    budgetType === CLIENT_BUDGET_TYPES.DEBIT
  ) {
    return {
      title: names.SOBREGIRADO,
      color: "var(--clr-danger)",
      label: labels.SOBREGIRADO,
    }
  }
  if (
    hasBudget &&
    !hasBilledTime
    // && isBudgetTypeCredit(budgetType)
  ) {
    return {
      title: names.POR_INICIAR,
      color: "var(--clr-info)",
      label: labels.POR_INICIAR,
    }
  }
  let phase = {}
  if (
    percentage > ranges.AGOTADO ||
    (hasBilledTime &&
      hasBudget &&
      parseFloat(percentage) > 100 &&
      !hasMonthlyLimit)
  ) {
    phase = {
      title: names.SOBREGIRADO,
      color: "var(--clr-danger)",
      label: labels.SOBREGIRADO,
    }
  } else if (percentage === ranges.AGOTADO) {
    phase = {
      title: names.AGOTADO,
      color: "#D9D9D9",
      label: labels.AGOTADO,
    }
  } else if (percentage > ranges.POR_TERMINAR) {
    phase = {
      title: names.POR_TERMINAR,
      color: "var(--orange-500)",
      label: labels.POR_TERMINAR,
    }
  } else if (percentage > ranges.REDUCIDO) {
    phase = {
      title: names.REDUCIDO,
      color: "var(--clr-warning)",
      label: labels.REDUCIDO,
    }
  } else if (percentage >= ranges.EN_CURSO) {
    phase = {
      title: names.EN_CURSO,
      color: "var(--clr-success)",
      label: labels.EN_CURSO,
    }
  } else if (percentage === ranges.POR_INICIAR) {
    const name =
      hasBudget && isBudgetTypeCredit(budgetType) ?
        names.RENOVADO
      : names.POR_INICIAR
    const label =
      hasBudget && isBudgetTypeCredit(budgetType) ?
        labels.RENOVADO
      : labels.POR_INICIAR
    phase = {
      title: name,
      color: "var(--clr-info)",
      label: label,
    }
  }

  return phase
}

export const getEstimatedBudgetEnd = (
  remainingBudget,
  historicalAverage,
  last3MonthsAverage
) => {
  let minAverage = Math.min(historicalAverage, last3MonthsAverage) || 0
  let maxAverage = Math.max(historicalAverage, last3MonthsAverage) || 0
  if (minAverage === 0 && maxAverage !== 0) {
    minAverage = maxAverage
  } else if (maxAverage === 0 && minAverage !== 0) {
    maxAverage = minAverage
  } else if (minAverage === 0 || maxAverage === 0) {
    return {
      label: null,
      estimatedEndMinDate: DateTime.local(),
      estimatedEndMaxDate: DateTime.local(),
    }
  }
  // Consumo promedio mensual
  const estimatedEndMin = remainingBudget / maxAverage
  const estimatedEndMax = remainingBudget / minAverage

  const firstLabel = getEstimatedLabel(estimatedEndMin)
  const secondLabel = getEstimatedLabel(estimatedEndMax)

  let fullLabel = "Fin estimado no disponible"
  if (firstLabel.value <= 0 && secondLabel.value <= 0) {
    return {
      label: "0 días restantes",
      estimatedEndMinDate: DateTime.local(),
      estimatedEndMaxDate: DateTime.local(),
    }
  } else if (
    firstLabel.value === secondLabel.value &&
    firstLabel.unidadesSufijo === secondLabel.unidadesSufijo
  ) {
    fullLabel = `Fin estimado en ${firstLabel.value} ${firstLabel.unidadesSufijo}`
  } else if (firstLabel.unidadesSufijo === secondLabel.unidadesSufijo) {
    fullLabel = `~De ${firstLabel.value} a ${secondLabel.value} ${firstLabel.unidadesSufijo}`
  } else {
    fullLabel = `~De ${firstLabel.value} ${firstLabel.unidadesSufijo} a ${secondLabel.value} ${secondLabel.unidadesSufijo}`
  }

  return {
    label: fullLabel,
    estimatedEndMinDate: DateTime.local().plus({ months: estimatedEndMin }),
    estimatedEndMaxDate: DateTime.local().plus({ months: estimatedEndMax }),
  }
}

const getEstimatedLabel = (valueInMonths) => {
  let unidadesSufijo = ""
  if (valueInMonths > 12) {
    const years = Math.floor(valueInMonths / 12)
    unidadesSufijo = "año" + (years >= 2 ? "s" : "")
    valueInMonths = Math.floor(valueInMonths / 12)
  } else if (valueInMonths >= 1) {
    unidadesSufijo = "mes" + (valueInMonths >= 2 ? "es" : "")
    valueInMonths = Math.floor(valueInMonths)
  } else if (valueInMonths >= 0.25) {
    unidadesSufijo = "semana" + (valueInMonths >= 0.5 ? "s" : "")
    valueInMonths = Math.floor(valueInMonths * 4)
  } else {
    unidadesSufijo = "día" + (valueInMonths >= 0.25 / 7 ? "s" : "")
    valueInMonths = Math.floor(valueInMonths * 30)
  }
  return {
    value: valueInMonths,
    unidadesSufijo,
  }
}

export const getBudgetMovementsEmptyMessage = (budgetTypeId) => {
  const message = {
    icon: <CricketIcon width={18} height={16} />,
    title: "Cri-cri, cri-cri...",
    message: "Intenta con otros filtros",
  }
  if (isBudgetTypeDebit(budgetTypeId)) {
    message.message =
      "Registra tu primer bolsa de horas para empezar a ver información sobre tu presupuesto"
  } else {
    message.message =
      "Aquí verás los registros cuando comience el consumo de horas de este cliente."
  }
  return message
}

export const getBudgetMovementChipType = (rowData) => {
  if (rowData.isHistoryEntry) return "warning"
  if (
    rowData.clientBudgetMovementTypeName ===
    BUDGET_MOVEMENTS_TYPES.AUMENTO_MANUAL
  )
    return "success"
  else if (
    rowData.clientBudgetMovementTypeName ===
    BUDGET_MOVEMENTS_TYPES.DESCENSO_MANUAL
  )
    return "danger"
  else if (
    rowData.clientBudgetMovementTypeName ===
    BUDGET_MOVEMENTS_TYPES.CONSUMO_AUTOMATICO
  )
    return "info"
  else return "warning"
}

export const getBudgetMovementDescription = (rowData) => {
  if (rowData.isBudgetChange && rowData.budgetType === 1)
    return BUDGET_MOVEMENTS_DESCRIPTIONS.CAMBIO_A_BOLSA_DE_HORAS
  else if (rowData.isBudgetChange && rowData.budgetType === 2)
    return BUDGET_MOVEMENTS_DESCRIPTIONS.CAMBIO_A_CONSUMO_LIMITE
  else if (rowData.isHistoryEntry && rowData.suggestedMonthlyBudgetLimit > 0)
    return BUDGET_MOVEMENTS_DESCRIPTIONS.ASIGNACION_LIMITE_MENSUAL_SUGERIDO
  else if (
    rowData.isHistoryEntry &&
    (rowData.suggestedMonthlyBudgetLimit <= 0 ||
      rowData.suggestedMonthlyBudgetLimit === undefined)
  )
    return BUDGET_MOVEMENTS_DESCRIPTIONS.ELIMINACION_LIMITE_MENSUAL_SUGERIDO
  else return rowData.description
}

export const getBudgetMovementQuantityValue = (rowData) => {
  if (rowData.isBudgetChange || rowData.isHistoryEntry) return ""
  else {
    if (rowData.quantity !== undefined) {
      const isPositive = rowData.quantity >= 0
      const isZero = rowData.quantity === 0
      const absoluteValue = numberFormatter(Math.abs(rowData.quantity))
      if (isZero) return "0"
      else return isPositive ? `+${absoluteValue}` : `-${absoluteValue}`
    } else return "-"
  }
}

export const getBudgetMovementMonthlyLimitValue = (rowData) => {
  if (rowData.isBudgetChange || rowData.isHistoryEntry) return ""
  else if (rowData.suggestedMonthlyBudgetLimit > 0)
    return rowData.suggestedMonthlyBudgetLimit
  else if (
    rowData.balanceType === 2 &&
    rowData.suggestedMonthlyBudgetLimit <= 0
  )
    return "0"
  else if (
    rowData.balanceType === 1 &&
    rowData.suggestedMonthlyBudgetLimit <= 0
  )
    return "-"
}

export const getBudgetProgressPercentage = (data) => {
  const suggestedMonthlyBudgetLimit = parseFloat(
    data?.suggestedMonthlyBudgetLimit
  )
  if (
    suggestedMonthlyBudgetLimit === 0
    // && isBudgetTypeDebit(data?.clientBudgetTypeId)
  ) {
    const displayToUse =
      data?.consumedBagBudget ?? data?.consumedHoursSinceLastBag
    // return Number(data?.totalBudget) ? displayToUse : 0
    return data?.hasBudget ? displayToUse : 0
  } else {
    const divider = suggestedMonthlyBudgetLimit ?? 0
    if (divider === 0) {
      return 0
    }
    return (data.currentMonthBilledTime / divider) * 100
  }
}
