/* eslint-disable */
import React from "react"
import { numberFormatter } from "../../../../../utils/numbers.utils"
import { getMonthLongNameByNumber } from "../../../../../utils/date.utils"
import { UNIFIEDDATE_VALUES } from "../../../../../consts/CompoundRangeDatePicker"

const billedSisuTimeTemplate = (rowData) => {
  if (rowData.data.billedTime) {
    return (
      <span className="flex align-items-center gap-1">
        {numberFormatter(rowData.data.billedTime)}
      </span>
    )
  } else {
    return <span className="flex align-items-center gap-1">0</span>
  }
}

const summaryTemplate = (rowData) => {
  if (rowData.data.averageTime) {
    return (
      <span className="flex align-items-center gap-1">
        {numberFormatter(rowData.data.averageTime)}
      </span>
    )
  } else {
    return <span className="flex align-items-center gap-1">0</span>
  }
}

const ColumnContent = (rangeToConsider, startYear, endYear, period, totals) => {
  return [
    // Period columns
    ...rangeToConsider
      .reduce((acc, next) => {
        const lastItem = acc[acc.length - 1]?.[0]
        const lastItemState = acc[acc.length - 1]?.[1]
        if ((lastItem && next < lastItem) || lastItemState === true) {
          acc.push([next, true])
          return acc
        } else {
          acc.push([next, false])
          return acc
        }
      }, [])
      .map(([rangePeriod, isLastYear]) => {
        return {
          key: rangePeriod,
          field: `rangeData.${rangePeriod}.sisuTime`,
          header: () => {
            if (period === UNIFIEDDATE_VALUES.RANGEMONTH) {
              return (
                getMonthLongNameByNumber(rangePeriod)?.slice(0, 3) +
                "/" +
                (isLastYear ? endYear.slice(2, 4) : startYear.slice(2, 4))
              )
            } else if (period === UNIFIEDDATE_VALUES.RANGEWEEK) {
              return (
                rangePeriod +
                "/" +
                (isLastYear ? endYear.slice(2, 4) : startYear.slice(2, 4))
              )
            }

            isEndYear(rangeToConsider, rangePeriod, startYear, endYear)
            return `${rangePeriod}`
          },
          style: { padding: "7px 7px 7px 0px" },
          body: (rowData) => {
            if (rowData.data.rangeData[`${rangePeriod}`].sisuTime) {
              return (
                <span className="flex align-items-center gap-1">
                  {numberFormatter(
                    rowData.data.rangeData[`${rangePeriod}`].sisuTime
                  )}
                </span>
              )
            } else {
              return <span className="flex align-items-center gap-1">0</span>
            }
          },
          footer: () => {
            if (totals === null) return

            if (
              period === UNIFIEDDATE_VALUES.RANGEMONTH &&
              totals.monthData !== undefined
            ) {
              return numberFormatter(totals.monthData[rangePeriod].sisuTime)
            } else if (
              period === UNIFIEDDATE_VALUES.RANGEWEEK &&
              totals.weekData !== undefined
            ) {
              return numberFormatter(totals.weekData[rangePeriod].sisuTime)
            }
          },
        }
      }),
    {
      body: billedSisuTimeTemplate,
      style: { padding: "7px 7px 7px 0px" },
      field: `billedTime`,
      header: "Totales",
      footer: numberFormatter(totals.billedTime),
    },
    {
      body: summaryTemplate,
      style: { padding: "7px 7px 7px 0px" },
      field: "averageTime",
      header: "Promedio",
      footer: numberFormatter(totals.averageTime),
    },
  ]
}

export default ColumnContent
