import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import DataTable from "../../../components/atoms/table/DataTable.atom"
import Column from "../../../components/atoms/table/Column.atom"
import PendingTaskItem from "./PendingTaskItem.compound"
import { clsx } from "../../../utils/strings.utils"
import { ReactComponent as CelebrateIcon } from "../../../design/assests/images/icons/celebrate.svg"
import {
  deletePendingTaskById,
  finishPendingTaskById,
  unfinishPendingTaskById,
} from "../../../services/pendingTask.service"
import {
  PENDING_TASK_FINISH_ERROR_MESSAGE,
  PENDING_TASK_FINISH_SUCCESS_MESSAGE,
  SORT_OVERLAY_VALUES,
} from "../../../consts/modules/pendingTasks.consts"
import useToast from "../../../hooks/useToast.hook"

const MyPendingTasksDataTable = ({
  tasks = [],
  activeProjects = [],
  activeTeamMembers = [],
  handleItemDrop = () => {},
  showLoading = false,
  loading = false,
  isReorderable = false,
  sortConfig = {},
  triggerRefresh,
}) => {
  const memoizedTasks = useMemo(() => tasks, [tasks])
  const [showCustomSort, setShowCustomSort] = useState(false)

  const isFirstRender = useRef(true)

  const { setSuccessMessage, setErrorMessage } = useToast()
  const reorderColumnAnimationDuration = 300

  useEffect(() => {
    if (sortConfig.field === SORT_OVERLAY_VALUES.CUSTOM) {
      setTimeout(() => {
        setShowCustomSort(true)
      }, 0)
    } else {
      setTimeout(() => {
        setShowCustomSort(false)
      }, reorderColumnAnimationDuration + 50)
    }
  }, [sortConfig.field])

  if (showLoading && loading && isFirstRender.current) {
    return <LoadingTasksTemplate />
  }

  if (showLoading && !loading && isFirstRender.current) {
    isFirstRender.current = false
  }

  if (tasks.length === 0) {
    return <EmptyPendingTasksTemplate />
  }
  return (
    <DataTable
      className={clsx("pending-tasks-list")}
      value={memoizedTasks}
      reorderableRows
      onRowReorder={handleItemDrop}
      responsiveLayout="scroll"
      noSameWidthColumns
      noFixedColumns
      withOutHeader
      virtualScrollerOptions={{ itemSize: 77 }}
    >
      {showCustomSort && isReorderable && (
        <Column
          className={clsx(
            "reorder-column",
            sortConfig.field === SORT_OVERLAY_VALUES.CUSTOM ?
              "animate-in"
            : "animate-out",
            memoizedTasks.length === 1 ? "non-reorderable" : ""
          )}
          rowReorder
        />
      )}

      <Column
        body={(rowData) => (
          <PendingTaskItem
            key={rowData.id}
            {...rowData}
            activeProjects={activeProjects}
            activeTeamMembers={activeTeamMembers}
            finishService={async () => {
              const isFinish = !rowData.isFinished
              const service =
                isFinish ? finishPendingTaskById : unfinishPendingTaskById
              try {
                const response = await service(rowData.id)
                if (!response.success) {
                  throw new Error()
                }
                if (isFinish) {
                  setSuccessMessage(PENDING_TASK_FINISH_SUCCESS_MESSAGE)
                }
                triggerRefresh()
              } catch (error) {
                setErrorMessage(PENDING_TASK_FINISH_ERROR_MESSAGE)
              }
            }}
            deleteService={async () => {
              await deletePendingTaskById(rowData.id)
              triggerRefresh()
            }}
            triggerRefresh={triggerRefresh}
          />
        )}
        style={{
          padding: "0",
        }}
      />
    </DataTable>
  )
}

const LoadingTasksTemplate = () => {
  return (
    <div className="empty-tasks-wrapper w-full">
      <div
        className="flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto"
        style={{ height: "107px" }}
      >
        <i
          className="pi pi-spin pi-spinner text-3xl "
          style={{ lineHeight: "normal" }}
        />
        <span className="text-xl font-semibold">Cargando tareas...</span>
      </div>
    </div>
  )
}

const EmptyPendingTasksTemplate = () => {
  return (
    <div className="custom-empty-message flex flex-column justify-content-center align-items-center my-2 gap-1">
      <CelebrateIcon className="clr-text p-menuitem-icon svg" />
      <h3 className="message-title font-cairo text-xl clr-secondary-dark font-semibold my-2">
        Todo listo
      </h3>
      <span className="message-content clr-secondary-dark">
        Sin pendientes por ahora, disfruta el momento mientras dure.
      </span>
    </div>
  )
}

export default MyPendingTasksDataTable

MyPendingTasksDataTable.propTypes = {
  tasks: PropTypes.array,
  activeProjects: PropTypes.array,
  activeTeamMembers: PropTypes.array,
  handleItemDrop: PropTypes.func,
  showLoading: PropTypes.bool,
  loading: PropTypes.bool,
  isReorderable: PropTypes.bool,
  sortConfig: PropTypes.object,
  triggerRefresh: PropTypes.func,
}
