import React, { useState } from "react"
import Chip from "../../../components/atoms/misc/Chip.atom"
import UserChip, {
  USER_CHIP_SIZES,
  USER_CHIP_TYPES,
} from "../../../components/molecules/UserChip.molecule"

const FollowerChip = ({
  follower,
  optionValue = "id",
  onRemove = () => {},
  removable = false,
  readOnly = false,
  chipStyle = {},
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)
  const handleClick = () => onRemove(follower[optionValue])
  const editableProps =
    !readOnly && removable ?
      {
        onClick: handleClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        isHovered,
        hoveredTemplate: (
          <i
            className="pi pi-trash text-xs flex items-center justify-center"
            style={{ minHeight: "1rem", lineHeight: "1rem", color: "inherit" }}
          />
        ),
      }
    : {}
  return (
    <Chip
      className="follower-chip relative p-0 cursor-auto justify-content-center align-items-center"
      template={
        <UserChip
          user={follower}
          variant={USER_CHIP_TYPES.CUSTOM}
          size={USER_CHIP_SIZES.NORMAL}
          {...editableProps}
          useTooltip
          tooltipConfig={{
            "data-pr-position": "bottom",
          }}
        />
      }
      style={{ ...chipStyle }}
    />
  )
}

export default React.memo(FollowerChip)
