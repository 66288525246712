import React from "react"
import PropTypes from "prop-types"

export function CustomMessage({
  icon = null,
  title = "Sin información",
  message = "Intenta con otros filtros",
  className = "",
  classNameContainer = "custom-empty-message flex justify-content-center align-items-center py-5",
  children,
}) {
  return (
    <section className={classNameContainer}>
      <article
        className={`flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center ${className}`}
        style={{ color: "#555E6A" }}
      >
        {icon && <span className="icon-container">{icon}</span>}
        {title && (
          <span className="text-xl font-semibold font-cairo message-title">
            {title}
          </span>
        )}
        {message && <span className="message-content">{message}</span>}
        {children}
      </article>
    </section>
  )
}

CustomMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
