import React, { useEffect } from 'react'
import DataTable from '../../../../../components/atoms/table/DataTable.atom'
import Column from '../../../../../components/atoms/table/Column.atom'
import useThemeContext from '../../../../../hooks/contextConsumers/useThemeContext.hook'
import Chip from '../../../../../components/atoms/misc/Chip.atom'
import ClientProjectChip from '../../../../../components/molecules/ClientProjectChip.molecule'
import { formatHours } from '../../../../../utils/time.utils'
import Tooltip from '../../../../../components/atoms/Tooltip.atom'
import PrimaryButton from '../../../../../components/molecules/buttons/PrimaryButton.molecule'
import { InputCheckbox } from '../../../../../components/atoms/InputCheckbox.atom'
import { clsx } from '../../../../../utils/strings.utils'
import { compareDates } from '../../../../../utils/date.utils'
import { DateTime } from 'luxon'
import useTimeRegisterContext from '../../../hooks/useTimeRegisterContext.hook'

const TaskList = ({
  dailyTasks,
  handleItemDrop,
  isDayEditable = true,
  setSelectedTask,
  contextMenuRef: cm,
  messageProps,
  onClickBeginEditingNewTaskName,
  selectedTasks,
  onClickSelectCheckbox,
  multiSelectCMRef,
  lastWorkDay,
  selectedDate,
  loading
}) => {
  const {
    showedMissingDayDialog,
    editingMissingDay
  } = useTimeRegisterContext()
  const isSelectingTasks = selectedTasks.length > 0
  const { theme } = useThemeContext()

  const taskTemplate = (item) => {
    const textColor = theme === 'light' ? 'clr-secondary' : ''
    const isSelected = selectedTasks.some(task => task.taskID === item.taskID)

    // MIssing elipsis
    const category = item.specialCategory || item.category
    const module = item.module

    const subtitle = `${module || ''}${module && category ? ' | ' : ''}${category || ''}`

    return (
      <div className={`flex align-items-center justify-content-flex-start task-wrapper h-fit py-2 ${isSelected ? 'p-datatable-cell-selected' : ''}`}>
        <div className="w-full flex align-items-center task-data gap-2">
          <ClientProjectChip
            clientCode={item.clientCode}
            projectCode={item.projectCode}
          />

          <div style={{ display: "flex", minWidth: 0 }}>
            <span style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }} className="task-title">
              <p className='task-name' title={item.task}>
                {item.task}
              </p>
              <span className={`task-module ${textColor}`}>
                {subtitle}
              </span>
            </span>
          </div>
          <div className='task-data-chips flex flex-1 gap-2 align-items-center justify-content-end ml-auto'>
            {item?.comment && <Chip
              className={`comment-badge h-full p-chip-text justify-content-center p-0 mx-1 comment-bubble-${item?.taskID}`}
              icon={`pi pi-comment p-chip-text justify-content-center p-0 m-0 text-xs comment-${item?.taskID}`}
            />}
            {item?.comment && (<Tooltip target={`.comment-bubble-${item?.taskID}`} position='bottom' content={item?.comment} />)}

          </div>
          <div className='flex gap-2 align-items-center justify-content-end'>
            <div className={`flex justify-content-between gap-3 ${textColor}`}>
              {
                item.sisuTime !== item.billedTime &&
                <>
                  <Tooltip target={`#diff-chip-${item.taskOrder}`} position='bottom'>
                    <strong>Motivo de la diferencia:</strong>
                    <p>

                      {item.timeDifferenceComment}
                    </p>
                  </Tooltip>
                  <span id={`diff-chip-${item.taskOrder}`} className='select-none flex gap-1 align-items-center difference-reason-chip'>
                    <i className='pi pi-dollar' style={{ color: 'var(--color-text)' }} />
                    {formatHours(item.billedTime)}
                  </span>
                </>
              }
              <span className='select-none flex gap-1 align-items-center'>
                <i className='pi pi-stopwatch' style={{ color: 'var(--color-text)' }} />
                {formatHours(item.sisuTime)}
              </span>
            </div>
            {
              isDayEditable
                ? <i
                  className='pi pi-ellipsis-v text-xl cursor-pointer px-2'
                  onClick={(event) => {
                    cm.current.show(event)
                    setSelectedTask(item)
                  }}
                />
                : null
            }
          </div>
        </div>
      </div>
    )
  }
  const editableDayEmptyTaskTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className="pi pi-folder-open text-3xl" />
          <span className='text-xl font-semibold'>Nada por aquí (todavía)</span>
          <span>Pequeños pasos, grandes avances y tus mejores victorias ¡Registra todas tus tareas del día!</span>
          <PrimaryButton
            label={'¡Añade una tarea!'}
            icon={'pi pi-plus'}
            disabled={!isDayEditable}
            className='w-fit'
            onClick={onClickBeginEditingNewTaskName}
          />
        </div>
      </div>
    )
  }

  const nonEditableEmptyTaskTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className="pi pi-folder-open text-3xl" />
          <span className='text-xl font-semibold'>¡Día libre!... de tareas</span>
          <span>Si no es lo que esperabas, contacta al administrador para que te ayude a registrar tus tareas.</span>
          {isDayEditable && <PrimaryButton
            label={'¡Añade una tarea!'}
            icon={'pi pi-plus'}
            disabled={!isDayEditable}
            className='w-fit'
            onClick={onClickBeginEditingNewTaskName}
          />}
        </div>
      </div>
    )
  }

  const loadingTasksTemplate = () => {
    return (
      <div className="empty-tasks-wrapper w-full">
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto'>
          <i className="pi pi-spin pi-spinner text-3xl" />
          <span className='text-xl font-semibold'>Cargando tareas...</span>
        </div>
      </div>
    )
  }

  const ignoredDayTemplate = () => {
    return (
      <div className='empty-tasks-wrapper w-full'>
        <div className='flex flex-column gap-2 align-items-center justify-content-center max-w-25rem m-auto text-center'>
          <i className='pi pi-folder-open text-3xl' />
          <span className='text-xl font-semibold'>¡Día!... ignorado ...</span>
          <span>Decidiste ignorar este día, por lo que puedes seguir con tu registro normal.</span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    // const resizeObserver = new ResizeObserver(() => {
    //   const dailyTasksWrapperHeight = document.querySelector('.daily-tasks-wrapper')?.clientHeight
    //   const titleCalendarHeight = document.querySelector('.daily-tasks-wrapper > div:first-child')?.clientHeight
    //   const taskListHeight = dailyTasks?.length * 54
    //   const containerPadding = 16 * 2
    //   const dropTopHeight = 20
    //   document.documentElement.style.setProperty('--fill-drop-area-task-list', `${dailyTasksWrapperHeight - titleCalendarHeight - taskListHeight - containerPadding - dropTopHeight}px`)
    // })
    // resizeObserver.observe(document.body)
    // return () => {
    //   resizeObserver.disconnect()
    // }
    document.documentElement.style.setProperty('--fill-drop-area-task-list', '20px')
  }, [dailyTasks])
  return (
    <>
      {
        dailyTasks && dailyTasks.length > 0 && !loading &&
        <div className='card w-full p-0'>
          <DataTable
            className={clsx('today-tasks-list', isSelectingTasks && 'is-multiselect')}
            value={dailyTasks}
            reorderableRows={dailyTasks.length !== 1 && selectedTasks.length === 0}
            onRowReorder={handleItemDrop}
            responsiveLayout='scroll'
            noSameWidthColumns
            noFixedColumns
            withOutHeader
            onContextMenu={!isDayEditable
              ? null
              : ({ originalEvent, data }) => {
                if (isSelectingTasks) {
                  multiSelectCMRef?.current.show(originalEvent)
                } else {
                  cm?.current.show(originalEvent)
                  setSelectedTask(data)
                }
              }}
            onRowClick={({ e, data }) => {
              if (isSelectingTasks) onClickSelectCheckbox(data)
            }}
          >
            {
              isSelectingTasks
                ? (
                  <Column
                    style={{ width: '1rem', padding: '7px' }}
                    body={(rowData) => {
                      const checkboxId = `checkbox-${rowData.taskID}`
                      return (
                        <InputCheckbox
                          inputId={checkboxId}
                          checked={selectedTasks.some(task => task.taskID === rowData.taskID)}
                        />
                      )
                    }}
                  />
                )
                : (
                  isDayEditable &&
                  <Column
                    style={{ width: '1rem', padding: '7px' }}
                    className={`${dailyTasks.length === 1 ? 'non-reorderable' : ''}`}
                    rowReorder
                  />
                )
            }
            <Column
              body={taskTemplate}
              style={{ padding: '7px 7px 7px 0px', width: '100%' }}
              className={clsx(isSelectingTasks && 'p-datatable-cell-multiselect-content')}
            />
          </DataTable>
        </div>
      }
      {
        (loading) &&
        loadingTasksTemplate()
      }
      {
        (dailyTasks?.length === 0 && isDayEditable && compareDates(DateTime.local().toJSDate(), selectedDate) && !loading) &&
        editableDayEmptyTaskTemplate()
      }
      {
        (!compareDates(DateTime.local().toJSDate(), selectedDate) && dailyTasks?.length === 0 && !loading) &&
        nonEditableEmptyTaskTemplate()
      }
      {/* {
  loading && loadingTasksTemplate()
}

{
  !loading && dailyTasks?.length === 0 && isDayEditable &&
  showedMissingDayDialog &&
  compareDates(selectedDate, DateTime.local().toJSDate()) &&
  editableDayEmptyTaskTemplate()
}

{
  !loading && dailyTasks?.length === 0 &&
  // !compareDates(DateTime.local().toJSDate(), selectedDate) &&
  nonEditableEmptyTaskTemplate()
}
{/*
{
  !loading && dailyTasks?.length === 0 &&
  !compareDates(selectedDate, DateTime.local().toJSDate()) && !editingMissingDay &&
  showedMissingDayDialog &&
  ignoredDayTemplate()
} */}

    </>
  )
}

export default TaskList
