/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import WeekHoursReportDataTable from "./tables/WeekHoursReportDataTable/WeekHoursReportDataTable.controller"
import { generateWeekHoursReport } from "../../../services/report.service"
import ProgressSpinner from "../../../components/atoms/misc/ProgressSpinner.atom"
import useReportFiltersContext from "../../../hooks/contextConsumers/useReportFiltersContext.hook"
import { DateTime } from "luxon"
import ResetButton from "./components/ResetButton"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { UNIFIEDDATE_VALUES } from "../../../consts/CompoundRangeDatePicker"
import esES from "antd/locale/es_ES"
import { ConfigProvider } from "antd"

/** @param {import('luxon').DateTime} date */
const getFullWeekFromDate = (date) => {
  const weekStart = date.startOf("week")
  const weekEnd = weekStart.plus({ days: 6 }).endOf("day")
  return {
    weekStart: weekStart.toJSDate(),
    weekEnd: weekEnd.toJSDate(),
  }
}

const WeekHoursReport = React.forwardRef((props, tableRef) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const { weekHoursDateRange, setWeekHoursDateRange } =
    useReportFiltersContext()
  const [sortOrder, setSortOrder] = useState(1)
  const resetSortOrderRef = useRef(null)

  useEffect(() => {
    gettingWeekHoursReport()
  }, [weekHoursDateRange])

  const gettingWeekHoursReport = async () => {
    setError(null)
    setIsLoading(true)
    const fullWeek = getFullWeekFromDate(weekHoursDateRange[0])
    const response = await generateWeekHoursReport(
      DateTime.fromJSDate(fullWeek.weekStart).startOf("day").toISODate(),
      DateTime.fromJSDate(fullWeek.weekEnd).endOf("day").toISODate()
    )
    if (response.success) {
      setReportData(response.result)
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div
        style={{
          marginBottom: 17.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ConfigProvider locale={esES}>
          <UnifiedDatePicker
            dateRange={weekHoursDateRange}
            setDateRange={setWeekHoursDateRange}
            period={UNIFIEDDATE_VALUES.SINGLEWEEK}
            hidePeriodSelector
            format="'Semana' wo '-' YYYY"
          />
        </ConfigProvider>
        <ResetButton
          onClick={() => {
            resetSortOrderRef.current()
            tableRef.current?.reset()
            setWeekHoursDateRange([
              DateTime.local().startOf("week"),
              DateTime.local().startOf("week"),
            ])
          }}
        />
      </div>
      {isLoading ?
        <div className="w-full h-20rem flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      : error ?
        <div>Error: {error}</div>
      : <WeekHoursReportDataTable
          ref={tableRef}
          resetSortOrderRef={resetSortOrderRef}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          reportData={reportData}
        />
      }
    </>
  )
})

export default WeekHoursReport
