import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import FormSectionTitle from "./FormSectionTitle"
import FormContainer from "../../../../../components/layouts/containers/FormContainer.container"
import FieldContainer from "../../../../../components/layouts/containers/FieldContainer.container"
import InputTextForm from "../../../../../components/molecules/InputTextForm.molecule"
import BadgePreview from "../../components/BadgePreview"
import InputSwitchForm from "../../../../../components/molecules/InputSwitchForm.molecule"
import Label from "../../../../../components/atoms/Label.atom"
import DropdownColorInput from "../../../../../components/molecules/DropdownColorInput.molecule"
import {
  validateFieldValue,
  fieldsDoesNotHaveErrors,
} from "../../../../../utils/validations.utils"
import {
  AVAILABLE_CLIENT_COLORS,
  CLIENT_DETAILS_FORM_INITIAL_STATE,
} from "../../../../../consts/modules/clients.consts"

const FormClientDetails = ({
  client,
  setClient,
  isCreate,
  projects,
  setError,
  setProjects,
}) => {
  const [validations, setValidations] = useState(
    CLIENT_DETAILS_FORM_INITIAL_STATE
  )
  const [formData, setFormData] = useState({
    name: client?.name || "",
    code: client?.code || "",
    color: client?.color || "",
    active: client?.active || true,
  })

  useEffect(() => {
    if (client) {
      setFormData({
        name: client.name,
        code: client.code,
        color: client.color,
        active: client.active,
      })
    }
  }, [client])

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
    setClient({ ...client, [name]: value })
    if (name === "active" && projects) {
      if (!value) {
        const deactivateProjects = projects.map((p) => ({
          ...p,
          active: 0,
          modules: p.modules.map((m) => ({
            ...m,
            active: 0,
          })),
        }))
        setProjects(deactivateProjects)
      } else {
        setProjects(projects)
      }
    }
    if (
      formData.code &&
      formData.code &&
      formData.code.length === 3 &&
      name !== "color"
    ) {
      setValidations(validateFieldValue(validations, "color", formData.color))
    }
    let isError = false
    if (!fieldsDoesNotHaveErrors(validations)) {
      isError = true
    }
    setError((prev) => ({ ...prev, details: isError }))
  }

  const getDropdownColorOptions = () => {
    let dropdownColorOptions =
      AVAILABLE_CLIENT_COLORS[isCreate ? "CREATE" : "EDIT"]
    if (!isCreate && client?.id !== 1) {
      dropdownColorOptions = dropdownColorOptions.filter(
        (color) => color.label !== "SISU"
      )
    }
    return dropdownColorOptions
  }

  return (
    <>
      <FormSectionTitle title="Detalles del cliente" />
      <FormContainer className="mt-3">
        <FieldContainer col={12} md={4}>
          <InputTextForm
            label="Nombre"
            name="name"
            onChange={handleChange}
            value={formData.name}
            error={validations.name.errors}
          />
        </FieldContainer>
        <FieldContainer col={12} md={4}>
          <InputTextForm
            label="Código"
            name="code"
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              handleChange(e)
            }}
            value={formData.code}
            error={validations.code.errors}
            maxLength="3"
          />
        </FieldContainer>
        {!isCreate && (
          <FieldContainer col={4} md={4}>
            <Label className="mb-2">ID del cliente</Label>
            <div className="mt-3">{client?.id}</div>
          </FieldContainer>
        )}
        <FieldContainer col={12} md={4}>
          <DropdownColorInput
            label={"Color"}
            colors={getDropdownColorOptions()}
            placeholder="Selecciona un color"
            name="color"
            value={formData.color}
            onChange={handleChange}
            error={validations.color.errors}
          />
        </FieldContainer>
        <FieldContainer col={6} md={4}>
          <BadgePreview
            label={formData?.code || "-"}
            color={formData?.color || "var(--secondary-color)"}
          />
        </FieldContainer>
        <FieldContainer col={4} md={4}>
          <Label>Estatus</Label>
          <InputSwitchForm
            name="active"
            className="success-slider"
            label={formData.active ? "Activo" : "Inactivo"}
            value={Boolean(formData.active)}
            onChange={handleChange}
          />
        </FieldContainer>
      </FormContainer>
    </>
  )
}

export default FormClientDetails

FormClientDetails.propTypes = {
  client: PropTypes.object,
  setClient: PropTypes.func,
  isCreate: PropTypes.bool,
  projects: PropTypes.array,
  setProjects: PropTypes.func,
}
