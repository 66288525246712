import { deactivateClient, deleteClient } from "../../services/client.service"
import {
  deactivateProject,
  deleteProject,
} from "../../services/project.service"
import { isNotEmpty, isNotMaxLength } from "../../utils/validations.utils"

export const CLIENT_TABLE_ROW_TYPE = {
  CLIENT: "client",
  PROJECT: "project",
}

export const CLIENT_TABLE_DEFAULT_FILTER = {
  "data.active": {
    value: 1,
    matchMode: "equals",
  },
}

export const CLIENT_TABLE_ROW_ACTIONS = {
  REACTIVATE: "reactivate",
  DEACTIVATE: "deactivate",
  DELETE: "delete",
}

export const CLIENT_ACTION_MODAL_MESSAGES = {
  DEACTIVATE_CLIENT: {
    title: "Desactivar cliente",
    description:
      "La información relacionada con este cliente, se mantendrá sin modificaciones. Sin embargo, no estará disponible para futuros registros",
    service: deactivateClient,
  },
  DEACTIVATE_PROJECT: {
    title: "Desactivar proyecto",
    description:
      "La información relacionada con este proyecto, se mantendrá sin modificaciones. Sin embargo, no estará disponible para futuros registros",
    service: deactivateProject,
  },
  DELETE_CLIENT: {
    title: "Eliminar cliente",
    description:
      "Si eliminas este cliente, se eliminarán los proyectos y módulos relacionados con él. Esta acción es irreversible ¿Estás seguro de eliminarlo?",
    service: deleteClient,
  },
  DELETE_PROJECT: {
    title: "Eliminar proyecto",
    description:
      "Si eliminas este proyecto, se eliminarán los módulos relacionados con él. Esta acción es irreversible ¿Estás seguro de eliminarlo?",
    service: deleteProject,
  },
}

export const FORM_CLIENT_MODE = {
  CREATE: "create",
  EDIT: "edit",
}

export const CLIENT_INITIAL_STATE = {
  name: "",
  code: "",
  active: true,
}

export const AVAILABLE_CLIENT_COLORS = {
  CREATE: [
    { label: "Amarillo", value: "var(--yellow-600)" },
    { label: "Naranja", value: "var(--orange-500)" },
    { label: "Rojo", value: "var(--red-600)" },
    { label: "Rosa", value: "var(--pink-500)" },
    { label: "Púrpura", value: "var(--purple-500)" },
    { label: "Índigo", value: "var(--indigo-600)" },
    { label: "Azul", value: "var(--blue-600)" },
    { label: "Teal", value: "var(--teal-500)" },
    { label: "Verde", value: "var(--green-600)" },
  ],
  EDIT: [
    { label: "Amarillo", value: "#C79807" },
    { label: "Naranja", value: "#F97316" },
    { label: "Rojo", value: "#D9342B" },
    { label: "Rosa", value: "#EC4899" },
    { label: "Púrpura", value: "#A855F7" },
    { label: "Índigo", value: "#5457CD" },
    // ...(client.id === 1 ? [{ label: 'SISU', value: '#2896D8' }] : []),
    { label: "SISU", value: "#2896D8" },
    { label: "Azul", value: "#326FD1" },
    { label: "Teal", value: "#14B8A6" },
    { label: "Verde", value: "#1DA750" },
  ],
}

export const CLIENT_DETAILS_FORM_INITIAL_STATE = {
  name: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty, isNotMaxLength],
    validationOptions: { isNotMaxLength: { max: 255 } },
  },
  code: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty, isNotMaxLength],
    validationOptions: { isNotMaxLength: { max: 3 } },
  },
  color: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
  },
  active: {
    errors: [],
    value: null,
    typeValidations: [isNotEmpty],
  },
}

export const CLIENT_BUDGET_TYPES = {
  DEBIT: "1",
  CREDIT: "2",
}

export const CLIENT_BUDGET_INITIAL_STATE = {
  budgetType: {
    errors: [],
    value: CLIENT_BUDGET_TYPES.DEBIT.toString(),
    typeValidations: [isNotEmpty],
  },
  initialBudgetBalance: {
    errors: [],
    value: 0,
    typeValidations: [isNotEmpty],
  },
  suggestedMonthlyBudget: {
    errors: [],
    value: 0,
    typeValidations: [isNotEmpty],
  },
}

export const MODULE_SORT_OPTIONS = {
  CUSTOM: 0,
  ALPHABETICAL: 1,
}
