import React from 'react'
import UnifiedDatePicker from '../../../components/compounds/UnifiedDatePicker.compound'
import { UNIFIEDDATE_VALUES } from '../../../consts/CompoundRangeDatePicker'

function dashboardGetFormat (period) {
  let format

  switch (period) {
  case UNIFIEDDATE_VALUES.SINGLEDAY:
  case UNIFIEDDATE_VALUES.RANGEDAY:
    format = 'DD/MM/YYYY'
    break
  case UNIFIEDDATE_VALUES.SINGLEWEEK:
  case UNIFIEDDATE_VALUES.RANGEWEEK:
    format = '\'Semana\' wo\'/\'YY'
    break
  case UNIFIEDDATE_VALUES.SINGLEMONTH:
  case UNIFIEDDATE_VALUES.RANGEMONTH:
    format = 'MMMM/YY'
    break
  case UNIFIEDDATE_VALUES.SINGLEYEAR:
  case UNIFIEDDATE_VALUES.RANGEYEAR:
    format = 'YYYY'
    break
  default:
    format = 'DD/MM/YYYY'
    break
  }

  return format
}

const DashboardControls = ({
  period,
  setPeriod,
  date,
  setDate
}) => {
  return (
    <div className="dashboard-filter flex flex-1 align-items-center gap-3 mb-3 flex-column sm:flex-row">
      {/* <SwitchableDatePicker
        period={period}
        setPeriod={setPeriod}
        selectProps={{ style: { minWidth: 125 } }}
        date={date}
        setDate={setDate}
        datePickerProps={{ allowClear: false, style: { width: '20ch' }, inputReadOnly: true }}
      /> */}
      <UnifiedDatePicker
        dateRange={date}
        setDateRange={setDate}
        period={period}
        setPeriod={setPeriod}
        getFormat={dashboardGetFormat}
        hasDayPeriod={false}
      />
    </div>
  )
}

export default DashboardControls
