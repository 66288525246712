import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalForm from '../../../../../components/compounds/modalForm/ModalForm.compunds'
import { checkAllFields, fieldsDoesNotHaveErrors, isNotEmpty, validateFieldValue, isNotMaxLength } from '../../../../../utils/validations.utils'
import FormProject from './FormProject.controller'

const ModalFormCreateProject = ({
  visible,
  setVisible,
  createProject,
  projects,
  isCreate
}) => {
  const initialFieldState = {
    name: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty]
    },
    code: {
      errors: [],
      value: null,
      typeValidations: [isNotEmpty, isNotMaxLength],
      validationOptions: { isNotMaxLength: { max: 3 } }
    },
    memberInCharge: {
      errors: [],
      value: null,
      typeValidations: []
    }
  }
  const initialFormData = {
    name: '',
    code: '',
    memberInCharge: null
  }
  const [validations, setValidations] = useState(initialFieldState)
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value })
    const isDuplicate = projects.some(project => project[name] === value)
    const customError = {
      errorName: name,
      errorMessage: 'Ya existe un proyecto con estos datos'
    }
    validateFieldValue(validations, name, value)
    setValidations({
      ...validations,
      [name]: {
        ...validations[name],
        errors: [
          ...(isDuplicate ? [customError] : [])
        ]
      }
    })
  }

  const handleSave = () => {
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) return
    createProject(formData)
    setVisible(false)
    setFormData(initialFormData)
  }

  const handleCleanUp = () => {
    setValidations(initialFieldState)
    setFormData(initialFormData)
  }
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerTitle='Crear Proyecto'
      headerIconClass='pi pi-folder-open'
      saveLabel='Crear'
      service={handleSave}
      disabledSaveBtn={validations.name.errors.length > 0 || validations.code.errors.length > 0}
      cleanUp={handleCleanUp}
    >
      <FormProject
        formData={formData}
        handleChange={handleChange}
        validations={validations}
        isCreate={isCreate}
      />
    </ModalForm>
  )
}

export default ModalFormCreateProject

ModalFormCreateProject.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setProject: PropTypes.func,
  projects: PropTypes.array,
  isCreate: PropTypes.bool
}
