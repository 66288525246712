import React from "react"
import {
  getBudgetProgressBarLabelFromPercentage,
  getBudgetProgressPercentage,
} from "../../../utils/budget.utils"
import PhaseLegendTemplate from "./PhaseLegendTemplate.compound"

const ReducedBudgetPhase = ({
  budgetPercentageProps,
  phaseInfoProps,
  showNoBudgetWithHours,
}) => {
  const value = getBudgetProgressPercentage(budgetPercentageProps)
  const phaseInfo = getBudgetProgressBarLabelFromPercentage({
    percentage: value,
    hasBudget: phaseInfoProps?.hasBudget,
    hasBilledTime: phaseInfoProps?.totalBilledTime > 0,
    budgetType: phaseInfoProps?.clientBudgetTypeId,
    hasMonthlyLimit: phaseInfoProps?.suggestedMonthlyBudgetLimit > 0,
    showNoBudgetWithHours,
  })
  const showTooltip =
    phaseInfo.title === "Sin bolsa de horas" &&
    phaseInfoProps?.totalBilledTime > 0
  return (
    <PhaseLegendTemplate
      color={phaseInfo.color}
      label={phaseInfo.title}
      showTooltip={showTooltip}
    />
  )
}

export default ReducedBudgetPhase
