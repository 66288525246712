export const PERIODS = [
  { name: 'Diario', value: 'diario' },
  { name: 'Semanal', value: 'semanal' },
  { name: 'Mensual', value: 'mensual' }
]

export const CLIENT_REPORTS_CSV_STARTING_NAME = 'client-reports-'

export const INDIVIDUAL_REPORT_TABS = {
  INDIVIDUAL_TASKS: 0,
  INDIVIDUAL_SUMMARY: 1
}

export const OCCUPATION_HOUR_TYPES = {
  TRABAJADAS: 0,
  FACTURADAS: 1,
  PERDONADAS: 2
}

export const OCCUPATION_CHART_TYPES = {
  ABSOLUTO: 0,
  PORCENTUAL: 1
}

export const OCCUPATION_HOUR_TYPES_OPTIONS = [
  {
    label: 'Trabajadas',
    value: OCCUPATION_HOUR_TYPES.TRABAJADAS,
    color: 'var(--clr-info)'
  },
  {
    label: 'Facturadas',
    value: OCCUPATION_HOUR_TYPES.FACTURADAS,
    color: 'light-dark(var(--primary-color-dark), white)',
    textColor: 'light-dark(white, var(--primary-color-dark))'
  },
  {
    label: 'Perdonadas',
    value: OCCUPATION_HOUR_TYPES.PERDONADAS,
    color: 'var(--teal-500)'
  }
]

export const OCCUPATION_PERCENTAGE_RANGE = {
  OPTIMO: 80,
  ESTABLE: 70,
  INESTABLE: 60,
  CRITICO: 0
}

export const OCCUPATION_PERCENTAGE_RANGE_COLORS = {
  OPTIMO: '#00B4FF',
  ESTABLE: 'var(--clr-success)',
  INESTABLE: 'var(--orange-500)',
  CRITICO: 'var(--clr-danger)'
}

export const OCCUPATION_PERCENTAGE_RANGE_LABELS = {
  OPTIMO: 'Óptimo',
  ESTABLE: 'Estable',
  INESTABLE: 'Inestable',
  CRITICO: 'Crítico'
}

export const OCCUPATION_REPORT_RANGE_REFERENCE_LINES_Y = [
  {
    y: 100,
    stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.OPTIMO,
    strokeDasharray: '4 2'
  },
  {
    y: 80,
    stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.ESTABLE,
    strokeDasharray: '4 4'
  },
  {
    y: 70,
    stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.INESTABLE,
    strokeDasharray: '4 4'
  },
  {
    y: 60,
    stroke: OCCUPATION_PERCENTAGE_RANGE_COLORS.CRITICO,
    strokeDasharray: '4 4'
  }
]

export const INDIVIDUAL_REPORT_CSV_HEADERS = [
  {
    field: 'date',
    header: 'Fecha'
  },
  {
    field: 'clientName',
    header: 'Cliente'
  },
  {
    field: 'projectCode',
    header: 'Proyecto'
  },
  {
    field: 'projectName',
    header: 'Nombre del proyecto'
  },
  {
    field: 'module',
    header: 'Módulo'
  },
  {
    field: 'category',
    header: 'Grupo de categoría'
  },
  {
    field: 'categoryTitle',
    header: 'Categoría'
  },
  {
    field: 'taskId',
    header: 'ID Tarea'
  },
  {
    field: 'task',
    header: 'Tarea'
  },
  {
    field: 'sisuTime',
    header: 'Horas trabajadas'
  },
  {
    field: 'billedTime',
    header: 'Horas facturadas'
  },
  {
    field: 'timeDifference',
    header: 'Diferencia'
  },
  {
    field: 'timeDifferenceComment',
    header: 'Comentario Diferencia'
  }
]

export const CLIENT_INDIVIDUAL_REPORT_CSV_HEADERS = [
  {
    field: 'date',
    header: 'Fecha'
  },
  {
    field: 'clientName',
    header: 'Cliente'
  },
  {
    field: 'projectCode',
    header: 'Proyecto'
  },
  {
    field: 'module',
    header: 'Módulo'
  },
  {
    field: 'category',
    header: 'Grupo de categoría'
  },
  {
    field: 'categoryTitle',
    header: 'Categoría'
  },
  {
    field: 'specialCategoryTitle',
    header: 'Categoría especial'
  },
  {
    field: 'teamMemberNameTag',
    header: 'Integrante'
  },
  {
    field: 'task',
    header: 'Tarea'
  },
  {
    field: 'sisuTime',
    header: 'Horas trabajadas'
  },
  {
    field: 'billedTime',
    header: 'Horas facturadas'
  },
  {
    field: 'timeDifference',
    header: 'Diferencia'
  }
]

export const INDIVIDUAL_REPORT_DEFAULT_FILTERS = {
  global: { value: null, matchMode: 'contains' },
  projectName: { value: null, matchMode: 'in' },
  moduleId: { value: null, matchMode: 'in' },
  categoryId: { value: null, matchMode: 'in' },
  task: { value: null, matchMode: 'contains' }
}

export const INDIVIDUAL_REPORT_SKELETON_COLUMNS = [
  'projectCode',
  'module',
  'categoryTitle',
  'task',
  'sisuTime',
  'billedTime',
  'timeDifference',
  'actions'
]

export const INDIVIDUAL_REPORT_ROW_HEIGHT = 37
export const INDIVIDUAL_REPORT_SUBHEADER_HEIGHT = 33
