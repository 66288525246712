import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Card from "../../../../components/atoms/Card.atom"
import Button from "../../../../components/atoms/Button.atom"
import TabMenuContentContainer from "../../../../components/layouts/containers/TabMenuContentContainer.container"
import _ from "lodash"
import {
  getActiveProjects,
  getAllProjects,
} from "../../../../services/project.service"
import {
  checkAllFields,
  validateFieldValue,
  fieldsDoesNotHaveErrors,
} from "../../../../utils/validations.utils"
import DropdownProjectInplace from "../../components/DropdownProjectInplace.compound"
import ChooseTimeInput from "../../components/ChooseTimeInput.compound"
import {
  CREATE_PENDING_TASK_INITIAL_FIELD_STATE,
  PENDING_TASK_COMMENT_MAX_LENGTH,
  PENDING_TASK_CREATE_ERROR_MESSAGE,
  PENDING_TASK_CREATE_SUCCESS_MESSAGE,
  PENDING_TASK_NAME_MAX_LENGTH,
} from "../../../../consts/modules/pendingTasks.consts"
import InputCommentOverlay from "../../components/InputCommentOverlay.compound"
import { KEYBOARD_ACTIONS } from "../../../../consts/keyBoard.consts"
import InputTimeOverlay from "../../components/InputTimeOverlay.compound"
import CustomInputTextInplace from "../../components/CustomInputTextInplace.compound"
import DropdownFollowerInput from "../../components/DropdownFollowerInput.compound"
import { getTeamMembers } from "../../../../services/teamMember.service"
import { createPendingTask } from "../../../../services/pendingTask.service"
import useToast from "../../../../hooks/useToast.hook"
import { useSession } from "../../../../hooks/auth/useSession.hook"

const FormCreatePendingTask = ({ triggerRefresh }) => {
  const initialFieldState = _.cloneDeep(CREATE_PENDING_TASK_INITIAL_FIELD_STATE)
  const initialFormData = {
    name: "",
    projectId: null,
    limitDate: null,
    followerIds: [],
    estimatedTime: null,
    comment: "",
  }
  // Form state
  const [validations, setValidations] = useState(initialFieldState)
  const [data, setData] = useState(initialFormData)
  const [submitting, setSubmitting] = useState(false)
  // External data
  const [activeProjects, setActiveProjects] = useState([])
  const [activeTeamMembers, setActiveTeamMembers] = useState([])

  // Refs
  const dropdownProjectRef = useRef()
  const dropdownFollowersRef = useRef()
  const limitDateRef = useRef()

  // Hooks
  const { setSuccessMessage, setErrorMessage } = useToast()
  const { user } = useSession()

  useEffect(() => {
    gettingActiveProjectsData()
    gettingActiveTeamMembersData()
    return () => {
      resetFormState()
    }
  }, [])
  const gettingActiveProjectsData = async () => {
    const response = await getActiveProjects()
    if (response.success) {
      setActiveProjects(response.result)
    }
  }

  const gettingActiveTeamMembersData = async () => {
    const response = await getTeamMembers()
    if (response.success) {
      const allTeamMembers = response.result
      const allActiveTeamMembersExceptMe = allTeamMembers.filter(
        (tm) => tm.active && tm.id !== user.id
      )
      setActiveTeamMembers(allActiveTeamMembersExceptMe)
    }
  }
  const handleSubmit = () => {
    checkAllFields(validations, setValidations)
    if (!fieldsDoesNotHaveErrors(validations)) {
      setSubmitting(false)
      return
    }
    handleSavePendingTask()
  }

  const handleSavePendingTask = async () => {
    setSubmitting(true)
    try {
      await createPendingTask({
        pendingTaskData: data,
      })
      setSuccessMessage(PENDING_TASK_CREATE_SUCCESS_MESSAGE)
      triggerRefresh()
    } catch (error) {
      setErrorMessage(PENDING_TASK_CREATE_ERROR_MESSAGE)
    } finally {
      setSubmitting(false)
      resetFormState()
    }
  }

  const resetFormState = () => {
    setValidations(initialFieldState)
    setData(initialFormData)
  }

  const handleKeyDown = (e, nextRef, currentRef) => {
    if (e.key === KEYBOARD_ACTIONS.TAB) {
      e.preventDefault()
      if (nextRef?.current?.show) {
        nextRef?.current?.show()
      }
      if (nextRef?.current?.focus) {
        nextRef?.current?.focus()
      }

      if (currentRef?.current?.hide) {
        currentRef?.current?.hide()
      }

      if (currentRef?.current?.getFocusInput) {
        currentRef?.current?.getFocusInput().blur()
      }
    }
  }
  return (
    <Card className="pending-task-creation-wrapper shadow-2">
      <TabMenuContentContainer
        className={`flex align-items-center gap-3 w-full p-0 ${submitting ? "pointer-events-none" : ""}`}
      >
        <div className="flex flex-column w-full gap-2">
          <CustomInputTextInplace
            value={data.name}
            name="name"
            placeholder="¿Tienes una tarea pendiente? Escríbela aquí"
            onChange={(e) => {
              setData({ ...data, name: e.target.value })
              setValidations(
                validateFieldValue(validations, "name", e.target.value)
              )
            }}
            onBlur={() => {
              setData((prev) => ({ ...prev, name: prev.name.trim() }))
            }}
            error={validations.name.errors.length}
            inputProps={{
              maxLength: PENDING_TASK_NAME_MAX_LENGTH,
              onKeyDown: (e) => handleKeyDown(e, dropdownProjectRef),
            }}
          />
          <div className="flex gap-2 align-items-center flex-wrap">
            <DropdownProjectInplace
              value={data.projectId}
              onChange={(e) => {
                setData({ ...data, projectId: e.value })
                setValidations(
                  validateFieldValue(validations, "projectId", e.value)
                )
              }}
              options={activeProjects}
              error={validations.projectId.errors?.length}
              filter
              dropdownInputProps={(inputProps) => ({
                autoFocus: true,
                className: "w-full",
                onKeyDown: (e) => {
                  inputProps.filterInputKeyDown(e)
                },
              })}
              dropdownRef={dropdownProjectRef}
            />
            <ChooseTimeInput
              value={data.limitDate}
              onChange={(e) => {
                setData({ ...data, limitDate: e })
                setValidations(validateFieldValue(validations, "limitDate", e))
              }}
              error={validations.limitDate.errors?.length}
              className="w-full"
              inputRef={limitDateRef}
            />
            <DropdownFollowerInput
              selectedValues={data.followerIds}
              options={activeTeamMembers}
              onChange={(e) => {
                const currentFollowerIds = data.followerIds
                const newFollower = e.value
                const followerIds =
                  currentFollowerIds.includes(newFollower) ?
                    currentFollowerIds.filter((id) => id !== newFollower)
                  : currentFollowerIds.concat(newFollower)
                setData({ ...data, followerIds })
              }}
              dropdownRef={dropdownFollowersRef}
              removableFollowers
            />
            <InputTimeOverlay
              key={"time" + submitting}
              currentValue={data.estimatedTime}
              onSubmit={(estimatedTime) => setData({ ...data, estimatedTime })}
              inputProps={{
                onKeyDown: (e, currentValue) => {
                  if (e.key === KEYBOARD_ACTIONS.ENTER) {
                    checkAllFields(validations, setValidations)
                    if (fieldsDoesNotHaveErrors(validations)) {
                      setData({ ...data, estimatedTime: currentValue })
                    }
                    e.preventDefault()
                  }
                },
              }}
              error={validations.comment.errors?.length}
            />
            <InputCommentOverlay
              key={"comment" + submitting}
              currentValue={data.comment}
              onSubmit={(comment) => {
                setValidations(
                  validateFieldValue(validations, "comment", comment)
                )
                setData({ ...data, comment })
              }}
              inputProps={{
                maxLength: PENDING_TASK_COMMENT_MAX_LENGTH,
                rows: 3,
                autoFocus: true,
                onKeyDown: (e, currentValue) => {
                  const lines = currentValue.split("\n")
                  if (
                    (lines.length >= 5 && e.key === KEYBOARD_ACTIONS.ENTER) ||
                    currentValue.length >= PENDING_TASK_COMMENT_MAX_LENGTH
                  ) {
                    e.preventDefault()
                  }
                },
              }}
              error={validations.comment.errors?.length}
            />
          </div>
        </div>
        <Button
          icon={`pi ${submitting ? "pi-spin pi-spinner" : "pi-plus"}`}
          className="p-button-success border-circle flex-shrink-0 border-none"
          onClick={handleSubmit}
          disabled={submitting}
        />
      </TabMenuContentContainer>
    </Card>
  )
}

export default FormCreatePendingTask

FormCreatePendingTask.propTypes = {
  triggerRefresh: PropTypes.func.isRequired,
}
