import React from "react"
import ReportBadgeWithTooltip from "../../../components/molecules/ReportBadgeWithTooltip.molecule"

export const TimeDifferenceBadge = ({
  value,
  minTreshold = 0.5,
  maxTreshold = 1,
  referenceValue = 40,
  ...props
}) => {
  let isDanger = false
  let isDangerIcon = ""
  if (!Number.isNaN(value) && referenceValue !== 0) {
    isDanger =
      value < referenceValue - minTreshold ||
      value > referenceValue + maxTreshold
    value < referenceValue - minTreshold ? (isDangerIcon = "")
    : value > referenceValue + maxTreshold ? (isDangerIcon = "+")
    : (isDangerIcon = "")
  }
  return (
    isDanger && (
      <ReportBadgeWithTooltip
        type="danger"
        iconClassName="pi pi-exclamation-triangle text-white"
        legend={`${isDangerIcon}${props.percentage}`}
        legendProps={{
          style: {
            color: "red",
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
        className={"ml-1 " + props.className}
        {...props}
      />
    )
  )
}
