const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const API_USUARIO = `${API_BASE_URL}/user-api`
export const API_PERFIL = `${API_BASE_URL}/team-member-api`
export const API_MENU = `${API_BASE_URL}/menu-api`
export const API_PROJECT = `${API_BASE_URL}/project-api`
export const API_MODULE = `${API_BASE_URL}/module-api`
export const API_CATEGORY = `${API_BASE_URL}/category-api`
export const API_TASKS = `${API_BASE_URL}/task-api`
export const API_REPORT = `${API_BASE_URL}/report-api`
export const API_CLIENT = `${API_BASE_URL}/client-api`
export const API_WORKDAY = `${API_BASE_URL}/workday-api`
export const API_DASHBOARD = `${API_BASE_URL}/dashboard-api`
export const API_BUDGET = `${API_BASE_URL}/budget-api`
export const API_PENDING_TASK = `${API_BASE_URL}/pending-task-api`
export const API_FOLLOWED_TASK = `${API_BASE_URL}/followed-task-api`
