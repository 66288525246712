import React from 'react'
import { InputTextarea as PrimeInputTextarea } from 'primereact/inputtextarea'

const InputTextarea = (props) => {
  return (
    <PrimeInputTextarea {...props}/>
  )
}

export default InputTextarea
