/**
 * @description Contiene todas las rutas de la aplicación
 */

const homeBaseRoute = "/"
const homeRoutes = {
  index: `${homeBaseRoute}`,
}

const authBaseRoute = "/"
const authRoutes = {
  login: `${authBaseRoute}`,
  loginExplicit: "/login",
  logout: `${authBaseRoute}/logout`,
}

const administracionBaseRoute = "/administracion"
const usuariosRoutes = {
  index: `${administracionBaseRoute}/usuarios`,
  crear: `${administracionBaseRoute}/usuarios/formulario/crear`,
  actualizar: `${administracionBaseRoute}/usuarios/formulario/actualizar/:userId`,
}

const registroTiemposBaseRoute = "/registro-de-tiempos"
const registroTiemposRoutes = {
  index: `${registroTiemposBaseRoute}`,
}

const reporteGeneralBaseRoute = "/reporte-general"
const reporteGeneralRoutes = {
  index: `${reporteGeneralBaseRoute}`,
  horasSemana: `${reporteGeneralBaseRoute}/carga-semanal`,
  horasProyecto: `${reporteGeneralBaseRoute}/horas-proyecto`,
  horasEquipo: `${reporteGeneralBaseRoute}/horas-equipo`,
  horasCliente: `${reporteGeneralBaseRoute}/horas-cliente`,
  horasClienteRedirect: `${reporteGeneralBaseRoute}/horas-cliente/:clientCode/:startDate/:endDate`,
  consumosEquipo: `${reporteGeneralBaseRoute}/consumos-equipo`,
}

const reporteIndividualBaseRoute = "/reporte-individual"
const reporteIndividualRoutes = {
  index: `${reporteIndividualBaseRoute}`,
  redirect: `${reporteIndividualBaseRoute}/:teamMemberId/:startDate/:endDate/:period`,
}

const reporteClienteBaseRoute = "/reporte-client"
const reporteClienteRoutes = {
  index: `${reporteClienteBaseRoute}`,
  redirect: `${reporteClienteBaseRoute}/:clientId/:startDate/:endDate/:period`,
}

const clientesRoutes = {
  index: `${administracionBaseRoute}/clientes`,
  crear: `${administracionBaseRoute}/clientes/crear`,
  editar: `${administracionBaseRoute}/clientes/editar`,
  presupuestoCliente: `${administracionBaseRoute}/clientes/presupuesto-cliente`,
}

const sandboxBaseRoute = "/sandbox"
const sandboxRoutes = {
  index: `${sandboxBaseRoute}`,
}

const configuracionBaseRoute = "/configuracion"
const configuracionRoutes = {
  index: `${configuracionBaseRoute}`,
}

const listaPendientesBaseRoute = "/lista-de-pendientes"
const listaPendientesRoutes = {
  index: `${listaPendientesBaseRoute}`,
}

const historialCambiosTareaBaseRoute = "/historial-tarea"
const historialCambiosTareaRoutes = {
  index: `${historialCambiosTareaBaseRoute}`,
  redirect: `${historialCambiosTareaBaseRoute}/:taskId`,
}

const dashboardBaseRoute = "/dashboard"
const dashboardRoutes = {
  index: `${dashboardBaseRoute}`,
}

const reporteOcupacionBaseRoute = "/reporte-ocupacion"
const reporteOcupacionRoutes = {
  index: `${reporteOcupacionBaseRoute}`,
}

const changeLogBaseRoute = "/change-log"
const changeLogRoutes = {
  index: `${changeLogBaseRoute}`,
}

export const AppRoutes = {
  home: homeRoutes,
  auth: authRoutes,
  usuarios: usuariosRoutes,
  registroTiempos: registroTiemposRoutes,
  reporteGeneral: reporteGeneralRoutes,
  reporteIndividual: reporteIndividualRoutes,
  reporteCliente: reporteClienteRoutes,
  clientes: clientesRoutes,
  sandbox: sandboxRoutes,
  configuracion: configuracionRoutes,
  listaPendientes: listaPendientesRoutes,
  historialCambios: historialCambiosTareaRoutes,
  dashboard: dashboardRoutes,
  reporteOcupacion: reporteOcupacionRoutes,
  changeLog: changeLogRoutes,
}
