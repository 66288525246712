import React from "react"
import PropTypes from "prop-types"
import Tooltip from "../atoms/Tooltip.atom"
import { contrastTextColor, generateRandomId } from "../../utils/strings.utils"

export const USER_CHIP_TYPES = {
  PRIMARY_WHITE: "primarywhite",
  CUSTOM: "custom",
}

export const USER_CHIP_SIZES = {
  SMALL: "small",
  NORMAL: "normal",
  LARGE: "large",
}

const UserChip = ({
  user,
  variant = USER_CHIP_TYPES.PRIMARY_WHITE,
  size = USER_CHIP_SIZES.NORMAL,
  isHovered,
  hoveredTemplate,
  tooltipConfig = {
    "data-pr-position": "top",
  },
  useTooltip = false,
  containerClassName = `user-chip-${generateRandomId()}`,
  ...props
}) => {
  const chipClassName =
    variant === USER_CHIP_TYPES.PRIMARY_WHITE ??
    "bg-white text-blue-600 font-bold"
  tooltipConfig =
    useTooltip ?
      {
        ...tooltipConfig,
        "data-pr-tooltip": `${user?.name} ${user?.lastName}`,
      }
    : null
  const getFontSize = (size) => {
    switch (size) {
      case USER_CHIP_SIZES.SMALL:
        return "6px"
      case USER_CHIP_SIZES.NORMAL:
        return "9px"
      case USER_CHIP_SIZES.LARGE:
        return "12px"
      default:
        return "0.75rem"
    }
  }

  const getChipSize = (size) => {
    switch (size) {
      case USER_CHIP_SIZES.SMALL:
        return "12px"
      case USER_CHIP_SIZES.NORMAL:
        return "24px"
      case USER_CHIP_SIZES.LARGE:
        return "36px"
      default:
        return "24px"
    }
  }
  return (
    <>
      <div
        className={`${containerClassName} flex align-items-center font-mulish`}
        {...tooltipConfig}
      >
        <span
          className={`${chipClassName} border-circle flex align-items-center justify-content-center 
      my-auto`}
          style={{
            fontSize: `${getFontSize(size)}`,
            minWidth: `${getChipSize(size)}`,
            minHeight: `${getChipSize(size)}`,
            aspectRatio: "1/1",
            background:
              variant === USER_CHIP_TYPES.CUSTOM ?
                `${user?.color || "rgb(100, 116, 139)"}`
              : null,
            cursor: isHovered ? "pointer" : "auto",
          }}
          {...props}
        >
          <span
            className="flex align-items-center justify-content-center w-full h-full"
            style={{
              color: contrastTextColor(user?.color, 150),
              minWidth: "1rem",
              minHeight: "1rem",
              lineHeight: "1rem",
            }}
          >
            {isHovered ? hoveredTemplate : user?.nameTag}
          </span>
        </span>
      </div>
      <Tooltip target={`.${containerClassName}`} />
    </>
  )
}

export default UserChip

UserChip.propTypes = {
  user: PropTypes.shape({
    nameTag: PropTypes.string.isRequired,
    color: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOf([
    USER_CHIP_TYPES.PRIMARY_WHITE,
    USER_CHIP_TYPES.CUSTOM,
  ]),
  size: PropTypes.oneOf([
    USER_CHIP_SIZES.SMALL,
    USER_CHIP_SIZES.NORMAL,
    USER_CHIP_SIZES.LARGE,
  ]),
  isHovered: PropTypes.bool,
  hoveredTemplate: PropTypes.node,
}
