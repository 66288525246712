import React, { useLayoutEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import { getLazyUniqueDaysDisplayed } from "../utils/report.utils"

const useStickyFooter = (
  isSort,
  dtValue,
  totals,
  deps,
  StickyFooterElement,
  isBillable = false
) => {
  const [footerRoot, setFooterRoot] = useState(null)
  useLayoutEffect(() => {
    setTimeout(() => {
      const datatableElement = document.querySelector(
        ".p-virtualscroller > .p-datatable-table"
      )
      if (datatableElement && dtValue?.length) {
        const subheadersCount = getLazyUniqueDaysDisplayed(
          dtValue,
          dtValue.length,
          isSort ? { sortField: "date" } : {}
        )
        const newTableHeight = 37 * dtValue.length + subheadersCount * 33 + 49
        datatableElement.style.minHeight = `${newTableHeight}px`
        // console.log(
        //   `Adjusting table height ${37 * dtValue?.length} -> ${newTableHeight}`,
        //   dtValue.length,
        //   subheadersCount
        // )
      } else if (datatableElement && !dtValue?.length) {
        datatableElement.style.minHeight = "fit-content"
      }
    }, 10)
    if (dtValue?.length) {
      const dataTable = document.querySelector(".p-virtualscroller")
      let footerDiv = document.querySelector(".custom-datatable-footer")
      if (!footerDiv) {
        footerDiv = document.createElement("table")
        footerDiv.className = "custom-datatable-footer"
        dataTable.appendChild(footerDiv)
        setFooterRoot(createRoot(footerDiv))
      }
      footerRoot?.render(
        <StickyFooterElement isBillable={isBillable} totals={totals} />
      )
      return () => {
        if (!dtValue?.length && footerRoot) {
          footerRoot.render(null)
        }
      }
    }
  }, [dtValue, totals, ...deps])
  return footerRoot !== null
}

export default useStickyFooter
