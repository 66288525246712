import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import DataTable from "../../../../../components/atoms/table/DataTable.atom"
import Column from "../../../../../components/atoms/table/Column.atom"
import MultiSelect from "../../../../../components/atoms/MultiSelect.atom"
import ClientColumnContent from "./ClientColumnContent.controller"
import {
  groupHeaderTemplate,
  StickyFooter,
} from "./ClientGroupTemplates.controller"
import { arraysContainSameStrings } from "../../../../../utils/arrays.utils"
import useIsHoldingCtrlDown from "../../../../../hooks/useIsHoldingCtrlDown"
import {
  calculateTotalsByDayForIndividualTasksReport,
  calculateTotalsIndividualTasksReport,
  convertToCSV,
  downloadCSV,
} from "../../../../../utils/report.utils"
import { CLIENT_INDIVIDUAL_REPORT_CSV_HEADERS } from "../../../../../consts/reports.consts"
import { DateTime } from "luxon"
import { useSession } from "../../../../../hooks/auth/useSession.hook"
import {
  getCategoriesOfTasksOfClientId,
  getModulesOfTasksOfClientId,
  getProjectsOfTasksOfClientId,
} from "../../../../../services/report.service"
import moduleFilterTemplate from "../../../../../components/compounds/registeredTasksTable/ModuleFilterTemplate.compound"
import categoryFilterTemplate from "../../../../../components/compounds/registeredTasksTable/CategoryFilterTemplate.compound"
import clientFilterTemplate from "../../../../../components/compounds/registeredTasksTable/ClientFilterTemplate.compound"
import useStickyFooter from "../../../../../hooks/useStickyFooter.hook"
import useHandleDuplicateGroupsDataTable from "../../../../../hooks/useHandleDuplicateGroupsDataTable.hook"
import useToast from "../../../../../hooks/useToast.hook"
import InputText from "../../../../../components/atoms/InputText.atom"

const RegisteredClientTaskTable = React.forwardRef(
  (
    {
      setSelectedTask,
      data,
      cm,
      updatingTask,
      dateRange,
      selectedClient,
      exportFilename,
      exportFunction,
      virtualScrollerOptions,
      setLazyLoading,
      filteredData,
      setFilteredData,
      sortConfig,
      setSortConfig,
    },
    ref
  ) => {
    if (!data) return null
    const {
      tasks,
      totals: originalTotals,
      totalsByDay: originalTotalsByDay,
    } = useMemo(() => data, [data])

    const dtValue = tasks?.map((task) => {
      if (task) {
        return {
          ...task,
          date: DateTime.fromISO(task?.date?.split("T")[0])
            .set({ hour: 12 })
            .toISODate(),
          categoryId: task?.specialCategoryId || task?.categoryId,
          categoryTitle: task?.specialCategoryTitle || task?.categoryTitle,
          moduleId: task?.moduleId ?? undefined,
        }
      }
      return undefined
    })
    const [totalsByDay, setTotalsByDay] = useState(originalTotalsByDay)
    const [totals, setTotals] = useState(originalTotals)
    const [categories, setCategories] = useState([])
    const [modules, setModules] = useState([])
    const [projects, setProjects] = useState([])
    const overridenRef = useRef()
    const categoryFilterOptionsRef = useRef(null)
    const moduleFilterOptionsRef = useRef(null)
    const clientFilterOptionsRef = useRef(null)
    const teamMemberFilterOptionsRef = useRef(null)
    const isHoldingCtrlDown = useIsHoldingCtrlDown()
    const { user } = useSession()
    const { setErrorMessage } = useToast()

    useEffect(() => {
      if (filteredData) {
        const dynamicTotalsByDay =
          calculateTotalsByDayForIndividualTasksReport(filteredData)
        setTotalsByDay(dynamicTotalsByDay)
        const dynamicTotals = calculateTotalsIndividualTasksReport(filteredData)
        setTotals(dynamicTotals)
        setLazyLoading(false)
      }
    }, [filteredData])

    const formatCategories = (tasks) => {
      return [
        ...new Map(
          tasks.map((item) => {
            return [
              item?.categoryId,
              {
                label: item?.categoryTitle,
                value: item?.categoryId,
                isSpecialCategory: false,
              },
            ]
          })
        ).values(),
      ].filter((c) => c.label)
    }
    const formatModules = (tasks) => {
      return [
        ...new Map(
          tasks?.map((item) => {
            return [item.module, { label: item.module, value: item.moduleId }]
          })
        ).values(),
      ]
    }
    const formatProjects = (tasks) => {
      const projects = []
      tasks?.forEach((item) => {
        let project = projects.find(
          (project) => project.name === item?.clientName
        )
        if (!project) {
          project = {
            name: item?.clientName,
            code: item?.clientCode,
            type: "client",
            items: [],
          }
          projects.push(project)
        }
        let projectItem = project.items.find(
          (project) => project.name === item?.projectName
        )
        if (!projectItem) {
          projectItem = {
            id: item?.projectId,
            name: item?.projectName,
            code: item?.projectCode,
            clientCode: item?.clientCode,
            type: "project",
          }
          project.items.push(projectItem)
        }
      })
      return projects
    }
    const hasSpecialCategories = (tasks) => {
      return tasks?.some((task) => task?.specialCategoryId)
    }
    useEffect(() => {
      if (!selectedClient) return
      const [startDate, endDate] = dateRange
      getCategoriesOfTasksOfClientId(selectedClient, startDate, endDate).then(
        (response) => {
          const result = response.result
          const categories = formatCategories(result)
          setCategories(categories)
        }
      )
      getModulesOfTasksOfClientId(selectedClient, startDate, endDate).then(
        (response) => {
          const result = response.result
          const modules = formatModules(result)
          setModules(modules)
        }
      )
      getProjectsOfTasksOfClientId(selectedClient, startDate, endDate).then(
        (response) => {
          const result = response.result
          const projects = formatProjects(result)
          setProjects(projects)
        }
      )
    }, [selectedClient, dateRange])
    const taskTeamMembers = [
      ...new Map(
        tasks?.map((item) => {
          return [
            item.teamMemberNameTag,
            {
              label: `${item.teamMemberNameTag} - ${item.teamMemberName} ${item.teamMemberLastName}`,
              value: item.teamMemberNameTag,
            },
          ]
        })
      ).values(),
    ]

    const categoryFilterTemplateWrapper = (options) => {
      return categoryFilterTemplate({
        options,
        categories,
        filterConfig: {},
        categoryFilterOptionsRef,
      })
    }
    const moduleFilterTemplateWrapper = (options) => {
      return moduleFilterTemplate({
        options,
        modules,
        filterConfig: {},
        moduleFilterOptionsRef,
      })
    }

    const teamMemberFilterTemplate = (options) => {
      teamMemberFilterOptionsRef.current = options
      return (
        <MultiSelect
          value={options.value}
          options={taskTeamMembers}
          onChange={(e) => {
            options.filterCallback(e.value)
          }}
          placeholder="Filtrar integrante"
          emptyFilterMessage="Misión fallida... no encontramos nada"
          panelClassName="max-w-10rem"
          itemTemplate={(option) => (
            <div
              className="max-w-10rem overflow-hidden white-space-nowrap text-overflow-ellipsis"
              title={option.label}
            >
              {option.label}
            </div>
          )}
          maxSelectedLabels={1}
          selectedItemsLabel="{0} integrantes seleccionados"
        />
      )
    }

    const clientFilterTemplateWrapper = (options) => {
      return clientFilterTemplate({
        options,
        projects,
        filterConfig: {},
        clientFilterOptionsRef,
      })
    }

    const taskNameFilterTemplate = (options) => {
      return (
        <InputText
          value={options.value || ""}
          onChange={(e) => {
            options.filterCallback(e.target.value)
          }}
        />
      )
    }
    useStickyFooter(
      sortConfig.sortField,
      filteredData || dtValue,
      totals,
      [dtValue, selectedClient],
      StickyFooter,
      data?.tasks?.[0]?.clientIsBillable
    )
    useHandleDuplicateGroupsDataTable(
      virtualScrollerOptions.loading,
      sortConfig.sortField,
      [virtualScrollerOptions.loading, dtValue]
    )
    useImperativeHandle(
      ref,
      () => ({
        ...overridenRef.current,
        exportCSV: async () => {
          const response = await exportFunction()
          if (!response.success) {
            setErrorMessage({ message: response.message })
          }
          const result = response.result
          const tasks = result.tasks.map((task) => ({
            ...task,
            date: DateTime.fromISO(task?.date?.split("T")[0])
              .set({ hour: 12 })
              .toISODate(),
          }))
          let headers = CLIENT_INDIVIDUAL_REPORT_CSV_HEADERS
          if (!hasSpecialCategories) {
            headers = headers.filter(
              (item) => item.field !== "specialCategoryTitle"
            )
          }
          const tasksCSV = convertToCSV(tasks, headers)
          downloadCSV(tasksCSV, exportFilename)
        },
      }),
      []
    )
    const onLeftClickFilter = (filterOptionsRef, value) => {
      setLazyLoading(true)
      const currValue = filterOptionsRef.current.value
      if (currValue?.length === 1) {
        filterOptionsRef.current.filterApplyCallback()
      } else {
        filterOptionsRef.current.filterApplyCallback([value])
      }
    }
    return (
      <>
        <DataTable
          key={dtValue}
          ref={overridenRef}
          className="individual-report-table"
          tableClassName="relative"
          value={dtValue}
          groupRowsBy="date"
          rowGroupMode="subheader"
          rowGroupHeaderTemplate={(rowData, options) =>
            groupHeaderTemplate(rowData, options, totalsByDay)
          }
          onValueChange={(e) => {
            setFilteredData(e)
            overridenRef.current.resetScroll()
          }}
          onContextMenu={({ originalEvent, data }) => {
            if (user?.isAdmin()) {
              setSelectedTask(data)
              cm.current.show(originalEvent)
            }
          }}
          scrollable
          style={{ height: "100%" }}
          tableStyle={{ tableLayout: "fixed" }}
          scrollHeight="100%"
          emptyMessageTitle="Nada por aquí. Todavía..."
          emptyMessageContent="Selecciona un cliente para ver sus tareas"
          virtualScrollerOptions={virtualScrollerOptions}
          removableSort
          sortField={sortConfig.sortField}
          sortOrder={sortConfig.sortOrder}
          onSort={(e) => setSortConfig(e)}
        >
          {ClientColumnContent({
            clientFilterTemplate: clientFilterTemplateWrapper,
            teamMemberFilterTemplate,
            moduleFilterTemplate: moduleFilterTemplateWrapper,
            categoryFilterTemplate: categoryFilterTemplateWrapper,
            taskNameFilterTemplate,
            cm,
            setSelectedTask,
            isHoldingCtrlDown,
            onLeftClickTeamMemberName: (teamMemberCode) =>
              onLeftClickFilter(teamMemberFilterOptionsRef, teamMemberCode),
            onLeftClickModuleName: (moduleId) =>
              onLeftClickFilter(moduleFilterOptionsRef, moduleId),
            onLeftClickCategoryName: (categoryId) =>
              onLeftClickFilter(categoryFilterOptionsRef, categoryId),
            onLeftClickProjectName: (projectName) =>
              onLeftClickFilter(clientFilterOptionsRef, projectName),
            onLeftClickClientCode: (clientCode) => {
              setLazyLoading(true)
              const currValues = clientFilterOptionsRef.current.value
              const filteredClients = Array.from(
                new Set(
                  tasks
                    .filter((task) => task.clientCode === clientCode)
                    .map((client) => client.projectName)
                )
              )
              if (
                currValues &&
                arraysContainSameStrings(currValues, filteredClients)
              ) {
                clientFilterOptionsRef.current.filterApplyCallback([])
              } else {
                clientFilterOptionsRef.current.filterApplyCallback(
                  filteredClients
                )
              }
            },
            updatingTask,
            hasSpecialCategories: hasSpecialCategories(tasks),
            setLazyLoading,
          })?.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </DataTable>
      </>
    )
  }
)

export default RegisteredClientTaskTable
