import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import PrimaryButton from "../../../../../components/molecules/buttons/PrimaryButton.molecule"
import {
  createClient,
  editClient,
} from "../../../../../services/client.service"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "../../../../../consts/appRoutes.consts"
import useToast from "../../../../../hooks/useToast.hook"
import useReportFiltersContext from "../../../../../hooks/contextConsumers/useReportFiltersContext.hook"

const FormClientFooter = ({ client, projects, error: formError, isCreate }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const { setErrorMessage } = useToast()
  const { refreshFiltersContext } = useReportFiltersContext()
  useEffect(() => {
    setError(formError)
  }, [formError])
  const handleSubmitClient = async () => {
    setLoading(true)
    setError(null)
    try {
      const service = isCreate ? createClient : editClient
      const hexColor = getComputedStyle(document.documentElement)
        .getPropertyValue(client?.color.match(/--[a-zA-Z0-9-]+/)?.[0])
        .trim()
      if (hexColor) {
        client.color = hexColor
      }
      client.budgetType = parseInt(client.budgetType)
      projects = projects.map((project) => ({
        ...project,
        modules: project?.modules?.map((module) => {
          const id = parseInt(module.id, 10)
          if (isNaN(id)) {
            const { id, ...rest } = module
            return rest
          }
          return { ...module, id }
        }),
      }))
      const response = await service(client, projects)
      if (!response.success) {
        throw new Error(response.message)
      }
      refreshFiltersContext()
      setTimeout(() => {
        navigate(AppRoutes.clientes.index)
      }, 500)
    } catch (error) {
      console.error(error)
      setErrorMessage({ message: error.message || "Hubo un error al guardar." })
    } finally {
      setLoading(false)
    }
  }
  const handleIconLoading = (icon) => (loading ? "spinner pi-spin" : icon)
  return (
    <>
      <div className="flex w-full justify-content-end mt-3">
        <PrimaryButton
          label={isCreate ? "Crear" : "Guardar"}
          icon={`pi pi-${handleIconLoading(isCreate ? "plus" : "check")}`}
          onClick={handleSubmitClient}
          disabled={
            projects?.length === 0 ||
            (error && Object.values(error).some((value) => value)) ||
            loading
          }
        />
      </div>
      {/* {error && <div className='text-red-500'>{error.message || error || 'Hubo un error al crear el cliente'}</div>} */}
    </>
  )
}

export default FormClientFooter

FormClientFooter.propTypes = {
  client: PropTypes.object,
  projects: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isCreate: PropTypes.bool,
}
