import {
  isANumberGreaterThanZero,
  isNotEmpty,
} from "../../utils/validations.utils"
import { CLIENT_BUDGET_TYPES } from "./clients.consts"

export const DEBIT_PHASE_PERCENTAGES_RANGES = {
  POR_INICIAR: 0,
  EN_CURSO: 1,
  REDUCIDO: 50,
  POR_TERMINAR: 85,
  AGOTADO: 100,
}

export const DEBIT_PHASE_RANGE_NAMES = {
  AGOTADO: "Agotado",
  POR_TERMINAR: "Por terminar",
  REDUCIDO: "Reducido",
  EN_CURSO: "En curso",
  POR_INICIAR: "Por iniciar",
  SOBREGIRADO: "Sobregirado",
}

export const CREDIT_PHASE_PERCENTAGES_RANGES = {
  POR_INICIAR: 0,
  EN_CURSO: 1,
  POR_TERMINAR: 85,
  AGOTADO: 100,
}

export const CREDIT_PHASE_RANGE_NAMES = {
  AGOTADO: "Agotado",
  POR_TERMINAR: "Por terminar",
  EN_CURSO: "En curso",
  POR_INICIAR: "Por iniciar",
  RENOVADO: "Renovado",
  SOBREGIRADO: "Límite excedido",
}

export const BUDGET_PHASE_PERCENTAGES_RANGES = {
  [CLIENT_BUDGET_TYPES.DEBIT]: DEBIT_PHASE_PERCENTAGES_RANGES,
  [CLIENT_BUDGET_TYPES.CREDIT]: CREDIT_PHASE_PERCENTAGES_RANGES,
}

export const BUDGET_PHASE_RANGE_NAMES = {
  [CLIENT_BUDGET_TYPES.DEBIT]: DEBIT_PHASE_RANGE_NAMES,
  [CLIENT_BUDGET_TYPES.CREDIT]: CREDIT_PHASE_RANGE_NAMES,
}

export const BUDGET_PHASE_LABELS = {
  [CLIENT_BUDGET_TYPES.DEBIT]: {
    SOBREGIRADO: "¡Ups! El presupuesto se ha sobregirado",
    AGOTADO: "Colorín colorado, el presupuesto se ha acabado",
    POR_TERMINAR: "¡Últimas horas! El fin se acerca",
    REDUCIDO: "Tu bolsa de horas cada vez pesa menos",
    EN_CURSO: "Tu bolsa tiene muchas horas... todavía",
    POR_INICIAR: "El reloj está a tu favor: tu bolsa de horas está llena",
  },
  [CLIENT_BUDGET_TYPES.CREDIT]: {
    SOBREGIRADO: "¡Ups! Te has excedido del límite mensual",
    AGOTADO: "Colorín colorado, el presupuesto se ha acabado",
    POR_TERMINAR: "¡Últimas horas! Ten en cuenta el límite asignado",
    EN_CURSO: "El consumo de horas está corriendo",
    POR_INICIAR: "Todavía no has usado tu presupuesto actual",
    RENOVADO: "Presupuesto renovado y lleno de vida",
  },
}

export const BUDGET_REGISTRY_TYPE = {
  AUMENTO: 0,
  REDUCCION: 1,
}

export const ADD_BUDGET_FORM_DATA_TEMPLATE = {
  description: "",
  authorizationDate: "",
  registryType: 0,
  quantity: BUDGET_REGISTRY_TYPE.AUMENTO,
}

export const ADD_BUDGET_FORM_INITIAL_STATE = {
  description: {
    value: "",
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {},
  },
  authorizationDate: {
    value: "",
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {},
  },
  registryType: {
    value: BUDGET_REGISTRY_TYPE.AUMENTO,
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {},
  },
  quantity: {
    value: "",
    errors: [],
    typeValidations: [isANumberGreaterThanZero],
    validationsOptions: {},
  },
}

export const AVERAGE_CONSUMPTION_TYPES = {
  MENSUAL: "Mensual",
  SEMANAL: "Semanal",
}

export const AVERAGE_CONSUMPTION_TYPES_OPTIONS = [
  {
    label: "Mensual",
    value: AVERAGE_CONSUMPTION_TYPES.MENSUAL,
  },
  {
    label: "Semanal",
    value: AVERAGE_CONSUMPTION_TYPES.SEMANAL,
  },
]

export const CREATE_BUDGET_MOVEMENT_ERROR_MESSAGE = {
  title: "Error al agregar registro",
  message:
    "Ocurrió un error al intentar agregar el registro al presupuesto. Por favor, intenta de nuevo.",
}

export const GET_BUDGET_INFORMATION_ERROR_MESSAGE = {
  title: "Error al cargar información",
  message:
    "Ocurrió un error al cargar la información del presupuesto. Por favor, intenta de nuevo.",
}

export const CLIENT_BUDGET_PHASES = {
  INICIAL: "Inicial",
  INTERMEDIA: "Intermedia",
  CIERRE: "Cierre",
  FINALIZADO: "Finalizado",
  SIN_PRESUPUESTO: "Sin presupuesto",
  SOBREGIRADO: "Sobregirado",
}

export const CHANGE_BUDGET_FORM_DATA_TEMPLATE = {
  budgetType: CLIENT_BUDGET_TYPES.DEBIT,
}

export const CHANGE_BUDGET_FORM_INITIAL_STATE = {
  budgetType: {
    value: CLIENT_BUDGET_TYPES.DEBIT,
    errors: [],
    typeValidations: [isNotEmpty],
    validationsOptions: {},
  },
}

export const CHANGE_BUDGET_MOVEMENT_ERROR_MESSAGE = {
  title: "Error al editar configuración",
  message:
    "Ocurrió un error al intentar editar la configuración. Por favor, intenta de nuevo.",
}

export const BUDGET_MOVEMENTS_TYPES = {
  AUMENTO_MANUAL: "Aumento manual",
  DESCENSO_MANUAL: "Descenso manual",
  CONSUMO_AUTOMATICO: "Consumo automatico",
  CONFIGURACION: "Configuracion",
}

export const BUDGET_MOVEMENTS_DESCRIPTIONS = {
  CAMBIO_A_BOLSA_DE_HORAS: "Cambio de tipo de presupuesto a 'Bolsa de horas'",
  CAMBIO_A_CONSUMO_LIMITE: "Cambio de tipo de presupuesto a 'Consumo límite'",
  ASIGNACION_LIMITE_MENSUAL_SUGERIDO: "Asignación de límite mensual sugerido",
  ELIMINACION_LIMITE_MENSUAL_SUGERIDO: "Eliminación de límite mensual sugerido",
}
