import React from "react"
import PropTypes from "prop-types"
import InputNumber from "../atoms/InputNumber.atom"
import { hasAnyErrors } from "../../utils/validations.utils"
import ErrorMessageForm from "../atoms/ErrorMessageForm.atom"
import Label from "../atoms/Label.atom"
import ViewOutputForm from "../atoms/ViewOutputForm.atom"
import Button from "../atoms/Button.atom"
import { KEYBOARD_ACTIONS } from "../../consts/keyBoard.consts"
export default function InputNumberConfirmForm(props) {
  const forId = `input-number-${props.label?.replace(/ /g, "-")}`
  const errorClass = hasAnyErrors(props.error) ? "p-invalid" : ""
  const grouping = props.useGrouping !== undefined ? props.useGrouping : true
  const confirmButtonConfig = {
    className: `bg-success h-full ${props.confirmButtonClassName ? props.confirmButtonClassName : ""}`,
    icon: `pi pi-check ${props.confirmButtonIcon ? props.confirmButtonIcon : ""}`,
    label: null,
    style: {
      marginLeft: "5px",
      width: "40px",
      border: "1px solid var(--surface-shadow)",
    },
  }
  const onChange = (e) => {
    if (!props.numberTime) return props.onChange({ value: e.value })
    const eventValue = e.value
    const key = e.originalEvent.key
    if (key === KEYBOARD_ACTIONS.ARROW_UP) {
      e.originalEvent.preventDefault()
      const roundedValue = Math.round(Number(props.value + 0.25) * 4) / 4
      props.onChange({ value: roundedValue })
    } else if (key === KEYBOARD_ACTIONS.ARROW_DOWN) {
      e.originalEvent.preventDefault()
      const roundedValue = Math.round(Number(props.value - 0.25) * 4) / 4
      props.onChange({ value: roundedValue })
    } else {
      props.onChange({ value: eventValue })
    }
  }
  return (
    <>
      <Label htmlFor={forId}>
        {props.label}
        {props.require ? " *" : ""}
        {props.optional ? " (opcional)" : ""}
      </Label>
      {props.viewOnly ?
        <ViewOutputForm value={props.value} suffix={props.suffix} />
      : <div onBlur={props.onBlur}>
          <InputNumber
            inputId={forId}
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            suffix={props.suffix ? ` ${props.suffix.trim()}` : false}
            useGrouping={grouping}
            max={props.max}
            onChange={onChange}
            min={props.min || 0}
            placeholder={props.placeholder}
            className={`flex-1 custom-inputnumber ${errorClass} ${props.className ? props.className : ""}`}
            minFractionDigits={props.minFractionDigits}
            maxFractionDigits={props.maxFractionDigits}
            showButtons={props.showButtons}
            step={props.step}
            allowEmpty={props.allowEmpty || false}
            onKeyDown={props.onKeyDown}
            autoFocus={props.autoFocus}
          />
          <Button
            onClick={props.onConfirm}
            {...confirmButtonConfig}
            disabled={
              props.value === null ||
              props.value === undefined ||
              hasAnyErrors(props.error) ||
              props.disableConfirmButton
            }
            onMouseDown={(e) => e.preventDefault()}
          />
        </div>
      }
      {hasAnyErrors(props.error) && !props.withoutErrorMessage ?
        <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} />
      : null}
    </>
  )
}

InputNumberConfirmForm.propTypes = {
  value: function ({ value }) {
    if (
      typeof value !== "number" &&
      typeof value !== "string" &&
      value !== null
    ) {
      return new Error(
        "Invalid prop value. Expected a number or null on InputNumberForm component"
      )
    }
  },
  suffix: PropTypes.string,
  minFractionDigits: PropTypes.number,
  maxFractionDigits: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.array,
  min: PropTypes.number,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  numberTime: PropTypes.bool,
}
