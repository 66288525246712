import React, { useEffect, useState } from "react"
import Card from "../../../components/atoms/Card.atom"
import { useSession } from "../../../hooks/auth/useSession.hook"
import { useLocation } from "react-router-dom"
import CardHeaderTemplate from "../components/CardHeaderTemplate"
import PrimaryButton from "../../../components/molecules/buttons/PrimaryButton.molecule"
import ModalFormAddBudget from "../../../components/compounds/ModalFormAddBudget.compound"
import { isBudgetTypeDebit } from "../../../utils/budget.utils"
import BudgetMovementsTable from "../components/BudgetMovementsTable"
import { getBudgetMovementsByClientID } from "../../../services/budget.service"
import useToast from "../../../hooks/useToast.hook"

const BudgetMovements = ({ data: budgetData, refresh, refreshData }) => {
  const [budgetMovements, setBudgetMovements] = useState([])
  const [currentBudget, setCurrentBudget] = useState(0)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state: selectedClient } = useLocation()
  const { user } = useSession()
  const { setErrorMessage } = useToast()

  useEffect(() => {
    getBudgetMovements()
  }, [refresh])

  const getBudgetMovements = async () => {
    setLoading(true)
    try {
      const response = await getBudgetMovementsByClientID(selectedClient.id)
      if (!response.success) {
        throw new Error(response.message)
      }
      const data = response.result
      setBudgetMovements(data)
      setCurrentBudget(
        data.reduce(
          (acc, curr) => {
            if (
              (curr.description.includes("Cambio de tipo de presupuesto") &&
                !curr.movementTypeId) ||
              acc.stopCount
            ) {
              acc.stopCount = true
              return acc
            }

            acc.stopCount = false
            acc.total += Number(curr.quantity)

            return acc
          },
          { total: 0, stopCount: false }
        ).total
      )
    } catch (error) {
      console.log(error)
      setErrorMessage({ message: error.message })
    } finally {
      setLoading(false)
    }
  }

  const HeaderTemplate = () => {
    return (
      <CardHeaderTemplate
        title="Historial de movimientos"
        actions={
          user.isAdmin() &&
          isBudgetTypeDebit(budgetData?.clientBudgetTypeId) && (
            <PrimaryButton
              label="Agregar registro"
              icon="pi pi-plus"
              className="bg-transparent"
              btnType="outlined"
              onClick={() => setOpenModalForm(true)}
            />
          )
        }
        containerStyle={{
          minHeight: "45px",
        }}
      />
    )
  }
  return (
    <Card className={"dashboard-card budget-movements-card shadow-2 min-h-0"}>
      <div className="flex flex-column gap-3 h-full">
        <HeaderTemplate />
        <BudgetMovementsTable
          data={budgetMovements}
          budgetTypeId={budgetData.clientBudgetTypeId}
          loading={loading}
        />
      </div>
      <ModalFormAddBudget
        visible={openModalForm}
        setVisible={setOpenModalForm}
        selectedClientID={selectedClient.id}
        onCreateCompleted={() => {
          getBudgetMovements()
          refreshData()
        }}
        currentBudget={currentBudget}
        currentConsumedTime={selectedClient.totalBilledTime}
      />
    </Card>
  )
}

export default React.memo(BudgetMovements)
