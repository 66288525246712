import { useState, useEffect, useRef } from "react"

const useWidthTransition = ({
  initialWidth = "fit-content",
  transitionDuration = "0.1s",
  widthIfZero = 10.5,
  widthOffset = 22,
  style = {
    minWidth: "0",
  },
}) => {
  const elementRef = useRef(null)
  const [width, setWidth] = useState(initialWidth)
  useEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        setWidth(
          `${(elementRef.current.offsetWidth || widthIfZero) + widthOffset}px`
        )
      }
    }

    const observer = new ResizeObserver(updateWidth)
    if (elementRef.current) {
      observer.observe(elementRef.current)
      updateWidth()
    }

    return () => observer.disconnect()
  }, [])

  return {
    elementRef,
    style: {
      transition: `width ${transitionDuration} ease`,
      width: width,
      overflow: "hidden",
      ...style,
    },
  }
}

export default useWidthTransition
