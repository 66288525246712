import React, { useEffect, useState } from "react"
import { CustomTreeTable as TreeTable } from "../../../components/atoms/table/CustomTreeTable.atom"
import ColumnGroup from "../../../components/atoms/table/ColumnGroup.atom"
import Row from "../../../components/atoms/table/RowColumnGroup.atom"
import Column from "../../../components/atoms/table/Column.atom"
import OccupationPercentageChip from "../../../components/molecules/OccupationPercentageChip.molecule"
import { roundReportData } from "../../../utils/report.utils"
import { getMonthLongNameByNumber } from "../../../utils/date.utils"
import {
  floatToPercentage,
  formatDecimals,
  numberFormatter,
} from "../../../utils/numbers.utils"

const OccupationReportMultipleYearsTable = React.forwardRef(
  ({ reportData: originalData, expandedKeys, setExpandedKeys }, ref) => {
    const reportData = roundReportData(originalData)

    const yearOrMonthData = (node) => {
      if (node?.children && node?.children.length > 0) {
        return node?.data?.year
      } else {
        return getMonthLongNameByNumber(node?.data?.month)
      }
    }

    const teamMemberExpandCondition = (node) => {
      if (node?.children && node?.children.length > 0) {
        return true
      } else {
        return false
      }
    }

    return (
      <>
        <TreeTable
          ref={ref}
          value={reportData.data}
          responsiveLayout="scroll"
          className="week-hours-report-data-table"
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          expanderConditions={(node) => teamMemberExpandCondition(node)}
          scrollable
          scrollHeight="100%"
          frozenExpandedColumn
        >
          <Column
            field="data.distributionSisuTime"
            style={{ minWidth: "90px" }}
            body={(node) => <span>{yearOrMonthData(node)}</span>}
            header={() => <p style={{ marginLeft: -45 }}>Fecha</p>}
            footer={() => <p style={{ marginLeft: -45 }}>Totales</p>}
            frozen
          />
          <Column
            field="data.totalSisuTime"
            body={(node) => (
              <span>{numberFormatter(node?.data?.totalSisuTime)}</span>
            )}
            header="Horas trabajadas"
            footer={numberFormatter(reportData?.totals?.totalSisuTime)}
          />
          <Column
            field="data.totalSisuClientTime"
            body={(node) => (
              <span>{numberFormatter(node?.data?.totalSisuClientTime)}</span>
            )}
            header="Horas internas"
            footer={numberFormatter(reportData?.totals?.totalSisuClientTime)}
          />
          <Column
            field="data.totalBilledTime"
            body={(node) => (
              <span>{numberFormatter(node?.data?.totalBilledTime)}</span>
            )}
            header="Horas facturadas"
            footer={numberFormatter(reportData?.totals?.totalBilledTime)}
          />
          <Column
            field="data.totalUnbilledHours"
            body={(node) => (
              <span>{numberFormatter(node?.data?.totalUnbilledHours)}</span>
            )}
            header="Horas perdonadas"
            footer={numberFormatter(reportData?.totals?.totalUnbilledHours)}
          />
          <Column
            body={(node) => (
              <span>
                <OccupationPercentageChip
                  percentageValue={node?.data?.ocupationPercentage}
                />
              </span>
            )}
            header="Porcentaje de ocupación"
            footer={
              <OccupationPercentageChip
                percentageValue={reportData?.totals?.ocupationPercentage}
              />
            }
          />
        </TreeTable>
      </>
    )
  }
)

export default OccupationReportMultipleYearsTable
