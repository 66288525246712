/* eslint-disable */
import React, { createContext, useEffect, useState } from "react"
import { DateTime } from "luxon"
import usePrevious from "../../../hooks/usePrevious"

export const TimeRegisterContext = createContext()
export default function TimeRegisterContextProvider({ children }) {
  const [refreshProjects, setRefreshProjects] = useState(false)
  const [doFetchTasks, setDoFetchTasks] = useState(false)
  const [isDayFinished, setIsDayFinished] = useState(false)
  const [isDayEditable, setIsDayEditable] = useState(true)
  const [isEditingSisuTime, setIsEditingSisuTime] = useState(false)
  const [isEditingBilledTime, setIsEditingBilledTime] = useState(false)
  const [showMissingDayDialog, setShowMissingDayDialog] = useState(false)
  const [showedMissingDayDialog, setShowedMissingDayDialog] = useState(false)
  const [showUncompleteDayDialog, setShowUncompleteDayDialog] = useState(false)
  const [showedUncompleteDayDialog, setShowedUncompleteDayDialog] =
    useState(false)
  const [selectedDate, setSelectedDate] = useState(
    DateTime.local().set({ hour: 12 }).toJSDate()
  )
  const previousSelectedDate = usePrevious(selectedDate)
  const [uncompleteDay, setUncompleteDay] = useState(null)
  const [lastWorkDay, setLastWorkDay] = useState(null)
  const [workDayToDisplay, setWorkDayToDisplay] = useState(null)
  const [hasTasks, setHasTasks] = useState(false)
  const [canSaveChanges, setCanSaveChanges] = useState(false)
  const [triggerFinishDay, setTriggerFinishDay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editingMissingDay, setEditingMissingDay] = useState(false)
  const [missingDays, setMissingDays] = useState(undefined)
  const [historyMissingDays, setHistoryMissingDays] = useState(undefined)
  const setRefresh = () => setDoFetchTasks(!doFetchTasks)
  // useEffect(() => {
  //   if (historyMissingDays) {
  //     const auxMissingDays = historyMissingDays.map(day => {
  //       if (missingDays && missingDays.some(missingDay => missingDay.date === day.date)) {
  //         return { ...day, ignored: 1 }
  //       }
  //     })
  //     console.warn('auxMissingDays', auxMissingDays)
  //     if (auxMissingDays.length) {
  //       setMissingDays(auxMissingDays)
  //     }
  //   }
  // }, [missingDays])
  return (
    <TimeRegisterContext.Provider
      value={{
        refreshProjects,
        setRefreshProjects,
        doFetchTasks,
        setDoFetchTasks,
        isDayFinished,
        setIsDayFinished,
        isDayEditable,
        setIsDayEditable,
        isEditingSisuTime,
        setIsEditingSisuTime,
        isEditingBilledTime,
        setIsEditingBilledTime,
        showUncompleteDayDialog,
        setShowUncompleteDayDialog,
        showMissingDayDialog,
        setShowMissingDayDialog,
        showedUncompleteDayDialog,
        setShowedUncompleteDayDialog,
        showedMissingDayDialog,
        setShowedMissingDayDialog,
        selectedDate,
        setSelectedDate,
        uncompleteDay,
        setUncompleteDay,
        lastWorkDay,
        setLastWorkDay,
        hasTasks,
        setHasTasks,
        canSaveChanges,
        setCanSaveChanges,
        triggerFinishDay,
        setTriggerFinishDay,
        loading,
        setLoading,
        previousSelectedDate,
        setRefresh,
        editingMissingDay,
        setEditingMissingDay,
        missingDays,
        setMissingDays,
        historyMissingDays,
        setHistoryMissingDays,
        workDayToDisplay,
        setWorkDayToDisplay,
      }}
    >
      {children}
    </TimeRegisterContext.Provider>
  )
}
