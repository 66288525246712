import React from "react"
import PropTypes from "prop-types"

const CardHeaderTemplate = ({
  title,
  actions,
  titleClassName = "line-height-3",
  containerClassName,
  containerStyle = {},
}) => {
  return (
    <div
      className={`flex justify-content-between align-items-center ${containerClassName}`}
      style={containerStyle}
    >
      <span className={`text-3xl font-cairo font-semibold ${titleClassName}`}>
        {title}
      </span>
      {actions}
    </div>
  )
}

export default CardHeaderTemplate

CardHeaderTemplate.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  titleClassName: PropTypes.string,
  containerClassName: PropTypes.string,
}
