import React, { useState } from 'react'
import FormHeaderCreateClient from './controllers/FormHeaderClient.controller'
import FormClient from './controllers/clientForm/FormClient.controller'
import { FORM_CLIENT_MODE } from '../../consts/modules/clients.consts'

const EditClient = () => {
  const [selectedClient, setSelectedClient] = useState()
  return (
    <>
      <FormHeaderCreateClient
        isCreate={false}
        selectedClient={selectedClient}
      />
      <FormClient
        mode={FORM_CLIENT_MODE.EDIT}
        setSelectedClient={setSelectedClient}
      />
    </>
  )
}

export default EditClient
