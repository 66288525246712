import React, { useEffect, useState } from "react"
import {
  generateTotalHoursPerProjectReport,
  generateTotalHoursPerProjectForTeamMemberReport,
} from "../../../services/report.service"
import useReportFiltersContext from "../../../hooks/contextConsumers/useReportFiltersContext.hook"
import { useSession } from "../../../hooks/auth/useSession.hook"
import { Rol } from "../../../consts/roles.consts"
import ProgressSpinner from "../../../components/atoms/misc/ProgressSpinner.atom"
import HoursByProjectReportDataTable from "./tables/HoursByProjectReportDataTable/HoursByProjectReportDataTable"
import { DateTime } from "luxon"
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import ResetButton from "./components/ResetButton"
import UnifiedDatePicker from "../../../components/compounds/UnifiedDatePicker.compound"
import { UNIFIEDDATE_VALUES } from "../../../consts/CompoundRangeDatePicker"

const HoursByProjectReport = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [reportData, setReportData] = useState([])
  const {
    projectsPerformanceDateRange,
    setProjectsPerformanceDateRange,
    projectsPerformanceExpandedKeys,
    setProjectsPerformanceExpandedKeys,
    projectsPerformancePeriod,
    setProjectsPerformancePeriod,
  } = useReportFiltersContext()
  const auth = useSession()

  useEffect(() => {
    gettingReport()
  }, [projectsPerformanceDateRange, projectsPerformancePeriod])

  const gettingReport = async () => {
    let response
    setError(null)
    setIsLoading(true)
    const [startDate, endDate] = projectsPerformanceDateRange
    if (
      auth.user?.projectRole === Rol.ADMIN ||
      auth.user?.projectRole === Rol.ADMIN_WITHOUT_REPORT
    ) {
      response = await generateTotalHoursPerProjectReport(startDate, endDate)
    } else if (
      auth.user?.projectRole === Rol.TEAM_LEADER ||
      auth.user?.projectRole === Rol.TEAM_MEMBER
    ) {
      response = await generateTotalHoursPerProjectForTeamMemberReport(
        startDate,
        endDate
      )
    }
    if (response.success) {
      const newReportData = []
      response.result.data.forEach((project) => {
        newReportData.push({
          startDate,
          endDate,
          ...project,
        })
      })

      setReportData({
        data: newReportData,
        totals: response.result.totals,
      })
    } else {
      setError(response.message)
    }
    setIsLoading(false)
  }

  const collapseAll = () => {
    setProjectsPerformanceExpandedKeys({})
  }

  const expandAll = () => {
    const newExpandedKeys = {}
    reportData.data.forEach((node) => {
      if (node.children?.length > 1) {
        newExpandedKeys[node.key] = true
      }
    })
    setProjectsPerformanceExpandedKeys(newExpandedKeys)
  }

  return (
    <>
      <div
        style={{
          marginBottom: 17.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="flex flex-1 gap-3 align-items-center">
          <UnifiedDatePicker
            dateRange={projectsPerformanceDateRange}
            setDateRange={setProjectsPerformanceDateRange}
            period={projectsPerformancePeriod}
            setPeriod={setProjectsPerformancePeriod}
          />
          <span
            className={`${reportData?.data?.length ? "cursor-pointer" : "cursor-none opacity-80"} flex align-items-center w-fit h-fit white-space-nowrap`}
            style={{
              color: "var(--text-color)",
            }}
            onClick={() => {
              if (Object.keys(projectsPerformanceExpandedKeys).length === 0) {
                expandAll()
              } else {
                collapseAll()
              }
            }}
          >
            {Object.keys(projectsPerformanceExpandedKeys).length === 0 ?
              <UnfoldMoreIcon />
            : <UnfoldLessIcon />}
            {Object.keys(projectsPerformanceExpandedKeys).length === 0 ?
              "Expandir todo"
            : "Contraer todo"}
          </span>
        </div>
        <ResetButton
          onClick={() => {
            ref.current?.reset()
            setProjectsPerformanceDateRange([
              DateTime.local(),
              DateTime.local(),
            ])
            setProjectsPerformancePeriod(UNIFIEDDATE_VALUES.SINGLEDAY)
          }}
        />
      </div>
      {isLoading ?
        <div className="w-full h-20rem flex align-items-center justify-content-center">
          <ProgressSpinner />
        </div>
      : error ?
        <div>Error: {error}</div>
      : <HoursByProjectReportDataTable
          ref={ref}
          reportData={reportData}
          expandedKeys={projectsPerformanceExpandedKeys}
          setExpandedKeys={setProjectsPerformanceExpandedKeys}
          expandAll={expandAll}
          collapseAll={collapseAll}
        />
      }
    </>
  )
})

export default HoursByProjectReport
