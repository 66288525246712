import React from "react"
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded"
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded"
import Tooltip from "../../../components/atoms/Tooltip.atom"

const TendencyIconWithTooltip = ({
  reference,
  value,
  labelIfMore,
  labelIfLess,
  tooltipPosition,
}) => {
  reference = parseFloat(reference)
  value = parseFloat(value)
  if (!reference || !value) return null
  if (reference === value) return null
  const id = Math.floor(Math.random() * 1000)
  const defaultPosition = "bottom"
  const TendencyIcon =
    value > reference ? TrendingUpRoundedIcon : TrendingDownRoundedIcon
  const tooltipContent = value > reference ? labelIfMore : labelIfLess
  return (
    <>
      <TendencyIcon
        className={`icon-tooltip-${id} clr-danger`}
        data-pr-tooltip={tooltipContent}
        style={{ fontSize: "24px" }}
      />
      <Tooltip
        target={`.icon-tooltip-${id}`}
        position={tooltipPosition || defaultPosition}
      />
    </>
  )
}

export default TendencyIconWithTooltip
