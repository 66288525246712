import React from "react"
import TimeRegister from "../../views/timeRegister/TimeRegister.view"
import { AppRoutes } from "../../consts/appRoutes.consts"
import Clients from "../../views/clients/Clients.view"
import CreateClient from "../../views/clients/CreateClient.view"
import EditClient from "../../views/clients/EditClient.view"
import Home from "../../views/home/Home.view"
import ChangeLog from "../../views/changeLog/ChangeLog.view"
import GeneralReport from "../../views/generalReport/GeneralReport.view"
import IndividualReport from "../../views/individualReport/IndividualReport.view"
import ClientBudget from "../../views/clientBudget/ClientBudget.view"
import PendingTasks from "../../views/pendingTasks/PendingTasks.view"
import { getRouteIfAllowedEnvironment } from "../../utils/routes.utils"
import { ENVIRONMENTS } from "../../consts/enviroments.consts"

export const TEAM_LEADER_ROUTES = [
  {
    path: AppRoutes.home.index,
    element: <Home />,
  },
  {
    path: AppRoutes.registroTiempos.index,
    element: <TimeRegister />,
  },
  {
    path: AppRoutes.clientes.index,
    element: <Clients />,
  },
  {
    path: AppRoutes.clientes.crear,
    element: <CreateClient />,
  },
  {
    path: AppRoutes.clientes.presupuestoCliente,
    element: <ClientBudget />,
  },
  {
    path: AppRoutes.reporteGeneral.index,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteGeneral.horasSemana,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteGeneral.horasProyecto,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteGeneral.horasCliente,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteGeneral.consumosEquipo,
    element: <GeneralReport />,
  },
  {
    path: AppRoutes.reporteIndividual.index,
    element: <IndividualReport />,
  },
  {
    path: AppRoutes.reporteIndividual.redirect,
    element: <IndividualReport />,
  },
  {
    path: AppRoutes.clientes.editar,
    element: <EditClient />,
  },
  {
    path: AppRoutes.changeLog.index,
    element: <ChangeLog />,
  },
  ...getRouteIfAllowedEnvironment(
    {
      path: AppRoutes.listaPendientes.index,
      element: <PendingTasks />,
    },
    [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.TEST]
  ),
]
