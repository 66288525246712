/* eslint-disable */
import React, { useState, useEffect } from "react"
import ClientProjectChip from "../../../../../components/molecules/ClientProjectChip.molecule"
import IconWithTooltip from "../../../../../components/molecules/IconWithTooltip.molecule"
import { WarningBadge } from "../../../../../components/molecules/CellReportBadges.molecule"
import { TimeDifferenceBadge } from "../../components/CellReportBadges"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"
import { useSession } from "../../../../../hooks/auth/useSession.hook"

/**
 * @returns {import('primereact/column').ColumnProps[]}
 */
const ClientColumnContent = ({
  clientFilterTemplate,
  teamMemberFilterTemplate,
  moduleFilterTemplate,
  categoryFilterTemplate,
  taskNameFilterTemplate,
  cm,
  setSelectedTask,
  isHoldingCtrlDown,
  onLeftClickTeamMemberName,
  onLeftClickModuleName,
  onLeftClickCategoryName,
  onLeftClickProjectName,
  onLeftClickClientCode,
  updatingTask,
  hasSpecialCategories,
  setLazyLoading,
}) => {
  const [, setIsHoldingCtrl] = useState(false)
  const { user } = useSession()
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === KEYBOARD_ACTIONS.CONTROL) {
        setIsHoldingCtrl(true)
      }
    }

    const onKeyUp = (e) => {
      if (e.key === KEYBOARD_ACTIONS.CONTROL) {
        setIsHoldingCtrl(false)
      }
    }

    document.addEventListener("keydown", onKeyDown)
    document.addEventListener("keyup", onKeyUp)

    return () => {
      document.removeEventListener("keydown", onKeyDown)
      document.removeEventListener("keyup", onKeyUp)
    }
  }, [])
  const adjustFilterLazyLoading = {
    onFilterApplyClick: () => {
      setLazyLoading(true)
    },
    onFilterClear: () => {
      setLazyLoading(true)
    },
  }
  const categoryColumn = (exportable = false, hidden = false) => ({
    field: "categoryTitle",
    header: "Categoría",
    colSpan: 1,
    className: "white-space-nowrap overflow-hidden text-overflow-ellipsis",
    style: {
      minWidth: "180px",
      flex: "1 0 180px",
      padding: "0.5rem",
      overflow: "hidden",
    },
    filter: true,
    filterMatchMode: "in",
    filterField: hasSpecialCategories ? "specialCategoryId" : "categoryId",
    filterElement: categoryFilterTemplate,
    showFilterMenuOptions: false,
    showFilterMatchModes: false,
    exportable,
    hidden,
    body: (rowData) => (
      <div
        className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
          isHoldingCtrlDown ? "ctrl-selectable" : ""
        }`}
        onClick={() => {
          if (isHoldingCtrlDown) {
            onLeftClickCategoryName(
              rowData[hasSpecialCategories ? "specialCategoryId" : "categoryId"]
            )
          }
        }}
        title={rowData?.specialCategoryTitle || rowData?.categoryTitle}
      >
        {rowData?.specialCategoryTitle || rowData?.categoryTitle}
      </div>
    ),
    ...adjustFilterLazyLoading,
  })

  const taskColumn = (exportable = false, hidden = false) => ({
    body: (rowData) => {
      return (
        <div className="w-full flex justify-content-between align-items-center gap-2">
          <span
            className="overflow-hidden white-space-nowrap text-overflow-ellipsis"
            title={rowData?.task}
          >
            {rowData?.task}
          </span>
          {rowData.comment ?
            <IconWithTooltip
              tooltipValue={rowData.comment}
              iconClassName="pi pi-comment"
            />
          : null}
        </div>
      )
    },
    field: "task",
    header: "Tarea",
    style: { minWidth: "360px", flex: "1 0 360px", padding: "0.5rem" },
    filter: true,
    filterMatchMode: "contains",
    filterField: "task",
    showFilterMenuOptions: false,
    filterElement: taskNameFilterTemplate,
    exportable,
    hidden,
    ...adjustFilterLazyLoading,
  })

  const moduleColumn = (exportable = false, hidden = false) => ({
    field: "module",
    header: "Módulo",
    colSpan: 1,
    style: {
      minWidth: "180px",
      maxWidth: "unset",
      padding: "0.5rem",
      overflow: "hidden",
    },
    filter: true,
    filterMatchMode: "in",
    filterElement: moduleFilterTemplate,
    showFilterMenuOptions: false,
    showFilterMatchModes: false,
    filterField: "moduleId",
    exportable,
    hidden,
    body: (rowData) => (
      <div
        className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
          isHoldingCtrlDown ? "ctrl-selectable" : ""
        }`}
        onClick={() => {
          if (isHoldingCtrlDown) {
            onLeftClickModuleName(rowData.moduleId)
          }
        }}
        title={rowData?.module}
      >
        {rowData?.module}
      </div>
    ),
    ...adjustFilterLazyLoading,
  })

  return [
    {
      header: "Fecha",
      field: "date",
      hidden: true,
    },
    {
      header: "Cliente",
      field: "clientName",
      hidden: true,
    },
    {
      body: (rowData) => {
        return (
          <div className="flex gap-2 align-items-center project-chip-td">
            <ClientProjectChip
              clientCode={rowData.clientCode}
              projectCode={rowData.projectCode}
              className={`${isHoldingCtrlDown ? "ctrl-selectable-badge" : ""}`}
              onClientClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickClientCode(rowData.clientCode)
                }
              }}
              onProjectClick={() => {
                if (isHoldingCtrlDown) {
                  onLeftClickProjectName(rowData.projectName)
                }
              }}
            />
          </div>
        )
      },
      field: "projectCode",
      header: "Proyecto",
      colSpan: 1,
      style: {
        minWidth: "102px",
        maxWidth: "102px",
        flex: "0 0 102px",
        padding: "0.5rem",
      },
      filter: true,
      filterElement: clientFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterMatchMode: "in",
      filterField: "projectName",
      ...adjustFilterLazyLoading,
    },
    moduleColumn(true, true),
    {
      header: "Grupo de categoría",
      field: "category",
      hidden: true,
    },
    categoryColumn(true, true),
    ...(hasSpecialCategories ?
      [
        {
          header: "Categoría Especial",
          field: "specialCategoryTitle",
          hidden: true,
        },
      ]
    : []),
    {
      field: "teamMemberNameTag",
      header: "Integrante",
      colSpan: 1,
      filter: true,
      filterMatchMode: "in",
      filterElement: teamMemberFilterTemplate,
      showFilterMenuOptions: false,
      showFilterMatchModes: false,
      filterField: "teamMemberNameTag",
      style: { minWidth: "180px", flex: "1 0 180px", padding: "0.5rem" },
      body: (rowData) => (
        <div
          className={`overflow-hidden white-space-nowrap text-overflow-ellipsis ${
            isHoldingCtrlDown ? "ctrl-selectable" : ""
          }`}
          onClick={() => {
            if (isHoldingCtrlDown) {
              onLeftClickTeamMemberName(rowData.teamMemberNameTag)
            }
          }}
          title={`${rowData.teamMemberNameTag} - ${rowData.teamMemberName} ${rowData.teamMemberLastName}`}
        >
          {`${rowData.teamMemberNameTag} - ${rowData.teamMemberName} ${rowData.teamMemberLastName}`}
        </div>
      ),
      ...adjustFilterLazyLoading,
    },
    taskColumn(true, true),
    moduleColumn(),
    categoryColumn(),
    taskColumn(),
    {
      field: "sisuTime",
      header: "Horas trabajadas",
      colSpan: 1,
      style: {
        minWidth: "115px",
        maxWidth: "115px",
        flex: "0 0 115px",
        padding: "0.5rem",
      },
      body: (rowData) => {
        return (
          <div className="flex gap-1">
            <span>{rowData?.sisuTime}</span>
            {rowData?.sisuTimeModifiedAfterFinished ?
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            : null}
          </div>
        )
      },
      sortable: true,
      sortField: "sisuTime",
    },
    {
      field: "billedTime",
      header: "Horas facturadas",
      sortable: true,
      sortField: "billedTime",
      colSpan: 1,
      body: (rowData) => {
        return (
          <div className="flex gap-1">
            <span
              className={`${rowData?.clientIsBillable ? "" : "opacity-30"}`}
            >
              {rowData?.clientIsBillable ? rowData?.billedTime : "N/A"}
            </span>
            {rowData?.billedTimeModifiedAfterFinished ?
              <WarningBadge
                iconClassName="pi pi-history"
                tooltipValue="Los tiempos registrados fueron editados después de finalizar el día."
              />
            : null}
          </div>
        )
      },
      style: {
        minWidth: "115px",
        maxWidth: "115px",
        flex: "0 0 115px",
        padding: "0.5rem",
      },
    },
    {
      header: "Diferencia",
      field: "timeDifference",
      sortable: true,
      style: {
        minWidth: "115px",
        maxWidth: "115px",
        flex: "0 0 115px",
        paddingLeft: "7px",
      },
      body: (rowData) => (
        <div className="flex gap-1 w-4rem justify-content-between">
          <span className={`${rowData?.clientIsBillable ? "" : "opacity-30"}`}>
            {rowData?.clientIsBillable ? rowData?.timeDifference : "N/A"}
          </span>
          {rowData.billedTime !== rowData.sisuTime && (
            <TimeDifferenceBadge
              value={rowData.timeDifference}
              minTreshold={0}
              maxTreshold={0}
              referenceValue={0}
              tooltip={
                rowData.timeDifferenceComment ?
                  `Motivo: ${rowData.timeDifferenceComment}`
                : "Sin comentarios"
              }
            />
          )}
        </div>
      ),
    },
    {
      body: (item) => {
        return (
          item.taskId === updatingTask?.taskId ?
            <IconWithTooltip
              iconClassName="pi pi-spin pi-spinner"
              tooltipValue="Guardando cambios"
            />
          : user?.isAdmin() ?
            <i
              className="pi pi-ellipsis-v text-xl cursor-pointer"
              onClick={(event) => {
                cm?.current.show(event)
                setSelectedTask(item)
              }}
            />
          : null
        )
      },
      colSpan: 1,
      style: {
        minWidth: "30px",
        flex: "0 0 30px",
        padding: "0.5rem",
        display: "flex",
        justifyContent: "center",
      },
    },
  ]
}

export default ClientColumnContent
