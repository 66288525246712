import React from 'react'
import Chip from '../atoms/misc/Chip.atom'
import useClientColorsContext from '../../hooks/contextConsumers/useClientColorsContext.hook'
import { backwardCompatibleClientProjectLabel } from '../../utils/strings.utils'
/**
 * @typedef {JSX.IntrinsicAttributes & JSX.IntrinsicElements['div']} JSXDivElement
 */

/**
 * @typedef {import("primereact/chip").ChipProps & JSXDivElement & {
*   code: string,
*   labelClassname?: string,
*   className?: string
* }} ClientChipProps
*/

/**
* @param {ClientChipProps} props
* @returns {JSX.Element}
*/
const ProjectChip = ({
  clientCode,
  projectCode,
  customColor,
  labelClassname = 'my-0',
  className = '',
  ...props
}) => {
  if (!clientCode) return null
  const { colors } = useClientColorsContext()
  const client = colors.find(client => client.code === clientCode) || {}
  if (!client || !Object.keys(client).length || customColor) client.color = customColor || 'lightgray'
  return (
    <Chip
      className={`project-chip text-white ${className}`}
      template={
        <>
          <div className='bg-color'></div>
          <span className={`${labelClassname} font-bold`} style={{ fontSize: '12px', color: client?.color }}>
            {backwardCompatibleClientProjectLabel(projectCode)}
          </span>
        </>
      }
      {...props}
      style={{
        border: `1px solid ${client?.color}`,
        ...props.style
      }}
    />
  )
}

export default ProjectChip
