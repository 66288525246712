/* eslint-disable */
import React, { useState } from "react"
import { classNames } from "primereact/utils"
import { getAppMenuByRole } from "../../utils/menu.utils"
import { AppSubmenu } from "./AppSubmenu"
import { useSession } from "../../hooks/auth/useSession.hook"
import { BREAKPOINTS } from "../../consts/responsiveBreakpoints.consts"
import useSidebarContext from "../../hooks/contextConsumers/useSidebarContext.hook"
import useScreenDimensionsContext from "../../hooks/contextConsumers/useScreenDimensionsContext.hook"
import AppMenuUserPreview from "./AppMenuUserPreview"
import AikaLogoDark from "../../design/assests/images/aika-logo-dark.svg"
import ReleaseVersion from "../../components/molecules/ReleaseVersion.molecule"
import ThemeMenu from "./ThemeMenu"

export default function AppMenu(props) {
  const OPEN_CODE = 1
  const CLOSE_CODE = 2
  const [menuActive, setMenuActive] = useState(true)
  const {
    sidebarActive,
    setSidebarActive,
    bockOpenTheNormalMenu,
    setBockOpenTheNormalMenu,
  } = useSidebarContext()
  const { user } = useSession()
  const { screenWidth } = useScreenDimensionsContext()
  const model = getAppMenuByRole(user.projectRole)
  function onMenuItemClick(event) {
    setSidebarActive(true)
    setMenuActive(true)
  }

  const handleOpenAndCloseNormalMenu = (value) => {
    if (!sidebarActive && value === OPEN_CODE) {
      if (bockOpenTheNormalMenu) return
      if (sidebarActive) return
      setSidebarActive(true)
      setBockOpenTheNormalMenu(true)
      setTimeout(() => {
        setBockOpenTheNormalMenu(false)
      }, 200)
    }
    if (sidebarActive && value === CLOSE_CODE) {
      if (!sidebarActive) return
      if (bockOpenTheNormalMenu) return
      setTimeout(() => {
        setSidebarActive(true)
        setBockOpenTheNormalMenu(true)
        setTimeout(() => {
          setBockOpenTheNormalMenu(false)
        }, 200)
      }, 200)
    }
  }
  handleOpenAndCloseNormalMenu(OPEN_CODE)

  const LogoTemplate = () => (
    <div className="sidebar-logo-container flex flex-column gap-2 flex justify-content-center align-items-center">
      <img
        src={AikaLogoDark}
        alt="Aika"
        height={42}
        width={80}
        className="block"
      />
      <ReleaseVersion className="text-center" />
    </div>
  )
  return (
    <div
      className={classNames("layout-menu-wrapper", {
        "layout-sidebar-active":
          sidebarActive || screenWidth < BREAKPOINTS.TABLET_BREAKPOINT,
      })}
    >
      <LogoTemplate />
      <div className="layout-menu-container flex flex-column justify-content-between">
        <div>
          <AppSubmenu
            items={model}
            className="layout-menu"
            menuMode={props.menuMode}
            menuActive={menuActive}
            root
            parentMenuItemActive
            onMenuClick={null}
            onMenuItemClick={onMenuItemClick}
          />
          <ThemeMenu />
        </div>
        <AppMenuUserPreview />
      </div>
    </div>
  )
}
