import React, { useEffect, useRef, useState } from "react"
import { validateFieldValue } from "../../../utils/validations.utils"
import { OverlayPanel } from "../../../components/atoms/OverlayPanel.atom"
import { KEYBOARD_ACTIONS } from "../../../consts/keyBoard.consts"
import Divider from "../../../components/atoms/Divider.atom"
import SecondaryButton from "../../../components/molecules/buttons/SecondaryButton.molecule"
import PrimaryButton from "../../../components/molecules/buttons/PrimaryButton.molecule"
import InputTime from "../../../components/molecules/InputTime.molecule"
import { formatHours } from "../../../utils/time.utils"
import InputPlaceholderChipTemplate from "../../../components/molecules/InputPlaceholderChipTemplate.molecule"
import useWidthTransition from "../../../hooks/useWidthTransition.hook"

const InputTimeOverlay = ({
  error,
  placeholderIcon = "pi pi-stopwatch",
  label = "Estimación",
  currentValue,
  onSubmit,
  inputProps,
  readOnly = false,
}) => {
  const [data, setData] = useState({
    time: currentValue || "",
  })
  const [isEditing, setIsEditing] = useState(false)
  const timeModalRef = useRef(null)
  const { elementRef: chipRef, style: chipStyle } = useWidthTransition({
    style: { minWidth: "33px" },
  })
  const initialFieldsState = {
    time: {
      value: currentValue || "",
      errors: [],
      typeValidations: [],
      validationsOptions: {},
    },
  }
  const [validations, setValidations] = useState(initialFieldsState)
  useEffect(() => {
    if (currentValue === undefined) {
      setData({ time: "" })
      setValidations(initialFieldsState)
    }
  }, [currentValue])

  function handleChange({ target: { name, value } }) {
    setData((prevData) => ({ ...prevData, [name]: value }))
    setValidations(validateFieldValue(validations, name, value))
  }

  function cleanUp() {
    setData({ time: currentValue || "" })
    setValidations(initialFieldsState)
  }

  const accept = (e) => {
    onSubmit(data.time)
    timeModalRef?.current?.hide(e)
  }

  const reject = (e) => {
    setIsEditing(false)
    if (e) {
      onSubmit(currentValue)
    }
    cleanUp()
    timeModalRef?.current?.hide(e)
  }
  const { onKeyDown: onInputKeyDown, ...restInputProps } = inputProps || {}
  return (
    <>
      <OverlayPanel className="w-17rem" ref={timeModalRef} onHide={reject}>
        <div className="time-modal flex flex-column">
          <InputTime
            label={label}
            autoFocus
            value={data.time || 0}
            onChange={({ value }) => {
              handleChange({ target: { name: "time", value } })
            }}
            onKeyDown={(e) => {
              if (e.key === KEYBOARD_ACTIONS.ENTER) {
                accept(e)
              }
            }}
            className="w-full"
            step={0.25}
            format={true}
            showButtons
            min={0}
            placeholder={null}
            minFractionDigits={0}
            maxFractionDigits={2}
            error={validations.time.errors?.length}
          />
          <Divider />
          <div className="w-full flex justify-content-end gap-3">
            <SecondaryButton label="Cancelar" type="text" onClick={reject} />
            <PrimaryButton
              label="Aceptar"
              onClick={accept}
              className="accept-project-button"
            />
          </div>
        </div>
      </OverlayPanel>
      {((readOnly && currentValue) || !readOnly) && (
        <InputPlaceholderChipTemplate
          readOnly={readOnly}
          error={error}
          isEditing={isEditing || currentValue}
          chipClassName={`time-button ${isEditing || currentValue ? "p-chip-highlight" : ""}`}
          labelTemplate={
            <span
              ref={chipRef}
              className="p-chip-text text-overflow-ellipsis white-space-nowrap overflow-hidden max-w-12rem"
            >
              <span className="flex gap-2 align-items-center">
                {error ?
                  <i className="pi pi-exclamation-triangle text-xs line-height-2 m-auto"></i>
                : <i
                    className={`pi ${placeholderIcon} text-xs line-height-2 m-auto`}
                  ></i>
                }
                <span style={{ fontSize: "12px" }}>
                  {currentValue ?
                    formatHours(currentValue || 0) || "00:00"
                  : label}
                </span>
              </span>
            </span>
          }
          onClick={(event) => {
            if (readOnly) return
            setIsEditing(!timeModalRef?.current.getElement())
            timeModalRef?.current?.toggle(event)
          }}
          style={chipStyle}
        />
      )}
    </>
  )
}

export default InputTimeOverlay
