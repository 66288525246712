import React from 'react'
import PropTypes from 'prop-types'

const ColumnControls = ({
  onMoveAllToLeft,
  onMoveAllToRight,
  onMoveToRight,
  onMoveToLeft,
  elements,
  selectedModules = []
}) => {
  const canMoveAllToLeft = elements.some((element) => element?.active === 1)
  const canMoveAllToRight = elements.some((element) => element?.active === 0)
  const canMoveToRight = selectedModules.some((module) => module?.active === 1)
  const canMoveToLeft = selectedModules.some((module) => module?.active === 0)
  return (
    <div className="p-picklist-buttons p-picklist-transfer-buttons flex gap-2">
      <button type="button" className={`p-button p-component p-button-icon-only ${canMoveToRight ? '' : 'p-disabled'}`} disabled="" onClick={onMoveToRight}>
        <span className="p-button-icon p-c pi pi-angle-right"></span>
        <span className="p-button-label p-c">&nbsp;</span>
        <span role="presentation" className="p-ink"></span>
      </button>
      <button type="button" className={`p-button p-component p-button-icon-only ${canMoveAllToLeft ? '' : 'p-disabled'}`} onClick={onMoveAllToRight}>
        <span className="p-button-icon p-c pi pi-angle-double-right"></span>
        <span className="p-button-label p-c">&nbsp;</span>
        <span role="presentation" className="p-ink"></span>
      </button>
      <button type="button" className={`p-button p-component p-button-icon-only ${canMoveToLeft ? '' : 'p-disabled'}`} disabled="" onClick={onMoveToLeft}>
        <span className="p-button-icon p-c pi pi-angle-left"></span>
        <span className="p-button-label p-c">&nbsp;</span>
        <span role="presentation" className="p-ink"></span>
      </button>
      <button type="button" className={`p-button p-component p-button-icon-only ${canMoveAllToRight ? '' : 'p-disabled'}`} disabled="" onClick={onMoveAllToLeft}>
        <span className="p-button-icon p-c pi pi-angle-double-left"></span>
        <span className="p-button-label p-c">&nbsp;</span>
        <span role="presentation" className="p-ink"></span>
      </button>
    </div>
  )
}

export default ColumnControls

ColumnControls.propTypes = {
  onMoveAllToLeft: PropTypes.func,
  onMoveAllToRight: PropTypes.func,
  onMoveToRight: PropTypes.func,
  onMoveToLeft: PropTypes.func,
  elements: PropTypes.array,
  selectedModules: PropTypes.array
}
