import React from "react"
import { TieredMenu as TieredMenuPrime } from "primereact/tieredmenu"

/**
 * @param {import("primereact/tieredmenu").TieredMenuProps} props
 * @returns
 */
const TieredMenu = React.forwardRef((props, ref) => {
  return <TieredMenuPrime {...props} ref={ref} />
})

export default TieredMenu
