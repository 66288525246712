import React from "react"
import MyTasksContent from "./MyTasks/MyTasksContent.controller"
import FollowedTasksContent from "./FollowedTasks/FollowedTasksContent.controller"
import { PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES } from "../../../consts/modules/pendingTasks.consts"

const PendingTasksContent = ({ selectedView }) => {
  const routeToSelectedView = (selectedView) => {
    switch (selectedView) {
      case PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES.MY_TASKS:
        return <MyTasksContent />
      case PENDING_TASK_VIEW_SELECT_OPTIONS_MAP_VALUES.FOLLOWED:
        return <FollowedTasksContent />
      default:
        return <MyTasksContent />
    }
  }

  return (
    <div id="pending-tasks-content">{routeToSelectedView(selectedView)}</div>
  )
}

export default PendingTasksContent
