import React, { useState, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import changelogPath from "../../design/documents/CHANGELOG.md"

const ChangeLog = () => {
  const [changeLog, setChangeLog] = useState("")

  useEffect(() => {
    fetch(changelogPath)
      .then((response) => {
        return response.text()
      })
      .then((text) => {
        console.log(text)
        setChangeLog(text)
      })
  }, [])

  return (
    <>
      <ReactMarkdown>{changeLog}</ReactMarkdown>
    </>
  )
}

export default ChangeLog
