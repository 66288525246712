import { useLayoutEffect } from "react"

const useHandleDuplicateGroupsDataTable = (loading, isSort, deps) => {
  useLayoutEffect(() => {
    if (loading) return
    const applyDuplicateStyles = () => {
      requestAnimationFrame(() => {
        const headers = document.querySelectorAll(
          ".p-rowgroup-header td:first-child"
        )
        let prevContent = null
        if (headers.length) {
          headers.forEach((header) => {
            const content = header.textContent.trim()
            if (content === "") {
              header.classList.remove("duplicate")
              return
            }
            if (content === prevContent) {
              header.classList.add("duplicate")
            } else {
              header.classList.remove("duplicate")
            }
            prevContent = content
          })
        }
        const frozenTds = document.querySelectorAll(
          "td:has(.project-chip-td), th:first-child"
        )
        if (frozenTds.length) {
          frozenTds.forEach((i) => i.classList.add("p-frozen-column", "left-0"))
        }
      })
    }

    const observer = new MutationObserver(() => {
      applyDuplicateStyles()
    })

    const table = document.querySelector(".p-datatable")
    if (table) {
      observer.observe(table, { childList: true, subtree: true })
      applyDuplicateStyles()
    }

    return () => observer.disconnect()
  }, [loading, isSort, ...deps])
}

export default useHandleDuplicateGroupsDataTable
