import React, { useState } from "react"
import PropTypes from "prop-types"

const EditableElement = ({ placeholderTemplate, onEditTemplate, onEdit }) => {
  const [editing, setEditing] = useState(false)
  const controlledOnEdit = () => {
    setEditing(true)
    onEdit && onEdit()
  }
  if (editing && onEditTemplate) {
    return onEditTemplate({
      onClose: () => setEditing(false),
      onBlur: () => setEditing(false),
    })
  }
  return (
    <div className="flex align-items-center gap-2">
      <span className="font-semibold text-xl">{placeholderTemplate}</span>
      <i
        className="pi pi-pencil cursor-pointer"
        style={{
          color: "light-dark(var(--primary-color-highlight), var(--clr-info))",
        }}
        onClick={controlledOnEdit}
      />
    </div>
  )
}

export default EditableElement

EditableElement.propTypes = {
  placeholderTemplate: PropTypes.node,
  onEditTemplate: PropTypes.func,
  onEdit: PropTypes.func,
  onBlur: PropTypes.func,
}
