import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { CustomTreeTable as TreeTable } from "../../../components/atoms/table/CustomTreeTable.atom"
import Column from "../../../components/atoms/table/Column.atom"
import { getClients } from "../../../services/client.service"
import ClientChip from "../../../components/molecules/ClientChip.molecule"
import ProjectChip from "../../../components/molecules/ProjectChip.molecule"
import ClientsTableContextMenu from "./components/ClientsTableContextMenu"
import ColumnGroup from "../../../components/atoms/table/ColumnGroup.atom"
import Row from "../../../components/atoms/table/RowColumnGroup.atom"
import ActionModal from "./ActionModal.controller"
import useClientColorsContext from "../../../hooks/contextConsumers/useClientColorsContext.hook"
import Card from "../../../components/atoms/Card.atom"
import { useSession } from "../../../hooks/auth/useSession.hook"
import statusFilterElement from "../../../components/compounds/columnFilters/StatusFilter.compond"
import useToast from "../../../hooks/useToast.hook"
import {
  CLIENT_TABLE_DEFAULT_FILTER,
  CLIENT_TABLE_ROW_ACTIONS,
  CLIENT_TABLE_ROW_TYPE,
} from "../../../consts/modules/clients.consts"
import ReducedBudgetPhase from "../../../components/compounds/budget/ReducedBudgetPhase.compound"

const ClientsTable = () => {
  const [loading, setLoading] = useState(false)
  const [prefilteredClients, setPrefilteredClients] = useState([])
  const [filter, setFilter] = useState(1)
  const [datatableFilter, setDatatableFilter] = useState(
    CLIENT_TABLE_DEFAULT_FILTER
  )
  const [clients, setClients] = useState([])
  const [expandedKeys, setExpandedKeys] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [modalAction, setModalAction] = useState(null)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const { setRefresh } = useClientColorsContext()
  const { user } = useSession()
  const { setErrorMessage } = useToast()

  const navigate = useNavigate()
  const filterApplyCallbackRef = useRef()
  const cmRef = useRef()

  useEffect(() => {
    gettingClients()
    setRefresh((prev) => !prev)
  }, [])

  useEffect(() => {
    if (isFirstRender && clients.length > 0 && filterApplyCallbackRef.current) {
      filterApplyCallbackRef.current(1)
      setIsFirstRender(false)
      setPrefilteredClients(
        clients.filter((client) => client.data.active === 1)
      )
    }
  }, [clients])

  useEffect(() => {
    if (modalAction && modalAction === CLIENT_TABLE_ROW_ACTIONS.REACTIVATE) {
      gettingClients()
    }
  }, [modalAction])

  const gettingClients = async () => {
    setLoading(true)
    try {
      const response = await getClients()
      if (!response.success) {
        throw new Error(response.message)
      }
      setClients(response.result)
    } catch (error) {
      setErrorMessage({
        message: "Ha ocurrido un error al obtener los clientes.",
      })
    }
    setLoading(false)
  }

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          frozen
          header="Clientes / Proyectos"
          colSpan={2}
          sortable
          sortField="data.code"
        />
        <Column
          header="Presupuesto actual"
          sortable
          sortField="data.consumedTimePercentage"
        />
        <Column
          header="Estatus"
          filter
          filterField="data.active"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterElement={(options) =>
            statusFilterElement({
              options,
              filterApplyCallbackRef,
              onChange: (e) => setFilter(e.value),
            })
          }
          filterMatchMode="equals"
          onFilterClear={() => setFilter(null)}
          onFilterApplyClick={() => {
            setDatatableFilter({
              "data.active": {
                value: filter,
                matchMode: "equals",
              },
            })
          }}
        />
        <Column style={{ maxWidth: "30px", width: "30px" }} />
      </Row>
    </ColumnGroup>
  )

  const getProjectsAndClientsFromRowEvent = (node) => {
    let clientId = node?.data?.clientId
    let projectsId = [node?.data?.projectId]
    if (projectsId && !clientId) {
      // Is client
      clientId = clients.find((client) =>
        client.children.find(
          (project) => project.data.projectId === projectsId[0]
        )
      )?.data.clientId
    } else if (!projectsId[0] && clientId) {
      projectsId = clients
        .find((client) => client.data.clientId === clientId)
        ?.children?.map((project) => project.data.projectId)
    }
    return { clientId, projectsId }
  }

  const canInteractWithElement = (user, projects) => {
    return (
      user.isAdmin() ||
      (user.isTeamLeader() &&
        user.leaderProjects.some((projectId) => projects.includes(projectId)))
    )
  }

  return (
    <Card id="clients-table" className="p-1 shadow-none">
      <TreeTable
        key={clients}
        value={isFirstRender && !loading ? prefilteredClients : clients}
        filters={datatableFilter}
        expandedKeys={expandedKeys}
        onToggle={(e) => setExpandedKeys(e.value)}
        removableSort
        headerColumnGroup={headerGroup}
        expanderConditions={(node) => {
          const isParent = node?.children?.length > 1
          if (filter === null) {
            return isParent
          } else if (filter === 0) {
            return (
              isParent &&
              node?.children?.some((project) => project.data.active === 0)
            )
          } else {
            return (
              isParent &&
              node?.children?.some((project) => project.data.active === 1)
            )
          }
        }}
        onContextMenu={({ originalEvent, data: node }) => {
          const { projectsId } = getProjectsAndClientsFromRowEvent(node)
          const isOptionEnabled = canInteractWithElement(user, projectsId)
          if (!isOptionEnabled) {
            cmRef?.current.hide()
            return
          }
          cmRef?.current.show(originalEvent)
          setSelectedRow({
            ...node,
            id: node?.data?.clientId || node?.data?.projectId,
            type:
              node?.data?.projectId ?
                CLIENT_TABLE_ROW_TYPE.PROJECT
              : CLIENT_TABLE_ROW_TYPE.CLIENT,
          })
        }}
        loading={loading}
        rowClassName={(node) => {
          const { projectsId } = getProjectsAndClientsFromRowEvent(node)
          const isOptionEnabled = canInteractWithElement(user, projectsId)
          return !isOptionEnabled ? "opacity-50" : ""
        }}
        loadingConfig={{
          qtyOfRows: 5,
        }}
        scrollHeight="calc(100vh - 170px)"
        scrollable
        style={{ tableLayout: "fixed" }}
        frozenExpandedColumn
      >
        <Column
          frozen
          field="data.code"
          body={(node) => {
            const isClient = node?.children?.length
            if (isClient) {
              return (
                <div className="flex gap-2 align-items-center">
                  <ClientChip className="w-fit" clientCode={node?.data?.code} />
                  <span className="font-bold">{node?.data?.name}</span>
                </div>
              )
            }
            return (
              <div className="flex gap-2 align-items-center">
                <ProjectChip
                  className="w-fit"
                  clientCode={node?.data?.clientCode}
                  projectCode={node?.data?.code}
                />
                <span>{node?.data?.name?.split("-")[1]}</span>
              </div>
            )
          }}
          expander
          sortable
          style={{ width: "58%" }}
        />
        <Column
          field="data.consumedBagBudget"
          sortable
          colSpan={1}
          style={{ width: "18%" }}
          body={(rowData) => {
            const data = rowData.data
            if (data?.projectId) return
            if (!data?.isBillable) {
              return <span className="opacity-30">N/A</span>
            }
            return (
              <ReducedBudgetPhase
                budgetPercentageProps={data}
                phaseInfoProps={data}
                showNoBudgetWithHours
              />
            )
          }}
        />
        <Column
          field="data.active"
          body={(node) => (node?.data?.active ? "Activo" : "Inactivo")}
          filterMatchMode="equals"
          colSpan={1}
          style={{ width: "18%" }}
        />
        <Column
          style={{ maxWidth: "30px", padding: 0 }}
          body={(node) => {
            const { projectsId } = getProjectsAndClientsFromRowEvent(node)
            const isOptionEnabled = canInteractWithElement(user, projectsId)
            if (!isOptionEnabled) return
            return (
              <i
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                  cmRef?.current?.show(event)
                  setSelectedRow({
                    ...node,
                    id: node?.data?.clientId || node?.data?.projectId,
                    type:
                      node?.data?.projectId ?
                        CLIENT_TABLE_ROW_TYPE.PROJECT
                      : CLIENT_TABLE_ROW_TYPE.CLIENT,
                    // eslint-disable-next-line
                    isClientActive:
                      node?.data?.projectId ?
                        clients.find(
                          (client) => client.data.clientId === node?.parentKey
                        )?.data?.active
                      : node?.data?.active,
                  })
                }}
              />
            )
          }}
        />
      </TreeTable>
      <ClientsTableContextMenu
        selectedRow={selectedRow}
        ref={cmRef}
        setOpenModal={setOpenModal}
        setModalAction={setModalAction}
        onEdit={() =>
          navigate("/administracion/clientes/editar", {
            state: { clientId: selectedRow?.data?.clientId, ...selectedRow },
          })
        }
      />
      <ActionModal
        row={selectedRow}
        visible={openModal}
        setVisible={setOpenModal}
        modalAction={modalAction}
        onFinishedSubmit={() => {
          setSelectedRow(null)
          setPrefilteredClients([])
          setLoading(true)
          gettingClients()
        }}
      />
    </Card>
  )
}

export default ClientsTable
