import React, { useState, useRef } from "react"
import ModalForm from "../../../../../components/compounds/modalForm/ModalForm.compunds"
import FormContainer from "../../../../../components/layouts/containers/FormContainer.container"
import InputTextForm from "../../../../../components/molecules/InputTextForm.molecule"
import FieldContainer from "../../../../../components/layouts/containers/FieldContainer.container"
import { KEYBOARD_ACTIONS } from "../../../../../consts/keyBoard.consts"

const TimeDifferenceModal = ({
  visible,
  setVisible,
  service,
  postSubmit,
  onChange,
  // error,
  value,
}) => {
  const [error, setError] = useState([])
  const inputTaskNameRef = useRef(null)

  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      headerIconClass="pi pi-info-circle"
      headerTitle="Diferencia de horas cliente"
      service={() => {
        if (!value) {
          setError([
            {
              errorMessage: "Este campo es requerido",
            },
          ])
          return null
        } else {
          setError([])
        }
        setVisible(false)
        return service(value)
      }}
      postSubmit={postSubmit}
    >
      <span>
        Tu tarea tiene una diferencia de horas cliente, nos ayudaría mucho saber
        el motivo de esta variación.
      </span>
      <FormContainer>
        <FieldContainer className="m-0 mt-3" md={12}>
          <InputTextForm
            className="time-difference-modal__text-input"
            label="Describe el motivo"
            value={value}
            ref={inputTaskNameRef}
            onChange={onChange}
            onKeyDown={(e) => {
              if (e.key === KEYBOARD_ACTIONS.ENTER) {
                inputTaskNameRef?.current?.blur()
                if (!value) {
                  setError([
                    {
                      errorMessage: "Este campo es requerido",
                    },
                  ])
                } else {
                  setError([])
                  setVisible(false)
                  return service(value)
                }
              }
            }}
            error={error}
          />
        </FieldContainer>
      </FormContainer>
    </ModalForm>
  )
}

export default TimeDifferenceModal
