import React from "react"
import Chip from "../../../components/atoms/misc/Chip.atom"
import Tooltip from "../../../components/atoms/Tooltip.atom"
import { generateRandomNumber } from "../../../utils/numbers.utils"
import PropTypes from "prop-types"
import LabelImportantSharpIcon from "@mui/icons-material/LabelImportantSharp"

const TendencyChip = ({ severity, position = undefined }) => {
  const id = generateRandomNumber()
  const defaultPosition = "bottom"
  let icon = ""
  let background = ""
  let tooltip = ""
  switch (severity) {
    case "success":
      icon = "pi pi-arrow-up"
      background = "bg-success"
      tooltip = "Aumento de presupuesto"
      break
    case "danger":
      icon = "pi pi-arrow-down"
      background = "bg-danger"
      tooltip = "Reducción de presupuesto"
      break
    case "info":
      icon = (
        <LabelImportantSharpIcon
          key={id}
          style={{
            color: "white",
            fontSize: "10.5px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        />
      )
      background = "bg-info"
      tooltip = "Registro de consumo mensual"
      break
    case "warning":
      icon = "pi pi-pencil"
      background = "bg-warning"
      tooltip = "Cambio de configuración"
      break
    default:
      break
  }
  const isIconString = typeof icon === "string" || icon instanceof String
  return (
    <>
      <Tooltip
        target={`.icon-tooltip-${id}`}
        position={position || defaultPosition}
        content={tooltip}
      />
      <Chip
        className={`icon-tooltip-${id} tendency-chip ${background} flex align-items-center`}
        icon={isIconString ? `text-white ${icon}` : icon}
      />
    </>
  )
}

export default TendencyChip

TendencyChip.propTypes = {
  severity: PropTypes.oneOf(["success", "danger", "info", "warning"])
    .isRequired,
}
