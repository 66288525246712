import React from "react"
import PropTypes from "prop-types"
import InputNumber from "../atoms/InputNumber.atom"
import { hasAnyErrors } from "../../utils/validations.utils"
import ErrorMessageForm from "../atoms/ErrorMessageForm.atom"
import Label from "../atoms/Label.atom"
import ViewOutputForm from "../atoms/ViewOutputForm.atom"
export default function InputNumberForm(props) {
  const forId = `input-number-${props.label?.replace(/ /g, "-")}`
  const errorClass = hasAnyErrors(props.error) ? "p-invalid" : ""
  const grouping = props.useGrouping !== undefined ? props.useGrouping : true
  return (
    <>
      <Label htmlFor={forId}>
        {props.label}
        {props.require ? " *" : ""}
        {props.optional ? " (opcional)" : ""}
      </Label>
      {props.viewOnly ?
        <ViewOutputForm value={props.value} suffix={props.suffix} />
      : <InputNumber
          inputId={forId}
          disabled={props.disabled}
          name={props.name}
          value={props.value}
          suffix={props.suffix ? ` ${props.suffix.trim()}` : false}
          useGrouping={grouping}
          max={props.max}
          onChange={props.onChange}
          min={props.min || 0}
          placeholder={props.placeholder}
          className={`flex-1 custom-inputnumber ${errorClass} ${props.className ? props.className : ""}`}
          minFractionDigits={props.minFractionDigits}
          maxFractionDigits={props.maxFractionDigits}
          showButtons={props.showButtons}
          step={props.step}
          allowEmpty={props.allowEmpty || false}
        />
      }
      {hasAnyErrors(props.error) && !props.withoutErrorMessage ?
        <ErrorMessageForm errorMessage={props.error[0]?.errorMessage} />
      : null}
    </>
  )
}

InputNumberForm.propTypes = {
  value: function ({ value }) {
    if (typeof value !== "number" && value !== null) {
      return new Error(
        "Invalid prop value. Expected a number or null on InputNumberForm component"
      )
    }
  },
  suffix: PropTypes.string,
  minFractionDigits: PropTypes.number,
  maxFractionDigits: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.array,
  min: PropTypes.number,
  disabled: PropTypes.bool,
}
