import { DateTime } from 'luxon'
import _ from 'lodash'

const validateSchema = (data, arrayProperties, options) => {
  if (!data) return false
  let result = true
  arrayProperties.forEach(el => {
    if (!data[el]) {
      if (options?.withNulls && data[el] === null) return
      result = false
    }
  })
  return result
}

const validateSchemaItemsArray = (arrayData, arrayProperties) => {
  let result = true
  arrayData.forEach(data => {
    const resultValidation = validateSchema(data, arrayProperties)
    if (!resultValidation) result = false
  })
  return result
}

const isNotEmpty = (value, options) => {
  const errorMessage = 'Este campo no puede estar vacío'
  const trimValue = value?.toString().trim()
  const resultValue = !(trimValue === '' || trimValue === null || trimValue === undefined)
  return { result: resultValue, errorMessage }
}

export const isDateNotGreaterThanToday = (value, options) => {
  const errorMessage = 'Esta fecha no debe de ser mayor a hoy'
  let dateValue = value
  if (!value.isValid) {
    dateValue = DateTime.fromJSDate(value)
  }
  const dateNow = DateTime.now().setZone('America/Mexico_city')
  const newDateNow = dateNow.set({ hour: 23, minute: 59, second: 59 })
  const resultValue = dateValue.ts < newDateNow
  return { result: resultValue, errorMessage }
}

export const ERROR_MESSAGE_GREATER_THAN = 'Este campo debe de ser mayor a {0}'
export const isANumberGreaterThan = (value, options) => {
  const errorMessage = ERROR_MESSAGE_GREATER_THAN.replace('{0}', options.numberToCompare)
  const number = Number(value)
  return { result: number > options.numberToCompare, errorMessage }
}

export const isANumberLowerThan = (value, options) => {
  const errorMessage = `Este campo debe de ser menor a ${options.numberToCompare}`
  const number = Number(value)
  return { result: number < options.numberToCompare, errorMessage }
}

export const isANumberGreaterThanZero = (value, options) => {
  const errorMessage = 'Este campo debe de ser mayor a cero'
  const number = Number(value)
  return { result: number > 0, errorMessage }
}

export const isANumberGreaterThanOrEqualZero = (value, options) => {
  const errorMessage = 'Este campo debe de ser mayor o igual a cero'
  const number = Number(value)
  return { result: number >= 0, errorMessage }
}

/* Valida un numero en un rango, puede ser exacto o no.
Acepta estos intervalos: [value1,value2] (value1,value2], [value1,value2), (value1,value2). El options es un objeto que contiene rangeMin y rangeMax y cada uno contiene un value y un exact */
export const isANumberInARangeOf = (value, options) => {
  let errorMessage
  const number = Number(value)
  const rangeMinNumber = Number(options.rangeMin.value)
  const rangeMaxNumber = Number(options.rangeMax.value)
  let valid
  if (options.rangeMax.exact && !options.rangeMin.exact) {
    errorMessage = `Este campo debe ser mayor a ${rangeMinNumber} y menor o igual a ${rangeMaxNumber}`
    valid = (number > rangeMinNumber) && (number <= rangeMaxNumber)
  } else if (options.rangeMin.exact && !options.rangeMax.exact) {
    errorMessage = `Este campo debe ser mayor o igual a ${rangeMinNumber} y menor a ${rangeMaxNumber}`
    valid = (number >= rangeMinNumber) && (number < rangeMaxNumber)
  } else if (options.rangeMax.exact && options.rangeMin.exact) {
    errorMessage = `Este campo debe ser mayor o igual a ${rangeMinNumber} y menor o igual a ${rangeMaxNumber}`
    valid = (number >= rangeMinNumber) && (number <= rangeMaxNumber)
  } else {
    errorMessage = `Este campo debe ser mayor  a ${rangeMinNumber} y menor  a ${rangeMaxNumber}`
    valid = (number > rangeMinNumber) && (number < rangeMaxNumber)
  }

  return { result: valid, errorMessage }
}

export const isAnEmail = (value, options) => {
  const errorMessage = 'Este campo no es un correo válido'
  const trimValue = value?.toString().trim()
  const regexEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/g
  return { result: Boolean(trimValue?.match(regexEmail)), errorMessage }
}

const isNotMaxLength = (value, options) => {
  const errorMessage = `Este campo no puede tener más de ${options.isNotMaxLength.max} carácteres`
  const resultValue = value.length <= options.isNotMaxLength.max
  return { result: resultValue, errorMessage }
}

/** @type {import('./validations.utils.d.ts')["checkAllFields"]} */
const checkAllFields = (formValidationState, setFormValidationState, options) => {
  const escapeSpecificFields = options?.escapeSpecificFields
  let copyFormValidationState = formValidationState
  for (const field in copyFormValidationState) {
    if (escapeSpecificFields && escapeSpecificFields.includes(field)) continue
    copyFormValidationState = { ...validateFieldValue(copyFormValidationState, field, copyFormValidationState[field].value) }
  }
  setFormValidationState(copyFormValidationState)
}

/** @type {import('./validations.utils.d.ts')["setFieldTypeValidations"]} */
const setFieldTypeValidations = (formValidationState, field, values) => {
  const copyFormValidationState = formValidationState
  const fieldState = { ...copyFormValidationState[field] }
  fieldState.typeValidations = values
  copyFormValidationState[field] = fieldState
  return copyFormValidationState
}

/** @type {import('./validations.utils.d.ts')["removeFieldTypeValidations"]} */
const removeFieldTypeValidations = (formValidationState, field) => {
  const copyFormValidationState = formValidationState
  const fieldState = { ...copyFormValidationState[field] }
  fieldState.typeValidations = []
  copyFormValidationState[field] = fieldState
  return copyFormValidationState
}

/** @type {import('./validations.utils.d.ts')["checkAllFieldsBreakdownTable"]} */
const checkAllFieldsBreakdownTable = (formValidationState, setFormValidationState, options) => {
  const escapeSpecificFields = options?.escapeSpecificFields
  const validationsEntries = Object.entries(formValidationState)
  const data = validationsEntries.reduce((acc, [key, value]) => {
    for (const column in value) {
      if (escapeSpecificFields) {
        const fieldToEscape = escapeSpecificFields.find(field => field.rowValue === key && field.columnValue === column)
        if (fieldToEscape) continue
      }

      acc[key] = { ...validateFieldValue(value, column, value[column].value) }
    }
    return acc
  }, {})
  setFormValidationState(data)
}

/** @type {import('./validations.utils.d.ts')["validateFieldValue"]} */
const validateFieldValue = (formValidationState, fieldName, fieldValue) => {
  const copyFormValidationState = formValidationState
  const validationFieldController = copyFormValidationState[fieldName]
  validationFieldController.typeValidations.forEach((validation) => {
    const resultValidation = validation(fieldValue, validationFieldController.validationOptions)
    if (resultValidation.result) {
      validationFieldController.errors = removeError(validationFieldController.errors, validation.toString())
    } else {
      validationFieldController.errors = addError(
        validationFieldController.errors,
        validation.toString(),
        resultValidation.errorMessage
      )
    }
  })

  copyFormValidationState[fieldName] = validationFieldController
  copyFormValidationState[fieldName].value = fieldValue
  return copyFormValidationState
}

/** @type {import('./validations.utils.d.ts')["resetField"]} */
const resetField = (formValidationState, fieldName) => {
  const copyFormValidationState = formValidationState
  copyFormValidationState[fieldName].errors = []
  copyFormValidationState[fieldName].value = null
  return copyFormValidationState
}

const resetFieldErrors = (formValidationState, fieldName) => {
  const copyFormValidationState = formValidationState
  copyFormValidationState[fieldName].errors = []
  return copyFormValidationState
}

const hasAnyErrors = (errors) => {
  if (errors) {
    return errors.length > 0
  }
  return false
}

const removeError = (arrayErrors, errorName) => {
  return arrayErrors.filter((error) => error.errorName !== errorName)
}

export const addError = (arrayErrors, errorName, errorMessage) => {
  if (arrayErrors.find((error) => error.errorName === errorName)) {
    return [...arrayErrors]
  }
  return [...arrayErrors, { errorName, errorMessage }]
}

const fieldsDoesNotHaveErrors = (validations, options) => {
  const escapeSpecificFields = options?.escapeSpecificFields
  return Object.entries(validations).every(([field, validation]) => {
    if (escapeSpecificFields && escapeSpecificFields.includes(field)) return true
    return hasAnyErrors(validation.errors) === false
  })
}

export const fieldsHaveErrors = (validations, options) => {
  return !fieldsDoesNotHaveErrors(validations, options)
}

const fieldsBreakdownTableDoesNotHaveErrors = (validations) => {
  let result = true
  const fieldsValidations = Object.values(validations)
  fieldsValidations.forEach((validationsField) => {
    const checkColumns = Object.values(validationsField)
      .every((validation) => (hasAnyErrors(validation.errors)) === false)
    if (!checkColumns) result = false
  })
  return result
}

export {
  validateSchema,
  validateSchemaItemsArray,
  isNotEmpty,
  checkAllFields,
  validateFieldValue,
  isNotMaxLength,
  hasAnyErrors,
  fieldsDoesNotHaveErrors,
  checkAllFieldsBreakdownTable,
  fieldsBreakdownTableDoesNotHaveErrors,
  setFieldTypeValidations,
  removeFieldTypeValidations,
  resetField,
  resetFieldErrors
}
