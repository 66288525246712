import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalForm from './modalForm/ModalForm.compunds'
import FormContainer from '../layouts/containers/FormContainer.container'
import FieldContainer from '../layouts/containers/FieldContainer.container'
import InputCalendarForm from '../molecules/InputCalendarForm.molecule'
import {
  validateFieldValue,
  fieldsHaveErrors,
  checkAllFields,
  resetFieldErrors,
  addError
} from '../../utils/validations.utils'
import { ADD_BUDGET_FORM_DATA_TEMPLATE, ADD_BUDGET_FORM_INITIAL_STATE, BUDGET_REGISTRY_TYPE, CREATE_BUDGET_MOVEMENT_ERROR_MESSAGE } from '../../consts/modules/budget.consts'
import { RANGES } from '../../consts/CompoundRangeDatePicker'
import { DateTime } from 'luxon'
import InputTextForm from '../molecules/InputTextForm.molecule'
import IconWithTooltip from '../molecules/IconWithTooltip.molecule'
import DropdownInput from '../molecules/DropdownInput.molecule'
import TendencyChip from '../../views/clientBudget/components/TendencyChip'
import InputNumberForm from '../molecules/InputNumberForm.molecule'
import SimpleMessage from '../atoms/SimpleMessage.atom'
import { useLocation } from 'react-router-dom'
import { createBudgetMovementToClientID } from '../../services/budget.service'
import useEffectAfterMount from '../../hooks/useEffectAfterMount.hook'
import _ from 'lodash'
import useToast from '../../hooks/useToast.hook'

const ModalFormAddBudget = ({
  visible,
  setVisible,
  selectedClientID,
  currentBudget,
  onCreateCompleted = () => { }
}) => {
  const [data, setData] = useState(_.cloneDeep(ADD_BUDGET_FORM_DATA_TEMPLATE))
  const [validations, setValidations] = useState(_.cloneDeep(ADD_BUDGET_FORM_INITIAL_STATE))
  const { setErrorMessage } = useToast()

  useEffectAfterMount(() => {
    if (!visible) {
      resetFormState()
    }
  }, [visible])

  useEffectAfterMount(() => {
    if (visible) {
      handleChangeQuantity(data.quantity)
    }
  }, [data.registryType])

  const registryTypeOptions = [
    { label: 'Aumento', value: BUDGET_REGISTRY_TYPE.AUMENTO },
    { label: 'Reducción', value: BUDGET_REGISTRY_TYPE.REDUCCION }
  ]

  const ModalHeaderTemplate = ({
    title = 'Agregar registro',
    iconClassName = 'pi pi-plus'
  }) => {
    return (
      <div className="flex gap-3 align-items-center">
        <i className={`${iconClassName} text-4xl`} style={{ color: '#2896D8' }} />
        <span className="text-3xl font-semibold">{title}</span>
      </div>
    )
  }

  const handleSubmit = async () => {
    checkAllFields(validations, setValidations)
    if (fieldsHaveErrors(validations)) {
      return
    }
    try {
      const reponse = await createBudgetMovementToClientID(selectedClientID, {
        ...data,
        authorizationDate: data.authorizationDate.toISODate(),
        quantity: data.quantity * (data.registryType === BUDGET_REGISTRY_TYPE.AUMENTO ? 1 : -1)
      })
      if (!reponse.success) {
        throw new Error()
      }
      setVisible(false)
      resetFormState()
      onCreateCompleted()
    } catch (error) {
      setErrorMessage(CREATE_BUDGET_MOVEMENT_ERROR_MESSAGE)
    }
  }

  const resetFormState = () => {
    setData(_.cloneDeep(ADD_BUDGET_FORM_DATA_TEMPLATE))
    setValidations(_.cloneDeep(ADD_BUDGET_FORM_INITIAL_STATE))
  }

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value })
    setValidations(validateFieldValue(validations, name, value))
  }

  const registryTypeItemTemplate = (option) => {
    if (!option) return 'Selecciona un tipo'
    return (
      <div className='flex gap-2 align-items-center'>
        <TendencyChip
          severity={option.value === 0 ? 'success' : 'danger'}
        />
        {option.label}
      </div>
    )
  }

  const messageTemplate = () => (
    <div className='flex px-2 py-1 w-full gap-3'>
      <div>
        <i className="pi pi-paperclip clr-info text-2xl"></i>
      </div>
      <div className='flex flex-column gap-2'>
        <span className='text-xl font-semibold'>Tómate un momento para pensarlo</span>
        <span>Recuerda que una vez agregado, no podrás editar ni eliminar este registro del presupuesto.</span>
      </div>
    </div>
  )

  const handleChangeQuantity = (quantity) => {
    resetFieldErrors(validations, 'quantity')
    if (quantity > currentBudget && data.registryType === BUDGET_REGISTRY_TYPE.REDUCCION) {
      setValidations({
        ...validations,
        quantity: {
          ...validations.quantity,
          errors: addError(validations.quantity.errors, 'quantity', 'No puedes dejar el presupuesto en negativo.')
        }
      })
    } else {
      handleChange({ target: { name: 'quantity', value: quantity } })
      setValidations(validateFieldValue(validations, 'quantity', quantity))
    }
  }
  return (
    <ModalForm
      visible={visible}
      setVisible={setVisible}
      header={<ModalHeaderTemplate />}
      service={handleSubmit}
      disabledSaveBtn={fieldsHaveErrors(validations)}
      saveLabel='Agregar registro'
      className='add-budget-modal'
      cleanUp={resetFormState}
    >
      <SimpleMessage
        severity='info'
        className='mb-3'
        content={messageTemplate}
        // style={{
        //   background: 'rgba(0, 159, 255, 0.10)'
        // }}
      />
      <FormContainer className='add-budget-form'>
        <FieldContainer md={12}>
          <InputTextForm
            label='Motivo'
            name='description'
            placeholder='¿Cuál es la razón de este registro?'
            value={data.description}
            onChange={handleChange}
            error={validations.description.errors}
            onBlur={() => {
              handleChange({ target: { name: 'description', value: data.description?.trim() } })
            }}
          />
        </FieldContainer>
        <FieldContainer md={12}>
          <InputCalendarForm
            label='Fecha de autorización'
            labelTemplate={
              <div className='flex gap-1 align-items-center'>
                <span>Fecha de autorización</span>
                <IconWithTooltip
                  iconClassName='pi pi-exclamation-circle'
                  tooltipValue='Fecha en la que se realizó el acuerdo con el cliente, no afecta la vigencia del presupuesto.'
                />
              </div>
            }
            name='authorizationDate'
            placeholder='Selecciona una fecha'
            value={data.authorizationDate}
            period={RANGES.DAY}
            inputReadOnly
            allowClear={false}
            format='DD/MM/YYYY'
            onChange={(authorizationDate) => {
              handleChange({ target: { name: 'authorizationDate', value: authorizationDate } })
            }}
            maxDate={DateTime.local().endOf('day')}
            error={validations.authorizationDate.errors}
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <DropdownInput
            placeholder="Selecciona un tipo"
            label="Tipo de registro"
            name="registryType"
            options={registryTypeOptions}
            optionLabel="label"
            optionValue="value"
            value={data.registryType}
            error={validations.registryType.errors}
            onChange={(e) => {
              handleChange({ target: { name: 'registryType', value: e.value } })
            }}
            itemTemplate={registryTypeItemTemplate}
            valueTemplate={registryTypeItemTemplate}
          />
        </FieldContainer>
        <FieldContainer md={6}>
          <InputNumberForm
            label='Horas'
            className='w-full relative input-time'
            name='quantity'
            value={data.quantity}
            onChange={(e) => {
              handleChangeQuantity(e.value)
            }}
            error={validations.quantity.errors}
            min={0}
            step={0.25}
            format={true}
            showButtons
            minFractionDigits={0}
            maxFractionDigits={2}
          />
        </FieldContainer>
      </FormContainer>
    </ModalForm>
  )
}

export default ModalFormAddBudget

ModalFormAddBudget.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  selectedClientID: PropTypes.number,
  currentBudget: PropTypes.number,
  onCreateCompleted: PropTypes.func
}
